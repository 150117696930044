import { createStyles, WithStyles, withStyles }
    from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import * as React from 'react';
import Sys from '../core/Sys';
import Button from '../coreui/Button';
import Icon from '../coreui/Icon';
import Link from '../coreui/Link';
import Menu, { Menu as MenuBase } from '../coreui/Menu';
import MenuItem from '../coreui/MenuItem';
import { CustomTheme } from '../muiTheme';
import BaseService from '../services/BaseService';
import { MenuButton } from './MenuButton';
import { MenuItemProps } from './MenuItem';
import { ToolbarOverflowChild } from './ToolbarContainerOverflowSection';

interface ConfigProperties
{
    dataId: string;
    name: string;
    propagated: { indent?: number } & ToolbarOverflowChild;
}

interface State
{
    rootRef?: HTMLDivElement;
}

const styles = (theme: CustomTheme) => createStyles(
    {
        largeVersion:
        {
            [theme.breakpoints.down('md')]:
            {
                display: 'none',
            },
        },
        smallVersion:
        {
            display: 'none',
            [theme.breakpoints.down('md')]:
            {
                display: 'inline-block',
            },
        },
    });

@observer
export class LanguageSelect extends
    React.Component<ConfigProperties & WithStyles<typeof styles>, State>
{
    private currentLanguageCode: string;
    private menuName: string;

    private static changeLanguage = (languageCode: string) =>
    {
        BaseService.request(
            `LanguageSelect/OnChange/${languageCode}`
        ).then(() =>
        {
            Sys.clearRequestCache();
        });
    };

    public static renderMenuItem(props: MenuItemProps): JSX.Element
    {
        const configProps = props.config as ConfigProperties;

        const indent: number = configProps.propagated
            && configProps.propagated.indent ? configProps.propagated.indent : 0;
        const gridSize: number = Sys.settings.baselineGridSize;

        return (
            <MenuButton.renderFlatMenu
                header={Sys.getTranslation('Language')}
                iconName={configProps.propagated.hideIcons
                    ? undefined : 'fas fa-language'}
                indent={indent}
                isDisabled={false}
            >
                {Sys.settings.availableLanguages.map(l => (
                    <MenuItem
                        children={l.description}
                        indent={indent + gridSize * 6}
                        key={l.code}
                        onClick={
                            () => LanguageSelect.changeLanguage(l.code)}
                    />
                ))}
            </MenuButton.renderFlatMenu>);
    }

    public constructor(props: ConfigProperties & WithStyles<typeof styles>)
    {
        super(props);

        this.state = {};

        this.currentLanguageCode = Sys.settings.currentLanguageCode;
        this.menuName = `${props.dataId}.${props.name}`;
    }

    private openMenu = () =>
    {
        MenuBase.open(this.menuName, this.state.rootRef);
    };

    private setRootRef(ref: HTMLDivElement | null)
    {
        if (ref && ref !== this.state.rootRef)
        {
            this.setState({ rootRef: ref });
        }
    }

    public render()
    {
        const languages = Sys.settings.availableLanguages;

        const currentLanuage = languages.find(
            l => l.code === this.currentLanguageCode);
        const ariaLabel: string = Sys.getTranslation('Change language');

        return (
            <div ref={r => this.setRootRef(r)}>
                <div className={this.props.classes.largeVersion}>
                    <Link
                        aria-haspopup={true}
                        aria-label={ariaLabel}
                        onClick={this.openMenu}
                        tabIndex={-1}
                    >
                        <Icon
                            icon="fas fa-language"
                            style={{ fontSize: '24px', marginRight: '.4em' }}
                        />
                        {currentLanuage!.description}
                        <Icon
                            icon="fas fa-caret-down"
                            style={{ marginLeft: '.4em' }}
                        />
                    </Link>
                </div>
                <div className={this.props.classes.smallVersion}>
                    <Button
                        aria-haspopup={true}
                        aria-label={ariaLabel}
                        fab
                        onClick={this.openMenu}
                        size="large"
                        tabIndex={-1}
                    >
                        <Icon
                            icon="fas fa-language"
                        />
                    </Button>
                </div>
                <Menu
                    anchorEl={this.state.rootRef}
                    anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                    getContentAnchorEl={undefined}
                    open={false}
                    name={this.menuName}
                >
                    {languages.map(l => (
                        <MenuItem
                            children={l.description}
                            key={l.code}
                            onClick={
                                () => LanguageSelect.changeLanguage(l.code)}
                            selected={l.code === this.currentLanguageCode}
                        />
                    ))}
                </Menu>
            </div>);
    }
}

export default withStyles(styles)(LanguageSelect);
