import { createStyles, Theme, WithStyles, withStyles }
    from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import * as React from 'react';
import Sys from '../../core/Sys';
import Button from '../../coreui/Button';
import Dialog from '../../coreui/Dialog';
import DialogActions from '../../coreui/DialogActions';
import DialogContent from '../../coreui/DialogContent';
import Icon from '../../coreui/Icon';
import Typography from '../../coreui/Typography';
import Api from '../../mustangui/Api';
import Grid from '../../mustangui/Grid';
import GridItem from '../../mustangui/GridItem';
import ConfirmContinueStore from '../../stores/ConfirmContinueStore';

const styles = (theme: Theme) => createStyles(
    {
        root:
        {
        },
    });

@observer
export class ConfirmContinueDialog extends
    React.Component<WithStyles<typeof styles>>
{
    private cancel = () =>
    {
        ConfirmContinueStore.instance.closeDialog(false);
    };

    private continue = () =>
    {
        ConfirmContinueStore.instance.closeDialog(true);
    };

    private onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) =>
    {
        switch (event.key)
        {
            case 'Enter':
                if (!('nodeName' in event.target)
                    || event.target['nodeName'] !== 'BUTTON')
                {
                    this.continue();
                }
                break;
            case 'Escape':
                this.cancel();
                break;
            default:
        }
    };

    public render()
    {
        const isOpen: boolean = ConfirmContinueStore.instance.isDialogOpen;

        const message = Sys.getTranslation(
            'All of your unsaved changes will be lost. '
                + 'Are your sure you want to leave this page?',
            'ConfirmContinue');

        return (
            <Dialog
                fullScreen={false}
                onClose={this.cancel}
                onKeyDown={this.onKeyDown}
                open={isOpen}
                maxWidth="sm"
            >
                <DialogContent>
                    <Grid grouping="Closely Related" xs={1}>
                        <GridItem xl={1} lg={1} md={1} sm={1} xs={1}>
                            <Typography
                                variant="h3"
                                style={{ color: Api.getSystemColor('danger') }}
                            >
                                <div
                                    className="fa-layers"
                                    style={{ marginRight: '.4em' }}
                                >
                                    <Icon
                                        fixedWidth
                                        icon="fas fa-exclamation"
                                        style={{ fontSize: '.6em' }}
                                    />
                                    <Icon
                                        fixedWidth
                                        icon="far fa-octagon"
                                    />
                                </div>
                                {Sys.getTranslation(
                                    'Unsaved Changes',
                                    'ConfirmContinue')}
                            </Typography>
                        </GridItem>
                        <GridItem xl={1} lg={1} md={1} sm={1} xs={1}>
                            <Typography>
                                {message}
                            </Typography>
                        </GridItem>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button color="danger" onClick={this.continue}>
                        {Sys.getTranslation('Leave', 'ConfirmContinue')}
                    </Button>
                    <Button onClick={this.cancel} style={{ marginLeft: 40 }}>
                        {Sys.getTranslation('Cancel', 'ConfirmContinue')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(styles)(ConfirmContinueDialog);
