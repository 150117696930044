import * as muiGrow from '@material-ui/core/Grow';
import { createStyles, Theme, WithStyles, withStyles }
    from '@material-ui/core/styles';
import * as React from 'react';

interface Props extends muiGrow.GrowProps
{
}

const styles = (theme: Theme) => createStyles(
    {
        root:
        {
        },
    });

export class Grow extends
    React.PureComponent<Props & WithStyles<typeof styles>>
{
    public render()
    {
        const _props = { ...this.props };

        if (!('in' in _props))
        {
            _props.in = true;
        }

        return (
            <muiGrow.default
                {..._props}
            />);
    }
}

export default withStyles(styles)(Grow);
