import { createStyles, Theme, WithStyles, withStyles }
    from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import * as React from 'react';
import Sys from '../core/Sys';
import Button from './Button';
import MultilineTextFieldDialog from './MultilineTextFieldDialog';
import TextField, { TextFieldProps } from './TextField';

interface Props extends TextFieldProps
{
}

interface State
{
    isDialogOpen: boolean;
    isFocused: boolean;
    isOverflowing: boolean;
}

const styles = (theme: Theme) => createStyles(
    {
        displayButton:
        {
            position: 'absolute',
            right: 8,

            // Bottom includes the helper text so it is simpler to set the top
            // property such that the button ends up 8 pixels above the bottom
            // of the field. This assumes that the field will have a fixed
            // height.
            top: 100,
        },
        displayButtonReadOnly:
        {
            bottom: 0,
            position: 'absolute',
            right: 0,
        },
        inputElement:
        {
            height: '100%',
            overflow: 'hidden',
        },
        inputRoot:
        {
            // If this height is changed, the editButton.top value also needs
            // to change to ensure the edit button is always 8px off the bottom
            // of the field.
            height: 132,
        },
        readOnlyContent:
        {
            overflow: 'hidden',
        },
        readOnlyRoot:
        {
            maxHeight: 132,
        },
        root:
        {
            position: 'relative',
            width: '100%',
        },
    });

@observer
export class MultilineTextField extends
    React.Component<Props & WithStyles<typeof styles>, State>
{
    private rawField: HTMLElement | null;

    public constructor(props: Props & WithStyles<typeof styles>)
    {
        super(props);

        this.state =
        {
            isDialogOpen: false,
            isFocused: false,
            isOverflowing: false,
        };
    }

    private checkForOverflow()
    {
        const field = this.rawField!;

        this.setState(
            {
                isOverflowing: field.clientHeight < field.scrollHeight,
            });
    }

    private onBlur = (event: React.FocusEvent<HTMLDivElement>) =>
    {
        if (!event.currentTarget.contains(event.relatedTarget as Node)
            && event.currentTarget.contains(event.target as Node)
            && !this.state.isDialogOpen)
        {
            this.setState({ isFocused: false });
        }
    };

    private onDialogAccept = (value: string) =>
    {
        this.setState({ isDialogOpen: false });

        if (this.props.onValueChange)
        {
            this.props.onValueChange(value);
        }
    };

    private onDialogCancel = () =>
    {
        this.setState({ isDialogOpen: false });
    };

    private onExpandButtonClick = () =>
    {
        this.setState({ isDialogOpen: true });
    };

    private onTextFieldFocus = (
        event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    {
        this.setState({ isFocused: true });
    };

    private onWindowResize = () =>
    {
        this.checkForOverflow();
    };

    public componentDidMount()
    {
        this.checkForOverflow();
        window.addEventListener('resize', this.onWindowResize);
    }

    public componentDidUpdate(prevProps: Props, prevState: State)
    {
        if (prevProps.value !== this.props.value)
        {
            this.checkForOverflow();
        }
    }

    public componentWillUnmount()
    {
        window.removeEventListener('resize', this.onWindowResize);
    }

    public render()
    {
        const {
            classes,
            inputProps,
            InputProps,
            readOnly,
            readOnlyProps,
            ...otherProps
        } = this.props;

        let expandButton: React.ReactNode = null;
        if (this.state.isOverflowing || this.state.isFocused)
        {
            const className = readOnly
                ? classes.displayButtonReadOnly
                : classes.displayButton;

            const icon = this.state.isFocused && !readOnly
                ? 'fas fa-edit'
                : 'fas fa-ellipsis-h';

            expandButton = (
                <Button
                    aria-label={Sys.getTranslation('View content')}
                    className={className}
                    icon={icon}
                    onClick={this.onExpandButtonClick}
                    // Bug 2501 - iPad only: ML Text Edit popup window doesnt open
                    // Added onTouchStart event handler below. Because the button
                    // Is inside the textarea, AND requires focus on the MLText
                    // To be visible, it seems that the iPad handles the touch
                    // Event and gives focus to the textarea, which hijacks the
                    // Focus of the button click before it can trigger. This is
                    // The only button nested inside a textarea, that's why this
                    // Is the only place we need this workaround.
                    onTouchStart={this.onExpandButtonClick}
                    size="small"
                />);
        }

        if (readOnly)
        {
            otherProps.value = otherProps.value ? otherProps.value as string : '-';
        }

        return (
            <div className={classes.root} onBlur={this.onBlur}>
                <TextField
                    inputProps={{
                        ref: (r: HTMLElement) => this.rawField = r,
                        ...inputProps,
                    }}
                    InputProps={{
                        classes:
                        {
                            input: classes.inputElement,
                            root: classes.inputRoot,
                        },
                        ...InputProps,
                    }}
                    multiline={true}
                    onFocus={this.onTextFieldFocus}
                    readOnly={readOnly}
                    readOnlyProps={{
                        classes:
                        {
                            content: classes.readOnlyContent,
                            root: classes.readOnlyRoot,
                        },
                        contentRef: (r: HTMLDivElement) => this.rawField = r,
                        ...readOnlyProps,
                    }}
                    rows={1}
                    type="text"
                    {...otherProps}
                />
                {expandButton}
                <MultilineTextFieldDialog
                    getErrors={otherProps.getErrors}
                    helperText={otherProps.helperText}
                    label={otherProps.label}
                    onAccept={this.onDialogAccept}
                    onCancel={this.onDialogCancel}
                    open={this.state.isDialogOpen}
                    readOnly={readOnly}
                    value={otherProps.value as string}
                />
            </div>
        );
    }
}

export default withStyles(styles)(MultilineTextField);
