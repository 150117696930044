import { createStyles, Theme, WithStyles, withStyles }
    from '@material-ui/core/styles';
import { observer, useObserver } from 'mobx-react';
import * as React from 'react';
import RequestPromise from '../../core/RequestPromise';
import Sys from '../../core/Sys';
import { Drawer } from '../../coreui/Drawer';
import { Menu as MenuBase } from '../../coreui/Menu';
import MenuItem from '../../coreui/MenuItem';
import { TableChildProps } from '../../coreui/Table';
import PaneRow from '../../models/PaneRow';
import ActionButtonService, { OnRoundTripResponse }
    from '../../services/ActionButtonService';
import { ActionButton, ActionButtonProps, ActionButtonRuntimeProps }
    from '../ActionButton';
import Api, { AccessLevel } from '../Api';
import ApiButton from '../ApiButton';
import { CaptchaControl as CaptchaControlBase } from '../CaptchaControl';
import { MenuItemProps } from '../MenuItem';
import ConfirmationDialog from './ConfirmationDialog';

interface Props extends ActionButtonProps
{
}

interface State
{
    isConfirmDialogOpen?: boolean;
}

const styles = (theme: Theme) => createStyles(
    {
        root:
        {
        },
    });

@observer
export class CustomActionButton extends
    React.Component<Props & WithStyles<typeof styles>, State>
{
    private onClickPromise: RequestPromise<OnRoundTripResponse>;

    private static onClick(
        config: {
            dataId: string;
            iconName?: string;
            name: string;
            propagated?: TableChildProps;
        }
        ): RequestPromise<OnRoundTripResponse>
    {
        let selectedRowKeys: string[] | null = null;
        if (config.propagated && config.propagated.parentTable)
        {
            selectedRowKeys = [];

            if (config.propagated.parentRowKey)
            {
                selectedRowKeys.push(config.propagated.parentRowKey);
            }
            else
            {
                const gridApi = config.propagated.parentTable.getApi();

                const rows =
                    config.propagated.parentTable.selection.getSelectedRows();
                if (rows.length <= 0)
                {
                    gridApi.forEachNode(r => rows.push(r.data));
                    for (let i = 0; i < gridApi.getPinnedTopRowCount(); i++)
                    {
                        rows.push(gridApi.getPinnedTopRow(i).data);
                    }
                }

                for (const row of rows)
                {
                    selectedRowKeys!.push(row.rowKey);
                }
            }
        }

        return ActionButtonService.onRoundTrip(
            config,
            selectedRowKeys
        ).then((response) =>
        {
            const runtimeProperties =
                Api.getWidgetProperties(config) as ActionButtonRuntimeProps;

            if (response.saved && !response.url)
            {
                Sys.clearBusinessErrors();
                ActionButton.showSavedToast(config.iconName);
            }

            if (runtimeProperties.validateCaptcha)
            {
                CaptchaControlBase.reset();
            }

            return response;
        });
    }

    public static renderMenuItem(props: MenuItemProps): JSX.Element
    {
        const configProps = props.config as Props;
        const runtimeProps = props.runtime as ActionButtonRuntimeProps;

        const [isConfirmDialogOpen, setIsConfirmDialogOpen] =
            React.useState<boolean>(false);

        const onClick = () =>
        {
            if (runtimeProps.accessLevel >= AccessLevel.actionable)
            {
                if (runtimeProps.confirmMessage)
                {
                    setIsConfirmDialogOpen(true);
                }
                else
                {
                    CustomActionButton.onClick(configProps);
                    MenuBase.closeAll();
                    Drawer.closeAll();
                }
            }
        };

        const onAcceptConfirm = () =>
        {
            setIsConfirmDialogOpen(false);
            CustomActionButton.onClick(configProps);
            MenuBase.closeAll();
        };

        const onCancelConfirm = () =>
        {
            setIsConfirmDialogOpen(false);
        };

        return useObserver(() => (
            <React.Fragment>
                {runtimeProps.confirmMessage ? (
                    <ConfirmationDialog
                        cancelButtonText={runtimeProps.cancelButtonText}
                        continueButtonColor={configProps.buttonColor}
                        continueButtonIcon={configProps.iconName}
                        continueButtonText={runtimeProps.continueButtonText}
                        isOpen={isConfirmDialogOpen}
                        message={runtimeProps.confirmMessage}
                        onCancel={onCancelConfirm}
                        onContinue={onAcceptConfirm}
                        title={runtimeProps.label
                            ? runtimeProps.label : configProps.alternateText}
                    />
                ) : null}
                <MenuItem
                    disabled={runtimeProps.accessLevel === AccessLevel.disabled
                        || (props.runtime.validateCaptcha!
                        && !CaptchaControlBase.isValid())}
                    iconName={configProps.iconName}
                    indent={props.config.propagated
                        ? props.config.propagated.indent : 0}
                    onClick={onClick}
                >
                    {runtimeProps.label}
                </MenuItem>
            </React.Fragment>
        ));
    }

    public constructor(props: Props & WithStyles<typeof styles>)
    {
        super(props);

        this.state = { isConfirmDialogOpen: false };
    }

    private onAcceptConfirm = () =>
    {
        this.setState({ isConfirmDialogOpen: false });
        CustomActionButton.onClick(this.props);
    };

    private onCancelConfirm = () =>
    {
        this.setState({ isConfirmDialogOpen: false });
    };

    private onClick = () =>
    {
        const runtimeProperties = PaneRow.getWidgetProperties(
            this.props.dataId, this.props.name) as ActionButtonRuntimeProps;

        if (runtimeProperties.confirmMessage)
        {
            this.setState({ isConfirmDialogOpen: true });
        }
        else
        {
            this.onClickPromise = CustomActionButton.onClick(this.props);
        }
    };

    public componentWillUnmount()
    {
        if (this.onClickPromise)
        {
            this.onClickPromise.abort();
        }
    }

    public render()
    {
        const runtimeProperties = PaneRow.getWidgetProperties(
            this.props.dataId, this.props.name) as ActionButtonRuntimeProps;
        const disabled: boolean = runtimeProperties.validateCaptcha
            && !CaptchaControlBase.isValid();

        return (
            <React.Fragment>
                {runtimeProperties.confirmMessage ? (
                    <ConfirmationDialog
                        cancelButtonText={runtimeProperties.cancelButtonText}
                        continueButtonColor={this.props.buttonColor}
                        continueButtonIcon={this.props.iconName}
                        continueButtonText={
                            runtimeProperties.continueButtonText}
                        isOpen={this.state.isConfirmDialogOpen!}
                        message={runtimeProperties.confirmMessage}
                        onCancel={this.onCancelConfirm}
                        onContinue={this.onAcceptConfirm}
                        title={runtimeProperties.label
                            ? runtimeProperties.label : this.props.alternateText}
                    />
                ) : null}
                <ApiButton
                    alternateText={this.props.alternateText}
                    buttonColor={this.props.buttonColor}
                    dataId={this.props.dataId}
                    disabled={disabled}
                    disabledHelpText={this.props.disabledHelpText}
                    iconName={this.props.iconName}
                    label={runtimeProperties.label}
                    name={this.props.name}
                    onClick={this.onClick}
                    size={this.props.size}
                    tabIndex={this.props.propagated
                        && this.props.propagated.parentTable ? -1 : 0}
                />
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(CustomActionButton);
