import * as muiChip from '@material-ui/core/Chip';
import { createStyles, Theme, WithStyles, withStyles }
    from '@material-ui/core/styles';
import * as React from 'react';
import Sys from '../core/Sys';
import Chip from '../coreui/Chip';
import Api from './Api';

interface Props
{
}

const styles = (theme: Theme) => createStyles(
    {
        root:
        {
            backgroundColor: theme.palette.common.white,
            borderColor: Api.getSystemColor('success'),
            borderStyle: 'solid',
            borderWidth: 2,
            zIndex: 1,
        },
    });

export class GridNewRowChip extends
    React.PureComponent<Props & WithStyles<typeof styles>>
{
    protected muiProps: muiChip.ChipProps;

    public constructor(props: Props & WithStyles<typeof styles>)
    {
        super(props);

        this.muiProps =
        {
            classes: props.classes,
            label: Sys.getTranslation('New', 'DataTable'),
        };
    }

    public render()
    {
        return <Chip {...this.muiProps} />;
    }
}

export default withStyles(styles)(GridNewRowChip);
