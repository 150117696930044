import * as muiIconButton from '@material-ui/core/IconButton';
import { createStyles, Theme, WithStyles, withStyles }
    from '@material-ui/core/styles';
import * as React from 'react';
import Icon from './Icon';

interface Props extends muiIconButton.IconButtonProps
{
    disableFocusRipple?: boolean;
    icon?: string;
    small?: boolean;
}

const styles = (theme: Theme) => createStyles(
    {
        ripple:
        {
            '& > span > span':
            {
                backgroundColor: theme.palette.grey[800],
            },
            overflow: 'visible',
        },
        root:
        {
            color: theme.palette.text.primary,
        },
        small:
        {
            fontSize: 12,
            height: 24,
            width: 24,
        },
    });

export class IconButton extends
    React.PureComponent<Props & WithStyles<typeof styles>>
{
    public render()
    {
        const _props = { ...this.props };
        const icon = _props.icon;

        // Required to suppress the warning:
        // the key `root` provided to the classes property is not
        // implemented in IconButton.
        _props.className = `${_props.classes.root} `;
        _props.TouchRippleProps = { className: _props.classes.ripple };

        if ('small' in _props)
        {
            if (_props.small)
            {
                _props.className += `${_props.classes.small} `;
            }

            delete _props.small;
        }

        if ('icon' in _props)
        {
            delete _props.icon;
        }

        if (!('color' in _props))
        {
            _props.color = 'inherit';
        }

        if (_props.disableFocusRipple)
        {
            _props.focusRipple = false;
        }
        delete _props.disableFocusRipple;

        delete _props.classes;

        return (
            <muiIconButton.default {..._props}>
                <Icon {...{ icon }} />
            </muiIconButton.default>);
    }
}

export default withStyles(styles)(IconButton);
