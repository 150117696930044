import { observer } from 'mobx-react';
import * as React from 'react';
import FormData from '../coreui/FormData';
import Link from '../coreui/Link';
import Api, { AccessLevel } from './Api';

interface ConfigProperties
{
    dataId: string;
    label: string;
    name: string;
    presentationId?: number;
}

interface RuntimeProperties
{
    accessLevel: AccessLevel;
    anchorText: string;
    objectId: number;
    presentationId?: number;
}

@observer
export class DataLink extends
    React.Component<ConfigProperties>
{
    public render()
    {
        const _props = { ...this.props };
        const runtimeProperties =
            Api.getWidgetProperties(_props) as RuntimeProperties;
        let result: React.ReactNode = null;

        if (!runtimeProperties)
        {
            return null;
        }

        let url = `#/object/${runtimeProperties.objectId}/`;
        if (_props.presentationId)
        {
            url += _props.presentationId;
        }
        else
        {
            // Used when in a data grid vertical layout for DataLinkColumns
            url += runtimeProperties.presentationId;
        }

        if (Api.setAccessLevel(_props, runtimeProperties))
        {
            if (!_props['readOnly'])
            {
                _props.children =
                    <Link
                        children={runtimeProperties.anchorText}
                        href={url}
                        style={{ height: 20, minHeight: 20 }}
                        target="_self"
                    />;
            }
            else
            {
                _props.children = runtimeProperties.anchorText;
            }

            result = <FormData {..._props} />;
        }

        return result;
    }
}

export default DataLink;
