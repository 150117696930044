import { createStyles, Theme, WithStyles, withStyles }
    from '@material-ui/core/styles';
import { autorun, Lambda } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import Sys from '../core/Sys';
import ComboBoxOption from '../coreui/ComboBoxOption';
import { Paper as PaperBase } from '../coreui/Paper';
import Presentation from '../coreui/Presentation';
import { TableVerticalLayoutProps } from '../coreui/Table';
import PaneRow from '../models/PaneRow';
import RoundTripService from '../services/RoundTripService';
import { AccessLevel } from './Api';
import ApiSelect from './ApiSelect';

interface Props
{
    controlledGroupName: string;
    controlledPaneKeysByValue: object | null;
    dataId: string;
    disabledHelpText: string;
    helperText: string;
    isPaneController: boolean;
    label: string;
    name: string;
    options: ComboBoxOption[];
    propagated?: TableVerticalLayoutProps;
    roundTripOnChange: boolean;
}

interface RuntimeProperties
{
    accessLevel: AccessLevel;
    businessErrors: string[];
    selectedDisplayValue: string;
    selectedValue: string | null;
    showAsMandatory: boolean;
    showDisabledHelp: boolean;
}

const styles = (theme: Theme) => createStyles(
    {
        root:
        {
            minWidth: 150,
        },
    });

@observer
export class DomainComboBox extends
    React.Component<Props & WithStyles<typeof styles>>
{
    protected disposeObserve: Lambda;

    public static getOptions(
        propOptions: ComboBoxOption[],
        selectedValue: string | null,
        selectedDisplayValue: string
        ): ComboBoxOption[]
    {
        const options = [...propOptions];

        if (selectedValue)
        {
            // Add the initially selected value as a historic option if is not
            // among the current candidates
            const optionInList = options.find(
                o => o.value === selectedValue);

            if (optionInList === undefined)
            {
                const historicOption: ComboBoxOption =
                {
                    display: selectedDisplayValue,
                    historic: true,
                    value: selectedValue,
                };

                options.push(historicOption);
            }
        }

        return options;
    }

    private onSelect = (event: React.ChangeEvent<HTMLInputElement>) =>
    {
        const value = Presentation.getValue(this.props);

        Sys.clearBusinessErrors(this.props.dataId, this.props.name);
        Presentation.setValue(this.props, event.target.value);

        if (!this.props.roundTripOnChange)
        {
            return;
        }

        RoundTripService.standardRoundTrip(
            'DomainComboBox/OnChange', this.props
        ).catch(() =>
        {
            // If the round trip fails, undo the value change.
            Presentation.setValue(this.props, value);
        });
    };

    protected showSubPane(): void
    {
        const _props = { ...this.props };

        if (!_props.isPaneController)
        {
            return;
        }

        const value = Presentation.getValue(_props, null);
        const controlledPaneName = _props.controlledPaneKeysByValue![value];

        if (controlledPaneName)
        {
            PaperBase.show(controlledPaneName);
        }
        else
        {
            PaperBase.hideGroup(_props.controlledGroupName);
        }
    }

    public componentDidMount(): void
    {
        this.disposeObserve = autorun(() =>
        {
            this.showSubPane();
        });
    }

    public componentWillUnmount(): void
    {
        this.disposeObserve();
    }

    public render(): React.ReactNode
    {
        let rowKey = null;
        if (this.props.propagated?.parentTable)
        {
            // Widget is rendered in the vertical layout of a grid.
            rowKey = this.props.propagated.rowKey;
        }

        const row = PaneRow.get(this.props.dataId, rowKey);
        if (!row)
        {
            return null;
        }

        const widget = row.getWidget(this.props.name);
        const runtimeProps = widget.properties as RuntimeProperties;

        const options = DomainComboBox.getOptions(
            this.props.options,
            runtimeProps.selectedValue,
            runtimeProps.selectedDisplayValue);

        return (
            <ApiSelect
                accessLevel={runtimeProps.accessLevel}
                businessErrors={runtimeProps.businessErrors}
                classes={this.props.classes}
                dataId={this.props.dataId}
                disabledHelpText={this.props.disabledHelpText}
                helperText={this.props.helperText}
                label={this.props.label}
                name={this.props.name}
                onSelect={this.onSelect}
                options={options}
                propagated={this.props.propagated}
                roundTripOnChange={this.props.roundTripOnChange}
                showAsMandatory={runtimeProps.showAsMandatory}
                showDisabledHelp={runtimeProps.showDisabledHelp}
            />);
    }
}

export default withStyles(styles)(DomainComboBox);
