import * as muiDrawer from '@material-ui/core/Drawer';
import * as muiMenuList from '@material-ui/core/MenuList';
import { createStyles, Theme, WithStyles, withStyles }
    from '@material-ui/core/styles';
import * as React from 'react';
import Sys from '../core/Sys';
import MaskingStore from '../stores/MaskingStore';
import Button from './Button';

export interface DrawerProps
{
    anchor?: 'left' | 'right';
    anchorEl?: Element | ((element: Element) => Element) | null;
    name?: string | null;
    open?: boolean;
}

interface State
{
    isOpen?: boolean;
}

const styles = (theme: Theme) => createStyles(
    {
        closeButton:
        {
            position: 'relative',
            top: 'calc(50% - 20px)',
        },
        header:
        {
            borderBottom: 1,
            borderBottomColor: theme.palette.grey[300],
            borderBottomStyle: 'solid',
            marginBottom: 8,
            paddingLeft: 40,
            paddingRight: 40,
            [theme.breakpoints.up('xs')]:
            {
                minHeight: 72,
            },
            [theme.breakpoints.up('sm')]:
            {
                minHeight: 88,
            },
            [theme.breakpoints.up('md')]:
            {
                minHeight: 108,
            },
            [theme.breakpoints.up('lg')]:
            {
                minHeight: 140,
            },
        },
        menuList:
        {
            [theme.breakpoints.up('xs')]:
            {
                marginLeft: 24,
                marginRight: 24,
            },
            [theme.breakpoints.up('md')]:
            {
                marginLeft: 16,
                marginRight: 16,
            },
            [theme.breakpoints.up('lg')]:
            {
                marginLeft: 0,
                marginRight: 0,
            },
            outline: 'none',
            padding: 0,
        },
        paper:
        {
            maxWidth: '100%',
            [theme.breakpoints.only('xs')]:
            {
                width: '100%',
            },
        },
    });

export class Drawer extends
    React.PureComponent<DrawerProps & WithStyles<typeof styles>, State>
{
    // Dictionary of drawer components, keyed by name.
    public static instances: Map<string | null, Set<Drawer>> =
        new Map<string | null, Set<Drawer>>();

    public static close(name?: string | null)
    {
        if (name && Drawer.instances.has(name))
        {
            Drawer.instances.get(name)!.forEach((drawer) =>
            {
                drawer.close();
            });
        }
    }

    public static closeAll()
    {
        Drawer.instances.forEach((value: Set<Drawer>) =>
        {
            value.forEach((drawer: Drawer) =>
            {
                drawer.close();
            });
        });
    }

    public static open(name?: string | null, anchorElement?: HTMLElement)
    {
        if (name && Drawer.instances.has(name))
        {
            Drawer.instances.get(name)!.forEach((drawer) =>
            {
                if (!anchorElement || drawer.props.anchorEl === anchorElement)
                {
                    drawer.open();
                }
            });
        }
    }

    public static toggle(name?: string | null)
    {
        if (name && Drawer.instances.has(name))
        {
            Drawer.instances.get(name)!.forEach((drawer) =>
            {
                drawer.toggle();
            });
        }
    }

    constructor(props: DrawerProps & WithStyles<typeof styles>)
    {
        super(props);

        this.state = { isOpen: props.open || false };

        if (props.name)
        {
            if (Drawer.instances.has(props.name))
            {
                Drawer.instances.get(props.name)!.add(this);
            }
            else
            {
                Drawer.instances.set(props.name, new Set<Drawer>([this]));
            }
        }
    }

    public close()
    {
        if (this.state.isOpen)
        {
            this.setState({ isOpen: false });
            MaskingStore.maskClosed();
        }
    }

    public componentWillUnmount()
    {
        if (this.props.name && Drawer.instances.has(this.props.name))
        {
            Drawer.instances.get(this.props.name)!.delete(this);

            if (Drawer.instances.get(this.props.name)!.size === 0)
            {
                Drawer.instances.delete(this.props.name);
            }
        }
        if (this.state.isOpen)
        {
            MaskingStore.maskClosed();
        }
    }

    public open()
    {
        if (!this.state.isOpen)
        {
            this.setState(
                { isOpen: true },
                () => setTimeout(MaskingStore.maskOpened));
        }
    }

    public render()
    {
        const anchor: 'left' | 'right' = this.props.anchor || 'right';

        return (
            <muiDrawer.default
                anchor={anchor}
                classes={{ paper: this.props.classes.paper }}
                className={this.props.classes.paper}
                onClose={() => this.close()}
                open={this.state.isOpen}
            >
                <div className={this.props.classes.header}>
                    <Button
                        aria-label={Sys.getTranslation('Close')}
                        className={this.props.classes.closeButton}
                        icon="fas fa-times"
                        onClick={() => this.close()}
                        style={{ float: anchor }}
                    />
                </div>
                <muiMenuList.default
                    autoFocus
                    className={this.props.classes.menuList}
                >
                    {this.props.children}
                </muiMenuList.default>
            </muiDrawer.default>);
    }

    public toggle()
    {
        this.setState((prevState) =>
        {
            return { isOpen: !prevState.isOpen };
        });
    }
}

export default withStyles(styles)(Drawer);
