import { createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { CustomTheme } from '../muiTheme';

interface Props
{
    children: React.ReactNode;
    grouping: 'Unrelated' | 'Related' | 'Closely Related' | 'Tight';
    lg?: number;
    md?: number;
    sm?: number;
    xs?: number;
}

const styles = (theme: CustomTheme) =>
{
    const visibilityStyles = {};
    for (const breakPoint of theme.visibilityBreakPoints)
    {
        for (let size = 1; size <= 12; size++)
        {
            visibilityStyles[`grid-${breakPoint}-${size}`] =
                {
                    [theme.breakpoints.up(breakPoint)]:
                    {
                        gridTemplateColumns: `repeat(${size}, 1fr)`,
                    },
                };
        }
    }

    /*
     * Note: The row gap is accomplished using margins on the content to ensure
     * the gap is collapsed if the grid row no longer has any visible content.
     *
     * If grid-row-gap is used, the gap is effectively doubled between the
     * visible rows if the contents of the row between them are hidden. This is
     * because it is the contents of the grid items, not the grid items
     * themselves that are hidden if, for example, a widget's access level
     * dictates that it is hidden or an expansion sub-pane is collaped. Such
     * behaviour is desirable, however, since we still want the grid item to
     * occupy the space left behind by the hidden widget. Otherwise the layout
     * would reflow to take up that space if the grid item itself was hidden.
     */
    const closelyRelatedSpaceStyle = {};
    const columnSpaceStyle = {};
    const relatedSpaceStyle = {};
    const unrelatedSpaceStyle = {};
    const tightSpaceStyle = {};

    for (const breakPoint of theme.spacingBreakPoints)
    {
        const columnSpacing = theme.horizontalSpacing.related[breakPoint];
        columnSpaceStyle[theme.breakpoints.up(breakPoint)] =
            {
                gridColumnGap: `${columnSpacing}px`,
            };

        const closelyRelatedSpacing =
            theme.verticalSpacing.closelyRelated[breakPoint];
        closelyRelatedSpaceStyle[theme.breakpoints.up(breakPoint)] =
            {
                '& > div > *':
                {
                    marginTop: closelyRelatedSpacing,
                },
                marginTop: -closelyRelatedSpacing,
            };

        const relatedSpacing = theme.verticalSpacing.related[breakPoint];
        relatedSpaceStyle[theme.breakpoints.up(breakPoint)] =
            {
                '& > div > *':
                {
                    marginTop: relatedSpacing,
                },
                '& > div > .mustangui-grid-closelyrelated':
                {
                    marginTop: relatedSpacing - closelyRelatedSpacing,
                },
                marginTop: -relatedSpacing,
            };

        const unrelatedSpacing = theme.verticalSpacing.unrelated[breakPoint];
        unrelatedSpaceStyle[theme.breakpoints.up(breakPoint)] =
            {
                '& > div > *':
                {
                    marginTop: unrelatedSpacing,
                },
                '& > div > .mustangui-grid-closelyrelated':
                {
                    marginTop: unrelatedSpacing - closelyRelatedSpacing,
                },
                '& > div > .mustangui-grid-related':
                {
                    marginTop: unrelatedSpacing - relatedSpacing,
                },
                marginTop: -unrelatedSpacing,
            };

        const tightSpacing = theme.verticalSpacing.tight[breakPoint];
        tightSpaceStyle[theme.breakpoints.up(breakPoint)] =
            {
                '& > div > *':
                {
                    marginTop: tightSpacing,
                },
                marginTop: -tightSpacing,
            };
    }

    const result =
        {
            closelyrelated:
            {
                ...closelyRelatedSpaceStyle,
            },
            container:
            {
                display: 'grid',
                ...columnSpaceStyle,
            },
            related:
            {
                ...relatedSpaceStyle,
            },
            tight:
            {
                ...tightSpaceStyle,
            },
            unrelated:
            {
                ...unrelatedSpaceStyle,
            },
            ...visibilityStyles,
        };

    return createStyles(result);
};

export class Grid extends
    React.PureComponent<Props & WithStyles<typeof styles>>
{
    public render()
    {
        const groupingMapping =
            {
                'Closely Related': 'closelyrelated',
                Related: 'related',
                Tight: 'tight',
                Unrelated: 'unrelated',
            };

        const groupingClassName = groupingMapping[this.props.grouping];
        const classes: string[] =
            [
                `mustangui-grid-${groupingClassName}`,
                this.props.classes.container,
                this.props.classes[groupingClassName],
                this.props.classes[`grid-xs-${this.props.xs || 12}`],
            ];

        if (this.props.sm !== undefined)
        {
            classes.push(this.props.classes[`grid-sm-${this.props.sm}`]);
        }

        if (this.props.md !== undefined)
        {
            classes.push(this.props.classes[`grid-md-${this.props.md}`]);
        }

        if (this.props.lg !== undefined)
        {
            classes.push(this.props.classes[`grid-lg-${this.props.lg}`]);
        }

        return (
            <div className={classes.join(' ')}>
                {this.props.children}
            </div>
        );
    }
}

export default withStyles(styles)(Grid);
