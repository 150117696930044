import grey from '@material-ui/core/colors/grey';
import { ICellEditorParams } from 'ag-grid-community';
import { observer } from 'mobx-react';
import * as React from 'react';
import Sys from '../../core/Sys';
import ComboBoxOption from '../../coreui/ComboBoxOption';
import ErrorBadge from '../../coreui/ErrorBadge';
import MenuItem from '../../coreui/MenuItem';
import Select, { Select as SelectBase, SelectProps } from '../../coreui/Select';
import { TableChildProps } from '../../coreui/Table';
import PaneRow from '../../models/PaneRow';
import Api, { AccessLevel } from '../Api';
import { DomainComboBox } from '../DomainComboBox';

interface ConfigProperties extends ICellEditorParams
{
    dataId: string;
    name: string;
    options: ComboBoxOption[];
    propagated: TableChildProps;
}

interface State
{
    value?: string | null;
}

interface RuntimeProperties
{
    accessLevel: AccessLevel;
    businessErrors: string[];
    selectedDisplayValue: string;
    selectedValue: string | null;
}

@observer
export class DomainComboBoxColumnEdit extends
    React.Component<ConfigProperties, State>
{
    private select: SelectBase;

    public constructor(props: ConfigProperties)
    {
        super(props);

        this.state = { value: props.value };
    }

    private onChange = (event: React.ChangeEvent<HTMLSelectElement>) =>
    {
        Sys.clearBusinessErrors(
            this.props.dataId, this.props.name, this.props.data.rowKey);
        this.setState({ value: event.target.value });
    };

    private onClose = () =>
    {
        // Must be deferred to run after focus.
        setTimeout(
            () =>
            {
                const parentTable = this.props.propagated.parentTable;
                const focusedCell = this.props.api!.getFocusedCell();

                parentTable.setStopEditingWhenGridLosesFocus(true);
                this.props.api!['rowRenderer'].stopEditing();
                this.props.api!.setFocusedCell(
                    focusedCell.rowIndex,
                    focusedCell.column,
                    focusedCell.rowPinned);
            },
            500);
    };

    private onFocus = () =>
    {
        const parentTable = this.props.propagated.parentTable;
        parentTable.setStopEditingWhenGridLosesFocus(false);
    };

    public componentDidMount(): void
    {
        if (this.select)
        {
            // AutoFocus does not work for initial render.
            window.setTimeout(
                () =>
                {
                    if (this.select)
                    {
                        const input: HTMLInputElement | null =
                            this.props.eGridCell.querySelector('input');

                        if (input)
                        {
                            // This is the div that hosts the select markup.
                            input.previousSibling!['focus']();
                        }

                        this.select.open();
                    }
                },
                10);
        }
    }

    public componentWillUnmount(): void
    {
        this.props.propagated.parentTable.setStopEditingWhenGridLosesFocus(true);
    }

    public getValue(): string | null
    {
        return this.state.value === '' ? null : this.state.value!;
    }

    public render(): React.ReactNode
    {
        const row = this.props.node.data as PaneRow | null;
        if (!row)
        {
            return null;
        }

        const widget = row.getWidget(this.props.name);
        const runtimeProps = widget.properties as RuntimeProperties;

        const options = DomainComboBox.getOptions(
            this.props.options,
            runtimeProps.selectedValue,
            runtimeProps.selectedDisplayValue);

        const errors: string[] = row.hasChanges(this.props.name)
            ? []
            : [...runtimeProps.businessErrors];

        const table = this.props.propagated.parentTable;
        return (
            <ErrorBadge
                isShort={row.isNew && table.isDocumentGrid}
                message={Api.getErrorMessages(errors)}
            >
                <Select
                    autoFocus={true}
                    disableUnderline={true}
                    fullWidth={true}
                    error={errors.length > 0}
                    hasOptions={options.length > 0}
                    innerRef={
                        (component: SelectBase) =>
                        {
                            this.select = component;
                        }}
                    MenuProps={{
                        anchorOrigin: { horizontal: 'left', vertical: 'bottom' },
                        getContentAnchorEl: undefined,
                        }}
                    onChange={this.onChange}
                    onClose={this.onClose}
                    onFocus={this.onFocus}
                    SelectDisplayProps={
                        {
                            style:
                            {
                                lineHeight: `${this.props.node.rowHeight}px`,
                                paddingBottom: 0,
                                paddingLeft: 24,
                                paddingTop: 0,
                            },
                        }}
                    style={{
                        cursor: 'pointer',
                        display: 'flex',
                        height: 'calc(100% + 2px)',
                        }}
                    value={this.state.value || ''}
                >
                    {
                        options.map(o => (
                           <MenuItem
                                children={o.display}
                                disabled={o.historic}
                                key={o.value ? o.value : ''}
                                value={o.value ? o.value : ''}
                            />))
                    }
                </Select>
            </ErrorBadge>);
    }
}

export default DomainComboBoxColumnEdit;
