import * as muiFormHelperText from '@material-ui/core/FormHelperText';
import { createStyles, Theme, WithStyles, withStyles }
    from '@material-ui/core/styles';
import * as React from 'react';

interface Props extends muiFormHelperText.FormHelperTextProps
{
}

const styles = (theme: Theme) => createStyles(
    {
        root:
        {
        },
    });

export class FormHelperText extends
    React.PureComponent<Props & WithStyles<typeof styles>>
{
    public render()
    {
        const _props = { ...this.props };

        _props.component = 'div';

        return <muiFormHelperText.default {..._props}/>;
    }
}

export default withStyles(styles)(FormHelperText);
