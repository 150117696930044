import * as React from 'react';
import AppServer from '../core/AppServer';
import Sys from '../core/Sys';
import { Dialog } from '../coreui/Dialog';
import Presentation from '../coreui/Presentation';
import RequestsStore from '../stores/RequestsStore';
import ErrorPageTemplate, { ErrorPageTemplateConfig }
    from '../templates/ErrorPageTemplate';

export interface ErrorPageConfig
{
    background404Url: string;
    background408Url: string;
    backgroundGeneralUrl: string;
    template: ErrorPageTemplateConfig;
}

export class ErrorPage
{
    private static config: ErrorPageConfig | null = null;

    public static render(
        title: string,
        message: string | null,
        status: number | null)
    {
        if (Dialog.currentOpenInstance)
        {
            Dialog.currentOpenInstance.forceClose();
        }

        AppServer.clearState();
        Sys.clearRequestCache(false);

        RequestsStore.instance.clearAllProcessing();

        if (ErrorPage.config !== null)
        {
            let backgroundImageUrl = ErrorPage.config.backgroundGeneralUrl;
            if (status === 404)
            {
                backgroundImageUrl = ErrorPage.config.background404Url;
            }
            else if (status === 408)
            {
                backgroundImageUrl = ErrorPage.config.background408Url;
            }

            Presentation.render(
                <ErrorPageTemplate
                    backgroundImageUrl={backgroundImageUrl}
                    footer={ErrorPage.config.template.footer}
                    header={ErrorPage.config.template.header}
                    message={message}
                    title={title}
                />);
        }
        else
        {
            Presentation.render(
                <ErrorPageTemplate
                    message={message}
                    title={title}
                />);
        }
    }

    public static setConfig(config: ErrorPageConfig)
    {
        if (ErrorPage.config !== null)
        {
            throw new Error('Error page config is already set');
        }

        ErrorPage.config = config;
    }
}
