import { createStyles, Theme, WithStyles, withStyles }
    from '@material-ui/core/styles';
import { observer, useObserver } from 'mobx-react';
import * as React from 'react';
import RequestPromise from '../../core/RequestPromise';
import Sys from '../../core/Sys';
import { Menu as MenuBase } from '../../coreui/Menu';
import MenuItem from '../../coreui/MenuItem';
import ActionButtonService, { OnRoundTripResponse }
    from '../../services/ActionButtonService';
import { ActionButton, ActionButtonProps, ActionButtonRuntimeProps }
    from '../ActionButton';
import Api, { AccessLevel } from '../Api';
import ApiButton from '../ApiButton';
import { CaptchaControl as CaptchaControlBase } from '../CaptchaControl';
import { MenuItemProps } from '../MenuItem';

interface Props extends ActionButtonProps
{
}

const styles = (theme: Theme) => createStyles(
    {
        root:
        {
        },
    });

@observer
export class SavePresentationButton extends
    React.Component<Props & WithStyles<typeof styles>>
{
    private onClickPromise: RequestPromise<OnRoundTripResponse>;

    private static onClick(
        props: {
            dataId: string;
            iconName?: string;
            name: string;
        }
        ): RequestPromise<OnRoundTripResponse>
    {
        return ActionButtonService.onRoundTrip(props).then((response) =>
        {
            const runtimeProperties =
                Api.getWidgetProperties(props) as ActionButtonRuntimeProps;

            if (response.saved && !response.url)
            {
                Sys.clearBusinessErrors();
                ActionButton.showSavedToast(props.iconName);
            }

            if (runtimeProperties.validateCaptcha)
            {
                CaptchaControlBase.reset();
            }

            return response;
        });
    }

    public static renderMenuItem(props: MenuItemProps): JSX.Element
    {
        const configProps = props.config as Props;
        const runtimeProps = props.runtime as ActionButtonRuntimeProps;
        const onClick = () =>
        {
            if (props.runtime.accessLevel >= AccessLevel.actionable)
            {
                SavePresentationButton.onClick(configProps);
                MenuBase.closeAll();
            }
        };

        return useObserver(() => (
            <MenuItem
                disabled={props.runtime.accessLevel === AccessLevel.disabled
                    || (props.runtime.validateCaptcha!
                        && !CaptchaControlBase.isValid())}
                iconName={configProps.iconName}
                indent={props.config.propagated
                    ? props.config.propagated.indent : 0}
                onClick={onClick}
            >
                {runtimeProps.label}
            </MenuItem>
        ));
    }

    private onClick = () =>
    {
        this.onClickPromise = SavePresentationButton.onClick(this.props);
    };

    public componentWillUnmount()
    {
        if (this.onClickPromise)
        {
            this.onClickPromise.abort();
        }
    }

    public render()
    {
        const _props = { ...this.props };
        const runtimeProperties =
            Api.getWidgetProperties(_props) as ActionButtonRuntimeProps;
        const disabled: boolean = runtimeProperties.validateCaptcha
            && !CaptchaControlBase.isValid();

        return (
            <ApiButton
                alternateText={this.props.alternateText}
                buttonColor={this.props.buttonColor}
                dataId={this.props.dataId}
                disabled={disabled}
                disabledHelpText={this.props.disabledHelpText}
                iconName={this.props.iconName}
                label={runtimeProperties.label}
                name={this.props.name}
                onClick={this.onClick}
                size={this.props.size}
            />
        );
    }
}

export default withStyles(styles)(SavePresentationButton);
