import { createStyles, WithStyles, withStyles, WithTheme, withTheme }
    from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import * as React from 'react';
import { TableChildProps } from '../coreui/Table';
import PaneRow from '../models/PaneRow';
import { CustomTheme } from '../muiTheme';
import { AccessLevel } from '../mustangui/Api';

interface Props
{
    childLayoutWidgetNames: string[];
    children: React.ReactNode;
    childWidgetNames: string[];
    dataId: string;
    justify: 'start' | 'middle' | 'end';
    lg: boolean;
    md: boolean;
    propagated: TableChildProps;
    sm: boolean;
    toolbarType: 'header' | 'footer' | 'selection' | 'summary';
    xl: boolean;
    xs: boolean;
}

interface WidgetProperties
{
    accessLevel?: AccessLevel;
}

const styles = (theme: CustomTheme) =>
{
    const visibilityStyles = {};
    for (const breakPoint of theme.visibilityBreakPoints)
    {
        visibilityStyles[`toolbarcontainergroup-${breakPoint}`] =
            {
                [theme.breakpoints.only(breakPoint)]:
                {
                    display: 'flex',
                },
            };
    }

    const groupSpaceStyle = {};
    for (const breakPoint of theme.spacingBreakPoints)
    {
        const itemSpacing = theme.freeflow.item.spacing[breakPoint];
        const groupSpacing = theme.freeflow.group.spacing[breakPoint];

        groupSpaceStyle[theme.breakpoints.up(breakPoint)] =
            {
                marginLeft: -itemSpacing.horizontal * 0.5,
                marginRight: -itemSpacing.horizontal * 0.5,

                paddingLeft: groupSpacing.horizontal * 0.5,
                paddingRight: groupSpacing.horizontal * 0.5,
            };
    }

    const result =
        {
            root:
            {
                alignItems: 'center',
                display: 'none',
                ...groupSpaceStyle,
            },
            ...visibilityStyles,
        };

    return createStyles(result);
};

@observer
export class ToolbarContainerGroup extends
    React.Component<Props & WithStyles<typeof styles> & WithTheme>
{
    private shouldRender(): boolean
    {
        if (this.props.childLayoutWidgetNames.length > 0)
        {
            return true;
        }

        for (const widgetName of this.props.childWidgetNames)
        {
            const widgetProperties = PaneRow.getWidgetProperties(
                this.props.dataId, widgetName) as WidgetProperties;

            if (widgetProperties.accessLevel === undefined)
            {
                return true;
            }

            if (widgetProperties.accessLevel >= AccessLevel.disabled)
            {
                return true;
            }
        }

        return false;
    }

    public render(): React.ReactNode
    {
        if (!this.shouldRender())
        {
            return null;
        }

        let justifyContent =
            {
                end: 'flex-end',
                middle: 'center',
                start: 'flex-start',
            }[this.props.justify];

        // When table is in vertical layout the footer toolbar container will
        // render centered justified for since there is only one full width
        // section instead of a start and end section.
        let flex = undefined;
        if (this.props.propagated.parentTable?.isVerticalLayout
            && this.props.toolbarType === 'footer')
        {
            justifyContent = 'center';
            flex = 'auto';
        }

        const flexWrap = justifyContent === 'center' ? 'nowrap' : 'wrap';
        const minWidth = justifyContent === 'center' ? 'auto' : 0;

        const classes: string[] = [this.props.classes.root];
        const theme = this.props.theme as CustomTheme;

        for (const breakPoint of theme.visibilityBreakPoints)
        {
            if (this.props[breakPoint])
            {
                classes.push(
                    this.props.classes[`toolbarcontainergroup-${breakPoint}`]);
            }
        }

        return (
            <div
                className={classes.join(' ')}
                style={{
                    flex,
                    flexWrap,
                    justifyContent,
                    minWidth,
                }}
            >
                {this.props.children}
            </div>
        );
    }
}

export default withStyles(styles)(withTheme(ToolbarContainerGroup));
