import { createStyles, Theme, WithStyles, withStyles }
    from '@material-ui/core/styles';
import * as muiTypography from '@material-ui/core/Typography';
import { observer } from 'mobx-react';
import * as React from 'react';
import Sys from '../core/Sys';
import Grid from '../coreui/Grid';
import GridItem from '../coreui/GridItem';
import Icon from '../coreui/Icon';
import Api, { AccessLevel } from './Api';

interface ConfigProperties
{
    dataId: string;
    headingSize:
        'Level 1' | 'Level 2' | 'Level 3' | 'Level 4' | 'Level 5' | 'Level 6';
    iconName: string;
    name: string;
}

interface RuntimeProperties
{
    accessLevel: AccessLevel;
    headingText: string;
    helperText: string;
    showAsMandatory: boolean;
}

const styles = (theme: Theme) => createStyles(
    {
        goToTopBtn:
        {
            backgroundColor: theme.palette.common.white,
            marginRight: 8,

            [theme.breakpoints.up('xs')]:
            {
                marginLeft: Sys.settings.baselineGridSize * 4,
                marginTop: Sys.settings.baselineGridSize * 1,
            },
            [theme.breakpoints.up('sm')]:
            {
                marginLeft: Sys.settings.baselineGridSize * 6,
                marginTop: Sys.settings.baselineGridSize * 2,
            },
            [theme.breakpoints.up('lg')]:
            {
                marginLeft: Sys.settings.baselineGridSize * 10,
                marginTop: Sys.settings.baselineGridSize * 4,
            },
        },
        helperText:
        {
            marginTop: 8,
            maxWidth: 580,
        },
        required:
        {
            '&:after':
            {
                color: Api.getSystemColor('danger'),
                content: '" *"',
            },
        },
        root:
        {
        },
    });

@observer
export class GroupHeading extends
    React.Component<ConfigProperties & WithStyles<typeof styles>>
{
    private readonly componentId: string;
    private readonly helperTextId: string;
    protected muiProps: muiTypography.TypographyProps;

    public constructor(props: ConfigProperties & WithStyles<typeof styles>)
    {
        super(props);

        this.componentId = `group-heading-${Sys.nextId}`;
        this.helperTextId = `${this.componentId}-helper-text`;

        this.muiProps = {};

        if ('headingSize' in props)
        {
            this.muiProps.variant =
                Api.getTextStyle(props.headingSize);
        }
    }

    public render()
    {
        const _props = { ...this.props };
        const runtimeProperties =
            Api.getWidgetProperties(_props) as RuntimeProperties;
        let icon: React.ReactNode = null;
        const button: React.ReactNode = null;

        if (!runtimeProperties)
        {
            return null;
        }

        this.muiProps.className = `${_props.classes.root} `;

        this.muiProps.children = runtimeProperties.headingText;

        if (!Api.setAccessLevel(_props, runtimeProperties))
        {
            return null;
        }

        if ('iconName' in _props && _props.iconName)
        {
            icon =
                <GridItem>
                    <muiTypography.default {...this.muiProps}>
                        <Icon
                            fixedWidth
                            icon={_props.iconName}
                            style={{ marginRight: '.4em' }}
                        />
                    </muiTypography.default>
                </GridItem>;
        }

        const _muiProps = { ...this.muiProps };
        if (runtimeProperties.showAsMandatory)
        {
            _muiProps.className += `${_props.classes.required} `;
        }

        return (
            <div>
                <Grid wrap="nowrap">
                    {icon}
                    <GridItem style={{ flex: '1 1 auto' }}>
                        <muiTypography.default
                            aria-describedby={runtimeProperties.helperText
                                ? this.helperTextId : undefined}
                            id={this.componentId}
                            style={{ display: 'inline-block' }}
                            {..._muiProps}
                        />
                    </GridItem>
                    {button}
                </Grid>
                {runtimeProperties.helperText ? (
                    <muiTypography.default
                        className={_props.classes.helperText}
                        id={this.helperTextId}
                    >
                        {runtimeProperties.helperText}
                    </muiTypography.default>
                ) : null}
            </div>);
    }
}

export default withStyles(styles)(GroupHeading);
