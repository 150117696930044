import * as muiCheckbox from '@material-ui/core/Checkbox';
import { IHeaderParams } from 'ag-grid-community';
import * as React from 'react';
import FormControlLabel from '../FormControlLabel';
import Icon from '../Icon';
import { TableChildProps } from '../Table';

interface Props extends IHeaderParams {
    propagated: TableChildProps;
}

class SelectionHeader extends React.Component<Props>
{
    protected muiProps: muiCheckbox.CheckboxProps;

    public constructor(props: Props)
    {
        super(props);

        this.muiProps =
        {
            checkedIcon: <Icon icon="fas fa-check-square" />,
            color: 'default',
            onChange: (
                event: React.ChangeEvent<HTMLInputElement>,
                checked: boolean) =>
            {
                this.props.propagated.parentTable.selection.setAllSelected(
                    checked);
            },
            tabIndex: -1,
        };
    }

    public render(): React.ReactNode
    {
        if (!this.props.propagated.parentTable.selection.isSelectAllEnabled)
        {
            return null;
        }

        const selectedCount =
            this.props.propagated.parentTable.selection.getSelectedCount();
        const icon = selectedCount === 0 ? 'far fa-square' :
            'fas fa-minus-square';
        const { api } = this.props;
        const rowCount = api.getModel().getRowCount()
            + api.getPinnedTopRowCount();

        if (rowCount <= 0)
        {
            return <div/>;
        }

        this.muiProps.checked = selectedCount === rowCount;
        this.muiProps.icon = <Icon icon={icon} />;

        return (
            <div
                style={
                    {
                        alignItems: 'center',
                        display: 'flex',
                        height: '100%',
                        margin: '0 2px',
                    }}
            >
                <FormControlLabel
                    control={<muiCheckbox.default {...this.muiProps} />}
                    label=""
                    style={{ maxWidth: 'none' }}
                />
            </div>);
    }
}

export default SelectionHeader;
