import * as React from 'react';
import { Layout, LayoutConfig } from '../config/Layout';
import AppServer, { State } from '../core/AppServer';
import Routing from '../core/Routing';
import Sys from '../core/Sys';
import TrackableCollection from '../core/TrackableCollection';
import Presentation from '../coreui/Presentation';
import { ValueByBreakpoint } from '../mustangui/Api';
import Panel from '../mustangui/Panel';
import PresentationService from '../services/PresentationService';
import UserService from '../services/UserService';
import PaneDataStore from '../stores/PaneDataStore';
import RequestsStore from '../stores/RequestsStore';
import BasePageTemplate, { BasePageTemplateConfig }
    from '../templates/BasePageTemplate';

export interface AuthenticatedPageConfig
{
    homeLayout: LayoutConfig;
    template: BasePageTemplateConfig;
}

export class AuthenticatedPage
{
    private static config: AuthenticatedPageConfig | null = null;

    public static async render(
        presentationId: number,
        objectHandle: string,
        parameters: string | null,
        appServerState: State | null = null
        ): Promise<void>
    {
        if (AuthenticatedPage.config === null)
        {
            throw new Error(
                'Authenticated page config must be set before the '
                + 'authenticated page may be rendered');
        }

        const templateResponse =
            await PresentationService.getAuthenticatedPageData(
                appServerState);
        AppServer.setState(templateResponse.appServerState);
        PaneDataStore.loadResponse(templateResponse.paneDataByDataId);

        const templateConfig = AuthenticatedPage.config.template;

        const dataResponse = await PresentationService.getPresentationData(
            presentationId, objectHandle, parameters);

        if (dataResponse.shouldRedirectHome)
        {
            AppServer.setState(dataResponse.appServerState);
            Sys.setHash('');

            return;
        }

        // FUTURE 7.4.1
        // The presentation is first rendered without any children to give the
        // models a chance to be cleared without the new presentation reacting
        // to their on-change events.
        //
        // The components should be made tolerant of missing data, that way the
        // on-change events fired by changing the data will be benign. Then we
        // can take full advantage of React's architecture for making minimum
        // changes to the DOM (this approach effectively removes the entire
        // presentation from the DOM and re-renders the new one, which is not as
        // efficient as it could be).
        const navigatingToSamePresentation = Presentation.currentPresentationId
            && Presentation.currentPresentationId === dataResponse.layoutId!;
        // Avoiding clearing the presentation if navigating to the same
        // presentation helps this to be slightly more efficient.
        if (!navigatingToSamePresentation)
        {
            Presentation.render(
                <BasePageTemplate
                    backgroundImageUrl={templateConfig.backgroundImageUrl}
                    footer={templateConfig.footer}
                    header={templateConfig.header}
                    paperWidth={{
                        lg: null,
                        md: null,
                        sm: null,
                        xl: null,
                        xs: null,
                    }}
                />);
        }

        const presentation = await PresentationService.getPresentationConfig(
            dataResponse.layoutId!);

        AppServer.setState(dataResponse.appServerState);
        PaneDataStore.loadResponse(dataResponse.paneDataByDataId!);

        Presentation.render(
            <BasePageTemplate
                backgroundImageUrl={templateConfig.backgroundImageUrl}
                footer={templateConfig.footer}
                header={templateConfig.header}
                paperWidth={presentation.paperWidth}
            >
                <Panel presentationId={presentation.layoutId}>
                    <Layout config={presentation} />
                </Panel>
            </BasePageTemplate>);

        Routing.setDocumentTitle(
            dataResponse.objectTitle, presentation.objectDefDescription);
    }

    public static async renderHome(): Promise<void>
    {
        if (AuthenticatedPage.config === null)
        {
            throw new Error(
                'Authenticated page config must be set before the '
                + 'authenticated page may be rendered');
        }

        await AuthenticatedPage.render(
            AuthenticatedPage.config.homeLayout.layoutId,
            UserService.accountObjectHandle,
            null);
    }

    public static setConfig(config: AuthenticatedPageConfig)
    {
        if (AuthenticatedPage.config !== null)
        {
            throw new Error('Authenticated page config is already set');
        }

        AuthenticatedPage.config = config;

        const templateConfig = AuthenticatedPage.config.template;
        const allDataIds =
            [
                ...templateConfig.footer.layout.dataIds,
                ...templateConfig.header.layout.dataIds,
                ...AuthenticatedPage.config.homeLayout.dataIds,
            ];

        for (const dataId of allDataIds)
        {
            new TrackableCollection('PaneRow', dataId);
        }
    }
}
