import { createStyles, Theme, WithStyles, withStyles }
    from '@material-ui/core/styles';
import * as muiTextField from '@material-ui/core/TextField';
import { observer } from 'mobx-react';
import * as React from 'react';
import Sys from '../core/Sys';
import Api from '../mustangui/Api';
import FormControl from './FormControl';
import Icon from './Icon';
import InputAdornment from './InputAdornment';
import Typography from './Typography';

export interface TextFieldProps extends muiTextField.FilledTextFieldProps
{
    getErrors?: (value: string) => string[];
    icon?: string;
    onValueChange?: (value: string) => void;
    readOnly?: boolean;
    readOnlyProps?:
    {
        classes?:
        {
            content?: string;
            root?: string;
        };
        contentRef?: React.Ref<HTMLDivElement> | React.RefObject<HTMLDivElement>;
    };
}

const styles = (theme: Theme) => createStyles(
    {
        inputLabelRoot:
        {
            width: 'calc(100% - 33px)',
        },
        inputLabelShrink:
        {
            width: 'calc((100% - 33px) * 1.333)',
        },
        readOnlyContentMultiline:
        {
            whiteSpace: 'pre-wrap',
        },
        readOnlyLabel:
        {
            color: theme.palette.grey[700],
            flexShrink: 0,
            marginBottom: 8,
        },
        readOnlyRoot:
        {
            '&:hover': { backgroundColor: 'transparent' },
            backgroundColor: 'transparent',
        },
        root:
        {
        },
        rootWithStartIcon:
        {
            '& label, & input':
            {
                marginLeft: 22,
            },
        },
        startIcon:
        {
            color: theme.palette.text.primary,
            left: 16,
            position: 'absolute',
        },
    });

@observer
export class TextField extends
    React.Component<TextFieldProps & WithStyles<typeof styles>>
{
    private readonly defaultId: string;

    public constructor(props: TextFieldProps & WithStyles<typeof styles>)
    {
        super(props);

        this.defaultId = `text-field-${Sys.nextId}`;
    }

    private onChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    {
        if (!this.props.onValueChange)
        {
            return;
        }

        this.props.onValueChange(event.target.value as string);
    };

    public render(): React.ReactNode
    {
        const {
            classes,
            className,
            getErrors,
            helperText,
            icon,
            id,
            inputProps,
            InputProps,
            onValueChange,
            readOnly,
            readOnlyProps: readOnlyPropOverrides,
            ...otherProps
        } = this.props;

        if (readOnly)
        {
            const {
                label,
                multiline,
                value,
            } = otherProps;

            let contentClassName = '';
            if (multiline)
            {
                contentClassName = classes.readOnlyContentMultiline;
            }

            const readOnlyProps =
                {
                    classes:
                    {
                        content: '',
                        root: '',
                    },
                    contentRef: undefined,
                    ...readOnlyPropOverrides,
                };

            const readOnlyClassNames: string[] =
                [
                    readOnlyProps.classes.root || '',
                    classes.readOnlyRoot,
                ];

            return (
                <FormControl className={readOnlyClassNames.join(' ')} fullWidth>
                    <Typography
                        className={classes.readOnlyLabel}
                        ellipsis
                        variant="caption"
                    >
                        {label}
                    </Typography>
                    <div
                        className={readOnlyProps.classes.content}
                        ref={readOnlyProps.contentRef}
                    >
                        <Typography className={contentClassName}>
                            {value as React.ReactNode}
                        </Typography>
                    </div>
                </FormControl>
            );
        }

        const classNames: string[] =
            [
                className ? className : '',
                classes.root,
            ];

        let endAdornment: React.ReactNode = null;
        if (icon !== undefined)
        {
            // End adornment is used instead of start since when start is set
            // the field moves the label above the input
            endAdornment = (
                <InputAdornment
                    position="end"
                    children={
                        <Icon
                            classes={{ root: classes.startIcon }}
                            icon={icon}
                        />
                    }
                />
            );

            classNames.push(classes.rootWithStartIcon);
        }

        let muiHelperText = helperText;
        let hasErrors = false;

        if (getErrors)
        {
            const errors = getErrors(otherProps.value as string);
            if (errors.length > 0)
            {
                hasErrors = true;

                const errorMessages = Api.getErrorMessages(errors!);
                if (helperText)
                {
                    muiHelperText = (
                        <div>
                            <Typography variant="caption">
                                {helperText}
                            </Typography>
                            {errorMessages}
                        </div>
                    );
                }
                else
                {
                    muiHelperText = errorMessages;
                }
            }
        }

        return (
            <muiTextField.default
                className={classNames.join(' ')}
                error={hasErrors}
                FormHelperTextProps={
                    {
                        // Required so the DOM is still valid when error
                        // messages are displayed in the helper text.
                        component: 'div',
                        style:
                        {
                            marginLeft: 16,
                            marginRight: 16,
                        },
                    }
                }
                fullWidth={true}
                helperText={muiHelperText}
                id={id !== undefined ? id : this.defaultId}
                InputLabelProps={
                    {
                        classes:
                        {
                            root: classes.inputLabelRoot,
                            shrink: classes.inputLabelShrink,
                        },
                    }
                }
                inputProps={inputProps}
                InputProps={
                    {
                        endAdornment,
                        ...InputProps,
                    }
                }
                onChange={this.onChange}
                variant="filled"
                {...otherProps}
            />
        );
    }
}

export default withStyles(styles)(TextField);
