import { createStyles, Theme, WithStyles, withStyles }
    from '@material-ui/core/styles';
import * as muiTypography from '@material-ui/core/Typography';
import withWidth, { WithWidth } from '@material-ui/core/withWidth';
import { observer } from 'mobx-react';
import * as React from 'react';
import Icon, { IconProps } from '../coreui/Icon';
import Api, { AccessLevel } from './Api';

interface IconHeight
{
    lg: number | undefined;
    md: number | undefined;
    sm: number | undefined;
    xs: number | undefined;
}

interface Props
{
    dataId: string;
    iconHeight: IconHeight;
    name: string;
}

interface RuntimeProperties
{
    accessLevel: AccessLevel;
    alternateText: string;
    iconName: string;
}

const styles = (theme: Theme) => createStyles(
    {
    });

@observer
export class IconDisplay extends
    React.Component<Props & WithWidth & WithStyles<typeof styles>>
{
    public render()
    {
        const runtimeProperties =
            Api.getWidgetProperties(this.props) as RuntimeProperties;

        if (!runtimeProperties)
        {
            return null;
        }

        const muiProps =
        {
            icon: runtimeProperties.iconName,
        };

        if (runtimeProperties.alternateText)
        {
            muiProps['aria-label'] = runtimeProperties.alternateText;
        }

        let height: number | undefined = undefined;
        if (this.props.width in this.props.iconHeight)
        {
            height = this.props.iconHeight[this.props.width];
        }

        return (
            <div style={{ overflow: 'hidden' }}>
                <muiTypography.default
                    style={{
                        fontSize: height,
                        height,
                        width: height,
                    }}
                >
                    <Icon {...muiProps} />
                </muiTypography.default>
            </div>);
    }
}

export default withStyles(styles)(withWidth()(IconDisplay));
