import * as muiCheckbox from '@material-ui/core/Checkbox';
import { createStyles, Theme, WithStyles, withStyles }
    from '@material-ui/core/styles';
import { GetQuickFilterTextParams } from 'ag-grid-community';
import { observer } from 'mobx-react';
import * as React from 'react';
import Sys from '../../core/Sys';
import ErrorBadge from '../../coreui/ErrorBadge';
import Icon from '../../coreui/Icon';
import Presentation from '../../coreui/Presentation';
import { TableChildProps } from '../../coreui/Table';
import { CellFocusUtil, FocusCellRendererParams }
    from '../../coreui/table/CellFocusUtil';
import { CellUtil } from '../../coreui/table/CellUtil';
import { RuntimeWidget } from '../../models/PaneRow';
import Api, { AccessLevel } from '../../mustangui/Api';
import { FunctionName } from '../TableSummary';

interface ConfigProperties extends FocusCellRendererParams
{
    dataId: string;
    name: string;
    propagated: TableChildProps;
}

interface State
{
    isErrorBadgeOpen?: boolean;
}

interface RuntimeProperties
{
    accessLevel: AccessLevel;
    businessErrors: string[];
}

const styles = (theme: Theme) => createStyles(
    {
        root:
        {
            alignItems: 'center',
            display: 'flex',
            height: '100%',
            justifyContent: 'center',
            marginLeft: -2,
            padding: '0 24px',
        },
    });

@observer
export class CheckBoxColumn extends
    React.Component<ConfigProperties & WithStyles<typeof styles>, State>
{
    protected muiProps: muiCheckbox.CheckboxProps;

    public static getQuickFilterText(params: GetQuickFilterTextParams): string
    {
        return '';
    }

    public static getSummaryValue(
        runtimeData: RuntimeWidget[],
        configProperties: ConfigProperties,
        functionName: FunctionName
        ): string | null
    {
        return runtimeData.filter(d => d.value).length.toString();
    }

    public constructor(props: ConfigProperties & WithStyles<typeof styles>)
    {
        super(props);

        this.state = { isErrorBadgeOpen: false };

        this.muiProps =
        {
            checkedIcon: <Icon icon="fas fa-check-square" />,
            color: 'default',
            icon: <Icon icon="far fa-square" />,
            onChange: (
                event: React.ChangeEvent<HTMLInputElement>,
                checked: boolean) =>
            {
                Sys.clearBusinessErrors(
                    this.props.dataId, this.props.name, this.props.data.rowKey);
                Presentation.setValue(props, checked);
            },
            tabIndex: -1,
        };

        CellUtil.runOnAction(props.eGridCell, () =>
        {
            const value: boolean = Presentation.getValue(props, false);
            Sys.clearBusinessErrors(
                this.props.dataId, this.props.name, this.props.data.rowKey);
            Presentation.setValue(props, !value);
        });

        CellFocusUtil.subscribeToCellKeyboardFocusedEvent(
            props, this.onCellFocus, this.onCellBlur);
        props.eGridCell.addEventListener('keydown', this.onCellKeyDown);
    }

    private onCellBlur = (): void =>
    {
        this.setState({ isErrorBadgeOpen: false });
    };

    private onCellFocus = (): void =>
    {
        this.setState({ isErrorBadgeOpen: true });
    };

    private onCellKeyDown = (event: KeyboardEvent): void =>
    {
        CellUtil.customizeGridNavigation(event, this.props);
    };

    private onCloseErrorBadge = (): void =>
    {
        this.setState({ isErrorBadgeOpen: false });
    };

    private onOpenErrorBadge = (): void =>
    {
        this.setState({ isErrorBadgeOpen: true });
    };

    public componentWillUnmount(): void
    {
        CellFocusUtil.unsubscribeToCellKeyboardFocusedEvent(
            this.props, this.onCellFocus, this.onCellBlur);
        this.props.eGridCell.removeEventListener('keydown', this.onCellKeyDown);
    }

    public refresh(): boolean
    {
        this.forceUpdate();

        return true;
    }

    public render(): React.ReactNode
    {
        const runtimeProperties = Api.getWidgetProperties(
            this.props, this.props.data) as RuntimeProperties;

        if (!runtimeProperties)
        {
            return null;
        }

        CellUtil.setReadOnlyAttribute(
            this.props.eGridCell,
            runtimeProperties.accessLevel <= AccessLevel.actionable);

        const errors: string[] = this.props.data.hasChanges(this.props.name)
            ? [] : [...runtimeProperties.businessErrors];

        if (runtimeProperties.accessLevel >= AccessLevel.enterable)
        {
            this.muiProps.checked =
                Presentation.getValue(this.props, false);

            if (errors.length)
            {
                return (
                    <ErrorBadge
                        isShort={
                            this.props.data.isNew
                            && this.props.propagated.parentTable.isDocumentGrid}
                        message={Api.getErrorMessages(errors)}
                        onClose={this.onCloseErrorBadge}
                        onOpen={this.onOpenErrorBadge}
                        open={this.state.isErrorBadgeOpen}
                        suppressEdit={true}
                        {...{ style: { justifyContent: 'center' } }}
                    >
                        <muiCheckbox.default {...this.muiProps} />
                    </ErrorBadge>);
            }

            return (
                <div className={this.props.classes.root}>
                    <muiCheckbox.default {...this.muiProps} />
                </div>);
        }

        return (
            <div
                className={this.props.classes.root}
                style={{ fontSize: 24 }}
            >
                <Icon
                    icon={this.props.value ? 'fas fa-check' : ''}
                    style={{ height: 24 }}
                />
            </div>);
    }
}

export default withStyles(styles)(CheckBoxColumn);
