import { computed, observable } from 'mobx';

export default class ConfirmContinueStore
{
    private static staticInstance: ConfirmContinueStore;

    @observable private isOpen = false;
    private promise: { reject?: Function; resolve?: Function } = {};

    public static get instance()
    {
        if (!ConfirmContinueStore.staticInstance)
        {
            ConfirmContinueStore.staticInstance = new ConfirmContinueStore();
        }

        return ConfirmContinueStore.staticInstance;
    }

    @computed public get isDialogOpen(): boolean
    {
        return this.isOpen;
    }

    public closeDialog(accepted: boolean)
    {
        this.isOpen = false;

        if (accepted && this.promise.resolve)
        {
            this.promise.resolve();
        }
        else if (!accepted && this.promise.reject)
        {
            this.promise.reject();
        }
    }

    public openDialog(resolve: Function, reject: Function): void
    {
        this.isOpen = true;
        this.promise = { reject, resolve };
    }
}
