import { observable, toJS } from 'mobx';
import TrackableCollection from '../core/TrackableCollection';
import TrackableModel from '../core/TrackableModel';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type RuntimeProperties = { [id: string]: any };

type Widgets = { [id: string]: RuntimeWidget };

export type WidgetValue = boolean | Date | number | string | null;

export interface RuntimeWidget
{
    properties: RuntimeProperties;
    value: WidgetValue;
}

export interface WidgetData
{
    [id: string]: RuntimeWidget;
}

export default class PaneRow extends TrackableModel
{
    @observable private rowWidgets: Widgets = {};
    public isNew: boolean = false;
    public objectHandle: string;
    public rowKey: string;

    public get widgets(): Widgets
    {
        return this.rowWidgets;
    }

    public set widgets(value: Widgets)
    {
        for (const widgetName of Object.keys(value))
        {
            const newWidget = value[widgetName] as RuntimeWidget;
            if (widgetName in this.rowWidgets)
            {
                const widget = this.rowWidgets[widgetName] as RuntimeWidget;
                for (const prop of Object.keys(newWidget.properties))
                {
                    // FUTURE
                    // This check is used to detect if the property is an array
                    // observable. It is safe right now because there are no
                    // runtime properties on the widgets that are objects.
                    //
                    // We may want to add support for objects, though that would
                    // essentially amount to implementing a recursive traversal,
                    // which greatly increases the complexity of this method.
                    const oldValue = widget.properties[prop];
                    const newValue = newWidget.properties[prop];
                    if (newValue instanceof Object)
                    {
                        if (typeof oldValue.replace !== 'function')
                        {
                            throw new Error('Observable arrays are the only'
                                + ' objects supported in runtime data. '
                                + `Widget: "${widgetName}" `
                                + `Property: "${prop}"`);
                        }

                        if (JSON.stringify(oldValue)
                            !== JSON.stringify(newValue))
                        {
                            // Clone object / array properties
                            oldValue.replace(toJS(newValue));
                        }
                    }
                    else
                    {
                        widget.properties[prop] = newValue;
                    }
                }
                widget.value = value[widgetName].value;
            }
            else
            {
                this.rowWidgets[widgetName] = observable(newWidget);
            }
        }
    }

    public static get(
        dataId: string,
        rowKey: string | null = null
        ): PaneRow | null
    {
        if (!TrackableModel.models.has(dataId))
        {
            return null;
        }

        const collection =
            TrackableModel.models.get(dataId) as TrackableCollection;

        const observableCollection = collection.observableCollection
            ? collection.observableCollection
            : collection;

        if (observableCollection.length === 0)
        {
            return null;
        }

        if (!rowKey)
        {
            if (observableCollection.length > 1)
            {
                throw new Error(
                    'A rowKey is required if the TrackableCollection has more '
                    + 'than one item');
            }

            return observableCollection[0] as PaneRow;
        }

        const model = observableCollection.find(
            m => m['rowKey'].startsWith(rowKey));

        if (!model)
        {
            return null;
        }

        return model as PaneRow;
    }

    public static getWidgetProperties(
        dataId: string,
        widgetName: string
        ): RuntimeProperties
    {
        if (!TrackableModel.models.has(dataId))
        {
            throw Error(`Could not find collection with dataId ${dataId}`);
        }

        const collection =
            TrackableModel.models.get(dataId) as TrackableCollection;

        if (!collection.isLoaded)
        {
            throw Error(
                `Collection with dataId ${dataId} has not yet been loaded`);
        }

        const observableCollection = collection.observableCollection
            ? collection.observableCollection
            : collection;

        const paneRow = observableCollection[0] as PaneRow;

        return paneRow.getWidget(widgetName).properties;
    }

    protected getPropertyNames(): string[]
    {
        return Object.keys(this.widgets);
    }

    protected loadData(data: TrackableModel)
    {
        const row = data as PaneRow;
        this.isNew = row.isNew;
        this.objectHandle = row.objectHandle;
        this.rowKey = row.rowKey;
        this.widgets = row.widgets;
    }

    protected setPropertyValue(propName: string, value: WidgetValue): void
    {
        this.widgets[propName].value = value;
    }

    public getPrimaryKey(): string
    {
        return this.rowKey;
    }

    public getPropertyValue(propName: string): WidgetValue
    {
        return this.widgets[propName].value;
    }

    public getReadOnlyProperties(propName: string): RuntimeProperties
    {
        return this.widgets[propName].properties;
    }

    public getWidget(widgetName: string): RuntimeWidget
    {
        return this.widgets[widgetName];
    }

    public updateWidget(widgetName: string, widgetData: RuntimeWidget): void
    {
        this.widgets[widgetName] = widgetData;
    }
}

TrackableModel.setPrimaryKeyName('PaneRow', 'rowKey');
