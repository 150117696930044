import * as muiDialogContent from '@material-ui/core/DialogContent';
import * as muiInputAdornment from '@material-ui/core/InputAdornment';
import { createStyles, Theme, WithStyles, withStyles }
    from '@material-ui/core/styles';
import { darken } from '@material-ui/core/styles/colorManipulator';
import * as muiTextField from '@material-ui/core/TextField';
import { observer } from 'mobx-react';
import * as moment from 'moment';
import * as React from 'react';
import * as DayPicker from 'react-day-picker';
// @ts-ignore
import { LocaleUtils } from 'react-day-picker/moment';
import Sys from '../core/Sys';
import Button from '../coreui/Button';
import Dialog from '../coreui/Dialog';
import InformationBadge from '../coreui/InformationBadge';
import Presentation from '../coreui/Presentation';
import Typography from '../coreui/Typography';
import Api, { AccessLevel } from './Api';

interface Props
{
    dataId: string;
    dateFormatError: string;
    disabledHelpText: string;
    helperText: string;
    label: string;
    name: string;
}

interface State
{
    calendarSelectedDate?: Date;
    calendarVisibleMonth?: Date;
    isDialogOpen?: boolean;
    isFocused?: boolean;
    rawValue?: string | null;
    showYearsView?: boolean;
}

interface WidgetProperties
{
    accessLevel: AccessLevel;
    businessErrors: string[];
    showAsMandatory: boolean;
    showDisabledHelp: boolean;
}

const styles = (theme: Theme) => createStyles(
    {
        calendar:
        {
            [theme.breakpoints.down('xs')]:
            {
                padding: 16,
            },

            '& .DayPicker-Body':
            {
                display: 'table-row-group',
            },

            '& .DayPicker-Caption':
            {
                display: 'table-caption',
                marginBottom: '0.5rem',
                maxHeight: 40,
                padding: '0 42px',
                textAlign: 'center',
            },
            '& .DayPicker-Caption button':
            {
                justifyContent: 'flex-start',
                maxWidth: 215,
                textTransform: 'none',
            },
            '& .DayPicker-Caption button span':
            {
                fontSize: 16,
                fontWeight: 700,
                letterSpacing: '0.01071em',
                lineHeight: '20px',
                whiteSpace: 'normal',
            },

            '& .DayPicker-Day':
            {
                display: 'table-cell',
                outline: 'none',
                textAlign: 'center',
            },
            '& .DayPicker-Day button':
            {
                borderRadius: '50%',
                fontWeight: 400,
                minWidth: '40px',
                padding: '0.5rem',
            },
            '& .DayPicker-Day--outside button':
            {
                color: theme.palette.grey['300'],
            },
            '& .DayPicker-Day--selected button':
            {
                background: `${theme.palette.grey['800']} !important`,
                color: theme.palette.getContrastText(theme.palette.grey['800']),
            },
            '& .DayPicker-Day--selected button:hover':
            {
                background: darken(theme.palette.grey['800'], 0.1),
            },
            '& .DayPicker-Day--selected:focus button':
            {
                background: theme.palette.grey['800'],
            },
            '& .DayPicker-Day--selected:focus button:hover':
            {
                background: darken(theme.palette.grey['800'], 0.1),
            },
            '& .DayPicker-Day:focus button':
            {
                background: theme.palette.grey['400'],
            },
            '& .DayPicker-Day:focus button:hover':
            {
                background: darken(theme.palette.grey['400'], 0.1),
            },

            '& .DayPicker-Month':
            {
                display: 'table',
                width: '100%',
            },

            '& .DayPicker-NavButton':
            {
                position: 'absolute',
                top: '5px',
            },
            '& .DayPicker-NavButton > button':
            {
                height: 24,
                padding: 0,
                width: 24,
            },
            '& .DayPicker-NavButton--next':
            {
                right: '0.5rem',
            },
            '& .DayPicker-NavButton--prev':
            {
                left: '0.5rem',
            },

            '& .DayPicker-Week':
            {
                display: 'table-row',
            },
            '& .DayPicker-Weekday':
            {
                color: theme.palette.grey['500'],
                display: 'table-cell',
                fontSize: 12,
                lineHeight: '1.42857',
                padding: '0.5rem',
                textAlign: 'center',
            },
            '& .DayPicker-Weekdays':
            {
                display: 'table-header-group',
            },
            '& .DayPicker-WeekdaysRow':
            {
                display: 'table-row',
            },

            '& .DayPicker-wrapper':
            {
                outline: 'none',
                position: 'relative',
            },

            padding: 40,
        },

        dialogContainer:
        {
            '& .CancelButton':
            {
                bottom: 16,
                display: 'none',
                position: 'absolute',
                right: 16,

                [theme.breakpoints.down('xs')]:
                {
                    display: 'inline-block',
                },
            },
            '&:first-child':
            {
                paddingTop: 0,
            },

            height: 405,
            margin: '0 auto',
            maxWidth: '100%',
            padding: 0,
            width: 380,
        },

        inputLabelRoot:
        {
            width: 'calc(100% - 67px)',
        },

        inputLabelShrink:
        {
            width: 'calc((100% - 67px) * 1.333)',
        },

        switchYearBtn:
        {
            justifyContent: 'center !important',
            minWidth: 150,
            paddingLeft: 0,
            paddingRight: 0,
            position: 'static',  // Fix for Safari not firing click event
        },

        switchYearBtnRipple:
        {
            background: 'transparent',
            margin: '0 auto',
            maxHeight: 40,
            maxWidth: 150,
        },

        yearSelector:
        {
            '& .YearSelector-Year':
            {
                fontSize: 16,
                fontWeight: 400,
                padding: '12, 40',
                width: '100%',
            },
            '& .YearSelector-Year span':
            {
                justifyContent: 'flex-start',
            },
            '& .YearSelector-Year--selected':
            {
                background: theme.palette.grey['800'],
                color: theme.palette.getContrastText(theme.palette.grey['800']),
            },
            '& .YearSelector-Year--selected:hover':
            {
                background: darken(theme.palette.grey['800'], 0.1),
            },
            '& .YearSelector-Year:hover':
            {
                background: darken(theme.palette.common.white, 0.1),
            },
            padding: '8px 0',
        },
    });

@observer
export class DateEdit extends
    React.Component<Props & WithStyles<typeof styles>, State>
{
    private dialogContentRef?: HTMLDivElement | null;
    private lastValidDate: Date | null = null;
    private readonly localeName = 'current';
    private muiProps: muiTextField.TextFieldProps;

    public static formatValue(
        value: Date | null | string,
        userFormatted: boolean)
    {
        if (value && typeof value !== 'string')
        {
            return moment(value).format(DateEdit.getDateFormat(userFormatted));
        }

        return value;
    }

    public static getDateFormat(userFormatted: boolean)
    {
        let format = '';

        if (userFormatted)
        {
            format = Sys.settings.dateFormat;
        }
        else
        {
            format = 'yyyy-mm-dd';
        }

        // Set case of date formatter to match moment format
        // https://momentjs.com/docs/#/parsing/string-format/
        format = format.replace(/d/gi, 'D');
        format = format.replace(/m/gi, 'M');
        format = format.replace(/y/gi, 'Y');

        return format;
    }

    // Copied from posse\Outrider\Resources\posseglobal.js
    // and updated to use the month names from the current language
    public static parseDate(input: string, format: string)
    {
        let aDateVal = input;
        let aFormat = format;

        /* eslint-disable */
        let c, f, d = 0, m = 0, y = 0, i = 0, l;
        let p, pts, parts: string[];
        let prevGood = false;
        let newString = '';
        const badChars = '., -/\\';
        l = aDateVal.length;
        let str;
        while (i < l)
        {
            str = aDateVal.substr(i, 1);
            if (badChars.indexOf(str) > -1)
            {
                if (prevGood)
                {
                    newString += '/';
                    prevGood = false;
                }
            }
            else
            {
                prevGood = true;
                newString += str;
            }
            i++;
        }
        while (newString.substr(newString.length - 1) === '/')
        {
            newString = newString.substr(0, newString.length - 1);
        }
        aDateVal = newString;

        const months = Sys.settings.months.map(month => month.toUpperCase());
        const monthAbbreviations = Sys.settings.monthAbbreviations.map(
            a => a.toUpperCase());

        parts = aDateVal.split('/');
        for (i = 0; i < parts.length; i++)
        {
            if (m === 0)
            {
                const search = parts[i].toUpperCase();
                let pos = monthAbbreviations.findIndex(a => a === search);
                if (pos < 0)
                {
                    pos = months.findIndex(month => month.startsWith(search));
                }
                if (pos >= 0)
                {
                    m = pos + 1;
                }
            }
        }

        i = 0;
        pts = [];
        for (p = 0; p < parts.length; p++)
        {
            const part = parseInt(parts[p], 0);
            if (!isNaN(Number(part)))
            {
                if (part === 0)
                {
                    // User explicitly set a year of "00" with no century.
                    y = 2000;
                }
                else if (part > 31)
                {
                    y = part;
                }
                else
                {
                    pts[i] = part;
                    i++;
                }
            }
        }
        aFormat = aFormat.toUpperCase();

        p = 0;
        f = 0;
        c = '';
        if (pts.length === 1 || m > 0 && y > 0)
        {
            d = pts[p];
        }
        else
        {
            while (f < aFormat.length)
            {
                c = aFormat.charAt(f);
                if (p >= pts.length)
                {
                    break;
                }
                if (c === 'Y' && y === 0)
                {
                    y = pts[p]; p++;
                }
                else if (c === 'M' && m === 0)
                {
                    m = pts[p]; p++;
                }
                else if (c === 'D' && d === 0)
                {
                    d = pts[p]; p++;
                }
                f++;
            }
        }

        const today = new Date();
        if (y === 0)
        {
            y = today.getFullYear();
        }
        else if (y < 1000)
        {
            y = 2000 + (y * 1);
        }
        else if (y > 3999)
        {
            return null;
        }
        if (m === 0)
        {
            m = today.getMonth() + 1;
        }

        let rDate: Date | null = new Date(y, m - 1, d);
        if (rDate.getFullYear() !== y || rDate.getMonth() !== m - 1
            || rDate.getDate() !== d)
        {
            rDate = null;
        }
        /* eslint-enable */

        return rDate;
    }

    public constructor(props: Props & WithStyles<typeof styles>)
    {
        super(props);

        this.state =
        {
            calendarSelectedDate: new Date(),
            calendarVisibleMonth: new Date(),
            isDialogOpen: false,
            isFocused: false,
            showYearsView: false,
        };

        this.muiProps = {};

        this.muiProps.fullWidth = true;
        this.muiProps.id = `date-field-${Sys.nextId}`;
        this.muiProps.label = props.label;
        this.muiProps.name = props.name;
        this.muiProps.autoFocus = props['autoFocus'];
        this.muiProps.InputProps = props['InputProps'] || {};
        this.muiProps.InputProps!.placeholder = Sys.settings.dateFormat;

        // Required so that when the help is an ul the dom is valid.
        this.muiProps.FormHelperTextProps =
        {
            component: 'div',
            style:
            {
                marginLeft: 16,
                marginRight: 16,
            },
        };

        this.muiProps.onBlur = () =>
        {
            this.setState({ isFocused: false });
            this.formatCurrentValueAndSetErrors(true);
        };
        this.muiProps.onChange = (e) =>
        {
            Sys.clearBusinessErrors(this.props.dataId, this.props.name);
            this.setState({ rawValue: e.target.value });
        };
        this.muiProps.onFocus = () =>
        {
            const currentValue = this.getCurrentValueParsed(false);
            this.setState({
                isFocused: true,
                rawValue: DateEdit.formatValue(currentValue, true),
            });
            if (typeof currentValue !== 'string')
            {
                this.lastValidDate = currentValue;
            }
        };
        this.muiProps.onKeyDown = (e) =>
        {
            if (e.key === 'Enter')
            {
                this.formatCurrentValueAndSetErrors(true);
                this.setState({
                    rawValue: DateEdit.formatValue(
                        this.getCurrentValueParsed(false), true),
                });
            }
        };

        if (moment.locale() !== this.localeName)
        {
            moment.defineLocale(this.localeName, {
                months: Sys.settings.months,
                monthsShort: Sys.settings.monthAbbreviations,
                weekdays: Sys.settings.days,
                weekdaysMin: Sys.settings.dayAbbreviations,
                weekdaysShort: Sys.settings.dayAbbreviations,
            });
        }
    }

    private closeCalendar = () =>
    {
        this.setState({ isDialogOpen: false });
    };

    private focusCalendarSelectedDay = () =>
    {
        let selected = this.dialogContentRef!.querySelector(
            '.DayPicker-Day--selected') as HTMLLinkElement;
        if (!selected)
        {
            selected = this.dialogContentRef!.querySelector(
                '.DayPicker-Day[tabindex="0"]') as HTMLLinkElement;
        }
        if (selected)
        {
            selected.focus();
        }
    };

    private formatCurrentValueAndSetErrors(raw: boolean)
    {
        const widgetProperties = Api.getWidgetProperties(this.props);

        if (!widgetProperties)
        {
            return;
        }

        const businessErrors = widgetProperties['businessErrors'];
        const errorIndex = businessErrors.indexOf(this.props.dateFormatError);

        const date = this.getCurrentValueParsed(raw);
        if (typeof date === 'string')
        {
            this.setValue(this.state.rawValue!);

            if (errorIndex < 0)
            {
                businessErrors.push(this.props.dateFormatError);
            }
        }
        else
        {
            this.setFormattedValue(date);

            if (errorIndex >= 0)
            {
                businessErrors.splice(errorIndex, 1);
            }
        }
    }

    private getCalendarContent()
    {
        return (
            <DayPicker.default
                className={this.props.classes.calendar}
                initialMonth={this.state.calendarVisibleMonth}
                locale={this.localeName}
                localeUtils={LocaleUtils}
                selectedDays={this.state.calendarSelectedDate}
                showOutsideDays
                onDayClick={(date) =>
                {
                    this.setFormattedValue(date);
                    this.formatCurrentValueAndSetErrors(false);
                    this.closeCalendar();
                }}
                captionElement={(props) =>
                {
                    const classes = this.props.classes;

                    return (
                        <div className={props.classNames.caption}>
                            <Button
                                arial-label={
                                    Sys.getTranslation('Switch year', 'DateEdit')}
                                className={classes.switchYearBtn}
                                variant="text"
                                onClick={this.switchToYearsView}
                                TouchRippleProps={{
                                    className: classes.switchYearBtnRipple,
                                }}
                            >
                                {props.localeUtils.formatMonthTitle(
                                    props.date, props.locale)}
                            </Button>
                        </div>);
                }}
                navbarElement={(props) =>
                {
                    return (
                        <div className={props.className}>
                            <span className={props.classNames.navButtonPrev}>
                                <Button
                                    arial-label={
                                        Sys.getTranslation(
                                            'Previous month', 'DateEdit')}
                                    icon="fas fa-chevron-left"
                                    size="small"
                                    onClick={() => props.onPreviousClick()}
                                />
                            </span>
                            <span className={props.classNames.navButtonNext}>
                                <Button
                                    arial-label={
                                        Sys.getTranslation(
                                            'Next month', 'DateEdit')}
                                    icon="fas fa-chevron-right"
                                    size="small"
                                    onClick={() => props.onNextClick()}
                                />
                            </span>
                        </div>
                    );
                }}
                renderDay={(day, modifiers) =>
                {
                    return (
                        <Button variant="text" tabIndex={-1}>
                            {day.getDate()}
                        </Button>);
                }}
                weekdayElement={(props) =>
                {
                    return (
                        <Typography
                            classes={{ root: props.className }}
                            component="span"
                        >
                            {props.localeUtils.formatWeekdayShort(
                                props.weekday, props.locale)}
                        </Typography>
                    );
                }}
            />
        );
    }

    private getCurrentValueParsed(raw: boolean)
    {
        const value = raw
            ? this.state.rawValue
            : Presentation.getValue(this.props);

        let parsed: Date | null | string = null;
        if (value)
        {
            const format = DateEdit.getDateFormat(raw);
            const date = moment(value, format, raw ? this.localeName : 'en', true);
            if (date.isValid())
            {
                parsed = date.toDate();
            }
            else
            {
                if (raw)
                {
                    parsed = DateEdit.parseDate(value, format);
                    if (!parsed)
                    {
                        parsed = value;
                    }
                }
                else
                {
                    parsed = value;
                }
            }
        }

        return parsed;
    }

    private getYearClass(year: number)
    {
        return `YearSelector-Year
            ${year === this.state.calendarVisibleMonth!.getFullYear() ?
            ' YearSelector-Year--selected' : ''}`;
    }

    private getYearTabIndex(year: number)
    {
        return year === this.state.calendarVisibleMonth!.getFullYear() ? 0 : -1;
    }

    private navigateYears = (event: React.KeyboardEvent<HTMLElement>) =>
    {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const targetElement = event.target as any;

        switch (event.key)
        {
            case 'ArrowDown':
                if (targetElement.nextSibling)
                {
                    targetElement.nextSibling.focus();
                    event.preventDefault();
                }
                break;
            case 'ArrowUp':
                if (targetElement.previousSibling)
                {
                    targetElement.previousSibling.focus();
                    event.preventDefault();
                }
                break;
            default:
        }
    };

    private openCalendar = () =>
    {
        let currentDate = this.getCurrentValueParsed(false);
        if (!currentDate)
        {
            currentDate = new Date();
        }
        else if (typeof currentDate === 'string')
        {
            currentDate = this.lastValidDate ? this.lastValidDate : new Date();
        }

        this.setState({
            calendarSelectedDate: currentDate,
            calendarVisibleMonth: currentDate,
            isDialogOpen: true,
            showYearsView: false,
        });
    };

    private selectYear(year: number, event: React.MouseEvent<HTMLElement>)
    {
        if (event.clientX === 0)  // Only focus when using keyboard
        {
            setTimeout(this.focusCalendarSelectedDay);
        }

        this.setState((lastState) =>
        {
            const currentMonth = lastState.calendarVisibleMonth!.getMonth();

            return {
                calendarVisibleMonth: new Date(year, currentMonth, 1),
                showYearsView: false,
            };
        });
    }

    private setFormattedValue(value: Date | null)
    {
        this.setValue(DateEdit.formatValue(value, false));
    }

    private setValue(value: string | null)
    {
        Sys.clearBusinessErrors(this.props.dataId, this.props.name);
        Presentation.setValue(this.props, value);
    }

    private switchToYearsView = () =>
    {
        this.setState({ showYearsView: true });
        setTimeout(() =>
        {
            const container = this.dialogContentRef!.parentElement!;
            const item = container.querySelector(
                '.YearSelector-Year--selected')! as HTMLLinkElement;
            const top = item.offsetTop + item.offsetHeight / 2
                - container.offsetHeight / 2;

            container.scrollTop = top;
            item.focus();
        });
    };

    public render()
    {
        const _props = { ...this.props };
        const widgetProperties =
           Api.getWidgetProperties(_props) as WidgetProperties;
        let result: React.ReactNode = null;

        if (!widgetProperties)
        {
            return null;
        }

        if (!Api.setAccessLevel(_props, widgetProperties))
        {
            return result;
        }

        this.muiProps.disabled = false;
        this.muiProps.InputProps!.endAdornment = undefined;
        this.muiProps.required = false;
        this.muiProps.style = undefined;
        this.muiProps.variant = 'filled';

        if (_props['disabled'])
        {
            this.muiProps.disabled = true;
            this.muiProps.helperText = null;
            this.muiProps.value = '';

            if (widgetProperties.showDisabledHelp)
            {
                result =
                    <InformationBadge message={_props.disabledHelpText}>
                        <muiTextField.default {...this.muiProps} />
                    </InformationBadge>;
            }
            else
            {
                result = <muiTextField.default {...this.muiProps} />;
            }
        }
        else if (_props['readOnly'])
        {
            const value =
                DateEdit.formatValue(this.getCurrentValueParsed(false), true);

            result =
                <div>
                    <Typography
                        component="div"
                        ellipsis
                        style={{ marginBottom: 8 }}
                        variant="caption"
                    >
                        {_props.label}
                    </Typography>
                    <Typography component="div">
                        {value ? value : '-'}
                    </Typography>
                </div>;
        }
        else
        {
            this.muiProps.InputProps!.endAdornment =
            (
                <muiInputAdornment.default
                    position="end"
                    style={{ marginTop: -4 }}
                >
                    <Button
                        aria-label={Sys.getTranslation('Select Date')}
                        icon="fas fa-calendar-alt"
                        size="small"
                        onClick={this.openCalendar}
                    />
                </muiInputAdornment.default>
            );

            this.muiProps.required = widgetProperties.showAsMandatory;

            Api.setHelperText(
                this,
                widgetProperties['businessErrors'],
                this.muiProps);

            let formatted: string | null;
            if (this.state.isFocused)
            {
                formatted = this.state.rawValue!;
            }
            else
            {
                formatted = DateEdit.formatValue(
                    this.getCurrentValueParsed(false), true);
            }
            this.muiProps.value = formatted === null ? '' : formatted;

            const years: number[] = [];
            const currentYear = this.state.calendarVisibleMonth!.getFullYear();
            for (let i = currentYear - 50; i <= currentYear + 50; i += 1)
            {
                years.push(i);
            }

            result =
                <React.Fragment>
                    <muiTextField.default
                        {...this.muiProps}
                        InputLabelProps={
                            {
                                classes:
                                {
                                    root: this.props.classes.inputLabelRoot,
                                    shrink: this.props.classes.inputLabelShrink,
                                },
                            }
                        }
                    />

                    <Dialog
                        onClose={this.closeCalendar}
                        open={this.state.isDialogOpen!}
                        onEntered={this.focusCalendarSelectedDay}
                    >
                        <muiDialogContent.default
                            className={this.props.classes.dialogContainer}
                        >
                            <div
                                ref={r => this.dialogContentRef = r}
                                style={{ margin: '0 auto' }}
                            >
                                {this.state.showYearsView ?
                                    <div
                                        className={this.props.classes.yearSelector}
                                    >
                                        {years.map(year => (
                                            <Button
                                                className={this.getYearClass(year)}
                                                key={year}
                                                onClick={e =>
                                                    this.selectYear(year, e)}
                                                onKeyDown={this.navigateYears}
                                                tabIndex={
                                                    this.getYearTabIndex(year)
                                                }
                                                variant="text"
                                            >
                                                {year}
                                            </Button>
                                        ))}
                                    </div>
                                :
                                    this.getCalendarContent()
                                }
                                {!this.state.showYearsView ? (
                                    <Button
                                        className="CancelButton"
                                        onClick={this.closeCalendar}
                                    >
                                        Cancel
                                    </Button>
                                ) : null}
                            </div>
                        </muiDialogContent.default>
                    </Dialog>
                </React.Fragment>;
        }

        return result;
    }
}

export default withStyles(styles)(DateEdit);
