import { createStyles, Theme, WithStyles, withStyles }
    from '@material-ui/core/styles';
import withWidth, { WithWidth } from '@material-ui/core/withWidth';
import { observer } from 'mobx-react';
import * as React from 'react';
import Button, { ButtonProps } from '../coreui/Button';
import Icon from '../coreui/Icon';
import InformationBadge from '../coreui/InformationBadge';
import Link from '../coreui/Link';
import Api, { AccessLevel, ValueByBreakpoint } from './Api';

export interface ApiButtonProps
{
    alternateText?: string;
    buttonColor: 'Danger' | 'Dark' | 'Default' | 'Success';
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    buttonRef?: React.Ref<any> | React.RefObject<any>;
    component?: 'span' | 'button';
    dataId: string;
    disabled?: boolean;
    disabledHelpText: string;
    endIcon?: string;
    href?: string;
    iconName?: string;
    label?: string;
    name: string;
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
    renderAsLink?: boolean;
    size: 'large' | 'medium' | 'small' | undefined
        | ValueByBreakpoint<'large' | 'medium' | 'small'>;
    tabIndex?: number;
    target?: string;
}

interface WidgetProperties
{
    accessLevel: AccessLevel;
    showDisabledHelp: boolean;
}

const styles = (theme: Theme) => createStyles(
    {
        root:
        {
        },
    });

@observer
export class ApiButton extends
    React.Component<ApiButtonProps & WithWidth & WithStyles<typeof styles>>
{
    protected muiProps: ButtonProps;

    public constructor(
        props: ApiButtonProps & WithWidth & WithStyles<typeof styles>
        )
    {
        super(props);

        this.state = { anchorElement: null };

        this.muiProps =
        {
            buttonRef: props.buttonRef,
            component: props.component || 'button',
            endIcon: props.endIcon,
            href: props.href,
            icon: props.iconName,
            onClick: props['onClick'],
            style: props['style'],
            tabIndex: props.tabIndex,
            target: props.target,
            variant: props['variant'],
        };

        if (props.buttonColor !== 'Default')
        {
            this.muiProps.color = props.buttonColor.toLowerCase();
        }

        if (props.alternateText)
        {
            this.muiProps['aria-label'] = props.alternateText;
        }
    }

    public render()
    {
        const _props = { ...this.props };
        const widgetProperties =
            Api.getWidgetProperties(_props) as WidgetProperties;
        let result: React.ReactNode = null;

        if (!widgetProperties)
        {
            return null;
        }

        this.muiProps.label = _props.label;
        this.muiProps.className = `${_props.classes.root} `;
        this.muiProps['children'] = _props['children'];
        delete _props.size;

        const renderLink = _props.renderAsLink
            && (_props.label || this.muiProps['children']);

        const renderButtonOrLink = (buttonProps: ButtonProps) =>
        {
            if (renderLink)
            {
                const children: React.ReactNode[] = [];
                if (this.muiProps['children'])
                {
                    children.push(this.muiProps['children'] as React.ReactNode);
                }
                else
                {
                    children.push(<span key="1">{_props.label}</span>);
                }

                if (_props['endIcon'])
                {
                    children.push(
                        <Icon
                            key="2"
                            icon={_props['endIcon']}
                            style={{ marginLeft: '.4em' }}
                        />);
                }

                return (
                    <Link
                        aria-label={_props.alternateText}
                        buttonRef={_props.buttonRef}
                        children={children}
                        disabled={buttonProps.disabled}
                        href={_props.href}
                        iconName={_props.iconName}
                        onClick={_props.onClick}
                        tabIndex={_props.tabIndex}
                        target="_self"
                    />);
            }

            let size: 'large' | 'medium' | 'small' | undefined = undefined;
            if (!this.props.size || typeof this.props.size === 'string')
            {
                size = this.props.size;
            }
            else if (this.props.width in this.props.size)
            {
                size = this.props.size[this.props.width];
            }

            return (
                <Button
                    {...buttonProps}
                    fullWidth={true}
                    size={size}
                />);
        };

        if (Api.setAccessLevel(_props, widgetProperties))
        {
            if (_props['disabled'])
            {
                this.muiProps.disabled = true;

                if (widgetProperties['showDisabledHelp'])
                {
                    result =
                        <InformationBadge
                            fullWidth={true}
                            message={_props.disabledHelpText}
                            tabIndex={_props.tabIndex}
                        >
                            {renderButtonOrLink(this.muiProps)}
                        </InformationBadge>;
                }
                else
                {
                    result = renderButtonOrLink(this.muiProps);
                }
            }
            else
            {
                this.muiProps.disabled = false;

                result = renderButtonOrLink(this.muiProps);
            }
        }

        return result;
    }
}

export default withStyles(styles)(withWidth()(ApiButton));
