import { createStyles, Theme, WithStyles, withStyles }
    from '@material-ui/core/styles';
import * as React from 'react';
import SubscribeEvent from '../../core/SubscribeEvent';
import PaneRow from '../../models/PaneRow';
import GridNewRowChip from '../../mustangui/GridNewRowChip';
import Paper from '../Paper';
import Presentation from '../Presentation';
import { TableChildProps, TableVerticalLayoutProps } from '../Table';

export interface Props
{
    cardDepth: number;
    child: object;
    data: PaneRow;
    onTableReady: SubscribeEvent;
    propagated: TableChildProps;
    selectToolbarChild?: object | null;
}

interface State
{
    isTableReady?: boolean;
}

const styles = (theme: Theme) => createStyles(
    {
        print:
        {
            '@media print':
            {
                display: 'none',
            },
        },
        root:
        {
            whiteSpace: 'normal',
        },
    });

export class FullWidthRenderer extends
    React.Component<Props & WithStyles<typeof styles>, State>
{
    public constructor(props: Props & WithStyles<typeof styles>)
    {
        super(props);

        this.state =
        {
            isTableReady: props.propagated.parentTable.isTableReady(),
        };

        props.onTableReady.addEventListener(this.onTableReady);
    }

    private onTableReady = () =>
    {
        this.setState({ isTableReady: true });
    };

    public componentWillUnmount()
    {
        this.props.onTableReady.removeEventListener(this.onTableReady);
    }

    public render()
    {
        const tableProps = this.props.propagated.parentTable.configProps;

        let newChip: React.ReactNode = null;

        if (this.props.data.isNew)
        {
            newChip =
                <React.Fragment>
                    <div className="cx-new-container-border-xs" />
                    <div className="cx-new-container-xs">
                        <GridNewRowChip />
                    </div>
                </React.Fragment >;
        }

        return (
            <Paper
                card
                cardDepth={this.props.cardDepth}
                className={this.props.classes.root}
                margin
                elevation={0}
                id={`${tableProps.contentDataId}.${tableProps.name}.`
                    + `${this.props.data.getPrimaryKey()}`}
            >
                {newChip}
                <div className={this.props.classes.print}>
                    {this.state.isTableReady && this.props.selectToolbarChild
                        ? Presentation.create(
                            this.props.selectToolbarChild,
                            {
                                ...this.props.propagated,
                                data: this.props.data,
                                parentRowKey: this.props.data.rowKey,
                                parentRowObjectHandle:
                                    this.props.data.objectHandle,
                            })
                        : null}
                </div>
                {Presentation.create(
                    this.props.child,
                    {
                        parentTable: this.props.propagated.parentTable,
                        rowKey: this.props.data.rowKey,
                    } as TableVerticalLayoutProps)}
            </Paper>);
    }
}

export default withStyles(styles)(FullWidthRenderer);
