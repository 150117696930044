import * as React from 'react';
import Icon from '../../coreui/Icon';
import { FocusCellRendererParams } from '../../coreui/table/CellFocusUtil';
import { CellUtil } from '../../coreui/table/CellUtil';
import PaneRow, { RuntimeWidget } from '../../models/PaneRow';
import Api, { AccessLevel } from '../Api';
import { FunctionName } from '../TableSummary';

interface ConfigProperties extends FocusCellRendererParams
{
    dataId: string;
    name: string;
}

interface RuntimeProperties
{
    accessLevel: AccessLevel;
    alternateText: string;
    iconName: string;
}

export class IconDisplayColumn extends
    React.PureComponent<ConfigProperties>
{
    public static getSummaryValue(
        runtimeData: RuntimeWidget[],
        configProperties: ConfigProperties,
        functionName: FunctionName
        ): string | null
    {
        let result = 0;
        for (const data of runtimeData)
        {
            const runtimeProperties = data.properties as RuntimeProperties;

            if (runtimeProperties.accessLevel >= AccessLevel.readOnly)
            {
                result += 1;
            }
        }

        return result.toString();
    }

    public constructor(props: ConfigProperties)
    {
        super(props);

        props.eGridCell.addEventListener('keydown', this.onCellKeyDown);
    }

    private onCellKeyDown = (event: KeyboardEvent): void =>
    {
        CellUtil.customizeGridNavigation(event, this.props);
    };

    public componentWillUnmount(): void
    {
        this.props.eGridCell.removeEventListener('keydown', this.onCellKeyDown);
    }

    public render(): React.ReactNode
    {
        const runtimeProperties = Api.getWidgetProperties(
            this.props, this.props.data) as RuntimeProperties;

        if (!runtimeProperties)
        {
            return null;
        }

        CellUtil.setReadOnlyAttribute(this.props.eGridCell, true);

        if (runtimeProperties.accessLevel <= AccessLevel.hidden)
        {
            return null;
        }

        return (
            <div
                style={
                    {
                        alignItems: 'center',
                        display: 'flex',
                        height: '100%',
                        margin: '0 24px 0 22px',
                        width: '16px',
                    }}
            >
                <Icon
                    aria-label={runtimeProperties.alternateText}
                    icon={runtimeProperties.iconName}
                    style={
                        {
                            fontSize: 16,
                            height: 'auto',
                        }}
                />
            </div>);
    }
}

export default IconDisplayColumn;
