import { createStyles, Theme, WithStyles, withStyles }
    from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import * as React from 'react';
import TrackableCollection from '../../core/TrackableCollection';
import TrackableModel from '../../core/TrackableModel';
import { Menu as MenuBase } from '../../coreui/Menu';
import MenuItem from '../../coreui/MenuItem';
import { TableChildProps } from '../../coreui/Table';
import PaneRow from '../../models/PaneRow';
import { RoundTripProps } from '../../services/ActionButtonService';
import { ActionButtonProps, ActionButtonRuntimeProps } from '../ActionButton';
import { AccessLevel } from '../Api';
import ApiButton from '../ApiButton';
import { MenuItemProps } from '../MenuItem';
import { SelectChildProps } from '../SelectControl';
import ConfirmationDialog from './ConfirmationDialog';

interface Props extends ActionButtonProps
{
}

interface State
{
    isConfirmDialogOpen?: boolean;
}

const styles = (theme: Theme) => createStyles(
    {
        root:
        {
        },
    });

@observer
export class DeleteRowButton extends
    React.PureComponent<Props & WithStyles<typeof styles>, State>
{
    private static onClick(
        props: RoundTripProps & {
            propagated: TableChildProps & SelectChildProps;
        }
        ): void
    {
        const parentTable = props.propagated.parentTable;

        if (props.propagated.parentSelect)
        {
            let objectHandles: string[];
            if (parentTable.isVerticalLayout)
            {
                objectHandles = [props.propagated.parentRowObjectHandle!];
            }
            else
            {
                objectHandles = parentTable.selection.getSelectedRows().map(
                    r => r.objectHandle);
            }

            props.propagated.parentSelect.onRowsRemoved(objectHandles);

            return;
        }

        if (parentTable.isVerticalLayout)
        {
            TrackableCollection.deleteRow(
                parentTable.configProps.contentDataId!,
                props.propagated.parentRowKey!);
        }
        else
        {
            const collection = TrackableModel.models.get(
                parentTable.configProps.contentDataId!) as TrackableCollection;

            collection.delete(parentTable.selection.getSelectedRows());
        }

        parentTable.populateData();
    }

    public static renderMenuItem(props: MenuItemProps): JSX.Element
    {
        const configProps = props.config as Props;
        const runtimeProps = props.runtime as ActionButtonRuntimeProps;

        const [isConfirmDialogOpen, setIsConfirmDialogOpen] =
            React.useState<boolean>(false);

        const onClick = () =>
        {
            if (props.runtime.accessLevel >= AccessLevel.actionable)
            {
                if (runtimeProps.confirmMessage)
                {
                    setIsConfirmDialogOpen(true);
                }
                else
                {
                    DeleteRowButton.onClick(configProps);
                    MenuBase.closeAll();
                }
            }
        };

        const onAcceptConfirm = () =>
        {
            setIsConfirmDialogOpen(false);
            DeleteRowButton.onClick(configProps);
            MenuBase.closeAll();
        };

        const onCancelConfirm = () =>
        {
            setIsConfirmDialogOpen(false);
        };

        return (
            <React.Fragment>
                {runtimeProps.confirmMessage ? (
                    <ConfirmationDialog
                        cancelButtonText={runtimeProps.cancelButtonText}
                        continueButtonColor={configProps.buttonColor}
                        continueButtonIcon={configProps.iconName}
                        continueButtonText={runtimeProps.continueButtonText}
                        isOpen={isConfirmDialogOpen}
                        message={runtimeProps.confirmMessage}
                        onCancel={onCancelConfirm}
                        onContinue={onAcceptConfirm}
                        title={runtimeProps.label
                            ? runtimeProps.label : configProps.alternateText}
                    />
                ) : null}
                <MenuItem
                    disabled={props.runtime.accessLevel === AccessLevel.disabled}
                    iconName={configProps.iconName}
                    indent={props.config.propagated
                        ? props.config.propagated.indent : 0}
                    onClick={onClick}
                >
                    {runtimeProps.label}
                </MenuItem>
            </React.Fragment>
        );
    }

    public constructor(props: Props & WithStyles<typeof styles>)
    {
        super(props);

        this.state = { isConfirmDialogOpen: false };
    }

    private onAcceptConfirm = () =>
    {
        this.setState({ isConfirmDialogOpen: false });
        DeleteRowButton.onClick(this.props);
    };

    private onCancelConfirm = () =>
    {
        this.setState({ isConfirmDialogOpen: false });
    };

    private onClick = () =>
    {
        const runtimeProperties = PaneRow.getWidgetProperties(
            this.props.dataId, this.props.name) as ActionButtonRuntimeProps;

        if (runtimeProperties.confirmMessage)
        {
            this.setState({ isConfirmDialogOpen: true });
        }
        else
        {
            DeleteRowButton.onClick(this.props);
        }
    };

    public render()
    {
        const runtimeProperties = PaneRow.getWidgetProperties(
            this.props.dataId, this.props.name) as ActionButtonRuntimeProps;

        return (
            <React.Fragment>
                {runtimeProperties.confirmMessage ? (
                    <ConfirmationDialog
                        cancelButtonText={runtimeProperties.cancelButtonText}
                        continueButtonColor={this.props.buttonColor}
                        continueButtonIcon={this.props.iconName}
                        continueButtonText={
                            runtimeProperties.continueButtonText}
                        isOpen={this.state.isConfirmDialogOpen!}
                        message={runtimeProperties.confirmMessage}
                        onCancel={this.onCancelConfirm}
                        onContinue={this.onAcceptConfirm}
                        title={runtimeProperties.label
                            ? runtimeProperties.label : this.props.alternateText}
                    />
                ) : null}
                <ApiButton
                    alternateText={this.props.alternateText}
                    buttonColor="Danger"
                    dataId={this.props.dataId}
                    disabledHelpText={this.props.disabledHelpText}
                    iconName={this.props.iconName}
                    label={runtimeProperties.label}
                    name={this.props.name}
                    onClick={this.onClick}
                    size="small"
                    tabIndex={-1}
                />
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(DeleteRowButton);
