import * as muiCheckbox from '@material-ui/core/Checkbox';
import { ICellRendererParams } from 'ag-grid-community';
import * as React from 'react';
import FormControlLabel from '../FormControlLabel';
import Icon from '../Icon';
import { TableChildProps } from '../Table';
import { CellUtil } from './CellUtil';

interface Props extends ICellRendererParams {
    propagated: TableChildProps;
}

interface State
{
    isChecked?: boolean;
}

class SelectionRenderer extends React.Component<Props, State>
{
    protected muiProps: muiCheckbox.CheckboxProps;

    public constructor(props: Props)
    {
        super(props);

        this.state = { isChecked: false };

        this.muiProps =
        {
            checkedIcon: <Icon icon="fas fa-check-square" />,
            color: 'default',
            icon: <Icon icon="far fa-square" />,
            onChange: (
                event: React.ChangeEvent<HTMLInputElement>,
                checked: boolean) =>
            {
                this.props.propagated.parentTable.selection.setRowsSelected(
                    [this.props.node], checked);
                this.setState({ isChecked: checked });
            },
            tabIndex: -1,
        };

        CellUtil.runOnAction(props.eGridCell, () =>
        {
            this.setState(
                (prevState) =>
                {
                    return { isChecked: !prevState.isChecked };
                },
                () =>
                {
                    this.props.propagated.parentTable.selection.setRowsSelected(
                        [this.props.node], this.state.isChecked!);
                });
        });

        CellUtil.setReadOnlyAttribute(props.eGridCell, false);
        props.eGridCell.addEventListener('keydown', this.onCellKeyDown);

        props.eGridCell.setAttribute('aria-label', 'Select Row');
    }

    private onCellKeyDown = (event: KeyboardEvent): void =>
    {
        CellUtil.customizeGridNavigation(event, this.props);
    };

    public componentWillUnmount(): void
    {
        this.props.eGridCell.removeEventListener('keydown', this.onCellKeyDown);
    }

    public refresh(): boolean
    {
        this.forceUpdate();

        return true;
    }

    public render(): React.ReactNode
    {
        const _props = { ...this.props };

        this.muiProps.checked =
            this.props.propagated.parentTable.selection.isRowSelected(
                _props.node);

        return (
            <div
                style={
                    {
                        alignItems: 'center',
                        display: 'flex',
                        height: '100%',
                        margin: '0 24px',
                    }}
            >
                <FormControlLabel
                    control={
                        <muiCheckbox.default {...this.muiProps} />}
                    label=""
                    style={{ maxWidth: 'none' }}
                />
            </div>);
    }
}

export default SelectionRenderer;
