import { createStyles, Theme, WithStyles, withStyles }
    from '@material-ui/core/styles';
import * as React from 'react';
import Paper, { PaperProps } from '../coreui/Paper';
import ErrorBoundary from './ErrorBoundary';

interface Props
{
    cardDepth: number;
    containerStyle: 'Blended' | 'Visual';
    controlKey: string;
    controlType: 'Custom' | 'Expansion' | 'List' | 'Static';
    dataId: string;
    paneKey: string;
}

const styles = (theme: Theme) => createStyles(
    {
        root:
        {
        },
    });

export class SubPane extends
    React.PureComponent<Props & WithStyles<typeof styles>>
{
    protected muiProps: PaperProps;

    public constructor(props: Props & WithStyles<typeof styles>)
    {
        super(props);

        this.muiProps =
        {
            blended: props.containerStyle === 'Blended',
            card: props.containerStyle === 'Visual',
            cardDepth: props.cardDepth,
            elevation: 0,
            group: null,
            margin: props.containerStyle === 'Visual',
            name: null,
        };

        const rowKey =
            props['propagated'] ? props['propagated']['rowKey'] : null;
        let controlKey = props.controlKey;
        let paneKey = props.paneKey;

        if (rowKey)
        {
            controlKey += `_${rowKey}`;
            paneKey += `_${rowKey}`;
        }

        if (props.controlType === 'Custom')
        {
            this.muiProps.dataId = props.dataId;
            this.muiProps.group = controlKey;
            this.muiProps.name = paneKey;
        }
        else if (props.controlType === 'Expansion')
        {
            this.muiProps.collapse = true;
            this.muiProps.name = paneKey;
        }
        else if (props.controlType === 'List')
        {
            this.muiProps.fade = 500;
            this.muiProps.group = controlKey;
            this.muiProps.name = paneKey;
        }
    }

    public render()
    {
        const _props = { ...this.props };

        this.muiProps.className = _props.classes.root;
        this.muiProps.children =
            <ErrorBoundary title={_props.paneKey}>
                {_props.children}
            </ErrorBoundary>;

        return <Paper {...this.muiProps} />;
    }
}

export default withStyles(styles)(SubPane);
