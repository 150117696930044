import Sys from '../core/Sys';
import
{
    AuthenticatedPage,
    AuthenticatedPageConfig,
} from '../pages/AuthenticatedPage';
import { ErrorPage, ErrorPageConfig } from '../pages/ErrorPage';
import { GuestPage, GuestPageConfig } from '../pages/GuestPage';
import { LandingPage, LandingPageConfig } from '../pages/LandingPage';
import CustomIconStore from '../stores/CustomIconStore';
import BaseService from './BaseService';

export interface SystemConfig
{
    ajaxTimeoutMilliSeconds: number;
    authenticatedPage: AuthenticatedPageConfig;
    availableLanguages: { code: string; description: string }[];
    colorPalette:
    {
        danger: string;
        information: string;
        primary: string;
        secondary: string;
        success: string;
        warning: string;
    };
    currentLanguageCode: string;
    customIconsByName: { [name: string]: string };
    dateFormat: string;
    dayAbbreviations: string[];
    days: string[];
    decimalSeparator: string;
    enableEmailAuthentication: boolean;
    environmentBannerColor: string | null;
    errorPage: ErrorPageConfig;
    favIconUrl: string;
    guestPage: GuestPageConfig;
    landingPage: LandingPageConfig;
    monthAbbreviations: string[];
    months: string[];
    nonProdEnvironment: string;
    reCaptchaSiteKey: string;
    rootUrl: string;
    siteName: string;
    thousandsSeparator: string;
    translations: object;
    useConfiguredAuthentication: boolean;
}

export default class SystemConfigService
{
    private static async getSystemConfig(
        languageCode: string | null
        ): Promise<SystemConfig>
    {
        let requestUrl = 'SystemConfig';
        if (languageCode)
        {
            requestUrl += `/${languageCode}`;
        }

        return BaseService.requestObject(requestUrl, null, null, null, 'GET');
    }

    private static setFavIcon(favIconUrl: string): void
    {
        const favIcon = document.getElementById('favIcon') as HTMLLinkElement;

        if (favIcon)
        {
            favIcon.href = favIconUrl;
        }
    }

    private static setLanguage(code: string): void
    {
        const html: HTMLElement = document.documentElement;

        if (html)
        {
            html.lang = code;
        }
    }

    public static async loadConfig(
        languageCode: string | null
        ): Promise<void>
    {
        const config: SystemConfig = await SystemConfigService.getSystemConfig(
            languageCode);

        SystemConfigService.setFavIcon(config.favIconUrl);
        SystemConfigService.setLanguage(config.currentLanguageCode);
        AuthenticatedPage.setConfig(config.authenticatedPage);
        BaseService.requestTimeoutMilliseconds = config.ajaxTimeoutMilliSeconds;
        CustomIconStore.load(config.customIconsByName);
        ErrorPage.setConfig(config.errorPage);

        if (config.useConfiguredAuthentication)
        {
            // Guest/Unauthenticated pages are only available if Use Configured
            // Authentication is enabled.
            GuestPage.setConfig(config.guestPage);
            LandingPage.setConfig(config.landingPage);
        }

        // FUTURE
        // Move these items out of a generic settings collection and into
        // specialized services / stores. That way the information can be
        // organized according to business domain.
        //
        // Heading comments are provided as an indication of some preliminary
        // thinking about where these settings can go.

        // Authentication
        Sys.settings.enableEmailAuthentication =
            config.enableEmailAuthentication;
        Sys.settings.useConfiguredAuthentication =
            config.useConfiguredAuthentication;

        // Language
        Sys.settings.availableLanguages = config.availableLanguages;
        Sys.settings.currentLanguageCode = config.currentLanguageCode;
        Sys.settings.dateFormat = config.dateFormat;
        Sys.settings.dayAbbreviations = config.dayAbbreviations;
        Sys.settings.days = config.days;
        Sys.settings.decimalSeparator = config.decimalSeparator;
        Sys.settings.monthAbbreviations = config.monthAbbreviations;
        Sys.settings.months = config.months;
        Sys.settings.thousandsSeparator = config.thousandsSeparator;
        Sys.settings.translations = config.translations;

        // Site
        Sys.settings.environmentBannerColor = config.environmentBannerColor;
        Sys.settings.nonProdEnvironment = config.nonProdEnvironment;
        Sys.settings.reCaptchaSiteKey = config.reCaptchaSiteKey;
        Sys.settings.rootUrl = config.rootUrl;
        Sys.settings.siteName = config.siteName;

        // Theme
        Sys.settings.baselineGridSize = 4;
        Sys.settings.colorPalette = config.colorPalette;
    }
}
