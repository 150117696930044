import { createStyles, Theme, WithStyles, withStyles }
    from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import * as React from 'react';
import FormControl from './FormControl';
import Presentation, { TextFieldProps } from './Presentation';
import Typography from './Typography';

interface Props extends TextFieldProps
{
    style?: React.CSSProperties;
}

const styles = (theme: Theme) => createStyles(
    {
        label:
        {
            color: theme.palette.grey[700],
            marginBottom: 8,
        },
        root:
        {
            '&:hover': { backgroundColor: 'transparent' },
            backgroundColor: 'transparent',
        },
    });

@observer
export class FormData extends
    React.Component<Props & WithStyles<typeof styles>>
{
    public render()
    {
        const _props = { ...this.props };

        if (_props.dataId)
        {
            if (_props.name && !_props.children)
            {
                _props.children = Presentation.getValue(_props);
            }

            delete _props.dataId;
            delete _props.name;
        }

        return (
            <FormControl
                className={_props.classes.root}
                fullWidth
                style={_props.style}
            >
                <Typography
                    variant="caption"
                    ellipsis
                    className={_props.classes.label}
                >
                    {_props.label}
                </Typography>
                <Typography
                    component="div"
                    style={{ minHeight: 20 }}
                >
                    {_props.children}
                </Typography>
            </FormControl>);
    }
}

export default withStyles(styles)(FormData);
