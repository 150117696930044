import { observable } from 'mobx';
import Logging from '../core/Logging';
import { Menu as MenuBase } from '../coreui/Menu';
import Presentation from '../coreui/Presentation';
import { Select as SelectBase } from '../coreui/Select';
import { Toast } from '../coreui/Toast';
import Credentials from '../models/Credentials';
import PaneRow from '../models/PaneRow';
import Api from '../mustangui/Api';
import { ApiSelect as ApiSelectBase } from '../mustangui/ApiSelect';
import { EmbeddedAddOn as EmbeddedAddOnBase } from '../mustangui/EmbeddedAddOn';
import BaseService from '../services/BaseService';
import SystemConfigService from '../services/SystemConfigService';
import ConfirmContinueStore from '../stores/ConfirmContinueStore';
import RequestsStore from '../stores/RequestsStore';
import AppServer from './AppServer';
import Monitor from './Monitor';
import Routing from './Routing';
import TrackableCollection from './TrackableCollection';
import TrackableModel from './TrackableModel';

export interface ISettings
{
    availableLanguages: { code: string; description: string }[];
    baselineGridSize: number;
    colorPalette:
    {
        danger: string;
        information: string;
        primary: string;
        secondary: string;
        success: string;
        warning: string;
    } | null;
    currentLanguageCode: string;
    dateFormat: string;
    dayAbbreviations: string[];
    days: string[];
    decimalSeparator: string;
    enableEmailAuthentication: boolean;
    environmentBannerColor: string | null;
    favIcon: string;
    monthAbbreviations: string[];
    months: string[];
    nonProdEnvironment: string;
    reCaptchaSiteKey: string;
    rootUrl: string;
    siteName: string;
    thousandsSeparator: string;
    translations: object;
    useConfiguredAuthentication: boolean;
}

export interface BusinessError
{
    message: string;
    rows: {
        dataId: string;
        parentGridDescription: string;
        parentGridKey: string;
        rowKey: string;
        topPaneUseKey?: string;
    }[];
    widgets: {
        dataId: string;
        isColumnWidget: boolean;
        parentGridDescription: string | null;
        parentGridKey: string | null;
        rowKey: string;
        topPaneUseKey?: string;
        widgetName: string;
    }[];
}

export interface SessionMessage
{
    message: string;
    messageId: number;
    messageType: 'Caution' | 'Danger' | 'Info' | 'Success';
}

export default class Sys
{
    private static currentNextId: number = 1;
    // Grab the cookie path from the url, this works since it's a single page app
    private static readonly cookiePath =
        window.location.pathname || '/';
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private static requestCache: any = null;
    public static baseUrl: string | null = null;
    @observable
    public static businessErrors: BusinessError[];
    public static currentCredentials: Credentials;
    public static fileUrl: string | null = 'FileHandler.ashx';
    public static guestSessionTokenCookie: string = 'guestSessionToken';
    // Indicates if pending changes should be ignored.
    public static ignoreChanges: boolean = false;
    // Indicates if changes to the hash should be ignored.
    public static ignoreHashChanges: boolean = false;
    public static isChrome: boolean =
        window.navigator.userAgent.includes('Chrome') &&
        !window.navigator.userAgent.includes('Edge');
    public static isEdge: boolean =
        window.navigator.userAgent.includes('Edge');
    public static isFirefox: boolean =
        window.navigator.userAgent.includes('Firefox');
    public static isMobile: boolean =
        window.navigator.userAgent.includes('Android')
        || window.navigator.userAgent.includes('BlackBerry')
        || window.navigator.userAgent.includes('iPad')
        || window.navigator.userAgent.includes('iPhone')
        || window.navigator.userAgent.includes('webOS')
        || window.navigator.userAgent.includes('Windows Phone');
    public static isSafari: boolean =
        window.navigator.userAgent.includes('Safari');
    // Dictionary of key listeners, keyed by key config.
    public static keyListeners: Map<string, EventListener> =
        new Map<string, EventListener>();
    public static lastHash: object | null = null;
    public static loadArgs: object | null = null;
    public static loadMonitor: boolean = false;
    public static modelNameSpace: string | null = 'mustang';
    public static monitor: Monitor | null = null;
    public static monitorKey: object =
        {
            alt: true,
            ctrl: true,
            key: 's',
            shift: false,
        };
    public static requestCacheClearKey: object =
        {
            alt: true,
            ctrl: true,
            key: 'r',
            shift: false,
        };
    public static requestCacheStorage: string = 'LocalStorage';
    public static requestId: string | null = 'dataId';
    // Collection of script request cache keys.
    // Used to track which scripts have already been executed.
    public static scripts: Set<string> = new Set<string>();
    public static scriptUrl: string | null = 'ScriptHandler.ashx';
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public static sessionExpirationTimeout: any;
    @observable
    public static sessionMessages: SessionMessage[];
    public static sessionTokenCookie: string = 'authenticatedSessionToken';
    public static traceKeyCookie: string = 'traceKey';
    // Must provide initial value for ajaxTimeout so settings can be requested.
    // Bracket placement is non standard because of linter bug.
    public static settings: ISettings = {
        availableLanguages: [],
        baselineGridSize: 4,
        colorPalette: null,
        currentLanguageCode: '',
        dateFormat: 'mmm dd, yyyy',
        dayAbbreviations: [],  // Expects Sunday to be first
        days: [],  // Expects Sunday to be first
        decimalSeparator: '.',
        enableEmailAuthentication: false,
        environmentBannerColor: null,
        externalAuthenticators:
        [
            {
                description: '',
                iconName: '',
                providerName: '',
                url: '',
            },
        ],
        favIcon: '',
        monthAbbreviations: [],  // Expects January to be first
        months: [],  // Expects January to be first
        nonProdEnvironment: '',
        reCaptchaSiteKey: '',
        rootUrl: '',
        showErrorDetails: false,
        siteName: '',
        thousandsSeparator: ',',
        translations: Object.create(null),
        useConfiguredAuthentication: false,
    } as ISettings;

    public static get nextId(): number
    {
        return Sys.currentNextId++;
    }

    private static addKeyHandler(config: object, handler: Function): void
    {
        let targetElement: HTMLElement | Window | null;
        const key = JSON.stringify(config);

        if (!Sys.keyListeners.has(key) && handler)
        {
            if (config['target'])
            {
                targetElement = document.getElementById(config['target']);
            }
            else
            {
                targetElement = window;
            }

            if (targetElement)
            {
                const listener = (event: Event) =>
                {
                    const keyboardEvent = event as KeyboardEvent;
                    let result = true;

                    if (keyboardEvent.key === config['key']
                        && keyboardEvent.ctrlKey === config['ctrl']
                        && keyboardEvent.altKey === config['alt']
                        && keyboardEvent.shiftKey === config['shift']
                        && !keyboardEvent.repeat)
                    {
                        result = handler(keyboardEvent);

                        if (!result)
                        {
                            keyboardEvent.stopPropagation();
                            keyboardEvent.preventDefault();
                            keyboardEvent.returnValue = false;
                            keyboardEvent.cancelBubble = true;
                        }
                    }

                    return result;
                };

                Sys.keyListeners.set(JSON.stringify(config), listener);
                targetElement.addEventListener('keydown', listener);
            }
        }
    }

    private static clearWidgetErrors(
        dataId: string,
        name: string,
        rowKey: string
        ): void
    {
        const row = PaneRow.get(dataId, rowKey);

        if (!row)
        {
            return;
        }

        const widget = row.getWidget(name);
        widget.properties.businessErrors.length = 0;
    }

    private static createBusinessError(message: string): BusinessError
    {
        const businessError =
        {
            message,
            rows: [],
            widgets: [],
        };

        return businessError;
    }

    private static updateErrorDisplay(): void
    {
        if (Sys.businessErrors.length === 0)
        {
            Sys.hideToast();

            return;
        }

        const messages: string[] = [];
        const gridErrorCountByKey:
            { [key: string]: {count: number; description: string} } = {};

        for (const error of Sys.businessErrors)
        {
            const gridErrors:
                {
                    description: string;
                    key: string;
                }[] = [];

            for (const widget of error.widgets)
            {
                if (widget.isColumnWidget)
                {
                    gridErrors.push(
                    {
                        description: widget.parentGridDescription!,
                        key: widget.parentGridKey!,
                    });
                }
            }

            for (const row of error.rows)
            {
                gridErrors.push(
                {
                    description: row.parentGridDescription,
                    key: row.parentGridKey,
                });
            }

            for (const gridError of gridErrors)
            {
                if (gridError.key in gridErrorCountByKey)
                {
                    gridErrorCountByKey[gridError.key].count++;
                }
                else
                {
                    gridErrorCountByKey[gridError.key] =
                        {
                            count: 1,
                            description: gridError.description,
                        };
                }
            }

            if (gridErrors.length === 0)
            {
                messages.push(error.message);
            }
        }

        for (const key in gridErrorCountByKey)
        {
            if (!gridErrorCountByKey.hasOwnProperty(key))
            {
                continue;
            }

            const translationArgs =
            {
                count: gridErrorCountByKey[key].count,
                description: gridErrorCountByKey[key].description,
            };

            const errorTemplate = translationArgs.count === 1
                ? '{count} error exists within the {description} table'
                : '{count} errors exist within the {description} table';

            const message = Sys.getTranslation(
                errorTemplate, 'Data Table', translationArgs);

            messages.push(message);
        }

        Sys.showToast(
            Presentation.create(
                {
                    children: Api.getErrorMessages(messages),
                    props:
                    {
                        color: 'error',
                        component: 'div',
                        variant: 'body2',
                    },
                    type: 'Typography',
                }),
            true,
            null,
            'right',
            'bottom',
            null,
            () => { Sys.clearErrors(); });
    }

    public static addRequestCacheItem(
        requestCacheKey: string,
        request: XMLHttpRequest
        ): void
    {
        switch (Sys.requestCacheStorage)
        {
            case 'InProcess':
                Sys.getRequestCache().set(requestCacheKey, request.responseText);
                break;
            case 'LocalStorage':
                try
                {
                    Sys.getRequestCache().setItem(
                        `${Sys.cookiePath}/${requestCacheKey}`,
                        request.responseText);
                }
                catch (exception)
                {
                    if (exception.name === 'QuotaExceededError')
                    {
                        Sys.clearRequestCache();
                        Logging.log(
                            'Request cache quota exceeded, cache cleared.');
                        Sys.addRequestCacheItem(requestCacheKey, request);
                    }
                    else
                    {
                        throw exception;
                    }
                }
                break;
            default:
                Logging.log(
                    `Invalid request cache type: ${Sys.requestCacheStorage}`);
                break;
        }
    }

    public static announce(message: string)
    {
        const announcer = document.getElementById('announcer');

        if (announcer)
        {
            announcer.innerText = message;

            // Allow dom to update before cleaning up.
            setTimeout(
                () =>
                {
                    announcer.innerText = '';
                },
                100);
        }
    }

    public static clearBusinessErrors(
        dataId?: string, name?: string, rowKey?: string)
    {
        if (dataId && name && rowKey)
        {
            let deleteCount: number = 0;
            let done: boolean = true;

            // Need to clone the array so we can delete items.
            [...Sys.businessErrors].forEach(
                (businessError: BusinessError, index: number) =>
                {
                    if (businessError.widgets.some(widget =>
                        widget.dataId === dataId
                        && widget.widgetName === name
                        && widget.rowKey === rowKey))
                    {
                        businessError.widgets.forEach((widget) =>
                        {
                            Sys.clearWidgetErrors(
                                widget.dataId,
                                widget.widgetName,
                                widget.rowKey);
                        });

                        Sys.businessErrors.splice(index - deleteCount, 1);
                        deleteCount++;
                        done = false;
                    }
                });

            if (!done)
            {
                Sys.updateErrorDisplay();
            }
        }
        else if (dataId && name)
        {
            let deleteCount: number = 0;
            let done: boolean = true;

            // Need to clone the array so we can delete items.
            [...Sys.businessErrors].forEach(
                (businessError: BusinessError, index: number) =>
                {
                    if (businessError.widgets.some(widget =>
                        widget.dataId === dataId
                        && widget.widgetName === name))
                    {
                        businessError.widgets.forEach((widget) =>
                        {
                            Sys.clearWidgetErrors(
                                widget.dataId,
                                widget.widgetName,
                                widget.rowKey);
                        });

                        Sys.businessErrors.splice(index - deleteCount, 1);
                        deleteCount++;
                        done = false;
                    }
                });

            if (!done)
            {
                Sys.updateErrorDisplay();
            }
        }
        else if (dataId && rowKey)
        {
            let deleteCount: number = 0;
            let done: boolean = true;

            // Need to clone the array so we can delete items.
            [...Sys.businessErrors].forEach(
                (businessError: BusinessError, index: number) =>
                {
                    if (businessError.widgets.some(widget =>
                        widget.dataId === dataId
                        && widget.rowKey === rowKey))
                    {
                        businessError.widgets.forEach((widget) =>
                        {
                            Sys.clearWidgetErrors(
                                widget.dataId,
                                widget.widgetName,
                                widget.rowKey);
                        });

                        Sys.businessErrors.splice(index - deleteCount, 1);
                        deleteCount++;
                        done = false;
                    }
                    else if (businessError.rows.some(row =>
                        row.dataId === dataId
                        && row.rowKey === rowKey))
                    {
                        Sys.businessErrors.splice(index - deleteCount, 1);
                        deleteCount++;
                        done = false;
                    }
                });

            if (!done)
            {
                Sys.updateErrorDisplay();
            }
        }
        else
        {
            Sys.clearErrors();
        }
    }

    public static clearErrors(): void
    {
        for (const error of Sys.businessErrors)
        {
            for (const widget of error.widgets)
            {
                Sys.clearWidgetErrors(
                    widget.dataId, widget.widgetName, widget.rowKey);
            }
        }

        Sys.businessErrors.length = 0;
        Sys.updateErrorDisplay();
    }

    public static clearRequestCache(reload: boolean = true)
    {
        const requestCache = Sys.getRequestCache();

        switch (Sys.requestCacheStorage)
        {
            case 'InProcess':
                requestCache.clear();
                break;
            case 'LocalStorage':
                for (const key of Object.keys(requestCache))
                {
                    if (key.startsWith(`${Sys.cookiePath}/`))
                    {
                        requestCache.removeItem(key);
                    }
                }
                break;
            default:
                Logging.log(
                    `Invalid request cache type: ${Sys.requestCacheStorage}`);
                break;
        }

        if (reload)
        {
            window.location.reload();
        }
    }
    // Prompts the user if outstanding changes exist.
    public static async confirmContinue(
        clearState: boolean = true
    ): Promise<boolean>
    {
        const promise = new Promise<boolean>((resolve, reject) =>
        {
            if (!Sys.ignoreChanges)
            {
                EmbeddedAddOnBase.instances.forEach(
                    (embeddedAddOn: EmbeddedAddOnBase) =>
                    {
                        embeddedAddOn.roundTripStarting();
                    });

                if (Sys.hasChanges())
                {
                    ConfirmContinueStore.instance.openDialog(resolve, reject);
                }
                else
                {
                    resolve(true);
                }
            }
            else
            {
                resolve(true);
            }
        });

        if (!Sys.ignoreChanges && Sys.hasChanges() && clearState)
        {
            promise.then(() =>
            {
                AppServer.clearState();
            }).catch(() => { /* Do nothing */ });
        }

        return promise;
    }

    public static defer(
        method: Function,
        deferPeriod: number,
        scope: object,
        args: IArguments
        ): void
    {
        if (deferPeriod)
        {
            setTimeout(() => { method.call(scope, ...args); }, deferPeriod);
        }
        else
        {
            method.call(scope, ...args);
        }
    }

    public static deleteCookie(name: string): void
    {
        if (name)
        {
            // eslint-disable-next-line max-len
            document.cookie = `${encodeURIComponent(name)}=; path=${Sys.cookiePath}; expires==Thu, 01 Jan 1970 00:00:01 GMT`;
        }
    }

    public static formatDate(
        dateValue: number,
        format?: string | null
        ): string | null
    {
        if (dateValue <= 0)
        {
            return null;
        }

        const date: Date = new Date(dateValue);
        if (!format)
        {
            return date.toLocaleString();
        }

        const fraction = /f+/;
        const dayNames = Sys.settings.days;
        const dayAbbreviations = Sys.settings.dayAbbreviations;
        const monthNames = Sys.settings.months;
        const monthAbbreviations = Sys.settings.monthAbbreviations;

        let result = format;
        result = result.replace('yyyy', date.getFullYear().toString());
        result = result.replace('yy', date.getFullYear().toString().slice(-2));
        result = result.replace('MM', `0${date.getMonth() + 1}`.slice(-2));
        result = result.replace('M', (date.getMonth() + 1).toString());
        result = result.replace('dd', `0${date.getDate()}`.slice(-2));
        result = result.replace('d', date.getDate().toString());
        result = result.replace('HH', `0${date.getHours()}`.slice(-2));
        result = result.replace('H', date.getHours().toString());
        const hh = date.getHours() > 12 ? date.getHours() - 12 :
            date.getHours() < 1 ? 12 : date.getHours();
        result = result.replace('hh', `0${hh}`.slice(-2));
        result = result.replace('h', date.getHours() > 12 ?
            (date.getHours() - 12).toString() :
                date.getHours() < 1 ? '12' : date.getHours().toString());
        result = result.replace('mm', `0${date.getMinutes()}`.slice(-2));
        result = result.replace('m', date.getMinutes().toString());
        result = result.replace('ss', `0${date.getSeconds()}`.slice(-2));
        result = result.replace('s', date.getSeconds().toString());
        result = result.replace('ap', date.getHours() > 11 ? 'pm' : 'am');

        const match = fraction.exec(result);

        if (match)
        {
            result = result.replace(
                fraction,
                date.getMilliseconds().toString().substr(
                    0, match[0].length));
        }

        result = result.replace('NNNN', monthNames[date.getMonth()]);
        result = result.replace('NNN', monthAbbreviations[date.getMonth()]);
        result = result.replace('DDDD', dayNames[date.getDay()]);
        result = result.replace('DDD', dayAbbreviations[date.getDay()]);

        return result;
    }

    public static getCookie(name: string): string | null
    {
        let result: string | null = null;
        let cookies: string[];

        if (name && document.cookie)
        {
            cookies = document.cookie.split(';');

            cookies.forEach((cookie) =>
            {
                const cookieParts: string[] = cookie.split('=');

                if (cookieParts[0].trim() === encodeURIComponent(name))
                {
                    result = decodeURIComponent(cookieParts[1]);
                }
            });
        }

        return result;
    }

    // Returns the current hash value.
    public static getHash(asObject: boolean = true): string | object | null
    {
        // Strip the trailing "/" from the hash
        const hash = window.location.hash.replace(/\/$/, '');
        let result: string | object | null = null;
        if (hash.length > 1)
        {
            result = hash.substr(1);

            if (asObject)
            {
                result = Sys.queryStringToObject(result);
            }
        }

        return result;
    }

    public static getObjectHandle(
        baseToken: string,
        hash: object | null = null
        ): string | null
    {
        return Sys.getRouteToken(baseToken, 1, hash);
    }

    public static getPresentation(
        baseToken: string,
        hash: object | null = null
        ): string | null
    {
        return Sys.getRouteToken(baseToken, 2, hash);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public static getRequestCache(): any
    {
        if (!Sys.requestCache)
        {
            switch (Sys.requestCacheStorage)
            {
                case 'InProcess':
                    Sys.requestCache = new Map<string, object>();
                    break;
                case 'LocalStorage':
                    Sys.requestCache = window.localStorage;
                    break;
                default:
                    Logging.log(
                        `Invalid request cache type: ${Sys.requestCacheStorage}`);
                    break;
            }
        }

        return Sys.requestCache;
    }

    public static getRequestCacheItem(requestCacheKey: string): string | null
    {
        let result: string | null = null;

        switch (Sys.requestCacheStorage)
        {
            case 'InProcess':
                result = Sys.getRequestCache().get(requestCacheKey);
                break;
            case 'LocalStorage':
                result =
                    Sys.getRequestCache()
                        .getItem(`${Sys.cookiePath}/${requestCacheKey}`);
                break;
            default:
                Logging.log(
                    `Invalid request cache type: ${Sys.requestCacheStorage}`);
                break;
        }

        return result;
    }

    public static getRequestCacheKey(config: object)
    {
        const args = { ...config['args'] };

        delete args['cacheRequest'];

        return JSON.stringify(
            {
                args,
                authenticated: Sys.getCookie(Sys.sessionTokenCookie),
                url: config['url'],
            });
    }

    public static getRouteRootToken(hash: object | null = null)
    {
        let result: string | null = null;
        const currentHash: object | null = hash || Sys.getHash() as object;

        if (currentHash && '0' in currentHash)
        {
            const parts: string[] = currentHash['0'].split('/');
            result = parts.filter((p: string) => p.length > 0)[0].toLowerCase();
        }

        return result;
    }

    // Return the specified token from a route with the indicated base token.
    public static getRouteToken(
        baseToken: string,
        offset: number,
        hash: object | null = null): string | null
    {
        let result: string | null = null;
        const currentHash: object | null = hash || Sys.getHash() as object;

        if (currentHash && '0' in currentHash)
        {
            const parts: string[] = currentHash['0'].split('/');
            const index: number = parts.indexOf(baseToken);

            if (index > -1)
            {
                result = parts[index + offset];
            }
        }

        return result;
    }

    // Method to prompt for and enable a trace key.
    public static getTraceKey(): void
    {
        const traceKey = prompt(
            'Enter the key given to you by the help desk:', '');
        if (traceKey)
        {
            Sys.setTraceKey(traceKey);
            alert('Trace key has been set; execute the action you wish to trace');
        }
        else
        {
            alert('Blank trace keys are not set.  Try again');
        }
    }

    public static getTranslation(
        text: string | null,
        context: string = 'Client Framework',
        args?: object): string
    {
        let result: string = text || '';

        if (context in Sys.settings.translations)
        {
            const contextTranslations: object = Sys.settings.translations[context];

            if (text && text in contextTranslations)
            {
                result = contextTranslations[text];
            }
        }

        if (args)
        {
            const regx = /\{\w+\}/gi;
            const matches = result.match(regx);

            if (matches)
            {
                matches.forEach((match) =>
                {
                    result = result.replace(
                        match,
                        args[match.substr(1, match.length - 2)]);
                });
            }
        }

        return result;
    }

    // FUTURE
    // Replace with string templates: `${replaceThis}`
    public static getUrl(
        url: string,
        args?: object | null
        ): string
    {
        let result: string = url;

        if (args)
        {
            for (const arg of Object.keys(args))
            {
                if (result!.indexOf(`{${arg}}`) > -1)
                {
                    result = result!.replace(`{${arg}}`, `${args[arg]}`);
                    delete args[arg];
                }
            }
        }

        return result;
    }

    // Returns true if all properties in object2 match properties in object1.
    public static hasAll(object1: object | null, object2: object | null)
    {
        let result = false;

        if (object1 && object2)
        {
            for (const property of Object.keys(object2))
            {
                if (object2[property])
                {
                    if (object1[property] === object2[property])
                    {
                        result = true;
                    }
                    else
                    {
                        result = false;
                        break;
                    }
                }
                else
                {
                    result = false;
                    break;
                }
            }
        }

        return result;
    }

    public static hasBusinessErrors(topPaneUseKey: string): boolean
    {
        let result: boolean = false;

        for (const businessError of Sys.businessErrors)
        {
            result = businessError.widgets.some(
                widget => widget.topPaneUseKey === topPaneUseKey);

            if (result)
            {
                break;
            }
        }

        if (!result)
        {
            for (const businessError of Sys.businessErrors)
            {
                result = businessError.rows.some(
                    row => row.topPaneUseKey === topPaneUseKey);

                if (result)
                {
                    break;
                }
            }
        }

        return result;
    }

    // Indicates if the application has any pending changes.
    public static hasChanges(): boolean
    {
        const items = Array.from(TrackableModel.models.values());

        if (items.length === 0)
        {
            return false;
        }

        if (AppServer.hasChanges())
        {
            return true;
        }

        return items.some(item => !item.ignoreChanges && item.hasChanges());
    }

    public static hasRequestCacheItem(requestCacheKey: string): boolean
    {
        let result: boolean = false;

        switch (Sys.requestCacheStorage)
        {
            case 'InProcess':
                result = Sys.getRequestCache().has(requestCacheKey);
                break;
            case 'LocalStorage':
                result = (Sys.getRequestCache().getItem(
                    `${Sys.cookiePath}/${requestCacheKey}`) !== null);
                break;
            default:
                Logging.log(
                    `Invalid request cache type: ${Sys.requestCacheStorage}`);
                break;
        }

        return result;
    }

    public static hideToast()
    {
        if (Toast.current)
        {
            Toast.current.close();
        }
    }

    public static async initialize()
    {
        if (!Sys.settings.useConfiguredAuthentication)
        {
            if (await Routing.isNonConfiguredAuthenticationSessionExpiredRoute())
            {
                return;
            }
        }

        Sys.businessErrors = [];
        Sys.sessionMessages = [];

        window['mustang'] =
        {
            BaseService,
            Logging,
            PaneRow,
            Sys,
            TrackableCollection,
            TrackableModel,
        };

        window.addEventListener('error', (event) =>
        {
            if (process.env.NODE_ENV === 'production'
                && Sys.getRouteRootToken() !== 'error')
            {
                Routing.goToErrorPage(Sys.getTranslation('Error'), null);
            }
            else
            {
                // Ensure loading animation is removed.
                RequestsStore.instance.clearAllProcessing();
            }
        });

        if (!Sys.isEdge)
        {
            window.addEventListener('dragover', (event) =>
            {
                if (event.dataTransfer)
                {
                    event.dataTransfer.dropEffect = 'none';
                }

                event.preventDefault();

                return false;
            });
        }

        (document.body as HTMLBodyElement).onbeforeunload = Sys.onBeforeUnload;
        (document.body as HTMLBodyElement).onscroll = () =>
        {
            MenuBase.closeAll();
            ApiSelectBase.closeAll();
            SelectBase.closeAll();
        };
        (document.body as HTMLBodyElement).onresize = () =>
        {
            MenuBase.closeAll();
            ApiSelectBase.closeAll();
        };

        if (Sys.loadMonitor)
        {
            Sys.monitor = new Monitor();
        }

        if (Sys.monitorKey)
        {
            Sys.addKeyHandler(
                Sys.monitorKey,
                (event: KeyboardEvent) =>
                {
                    if (!Sys.monitor)
                    {
                        Sys.monitor = new Monitor();
                    }

                    Sys.monitor.show();
                }
            );
        }

        if (Sys.requestCacheClearKey)
        {
            Sys.addKeyHandler(
                Sys.requestCacheClearKey,
                (event: KeyboardEvent) => { Sys.clearRequestCache(); });
        }

        let languageCode: string | null = null;
        const hash = Sys.getHash();

        if (hash)
        {
            languageCode = hash['languageCode'];
            if (languageCode)
            {
                delete hash['languageCode'];
                Sys.setHash(hash, false, true);

                Sys.clearRequestCache(false);
            }

            if (!!hash['PosseSessionToken'])
            {
                Sys.deleteCookie(Sys.guestSessionTokenCookie);
                Sys.setCookie(
                    Sys.sessionTokenCookie,
                    hash['PosseSessionToken']);
                delete hash['PosseSessionToken'];

                if (Object.keys(hash).length)
                {
                    Sys.setHash(hash, false, true, true);
                }
                else
                {
                    Sys.setHash('', false, true, true);
                }
            }
        }

        await SystemConfigService.loadConfig(languageCode);

        Sys.currentCredentials = new Credentials();
        TrackableModel.register(Sys.currentCredentials);

        await Routing.initialize();
    }

    public static loadScript(key: string, script: string): void
    {
        if (!Sys.scripts.has(key))
        {
            if (script)
            {
                try
                {
                    Sys.scripts.add(key);

                    // eslint-disable-next-line no-eval
                    eval(script);

                    if (Sys.monitor)
                    {
                        Sys.monitor.addScript(key, script);
                    }
                }
                catch (exception)
                {
                    Logging.error(exception);
                    Routing.goToErrorPage(
                        Sys.getTranslation('Script Error'),
                        exception.message);
                }
            }
        }
    }

    public static loadScripts(scripts: object): void
    {
        for (const dataId of Object.keys(scripts))
        {
            Sys.loadScript(dataId, scripts[dataId]);
        }
    }

    // Returns the specified object as an escaped query string.
    public static objectToQueryString(object: object): string
    {
        return Object.keys(object).map((key) =>
        {
            let result: string;

            if (isNaN(parseInt(key, 10)))
            {
                // eslint-disable-next-line max-len
                result = `${encodeURIComponent(key)}=${encodeURIComponent(object[key])}`;
            }
            else
            {
                result = object[key];
            }

            return result;
        }).join('&');
    }

    // Before unload event handler for the body.
    public static onBeforeUnload(event: BeforeUnloadEvent)
    {
        if (!Sys.ignoreChanges)
        {
            EmbeddedAddOnBase.instances.forEach(
                (embeddedAddOn: EmbeddedAddOnBase) =>
                {
                    embeddedAddOn.roundTripStarting();
                });

            if (Sys.hasChanges())
            {
                event.returnValue =
                    Sys.getTranslation('There are outstanding changes.');
            }
        }
    }

    // Determine when the document has been loaded.
    public static onReadyStateChange()
    {
        if (document.readyState === 'complete')
        {
            Sys.initialize();
        }
    }

    // Returns the specified object as an escaped query string.
    public static queryStringToObject(queryString: string | null): object
    {
        const result: object = Object.create({});

        if (queryString)
        {
            let parts: string[];

            queryString.split(/[&?]+/).forEach((item, index) =>
            {
                if (item)
                {
                    if (item.includes('='))
                    {
                        parts = item.split('=');
                        result[decodeURIComponent(parts[0]).replace('+', '%20')] =
                            decodeURIComponent(parts[1].replace('+', '%20'));
                    }
                    else
                    {
                        result[index] =
                            decodeURIComponent(item.replace('+', '%20'));
                    }
                }
            });
        }

        return result;
    }

    // Registers a request in the cache.
    public static registerRequest(request: XMLHttpRequest): void
    {
        let requestCacheKey;

        if (request['config']['args'] && request['config']['args']['cacheRequest'])
        {
            requestCacheKey = Sys.getRequestCacheKey(request['config']);

            if (!Sys.hasRequestCacheItem(requestCacheKey))
            {
                Sys.addRequestCacheItem(requestCacheKey, request);
            }
        }
    }

    public static removeKeyHandler(config: object): void
    {
        let targetElement: HTMLElement | Window | null;
        const key = JSON.stringify(config);

        if (Sys.keyListeners.has(key))
        {
            if (config['target'])
            {
                targetElement = document.getElementById(config['target']);
            }
            else
            {
                targetElement = window;
            }

            if (targetElement)
            {
                targetElement.removeEventListener(
                    'keydown', Sys.keyListeners.get(key)!);
                Sys.keyListeners.delete(key);
            }
        }
    }

    public static setBusinessErrors(
        businessErrors: BusinessError[] = [],
        clearMessages: boolean = true):
        boolean
    {
        let result: boolean = false;
        if (businessErrors.length > 0)
        {
            result = true;

            if (clearMessages)
            {
                // Must clear all existing messages because the round trip may
                // not refresh all the data.
                Sys.clearErrors();
            }

            Sys.businessErrors.push(...businessErrors);
            Sys.updateErrorDisplay();
        }
        else
        {
            Sys.businessErrors.forEach((businessError: BusinessError) =>
            {
                businessError.widgets.forEach((widget) =>
                {
                    const row = PaneRow.get(widget.dataId, widget.rowKey);

                    if (row)
                    {
                        const widgetErrors = row.getWidget(
                            widget.widgetName!).properties.businessErrors;

                        if (widgetErrors.indexOf(businessError.message) === -1)
                        {
                            widgetErrors.push(businessError.message);
                        }
                    }
                });
            });
        }

        return result;
    }

    public static setCookie(
        name: string,
        value: string,
        days: number = 0): void
    {
        if (name)
        {
            let expires: string | null = null;

            if (days > 0)
            {
                const today = new Date();
                expires = new Date(
                    today.getFullYear(),
                    today.getMonth(),
                    today.getDate() + days,
                    0, 0, 1).toUTCString();
            }

            document.cookie =
                // eslint-disable-next-line max-len
                `${encodeURIComponent(name)}=${encodeURIComponent(value)}; path=${Sys.cookiePath}; expires=${expires}`;
        }
    }

    // Sets the current hash value.
    public static setHash(
        value: string | object,
        merge: boolean = false,
        replaceHash: boolean = false,
        ignoreHashChanges: boolean = false
        )
    {
        const hash = Sys.getHash();
        let hashString: string;

        if (replaceHash)
        {
            const href = window.location.href.split('#')[0];

            if (typeof value === 'string')
            {
                window.history.replaceState(null, '', `${href}#${value}`);
            }
            else
            {
                if (merge)
                {
                    window.history.replaceState(
                        null,
                        '',
                        `${href}#${Sys.objectToQueryString(
                            Object.assign(hash || Object.create(null), value))}`);
                }
                else
                {
                    window.history.replaceState(
                        null,
                        '',
                        `${href}#${Sys.objectToQueryString(value)}`);
                }
            }
        }
        else
        {
            if (typeof value === 'string')
            {
                hashString = value;
            }
            else
            {
                if (merge)
                {
                    hashString = Sys.objectToQueryString(
                        Object.assign(hash || Object.create(null), value));
                }
                else
                {
                    hashString = Sys.objectToQueryString(value);
                }
            }

            if (window.location.hash !== hashString)
            {
                if (ignoreHashChanges)
                {
                    Sys.ignoreHashChanges = true;
                }

                window.location.hash = hashString;
            }
        }
    }

    // Method to enable / diable a trace key.
    public static setTraceKey(traceKey: string): void
    {
        Sys.setCookie(Sys.traceKeyCookie, traceKey);
    }

    public static showErrors(messages: string[] | null)
    {
        if (!messages)
        {
            return;
        }

        if (messages.length === 0)
        {
            return;
        }

        const errors = messages.map(m => Sys.createBusinessError(m));
        Sys.businessErrors.unshift(...errors);
        Sys.updateErrorDisplay();
    }

    public static showToast(
        message: React.ReactNode,
        light: boolean = false,
        icon?: string | null,
        horizontalPosition: string = 'center',
        verticalPosition: string = 'bottom',
        duration: number | null = 3000,
        closeHandler: Function | null = null)
    {
        Presentation.render(
            {
                props:
                {
                    anchorOrigin:
                    {
                        horizontal: horizontalPosition,
                        vertical: verticalPosition,
                    },
                    autoHideDuration: duration,
                    closeHandler,
                    icon,
                    light,
                    message,
                },
                type: 'Toast',
            },
            'toast');
    }

    // API to enable / diable a trace key.
    public static toggleTraceKey(): void
    {
        const traceKey = Sys.getCookie(this.traceKeyCookie);
        if (traceKey)
        {
            if (confirm(`Turn off trace key "${traceKey}"?`))
            {
                Sys.setTraceKey('');
            }
        }
        else
        {
            Sys.getTraceKey();
        }
    }
}
