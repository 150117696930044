import * as muiButton from '@material-ui/core/Button';
import { createStyles, Theme, WithStyles, withStyles }
    from '@material-ui/core/styles';
import * as React from 'react';
import Sys from '../core/Sys';
import Button from './Button';

interface Props extends muiButton.ButtonProps
{
    direction?: 'left' | 'right';
    visible?: boolean;
}

const styles = (theme: Theme) => createStyles(
    {
        left:
        {
            '@media print':
            {
                display: 'none',
            },
            alignSelf: 'flex-end',
            flexShrink: 0,
            marginBottom: 8,
            marginLeft: 8,
            order: 1,
            zIndex: 1,
        },
        right:
        {
            '@media print':
            {
                display: 'none',
            },
            alignSelf: 'flex-end',
            flexShrink: 0,
            marginBottom: 8,
            marginLeft: 8,
            marginRight: 1,
            order: 2,
            zIndex: 1,
        },
    });

export class TabScrollButton extends
    React.PureComponent<Props & WithStyles<typeof styles>>
{
    public render()
    {
        const {
            classes,
            className,
            endIcon,  // Removed so it can be ignored.
            direction,
            visible,
            ...buttonProps
        } = this.props;

        return (
            <Button
                aria-label={Sys.getTranslation(direction === 'left'
                    ? 'Scroll tabs left' : 'Scroll tabs right')}
                className={direction === 'left' ? classes.left : classes.right}
                disabled={!visible}
                fab
                icon={direction === 'left'
                    ? 'fas fa-chevron-left' : 'fas fa-chevron-right'}
                size="medium"
                tabIndex={-1}
                {...buttonProps}
            />);
    }
}

export default withStyles(styles)(TabScrollButton);
