import { WithTheme, withTheme } from '@material-ui/core/styles';
import withWidth, { WithWidth } from '@material-ui/core/withWidth';
import { observer } from 'mobx-react';
import * as React from 'react';
import Sys from '../core/Sys';
import Button from '../coreui/Button';
import Menu, { Menu as MenuBase } from '../coreui/Menu';
import { TableChildProps } from '../coreui/Table';
import PaneRow from '../models/PaneRow';
import { AccessLevel } from '../mustangui/Api';
import ToolbarContainerItem from './ToolbarContainerItem';
import ToolbarContainerOverflowItem, { ConfigProperties as ItemProps }
    from './ToolbarContainerOverflowItem';

interface ConfigProperties
{
    childItems: { props: ItemProps }[];
    dataId: string;
    hideIcons: boolean;
    isPageHeader: boolean;
    propagated: TableChildProps;
    toolbarType: 'header' | 'footer' | 'selection' | 'summary';
}

interface State
{
    overflowButtonRef?: HTMLElement;
}

export interface ToolbarOverflowChild
{
    hideIcons: boolean;
    isDrawerMenu?: boolean;
}

interface RuntimeProperties
{
    accessLevel: AccessLevel;
}

@observer
export class ToolbarContainerOverflowSection extends
    React.Component<ConfigProperties & WithTheme & WithWidth, State>
{
    private readonly overflowMenuName: string;

    public constructor(props: ConfigProperties & WithTheme & WithWidth)
    {
        super(props);

        this.state = {};

        this.overflowMenuName = `${props.dataId}`;
    }

    private openOverflowMenu = () =>
    {
        MenuBase.open(this.overflowMenuName, this.state.overflowButtonRef);
    };

    private setOverflowButtonRef(ref: HTMLElement | null)
    {
        if (ref && ref !== this.state.overflowButtonRef)
        {
            this.setState({ overflowButtonRef: ref });
        }
    }

    private shouldRender(): boolean
    {
        for (const child of this.props.childItems)
        {
            if (!child.props[this.props.width])
            {
                continue;
            }

            if (child.props.isLayoutOnly)
            {
                return true;
            }

            const widgetName = child.props.child.props.name;

            const runtimeProperties = PaneRow.getWidgetProperties(
                this.props.dataId, widgetName) as RuntimeProperties;

            if (!runtimeProperties)
            {
                return true;
            }

            if (runtimeProperties.accessLevel >= AccessLevel.disabled)
            {
                return true;
            }
        }

        return false;
    }

    public render()
    {
        if (!this.shouldRender())
        {
            return null;
        }

        const propagated: ToolbarOverflowChild =
            {
                ...this.props.propagated,
                hideIcons: this.props.hideIcons,
                isDrawerMenu: this.props.isPageHeader,
            };

        return (
            <React.Fragment>
                <ToolbarContainerItem
                    childLayoutWidgetName=""
                    childWidgetName=""
                    dataId=""
                    lg={true}
                    md={true}
                    propagated={this.props.propagated}
                    sm={true}
                    toolbarType={this.props.toolbarType}
                    xl={true}
                    xs={true}
                >
                    <Button
                        aria-label={Sys.getTranslation('Menu')}
                        buttonRef={(r: HTMLElement) =>
                            this.setOverflowButtonRef(r)}
                        icon={this.props.isPageHeader
                            ? 'fas fa-bars'
                            : 'fas fa-ellipsis-v'}
                        onClick={this.openOverflowMenu}
                        size={this.props.isPageHeader
                            ? 'large'
                            : 'small'}
                        tabIndex={-1}
                    />
                </ToolbarContainerItem>
                <Menu
                    anchorEl={this.state.overflowButtonRef}
                    anchorOrigin={{
                        horizontal: 'left',
                        vertical: 'bottom',
                    }}
                    drawerMenu={this.props.isPageHeader}
                    getContentAnchorEl={undefined}
                    name={this.overflowMenuName}
                    onExited={() =>
                        this.state.overflowButtonRef!.focus()}
                    open={false}
                >
                    {/* Pass in width instead of using withWidth in the item
                        to avoid ref errors */}
                    {this.props.childItems.map((child, index) =>
                        <ToolbarContainerOverflowItem
                            key={index}
                            propagated={propagated}
                            width={this.props.width}
                            {...child.props}
                        />
                    )}
                </Menu>
            </React.Fragment>
        );
    }
}

export default withTheme(withWidth()(ToolbarContainerOverflowSection));
