import { createStyles, Theme, WithStyles, withStyles }
    from '@material-ui/core/styles';
import withWidth, { WithWidth } from '@material-ui/core/withWidth';
import { observer } from 'mobx-react';
import * as React from 'react';
import { State as AppServerState } from '../core/AppServer';
import TrackableModel from '../core/TrackableModel';
import Presentation from '../coreui/Presentation';
import Table, { TableChildProps, TableProps } from '../coreui/Table';
import AsyncData, { GetDataResponse } from '../coreui/table/AsyncData';
import PaneRow from '../models/PaneRow';
import PresentationService from '../services/PresentationService';
import RoundTripService from '../services/RoundTripService';
import PaneDataStore, { PaneDataByDataId } from '../stores/PaneDataStore';
import Api, { AccessLevel } from './Api';
import GridColumn, { GridColumnConfigProperties } from './Columns/GridColumn';
import TableEditDialog from './Columns/TableEditDialog';
import ErrorBoundary from './ErrorBoundary';
import { GridRelatedEditButton } from './GridRelatedEditButton';

interface ConfigProperties extends WithWidth
{
    cardDepth: number;
    columns: GridColumnConfigProperties[];
    contentDataId: string;
    data?: object;
    dataId: string;
    footerToolbar?: object;
    headerToolbar?: object;
    name: string;
    relatedEditLayoutIds:
    {
        RelatedEditLayoutId: number;
        RowTypeId: number;
    }[];
    rowSelection: boolean;
    selectionToolbar?: object;
    summaryToolbar?: object;
    tableKey: string;
    verticalLayout?: object;
}

interface DialogOpenResponse
{
    appServerState: AppServerState;
    paneDataByDataId: PaneDataByDataId;
    relatedEditLayoutId: number;
    validationErrors: string[];
}

interface RuntimeProperties
{
    accessLevel: AccessLevel;
    rowKeys: string[];
}

interface State
{
    editDialogInfo?: { isFirstOpenOfNewRow: boolean; rowKey: string };
}

const styles = (theme: Theme) => createStyles(
    {
    });

@observer
export class ComplexGridControl extends
    React.Component<
        ConfigProperties & WithWidth & WithStyles<typeof styles>, State>
{
    private populate: ((rows: TrackableModel[]) => void) | null = null;
    private propagated: TableChildProps;
    private restoreLostFocus: (() => void) | null;
    private tableProps: TableProps;

    public constructor(
        props: ConfigProperties & WithWidth & WithStyles<typeof styles>)
    {
        super(props);

        this.state = {};

        this.propagated = {
            parentTable:
            {
                cardDepth: props.cardDepth,
                columns: props.columns,
                configProps:
                {
                    contentDataId: props.contentDataId,
                    data: props.data,
                    dataId: props.dataId,
                    name: props.name,
                },
                hasRelatedEditDialog: props.relatedEditLayoutIds ? true : false,
                isDocumentGrid: false,
                openRowEditDialog: (r, i) => this.openRowEditDialog(r, i),
                populateData: () => this.populateData(),
            },
        } as TableChildProps;

        this.tableProps = {
            cardDepth: props.cardDepth,
            cellEdit: false,
            columns: [],
            contentDataId: props.contentDataId,
            dataId: props.dataId,
            footerToolbarChild: props.footerToolbar,
            headerToolbarChild: props.headerToolbar,
            isColumnFlex: (colId: string) =>
                GridColumn.isColumnFlex(props.columns, colId),
            isColumnVisible: (colId: string, breakpoint: string) =>
                GridColumn.isColumnVisible(props.columns, colId, breakpoint),
            minRowHeight:
                GridColumn.getColumnsMinRowHeight(props.columns),
            name: props.name,
            propagated: this.propagated,
            rowSelection: props.rowSelection ? 'multiple' : undefined,
            selectToolbarChild: props.selectionToolbar,
            setPopulate: populate => this.populate = populate,
            setRestoreLostFocus:
                restoreFocus => this.restoreLostFocus = restoreFocus,
            summaryToolbarChild: props.summaryToolbar,
        };
    }

    private getData = () =>
    {
        return RoundTripService.partialDataRetrevial<GetDataResponse>(
            `ComplexGridControl/GetRowsData/${this.getRowKey()}`
            + `/${this.props.dataId}/${this.props.name}`
        );
    };

    private getRowKey(): string
    {
        const observable = Presentation.getObservable(this.props)! as PaneRow;
        return observable.rowKey;
    }

    private onDialogOpen = async (parentRowKey: string) =>
    {
        const dialogOpenResponse =
            await RoundTripService.customRoundTrip<DialogOpenResponse>(
                `ComplexGridControl/OnDialogOpen/${this.getRowKey()}`
                + `/${this.props.dataId}/${this.props.name}`,
                undefined,
                { dialogRowKey: parentRowKey }
            );

        const layoutId = dialogOpenResponse.relatedEditLayoutId.toString();

        return PresentationService.getConfigForDialog(
            layoutId
        ).then((configResponse) =>
        {
            return { ...dialogOpenResponse, ...configResponse, layoutId };
        });
    };

    private openRowEditDialog(rowKey: string, isFirstOpenOfNewRow: boolean)
    {
        this.setState({ editDialogInfo: { isFirstOpenOfNewRow, rowKey } });
    }

    private populateData = () =>
    {
        const rows: PaneRow[] =
            PaneDataStore.getPaneCollection(this.props.contentDataId);

        if (this.populate !== null)
        {
            this.populate(rows);
        }
    };

    public componentDidMount()
    {
        for (const column of this.props.columns)
        {
            this.tableProps.columns.push(GridColumn.getColumnDef(
                column,
                this.props.columns,
                this.propagated));
        }

        if (this.props.relatedEditLayoutIds)
        {
            this.tableProps.columns.push(GridRelatedEditButton.createColDef(
                this.propagated));
        }
    }

    public componentDidUpdate(prevProps: ConfigProperties)
    {
        if (prevProps.width !== this.props.width)
        {
            setTimeout(() => this.populateData());
        }
    }

    public render()
    {
        const runtimeProperties =
            Api.getWidgetProperties(this.props) as RuntimeProperties;

        if (!runtimeProperties)
        {
            return null;
        }

        if (runtimeProperties.accessLevel === AccessLevel.hidden)
        {
            return null;
        }

        return (
            <ErrorBoundary title={this.props.name}>
                <div style={{ position: 'relative' }}>
                    <AsyncData
                        contentDataId={this.props.contentDataId}
                        dataId={this.props.dataId}
                        getData={this.getData}
                        populateData={this.populateData}
                    />
                    <Table
                        {...this.tableProps}
                        fullWidthChild={this.props.verticalLayout}
                        tableKey={this.props.tableKey}
                    />
                    <TableEditDialog
                        contentDataId={this.props.contentDataId}
                        dataId={this.props.dataId}
                        isFirstOpenOfNewRow={this.state.editDialogInfo
                            ? this.state.editDialogInfo.isFirstOpenOfNewRow
                            : false}
                        name={this.props.name}
                        onClose={() =>
                            this.setState({ editDialogInfo: undefined })}
                        onDeleteRow={() => this.populateData()}
                        onExited={() => this.restoreLostFocus!()}
                        onOpen={this.onDialogOpen}
                        parentRowKey={this.state.editDialogInfo
                            ? this.state.editDialogInfo.rowKey : undefined}
                    />
                </div>
            </ErrorBoundary>);
    }
}

export default withStyles(styles)(withWidth()(ComplexGridControl));
