import { createStyles, WithStyles, withStyles }
    from '@material-ui/core/styles';
import * as React from 'react';
import { CustomTheme } from '../muiTheme';

interface Props
{
    children: React.ReactNode;
    lg: number;
    md: number;
    sm: number;
    style?: React.CSSProperties | null;
    xl: number;
    xs: number;
}

const styles = (theme: CustomTheme) =>
{
    const visibilityStyles = {};
    for (const breakPoint of theme.visibilityBreakPoints)
    {
        for (let size = 1; size <= 12; size++)
        {
            visibilityStyles[`griditem-${breakPoint}-${size}`] =
                {
                    [theme.breakpoints.up(breakPoint)]:
                    {
                        gridColumnEnd: `span ${size}`,
                    },
                };
        }

        // Allow 0 to indicate hidden.
        visibilityStyles[`griditem-${breakPoint}-0`] =
            {
                [theme.breakpoints.only(breakPoint)]:
                {
                    display: 'none !important',
                },
            };
    }

    const result =
        {
            container:
            {
                minWidth: 0,
            },
            ...visibilityStyles,
        };

    return createStyles(result);
};

export class GridItem extends
    React.PureComponent<Props & WithStyles<typeof styles>>
{
    public render()
    {
        const classes: string[] = [this.props.classes.container];

        classes.push(this.props.classes[`griditem-xs-${this.props.xs}`]);
        classes.push(this.props.classes[`griditem-sm-${this.props.sm}`]);
        classes.push(this.props.classes[`griditem-md-${this.props.md}`]);
        classes.push(this.props.classes[`griditem-lg-${this.props.lg}`]);
        classes.push(this.props.classes[`griditem-xl-${this.props.xl}`]);

        return (
            <div
                className={classes.join(' ')}
                style={this.props.style ? this.props.style : undefined}
            >
                {this.props.children}
            </div>
        );
    }
}

export default withStyles(styles)(GridItem);
