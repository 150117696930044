import { observable } from 'mobx';
import TrackableModel from '../core/TrackableModel';
import { AccessLevel } from '../mustangui/Api';

export default class Credentials extends TrackableModel
{
    /* eslint-disable */
    @observable
    private _UserName: string | null = null;
    public get UserName(): string | null
    {
        return this._UserName;
    }
    public set UserName(value: string | null)
    {
        this.setProperty('_UserName', value);
        this.Message = null;
    }

    @observable
    private _Password: string | null = null;
    public get Password(): string | null
    {
        return this._Password;
    }
    public set Password(value: string | null)
    {
        this.setProperty('_Password', value);
        this.Message = null;
    }

    @observable
    private _EmailAddress: string | null = null;
    public get EmailAddress(): string | null
    {
        return this._EmailAddress;
    }
    public set EmailAddress(value: string | null)
    {
        this.setProperty('_EmailAddress', value);
        this.EmailMessage = null;
    }

    @observable
    private _DisplayName: string | null = null;
    public get DisplayName(): string | null
    {
        return this._DisplayName;
    }
    public set DisplayName(value: string | null)
    {
        this._DisplayName = value;
    }

    @observable
    private _Message: string | null = null;
    public get Message(): string | null
    {
        return this._Message;
    }
    public set Message(value: string | null)
    {
        this._Message = value;
    }

    @observable
    private _EmailMessage: string | null = null;
    public get EmailMessage(): string | null
    {
        return this._EmailMessage;
    }
    public set EmailMessage(value: string | null)
    {
        this._EmailMessage = value;
    }

    @observable
    private _WidgetProperties: object | null = null;
    public get WidgetProperties(): object | null
    {
        return this._WidgetProperties;
    }
    public set WidgetProperties(value: object | null)
    {
        this._WidgetProperties = value;
    }
    /* eslint-enable */

    constructor()
    {
        super('Credentials');

        this.dataId = 'Credentials';
        this.ignoreChanges = true;
        this.UserName = null;
        this.Password = null;
        this.WidgetProperties =
        {
            EmailAddress:
            {
                accessLevel: AccessLevel.enterable,
                businessErrors: [],
            },
            Password:
            {
                accessLevel: AccessLevel.enterable,
                businessErrors: [],
            },
            UserName:
            {
                accessLevel: AccessLevel.enterable,
                businessErrors: [],
            },
        };
    }

    protected getPropertyNames(): string[]
    {
        return Object.keys(this);
    }

    protected loadData(data: TrackableModel)
    {
        for (const property of Object.keys(data))
        {
            this.setPropertyValue(property, data![property]);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected setPropertyValue(propName: string, value: any): void
    {
        this[propName] = value;
    }

    public getPrimaryKey(): string
    {
        throw new Error('Not implemented');
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getPropertyValue(propName: string): any
    {
        return this[propName];
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getReadOnlyProperties(propName: string): any
    {
        return this.WidgetProperties![propName];
    }
}
