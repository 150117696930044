import { library as fontAwesomeLibrary, IconProp }
    from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fad } from '@fortawesome/pro-duotone-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as muiIcon from '@material-ui/core/Icon';
import { createStyles, Theme, WithStyles, withStyles }
    from '@material-ui/core/styles';
import * as React from 'react';
import Logging from '../core/Logging';
import CustomIconStore from '../stores/CustomIconStore';

export interface IconProps extends muiIcon.IconProps
{
    fixedWidth?: boolean;
    fullHeight?: boolean;
    icon?: string;
}

interface State
{
    customIconContents: string | null;
}

const styles = (theme: Theme) => createStyles(
    {
        adjustSvgWidth:
        {
            '& svg':
            {
                display: 'block',
                width: '100% !important',
            },
        },
        adjustWidth:
        {
            display: 'block',
            width: '100% !important',
        },
        customSvg:
        {
            '& svg':
            {
                fill: 'currentColor',
                height: '1em',
                pointerEvents: 'none',
                verticalAlign: '-.125em',
                width: '1em',
            },
        },
        fullHeight:
        {
            height: '100% !important',
        },
        fullHeightSvg:
        {
            '& svg':
            {
                height: '100% !important',
            },
        },
        root:
        {
        },
    });

export class Icon extends
    React.PureComponent<IconProps & WithStyles<typeof styles>, State>
{
    private static initialized = false;

    public constructor(props: IconProps & WithStyles<typeof styles>)
    {
        super(props);

        if (!Icon.initialized)
        {
            Icon.initialized = true;
            fontAwesomeLibrary.add(fab);
            fontAwesomeLibrary.add(fad);
            fontAwesomeLibrary.add(far);
            fontAwesomeLibrary.add(fas);
        }

        this.state =
        {
            customIconContents: null,
        };
    }

    private async updateCustomIconContents(): Promise<void>
    {
        if (!this.props.icon)
        {
            this.setState({ customIconContents: null });

            return;
        }

        if (!this.props.icon.includes('.'))
        {
            this.setState({ customIconContents: null });

            return;
        }

        const icon = await CustomIconStore.iconForName(this.props.icon);
        if (icon === null)
        {
            this.setState({ customIconContents: null });

            return;
        }

        this.setState({ customIconContents: icon.contents });
    }

    public componentDidMount(): void
    {
        this.updateCustomIconContents();
    }

    public componentDidUpdate(prevProps: IconProps, prevState: State): void
    {
        if (prevProps.icon !== this.props.icon)
        {
            this.updateCustomIconContents();
        }
    }

    public render()
    {
        const {
            classes,
            className,
            fixedWidth,
            fullHeight,
            icon,
            ...iconProps
        } = this.props;

        if (!icon)
        {
            return null;
        }

        let child: React.ReactNode | null = null;

        if (icon.includes('.'))
        {
            if (!this.state.customIconContents)
            {
                return null;
            }

            const childClasses: string[] = [];
            if (!fixedWidth)
            {
                childClasses.push(classes.adjustSvgWidth);
            }

            if (fullHeight)
            {
                childClasses.push(classes.fullHeightSvg);
            }

            childClasses.push(classes.customSvg);

            child = (
                <span
                    aria-hidden="true"
                    className={childClasses.join(' ')}
                    // A SVG could have been created directly in React
                    // and with properties set from the XML,
                    // but this would have been expensive to build & maintain.
                    // Other ways such as converting the svg to a data url
                    // don't support color & sizing. Since the XML is from a
                    // known source and validated, it's safe to render directly.
                    dangerouslySetInnerHTML={
                        {
                            __html: this.state.customIconContents,
                        }}
                    role="img"
                />
            );
        }
        else
        {
            const iconNameParts: string[] = icon.split(' ');

            if (iconNameParts.length !== 2)
            {
                Logging.warn(`Unknown icon '${icon}'`);
                return null;
            }

            const iconPrefix: string = iconNameParts[0];
            if (iconPrefix.length !== 3 || !iconPrefix.startsWith('fa'))
            {
                Logging.warn(`Unknown icon '${icon}'`);
                return null;
            }

            let iconName: string = iconNameParts[1];
            const childClasses: string[] = [];
            if (!fixedWidth)
            {
                childClasses.push(classes.adjustWidth);
            }

            if (fullHeight)
            {
                childClasses.push(classes.fullHeight);
            }

            iconName = iconName.replace('fa-', '');

            child = (
                <FontAwesomeIcon
                    className={childClasses.join(' ')}
                    icon={[iconPrefix, iconName] as IconProp}
                />
            );
        }

        const rootClasses: string[] = [classes.root];
        if (className)
        {
            rootClasses.push(className);
        }

        return (
            <muiIcon.default
                className={rootClasses.join(' ')}
                {...iconProps}
            >
                {child}
            </muiIcon.default>);
    }
}

export default withStyles(styles)(Icon);
