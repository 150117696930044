import { observable } from 'mobx';
import AppServer, { State as AppServerState } from '../core/AppServer';
import Sys from '../core/Sys';
import TrackableCollection from '../core/TrackableCollection';
import TrackableModel from '../core/TrackableModel';
import PaneRow from '../models/PaneRow';
import DocumentService, { DocumentUploadResponse }
    from '../services/DocumentService';
import PaneDataStore, { PaneDataByDataId } from '../stores/PaneDataStore';
import RequestsStore from '../stores/RequestsStore';

export interface CompleteDocumentUploadResponse
{
    appServerState: AppServerState;
    paneDataByDataId: PaneDataByDataId;
    uploadErrorsByRowKey: object;
}

export interface CreateDocumentRowsResponse
{
    appServerState: AppServerState;
    newRows: PaneRow[];
    uploadErrorsByRowKey: object;
    validationErrors: string[];
}

export interface FileInfo
{
    event?: ProgressEvent;
    file: File;
    status: ProgressStatus;
}

export type ProgressStatus = 'Started' | 'Ongoing' | 'Uploaded' | 'Finalized';

export default class DocumentStore
{
    private completeUpload:
        (uploadedFiles: object[]) => Promise<CompleteDocumentUploadResponse>;
    private contentDataId: string;
    private createRows:
        (fileInfo: object[]) => Promise<CreateDocumentRowsResponse>;
    private onModelChanged: () => void;

    @observable public documents: FileInfo[] = [];

    constructor(
        contentDataId: string,
        completeUpload:
            (uploadedFiles: object[]) => Promise<CompleteDocumentUploadResponse>,
        createRows: (fileInfo: object[]) => Promise<CreateDocumentRowsResponse>,
        onModelChanged: () => void
        )
    {
        this.contentDataId = contentDataId;
        this.completeUpload = completeUpload;
        this.createRows = createRows;
        this.onModelChanged = onModelChanged;
    }

    private clearDocuments()
    {
        this.documents.length = 0;
    }

    private uploadError(file: File, errors: string[])
    {
        file['hasError'] = true;

        Sys.showErrors(errors.map(error => `${file.name} - ${error}`));

        TrackableCollection.deleteRow(this.contentDataId, file['rowKey']);

        this.onModelChanged();
    }

    private uploadProgress(file: File, event: ProgressEvent)
    {
        const uploaded = this.documents.find(f => f.file === file);
        if (uploaded)
        {
            uploaded.status = 'Ongoing';
            uploaded.event = event;
        }
    }

    public async uploadFiles(
        dataId: string,
        widgetName: string,
        files: File[]
        ): Promise<void>
    {
        if (files.length <= 0)
        {
            return;
        }

        RequestsStore.instance.processingStarted();

        const fileInfo: object[] = [];

        for (const file of files)
        {
            fileInfo.push(
                {
                    fileName: file.name,
                    size: file.size,
                });
        }

        return this.createRows(fileInfo).then((
            createResponse: CreateDocumentRowsResponse) =>
        {
            RequestsStore.instance.processingStopped();

            if (createResponse.validationErrors.length > 0)
            {
                Sys.showErrors(createResponse.validationErrors);

                return;
            }

            const collection = TrackableModel.models.get(
                this.contentDataId) as TrackableCollection;
            const newModels: TrackableModel[] = [];
            const newRows: PaneRow[] = createResponse.newRows;
            const uploadErrors: object = createResponse['uploadErrorsByRowKey'];
            let newRow: PaneRow;

            AppServer.setState(createResponse.appServerState);
            PaneDataStore.clearDeletedRows();

            // Files must be inserted in reverse order to match the data
            // returned by the server.
            const filesMap = new Map<string, File>();
            for (let index = files.length - 1; index >= 0; index--)
            {
                newModels.push(TrackableModel.create(
                    collection.modelName, collection.dataId, newRows[index]));
            }

            collection.unshift(...newModels);
            collection.observableCollection!.unshift(...newModels);
            this.onModelChanged();

            files.forEach((file, index) =>
            {
                newRow = newRows[index] as PaneRow;
                filesMap.set(newRow.rowKey, file);
                file['rowKey'] = newRow.rowKey;
            });

            for (const rowKey of Object.keys(uploadErrors))
            {
                if (uploadErrors[rowKey].length && filesMap.has(rowKey))
                {
                    this.uploadError(
                        filesMap.get(rowKey)!,
                        uploadErrors[rowKey]);
                }
            }

            if (files.every((file) => { return file['hasError']; }))
            {
                this.clearDocuments();

                return;
            }

            for (const file of files)
            {
                this.documents.push({ file, status: 'Started' });
            }

            const batch: Promise<DocumentUploadResponse>[] =
                DocumentService.uploadFiles(
                    dataId,
                    widgetName,
                    files,
                    (file: File, progressEvent: ProgressEvent) =>
                    { this.uploadProgress(file, progressEvent); },
                    (file: File, errors: string[]) =>
                    { this.uploadError(file, errors); });

            return Promise.all(batch).then((uploadResponses) =>
            {
                const uploadedFiles: object[] = [];

                uploadResponses.forEach((responseData) =>
                {
                    if (filesMap.has(responseData.clientKey)
                        && !filesMap.get(responseData.clientKey)!['hasError'])
                    {
                        uploadedFiles.push(
                            {
                                pendingDocumentId:
                                    responseData.pendingDocumentId,
                                pendingThumbnailId:
                                    responseData.pendingThumbnailId,
                                rowKey: responseData.clientKey,
                            });
                    }
                });

                for (const file of files)
                {
                    const uploaded = this.documents.find(f => f.file === file);
                    if (uploaded)
                    {
                        uploaded.status = 'Uploaded';
                    }
                }

                return this.completeUpload(uploadedFiles).then((
                    completeResponse: CompleteDocumentUploadResponse) =>
                {
                    const errors: object =
                        completeResponse.uploadErrorsByRowKey;

                    AppServer.setState(completeResponse.appServerState);

                    PaneDataStore.loadResponse(completeResponse.paneDataByDataId);

                    for (const file of files)
                    {
                        if (file['rowKey'] in errors
                            && errors[file['rowKey']].length)
                        {
                            this.uploadError(file, errors[file['rowKey']]);
                        }
                        else if (!file['hasError'])
                        {
                            const uploaded =
                                this.documents.find(f => f.file === file);
                            if (uploaded)
                            {
                                uploaded.status = 'Finalized';
                            }
                        }
                    }

                    this.clearDocuments();
                }).catch(() =>
                {
                    this.clearDocuments();
                });
            }).catch(() =>
            {
                this.clearDocuments();
            });
        }).catch(() =>
        {
            this.clearDocuments();
        });
    }
}
