import grey from '@material-ui/core/colors/grey';
import * as muiInputAdornment from '@material-ui/core/InputAdornment';
import { GetQuickFilterTextParams, ICellEditorParams }
    from 'ag-grid-community';
import { observer } from 'mobx-react';
import * as moment from 'moment';
import * as React from 'react';
import Sys from '../../core/Sys';
import TrackableModel from '../../core/TrackableModel';
import Button from '../../coreui/Button';
import ErrorBadge from '../../coreui/ErrorBadge';
import Input, { InputProps } from '../../coreui/Input';
import { TableChildProps } from '../../coreui/Table';
import { CellUtil } from '../../coreui/table/CellUtil';
import Api from '../../mustangui/Api';
import { DateEdit } from '../DateEdit';
import DateEditColumnDialog from './DateEditColumnDialog';

interface ConfigProperties extends ICellEditorParams
{
    dataId: string;
    dateFormatError: string;
    name: string;
    propagated: TableChildProps;
}

interface State
{
    isDialogOpen: boolean;
    value?: string | null;
}

interface RuntimeProperties
{
    businessErrors: string[];
}

@observer
export class DateEditColumnEdit extends
    React.Component<ConfigProperties, State>
{
    public static readonly localeName = 'current';
    private inputElement: HTMLInputElement;
    private lastValidDate: Date | null = null;
    protected muiProps: InputProps;

    private static getErrors(
        props: ConfigProperties,
        data: TrackableModel,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        value: any): string[]
    {
        const widgetProperties =
            Api.getWidgetProperties(props, data) as RuntimeProperties;
        let result: string[];

        if (!widgetProperties)
        {
            return [];
        }

        if (data.hasChanges(props.name))
        {
            result = [];
        }
        else
        {
            result = [...widgetProperties.businessErrors];
        }

        const parsedValue =
            DateEditColumnEdit.getCurrentValueParsed(true, value);

        if (typeof parsedValue === 'string')
        {
            result.push(props.dateFormatError);
        }

        return result;
    }

    public static getCurrentValueParsed(
        userFormatted: boolean,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        value: any
        ): Date | null
    {
        let parsed: Date | null = null;

        if (value)
        {
            const format = DateEdit.getDateFormat(userFormatted);
            const date = moment(
                value,
                format,
                userFormatted ? DateEditColumnEdit.localeName : 'en',
                true);

            if (date.isValid())
            {
                parsed = date.toDate();
            }
            else
            {
                if (userFormatted)
                {
                    parsed = DateEdit.parseDate(value, format);
                    if (!parsed)
                    {
                        parsed = value;
                    }
                }
                else
                {
                    parsed = value;
                }
            }
        }

        return parsed;
    }

    public static getQuickFilterText(params: GetQuickFilterTextParams): string
    {
        if (!params.value)
        {
            return '';
        }

        const parsed =
            DateEditColumnEdit.getCurrentValueParsed(false, params.value);
        const formatted = DateEdit.formatValue(parsed, true);

        return formatted ? formatted : '';
    }

    public constructor(props: ConfigProperties)
    {
        super(props);

        const currentDate =
            DateEditColumnEdit.getCurrentValueParsed(false, props.value);
        let formattedValue = DateEdit.formatValue(currentDate, true);

        // Backspace.
        if (props.keyPress === 8 && formattedValue)
        {
            formattedValue = formattedValue.substr(0, formattedValue.length - 1);
        }
        else if (props.charPress)
        {
            if (formattedValue)
            {
                formattedValue += props.charPress;
            }
            else
            {
                formattedValue = props.charPress;
            }
        }

        this.state =
        {
            isDialogOpen: false,
            value: formattedValue,
        };

        if (typeof currentDate !== 'string')
        {
            this.lastValidDate = currentDate;
        }

        this.muiProps =
        {
            autoFocus: true,
            fullWidth: true,
            icon: 'fas fa-pencil',
            iconColor: grey[300],
            inputRef: (element) => { this.inputElement = element; },
            onChange: e => this.setValue(e.target.value),
            style: { height: 'calc(100% + 2px)', paddingLeft: 2 },
        };
    }

    private openCalendar = () =>
    {
        this.setState({ isDialogOpen: true });
    };

    private setValue(value: string | null)
    {
        Sys.clearBusinessErrors(
            this.props.dataId, this.props.name, this.props.data.rowKey);
        this.setState({ value });
    }

    public closeCalendar = () =>
    {
        this.setState({ isDialogOpen: false });
    };

    public componentDidMount()
    {
        CellUtil.disableGridNavigation(
            this.props.eGridCell, this.inputElement);

        CellUtil.setInitialFocus(this.inputElement);
    }

    public componentWillUnmount()
    {
        CellUtil.enableGridNavigation(this.props.eGridCell);
    }

    public getValue(): string | null
    {
        return DateEdit.formatValue(
            DateEditColumnEdit.getCurrentValueParsed(true, this.state.value),
            false);
    }

    public render()
    {
        const _props = { ...this.props };
        let currentDate: string | Date | null;
        let errors: string[] = [];

        const parentTable = this.props.propagated.parentTable;
        if (this.state.isDialogOpen)
        {
            parentTable.setStopEditingWhenGridLosesFocus(false);
        }
        else
        {
            parentTable.setStopEditingWhenGridLosesFocus(true);
        }

        this.muiProps.value = this.state.value || '';

        currentDate =
            DateEditColumnEdit.getCurrentValueParsed(true, this.muiProps.value);

        if (!currentDate)
        {
            currentDate = new Date();
        }
        else if (typeof currentDate === 'string')
        {
            currentDate = this.lastValidDate ? this.lastValidDate : new Date();
        }

        errors = DateEditColumnEdit.getErrors(
            _props, _props.node.data, this.muiProps.value);

        this.muiProps.error = errors.length > 0;
        this.muiProps.endAdornment =
            (
                <muiInputAdornment.default
                    position="end"
                    style={{ marginRight: 24, marginTop: -4 }}
                >
                    <Button
                        aria-label={Sys.getTranslation('Select Date')}
                        icon="fas fa-calendar-alt"
                        size="small"
                        onClick={this.openCalendar}
                    />
                </muiInputAdornment.default>
            );

        return (
            <ErrorBadge
                isShort={_props.node.data.isNew
                    && _props.propagated.parentTable.isDocumentGrid}
                message={Api.getErrorMessages(errors)}
            >
                <Input {...this.muiProps} />
                <DateEditColumnDialog
                    currentDate={currentDate}
                    editor={this}
                    open={this.state.isDialogOpen}
                />
            </ErrorBadge>);
    }

    public restoreFocus()
    {
        if (this.inputElement)
        {
            this.inputElement.focus();
        }
    }

    public setFormattedValue(value: Date | null)
    {
        this.setValue(DateEdit.formatValue(value, true));
    }
}

export default DateEditColumnEdit;
