import { observer } from 'mobx-react';
import * as React from 'react';
import { ActionButton } from './ActionButton';
import Api, { AccessLevel } from './Api';
import { LanguageSelect } from './LanguageSelect';
import { ManualLink } from './ManualLink';
import { MenuButton } from './MenuButton';
import { PresentationButton } from './PresentationButton';

interface ConfigProperties
{
    child: { props: MenuItemConfig; type: string };
    propagated: object;
}

interface RuntimeProperties
{
    accessLevel: AccessLevel;
}

export interface MenuItemConfig
{
    dataId: string;
    name: string;
    propagated: { indent?: number };
    type: string;
}

export interface MenuItemProps
{
    config: MenuItemConfig & object;
    runtime: { accessLevel: AccessLevel; validateCaptcha?: boolean } & object;
}

@observer
export class MenuItem extends
    React.Component<ConfigProperties>
{
    public render(): React.ReactNode
    {
        const configProps =
            {
                ...this.props.child.props,
                propagated: this.props.propagated,
            };

        switch (this.props.child.type)
        {
            case 'LanguageSelect':
                return (
                    <LanguageSelect.renderMenuItem
                        config={configProps}
                        key={configProps.name}
                        runtime={{ accessLevel: AccessLevel.readOnly }}
                    />);
            default:
        }

        const runtimeProperties = Api.getWidgetProperties(
            this.props.child.props) as RuntimeProperties;

        if (!runtimeProperties)
        {
            return null;
        }

        if (runtimeProperties.accessLevel === AccessLevel.hidden)
        {
            return null;
        }

        switch (this.props.child.type)
        {
            case 'ActionButton':
                return (
                    <ActionButton.renderMenuItem
                        config={configProps}
                        key={configProps.name}
                        runtime={runtimeProperties}
                    />);

            case 'ManualLink':
                return (
                    <ManualLink.renderMenuItem
                        config={configProps}
                        key={configProps.name}
                        runtime={runtimeProperties}
                    />);

            case 'MenuButton':
                return (
                    <MenuButton.renderMenuItem
                        config={configProps}
                        key={configProps.name}
                        runtime={runtimeProperties}
                    />);

            case 'PresentationButton':
                return (
                    <PresentationButton.renderMenuItem
                        config={configProps}
                        key={configProps.name}
                        runtime={runtimeProperties}
                    />);

            default:
                throw new Error(
                    `Unknown menu item type ${this.props.child.type}`);
        }
    }
}

export default MenuItem;
