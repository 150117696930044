import { createStyles, Theme, WithStyles, withStyles }
    from '@material-ui/core/styles';
import withWidth, { WithWidth } from '@material-ui/core/withWidth';
import { observer } from 'mobx-react';
import * as React from 'react';
import Sys from '../core/Sys';
import { Drawer } from '../coreui/Drawer';
import { Menu as MenuBase } from '../coreui/Menu';
import MenuItem from '../coreui/MenuItem';
import RoundTripService from '../services/RoundTripService';
import Api, { AccessLevel } from './Api';
import ApiButton from './ApiButton';
import { MenuItemProps } from './MenuItem';
import { ToolbarChildProps } from './Toolbar';

interface Props
{
    alternateText: string;
    buttonColor: 'Default' | 'Dark' | 'Danger' | 'Success';
    dataId: string;
    disabledHelpText: string;
    href: string;
    iconName: string;
    name: string;
    navigationType:
        'New Component' |
        'New Related Component' |
        'New Process' |
        'Existing Component' |
        'Web Account';
    onClick: object;
    propagated?: ToolbarChildProps;
    renderAsLink: boolean;
    size:
    {
        lg: 'large' | 'medium' | 'small';
        md: 'large' | 'medium' | 'small';
        sm: 'large' | 'medium' | 'small';
        xl: 'large' | 'medium' | 'small';
        xs: 'large' | 'medium' | 'small';
    };
}

interface RuntimeProperties
{
    label: string;
    showDisabledHelp?: boolean;
    targetLayoutId?: number;
    targetObjectId?: number;
}

const styles = (theme: Theme) => createStyles(
    {
    });

function generateUrl(runtimeProperties: RuntimeProperties)
{
    const url = '#/object'
        + `/${runtimeProperties.targetObjectId}`
        + `/${runtimeProperties.targetLayoutId}`;
    return url;
}

function shouldGenerateUrl(navigationType: string)
{
    if (navigationType === 'New Related Component'
        || navigationType === 'New Component'
        || navigationType === 'New Process')
    {
        return false;
    }
    return true;
}

@observer
export class PresentationButton extends
    React.PureComponent<Props & WithWidth & WithStyles<typeof styles>>
{
    public static renderMenuItem(props: MenuItemProps): JSX.Element
    {
        const configProps = props.config as Props;
        const runtimeProperties =
            Api.getWidgetProperties(configProps) as RuntimeProperties;

        const onClick = () =>
        {
            if (props.runtime.accessLevel >= AccessLevel.actionable)
            {
                if (shouldGenerateUrl(configProps.navigationType))
                {
                    window.open(generateUrl(runtimeProperties), '_self');
                }
                else
                {
                    PresentationButton.requestNewRelatedObject(configProps);
                }
                MenuBase.closeAll();
                Drawer.closeAll();
            }
        };

        return (
            <MenuItem
                disabled={props.runtime.accessLevel === AccessLevel.disabled}
                iconName={configProps.iconName}
                indent={props.config.propagated
                    ? props.config.propagated.indent : 0}
                onClick={onClick}
            >
                {runtimeProperties.label}
            </MenuItem>
        );
    }

    public static requestNewRelatedObject(
        props: { dataId?: string; name?: string }
        ): void
    {
        Sys.confirmContinue(false).then(() =>
        {
            return RoundTripService.standardRoundTrip(
                'PresentationButton/OnClick', props);
        }).catch(() =>
        {
            // User cancelled dialog, do nothing
        });
    }

    private onClick = () =>
    {
        PresentationButton.requestNewRelatedObject(this.props);
    };

    public render()
    {
        const runtimeProperties =
            Api.getWidgetProperties(this.props) as RuntimeProperties;

        let size: 'large' | 'medium' | 'small' | undefined = undefined;
        if (this.props.width in this.props.size)
        {
            size = this.props.size[this.props.width];
        }

        let url: string | undefined = undefined;
        if (shouldGenerateUrl(this.props.navigationType))
        {
            url = generateUrl(runtimeProperties);
        }

        return (
            <ApiButton
                alternateText={this.props.alternateText}
                buttonColor={this.props.buttonColor}
                dataId={this.props.dataId}
                disabledHelpText={this.props.disabledHelpText}
                href={url}
                iconName={this.props.iconName}
                label={runtimeProperties.label}
                name={this.props.name}
                onClick={url ? undefined : this.onClick}
                renderAsLink={this.props.renderAsLink}
                size={size}
                tabIndex={this.props.propagated
                    && this.props.propagated.hasParentToolbar ? -1 : 0}
            />
        );
    }
}

export default withStyles(styles)(withWidth()(PresentationButton));
