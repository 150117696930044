import { PresentationConfig } from '../config/Presentation';
import AppServer, { State as AppServerState } from '../core/AppServer';
import RequestPromise from '../core/RequestPromise';
import Sys, { SessionMessage } from '../core/Sys';
import TrackableCollection from '../core/TrackableCollection';
import TrackableModel from '../core/TrackableModel';
import { BreakPointColumn } from '../coreui/Dialog';
import { PaneDataByDataId } from '../stores/PaneDataStore';
import BaseService from './BaseService';

export interface PresentationConfigResponse
{
    objectDefDescription: string;
    presentationConfig: object;
    scriptsByDataId: object;
}

export interface ConfigForDialogResponse
{
    breakPoints: BreakPointColumn[];
    presentationConfig: object;
    scriptsByDataId: object;
}

export interface PageDataResponse
{
    appServerState: AppServerState;
    paneDataByDataId: PaneDataByDataId;
}

export interface PresentationDataResponse
{
    appServerState: AppServerState;
    layoutId: number | null;
    newSessionMessages: SessionMessage[];
    objectTitle: string | null;
    paneDataByDataId: PaneDataByDataId | null;
    shouldRedirectHome: boolean;
    validationErrors?: string[];
}

export default class PresentationService
{
    public static getAuthenticatedPageData(
        appServerState: AppServerState | null
        ): RequestPromise<PageDataResponse>
    {

        return BaseService.requestObject(
            'Presentation/AuthenticatedPageData',
            null,
            null,
            { appServerState },
            'POST');
    }

    // FUTURE 7.4.1
    // Rename this method to "getEditDialogConfig"
    // Make corresponding changes through the stack
    public static getConfigForDialog(
        layoutId: string
        ): RequestPromise<ConfigForDialogResponse>
    {
        return BaseService.requestObject(
            `Presentation/ConfigForDialog/${layoutId}`,
            null,
            null,
            null,
            'GET');
    }

    // FUTURE 7.4.1
    // Rename this method to "getSelectDialogConfig"
    // Make corresponding changes through the stack
    public static getConfigForSelectDialog(
        layoutId: string
        ): RequestPromise<ConfigForDialogResponse>
    {
        return BaseService.requestObject(
            `Presentation/ConfigForSelectDialog/${layoutId}`,
            null,
            null,
            null,
            'GET');
    }

    public static getGuestPageData(
        appServerState: AppServerState | null
        ): RequestPromise<PageDataResponse>
    {

        return BaseService.requestObject(
            'Presentation/GuestPageData',
            null,
            null,
            { appServerState },
            'POST');
    }

    public static getLandingPageData(): RequestPromise<PageDataResponse>
    {
        return BaseService.requestObject(
            'Presentation/LandingPageData',
            null,
            null,
            null,
            'GET');
    }

    public static getPresentationConfig(
        layoutId: number
        ): RequestPromise<PresentationConfig>
    {
        const request = BaseService.requestObject<PresentationConfig>(
            `Presentation/PresentationConfig/${layoutId}`,
            null,
            null,
            null,
            'GET');

        return request.then((config: PresentationConfig) =>
        {
            for (const dataId of config.dataIds)
            {
                if (!TrackableModel.models.has(dataId))
                {
                    new TrackableCollection('PaneRow', dataId);
                }
            }

            return config;
        });
    }

    public static getPresentationData(
        layoutId: number,
        objectHandle: string,
        parameters: string | null = null
        ): RequestPromise<PresentationDataResponse>
    {
        const result = BaseService.requestObject<PresentationDataResponse>(
            `Presentation/PresentationData/${layoutId}/${objectHandle}`,
            null,
            null,
            {
                appServerState: AppServer.getState(),
                arguments: parameters,
            },
            'POST');

        result.then((response: PresentationDataResponse) =>
        {
            if (response.newSessionMessages.length)
            {
                Sys.sessionMessages.push(...response.newSessionMessages);
            }
        });

        return result;
    }

    public static getPresentationIdForName(
        presentationName: string,
        objectHandle: string
        ): RequestPromise<number>
    {
        const url = 'Presentation/GetPresentationIdForName'
            + `/${presentationName}`
            + `/${objectHandle}`;

        return BaseService.requestObject(
            url,
            null,
            null,
            null,
            'GET');
    }
}
