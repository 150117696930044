import * as muiDivider from '@material-ui/core/Divider';
import * as React from 'react';

interface Props extends muiDivider.DividerProps
{
}

export class DividerDisplay extends React.PureComponent<Props>
{
    public render()
    {
        const _props = { ...this.props };

        return <muiDivider.default {..._props} variant="fullWidth" />;
    }
}

export default DividerDisplay;
