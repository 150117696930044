import { createStyles, Theme, WithStyles, withStyles, withTheme }
    from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import * as React from 'react';
import MenuItem, { MenuItemConfig } from './MenuItem';

interface ConfigProperties
{
    indent?: number;
    menuItems: { props: MenuItemConfig; type: string }[];
    propagated: object;
}

const styles = (theme: Theme) => createStyles(
    {
    });

@observer
export class MenuPane extends
    React.Component<ConfigProperties & WithStyles<typeof styles>>
{
    public render(): React.ReactNode
    {
        const children: React.ReactNode[] = [];

        for (const childConfig of this.props.menuItems)
        {
            children.push(
                <MenuItem
                    key={childConfig.props.name}
                    child={childConfig}
                    propagated={this.props.propagated}
                />
            );
        }

        return <React.Fragment>{children}</React.Fragment>;
    }
}

export default withStyles(styles)(withTheme(MenuPane));
