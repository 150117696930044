import * as muiTypography from '@material-ui/core/Typography';
import { observer } from 'mobx-react';
import * as React from 'react';
import Sys from '../core/Sys';
import { AccessLevel } from './Api';
import { StepWidgetProperties, WizardControl as WizardControlBase }
    from './WizardControl';

interface Props
{
}

@observer
export class WizardStepNumberDisplay extends
    React.Component<Props>
{
    public render()
    {
        const widgetProperties = WizardControlBase.getWidgetProperties();

        if (!widgetProperties)
        {
            return null;
        }

        if (widgetProperties.accessLevel === AccessLevel.hidden)
        {
            return null;
        }

        const count: number = WizardControlBase.getStepCount();
        const selectedStep: number = widgetProperties.steps.findIndex(s =>
            s.paneUseKey === widgetProperties.selectedPaneUseKey)!;
        const step: number = WizardControlBase.getStepNumber(selectedStep);
        let result: React.ReactNode = null;

        if (step <= count)
        {
            result =
                <muiTypography.default
                    component="span"
                    style={{ fontWeight: 400 }}
                    variant="h3"
                >
                    {Sys.getTranslation(
                        'Step {step} of {count}',
                        'Wizard',
                        { count, step })}
                </muiTypography.default>;
        }

        return result;
    }
}

export default WizardStepNumberDisplay;
