import PaneDataStore from '../stores/PaneDataStore';
import RequestsStore from '../stores/RequestsStore';
import Routing from './Routing';
import Sys from './Sys';
import TrackableModel, { ITrackable } from './TrackableModel';

export default class Monitor
{
    // Collection of messages written to the console log.
    public logMessages: Set<object> = new Set<object>();
    // Collection of requests.
    public requests: Set<object> = new Set<object>();
    public scriptErrors: Set<object> = new Set<object>();
    // Dictionary of scripts, keyed by request cache key.
    public scripts: Map<string, string> = new Map<string, string>();

    constructor()
    {
        // Traps javascript errors.
        window.addEventListener('error', (
            event: Event | string,
            source?: string,
            lineNumber?: number,
            colno?: number,
            error?: Error) =>
        {
            const scriptError: object =
                {
                    callStack: null,
                    event,
                    exceptionType: 'javascript',
                };

            if (error && error.stack)
            {
                scriptError['callStack'] = error.stack;
            }
            else
            {
                scriptError['callStack'] = `${source} at line ${lineNumber}`;
            }

            if (Sys.monitor)
            {
                Sys.monitor.addScriptError(scriptError);
            }
        });

        window.addEventListener('unhandledrejection', (event) =>
        {
            if (process.env.NODE_ENV === 'production'
                && Sys.getRouteRootToken() !== 'error')
            {
                Routing.goToErrorPage(Sys.getTranslation('Error'), null);
            }
            else
            {
                // Ensure loading animation is removed.
                RequestsStore.instance.clearAllProcessing();
            }
        });
    }

    public addMessage(message: string)
    {
        this.logMessages.add(
            {
                message,
                timestamp: Date.now(),
            });
    }

    public addRequest(requestConfig: object)
    {
        this.requests.add(requestConfig);
    }

    public addScript(key: string, script: string)
    {
        this.scripts.set(key, script);
    }

    public addScriptError(error: object)
    {
        this.scriptErrors.add(
            {
                error,
                timestamp: Date.now(),
            });
    }

    public clear(): void
    {
        this.requests.clear();
        this.scripts.clear();
        this.logMessages.clear();
        this.scriptErrors.clear();
    }

    public show(): void
    {
        // eslint-disable-next-line max-len
        console.log('--- Monitor --------------------------------------------------------------------------------');

        if (Sys.hasChanges())
        {
            console.log('--- Changes ---');
            console.log(PaneDataStore.getChanges());
        }

        if (this.requests.size > 0)
        {
            console.log('--- Requests ---');
            this.requests.forEach((request) =>
            {
                console.log(request);
            });
        }

        if (this.scripts.size > 0)
        {
            console.log('--- Scripts ---');
            this.scripts.forEach((script, key) =>
            {
                console.log(`${key}  source: ${script}`);
            });
        }

        if (this.scriptErrors.size > 0)
        {
            console.log('--- Script Errors ---');
            this.scriptErrors.forEach((scriptError) =>
            {
                console.log(scriptError);
            });
        }

        if (this.logMessages.size > 0)
        {
            console.log('--- Messages ---');
            this.logMessages.forEach((logMessage) =>
            {
                const formattedTimestamp = Sys.formatDate(
                    logMessage['timestamp'], 'hh:mm:ss.fff');
                console.log(`${formattedTimestamp}: ${logMessage['message']}`);
            });
        }

        if (Sys.keyListeners.size > 0)
        {
            console.log('--- Key Listeners ---');
            Sys.keyListeners.forEach((listener: EventListener, config: string) =>
            {
                console.log(config);
            });
        }

        if (TrackableModel.models.size > 0)
        {
            console.log('--- Models ---');
            TrackableModel.models.forEach((
                model: ITrackable,
                dataId: string | null) =>
            {
                console.log(`${dataId}: ${JSON.stringify(model)}`);
            });
        }
        // eslint-disable-next-line max-len
        console.log('--- End ------------------------------------------------------------------------------------------');
    }
}
