import * as muiButton from '@material-ui/core/Button';
import * as muiFab from '@material-ui/core/Fab';
import { createStyles, Theme, WithStyles, withStyles }
    from '@material-ui/core/styles';
import { darken } from '@material-ui/core/styles/colorManipulator';
import * as React from 'react';
import Api from '../mustangui/Api';
import Icon from './Icon';

export interface ButtonProps extends muiButton.ButtonProps
{
    badge?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    color?: any;
    component?: string;
    customSize?: 'tiny';
    endIcon?: string;
    fab?: boolean;
    fullWidth?: boolean;
    icon?: string;
    label?: string;
    target?: string;
}

const styles = (theme: Theme) => createStyles(
    {
        badge:
        {
            fontSize: 9,
            height: 16,
            minHeight: 16,
            width: 16,
        },
        danger:
        {
            '&:hover':
            {
                backgroundColor: darken(Api.getSystemColor('danger'), 0.1),
            },
            backgroundColor: Api.getSystemColor('danger'),
            color: theme.palette.getContrastText(Api.getSystemColor('danger')),
        },
        dark:
        {
            '&:hover':
            {
                backgroundColor: darken(Api.getSystemColor('dark'), 0.1),
            },
            backgroundColor: Api.getSystemColor('dark'),
            color: theme.palette.getContrastText(Api.getSystemColor('dark')),
        },
        fabIcon:
        {
            width: '100%',
        },
        fabIconMedium:
        {
            fontSize: 24,
        },
        fabIconTiny:
        {
            fontSize: 10,
        },
        fullWidth:
        {
            width: '100%',
        },
        label:
        {
        },
        root:
        {
        },
        success:
        {
            '&:hover':
            {
                backgroundColor: darken(Api.getSystemColor('success'), 0.1),
            },
            backgroundColor: Api.getSystemColor('success'),
            color: theme.palette.getContrastText(Api.getSystemColor('success')),
        },
        text:
        {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        tiny:
        {
            height: 16,
            minHeight: 16,
            minWidth: 16,
            width: 16,
        },
    });

export class Button extends
    React.PureComponent<ButtonProps & WithStyles<typeof styles>>
{
    public render()
    {
        const _props = { ...this.props };
        let isFab: boolean = !!_props.fab;
        let result: React.ReactNode = null;

        // Class name may be inherited.
        if (!_props.className)
        {
            _props.className = `${_props.classes.root} `;
        }
        else
        {
            _props.className += ' ';
        }

        if ('label' in _props)
        {
            if (_props.label)
            {
                _props.children = _props.label;
            }

            delete _props.label;
        }

        if ('color' in _props)
        {
            if (_props.color === 'danger' || _props.color === 'dark' ||
                _props.color === 'success')
            {
                _props.className += `${_props.classes[_props.color]} `;
                delete _props.color;
            }
        }

        if ('badge' in _props)
        {
            _props.className += `${_props.classes.badge} `;

            delete _props.badge;
        }

        if (!_props.variant)
        {
            _props.variant = 'contained';
        }

        if (_props.children && typeof _props.children === 'string')
        {
            _props.children = (
                <span className={_props.classes.text}>
                    {_props.children}
                </span>
            );
        }

        if ('icon' in _props)
        {
            if (_props.icon)
            {
                if (_props.children)
                {
                    _props.children =
                        <React.Fragment>
                            <Icon
                                icon={_props.icon}
                                style={{ marginRight: '.4em' }}
                            />
                            {_props.children}
                        </React.Fragment>;
                }
                else
                {
                    isFab = true;

                    let iconClass = _props.classes.fabIcon;
                    if (_props.size === 'medium')
                    {
                        iconClass += ` ${_props.classes.fabIconMedium}`;
                    }
                    if (_props.customSize === 'tiny')
                    {
                        iconClass += ` ${_props.classes.fabIconTiny}`;
                        _props.className += `${_props.classes.tiny} `;
                        _props.size = 'small';
                    }

                    _props.children =
                        <Icon
                            className={iconClass}
                            icon={_props.icon}
                        />;
                }
            }

            delete _props.icon;
        }

        if ('endIcon' in _props)
        {
            if (_props.endIcon)
            {
                if (_props.children)
                {
                    if (_props.children instanceof Array)
                    {
                        _props.children.push(
                            <Icon
                                icon={_props.endIcon}
                                key="endicon"
                                style={{ marginLeft: '.4em' }}
                            />);
                    }
                    else
                    {
                        _props.children =
                            <React.Fragment>
                                {_props.children}
                                <Icon
                                    icon={_props.endIcon}
                                    style={{ marginLeft: '.4em' }}
                                />
                            </React.Fragment>;
                    }
                }
                else
                {
                    _props.children = <Icon icon={_props.endIcon}/>;
                }
            }

            delete _props.endIcon;
        }

        delete _props.classes;
        delete _props.customSize;
        delete _props.fullWidth;

        if ('fab' in _props)
        {
            delete _props.fab;
        }

        if (isFab)
        {
            const fabProps = { ..._props } as muiFab.FabProps;

            delete fabProps.variant;

            result =
                <muiFab.default
                    classes={{
                        label: this.props.classes.label,
                    }}
                    {...fabProps}
                />;
        }
        else
        {
            result =
                <muiButton.default
                    classes={{
                        label: this.props.classes.label,
                        root: this.props.fullWidth
                            ? this.props.classes.fullWidth : undefined,
                    }}
                    {..._props}
                />;
        }

        return result;
    }
}

export default withStyles(styles)(Button);
