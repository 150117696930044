import * as WebFont from 'webfontloader';
import Sys from './core/Sys';

Sys.baseUrl = 'dynamic/';

WebFont.load(
    {
        google:
        {
            families: ['Roboto:400,700,900'],
        },
    });

// Determine when the document has been loaded.
document.onreadystatechange = () =>
{
    Sys.onReadyStateChange();
};
