import AppServer, { State } from '../core/AppServer';
import Logging from '../core/Logging';
import RequestPromise from '../core/RequestPromise';
import Sys, { BusinessError, SessionMessage } from '../core/Sys';
import TrackableCollection from '../core/TrackableCollection';
import TrackableModel from '../core/TrackableModel';
import { TableChildProps } from '../coreui/Table';
import { CaptchaControl as CaptchaControlBase }
    from '../mustangui/CaptchaControl';
import RoundTripService, { RoundTripResponse }
    from '../services/RoundTripService';
import PaneDataStore, { PaneDataByDataId } from '../stores/PaneDataStore';
import RequestsStore from '../stores/RequestsStore';
import NewObjectService from './NewObjectService';

export interface OnRoundTripResponse extends RoundTripResponse
{
    newSessionMessages: SessionMessage[];
    saved: boolean;
}

interface OnNewRowResponse
{
    appServerState: State;
    businessErrors: BusinessError[];
    newRowPaneDataByDataId: PaneDataByDataId;
    paneDataByDataId: PaneDataByDataId;
    validationErrors: string[];
}

export interface RoundTripProps
{
    dataId: string;
    name: string;
}

export default class ActionButtonService
{
    public static onNewRow(
        rowKey: string,
        dataId: string,
        widgetName: string,
        propagated: TableChildProps
        ): RequestPromise<void>
    {
        RequestsStore.instance.processingStarted();

        if (propagated.parentTable.hasRelatedEditDialog)
        {
            AppServer.createStateRecoveryPoint();
        }

        return RoundTripService.customRoundTrip(
            'ActionButton/OnNewRow/{rowKey}/{dataId}/{widgetName}',
            { dataId, rowKey, widgetName }
        ).then((response: OnNewRowResponse) =>
        {
            if (response.validationErrors.length > 0)
            {
                Sys.showErrors(response.validationErrors);
                if (propagated.parentTable.hasRelatedEditDialog)
                {
                    AppServer.clearStateRecoveryPoint();
                }

                return;
            }

            AppServer.setState(response.appServerState);

            if (response.businessErrors.length > 0)
            {
                AppServer.setState(response.appServerState);
                Sys.clearBusinessErrors();
                PaneDataStore.loadResponse(response.paneDataByDataId);
                Sys.setBusinessErrors(response.businessErrors, false);
                if (propagated.parentTable.hasRelatedEditDialog)
                {
                    AppServer.clearStateRecoveryPoint();
                }

                return;
            }

            const newRowData = response.newRowPaneDataByDataId;
            const newRowDataId = Object.keys(newRowData)[0];
            const row = newRowData[newRowDataId].rows[0];

            PaneDataStore.clearDeletedRows();
            const collection = TrackableModel.models.get(newRowDataId) as
                TrackableCollection;
            collection.insert(row, false);
            propagated.parentTable.populateData();

            if (propagated.parentTable.hasRelatedEditDialog)
            {
                propagated.parentTable.openRowEditDialog(
                    row['rowKey'], true);
            }
            else
            {
                propagated.parentTable.scrollToRow(row['rowKey']);
            }
        }).finally(() => RequestsStore.instance.processingStopped());
    }

    public static onRoundTrip(
        props: RoundTripProps,
        selectedRowKeys: string[] | null = null
        ): RequestPromise<OnRoundTripResponse>
    {
        const captchaResponseToken = CaptchaControlBase.responseToken;

        return RoundTripService.standardRoundTrip(
            'ActionButton/OnRoundTrip',
            props,
            { captchaResponseToken, selectedRowKeys  }
        ).then((response: OnRoundTripResponse) =>
        {
            if (response.newSessionMessages.length)
            {
                Sys.sessionMessages.push(...response.newSessionMessages);
            }

            if (response.validationErrors
                && response.validationErrors.length > 0)
            {
                return response;
            }

            if (response.businessErrors
                && response.businessErrors.length > 0)
            {
                return response;
            }

            if ('newObjectId' in response && response.newObjectId)
            {
                NewObjectService.navigateToNewObject(
                    response.newObjectId, response.layoutId!);
            }

            return response;
        }).catch((response) =>
        {
            Logging.error(response);
            return response;
        });
    }
}
