import * as muiCollapse from '@material-ui/core/Collapse';
import { createStyles, Theme, WithStyles, withStyles }
    from '@material-ui/core/styles';
import * as React from 'react';
import * as ReactDOM from 'react-dom';

interface Props
{
    children: React.ReactNode;
    in: boolean;
    name?: string | null;
}

const styles = (theme: Theme) => createStyles(
    {
        container:
        {
            transition: theme.transitions.create(['height', 'margin-top']),
        },
    });

export class Collapse extends
    React.PureComponent<Props & WithStyles<typeof styles>>
{
    // Dictionary of collapse components, keyed by name.
    public static instances = new Map<string, Set<Collapse>>();
    private hasExpanded: boolean = false;

    private static addInstance(instance: Collapse, name: string)
    {
        if (!Collapse.instances.has(name))
        {
            Collapse.instances.set(name, new Set<Collapse>());
        }

        Collapse.instances.get(name)!.add(instance);
    }

    public static close(name?: string | null)
    {
        if (!name || !Collapse.instances.has(name))
        {
            return;
        }

        Collapse.instances.get(name)!.forEach((collapse) =>
        {
            collapse.close();
        });
    }

    public static open(name?: string | null)
    {
        if (!name || !Collapse.instances.has(name))
        {
            return;
        }

        Collapse.instances.get(name)!.forEach((collapse) =>
        {
            collapse.open();
        });
    }

    public static toggle(name?: string | null)
    {
        if (!name || !Collapse.instances.has(name))
        {
            return;
        }

        Collapse.instances.get(name)!.forEach((collapse) =>
        {
            collapse.toggle();
        });
    }

    constructor(props: Props & WithStyles<typeof styles>)
    {
        super(props);

        if ('in' in props)
        {
            this.state = { open: props.in };
        }
        else
        {
            this.state = { open: true };
        }

        if (props.name)
        {
            Collapse.addInstance(this, props.name);
        }
    }

    private onCollapse = (node: HTMLElement): void =>
    {
        node.style.marginTop = '0px';
    };

    private onCollapseEnd = (node: HTMLElement): void =>
    {
        node.style.display = 'none';
    };

    private onExpand = (node: HTMLElement, isAppearing?: boolean): void =>
    {
        node.style.marginTop = '';
    };

    private onExpandStart = (node: HTMLElement, isAppearing?: boolean): void =>
    {
        node.style.display = '';
    };

    public close()
    {
        if (!this.state['open'])
        {
            return;
        }

        this.setState({ open: false });
    }

    public componentDidMount()
    {
        if (this.state['open'])
        {
            return;
        }

        // Ensure the element is in 'collapsed' state if it starts collapsed.
        const node = ReactDOM.findDOMNode(this)! as HTMLElement;
        node.style['display'] = 'none';
        node.style['marginTop'] = '0px';
    }

    public componentWillUnmount()
    {
        if (!this.props.name || !Collapse.instances.has(this.props.name))
        {
            return;
        }

        Collapse.instances.get(this.props.name)!.delete(this);

        if (Collapse.instances.get(this.props.name)!.size === 0)
        {
            Collapse.instances.delete(this.props.name);
        }
    }

    public open()
    {
        if (this.state['open'])
        {
            return;
        }

        this.setState({ open: true });
    }

    public render()
    {
        if (this.state['open'])
        {
            this.hasExpanded = true;
        }

        return (
            <muiCollapse.default
                className={this.props.classes.container}
                in={this.state['open']}
                onEnter={this.onExpandStart}
                onEntering={this.onExpand}
                onExited={this.onCollapseEnd}
                onExiting={this.onCollapse}
            >
                {this.hasExpanded ? this.props.children : null}
            </muiCollapse.default>
        );
    }

    public toggle()
    {
        this.setState({ open: !this.state['open'] });
    }
}

export default withStyles(styles)(Collapse);
