import { createStyles, WithStyles, withStyles, WithTheme, withTheme }
    from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { observer } from 'mobx-react';
import * as React from 'react';
import Presentation from '../coreui/Presentation';
import PaneRow from '../models/PaneRow';
import { CustomTheme } from '../muiTheme';
import { AccessLevel } from '../mustangui/Api';
import
    FreeflowContainerSection,
    {
        FreeflowContainerSection as FreeflowContainerSectionBase,
        ConfigProperties as SectionConfig,
    }
    from './FreeflowContainerSection';

interface ConfigProperties
{
    dataId: string;
    end: SectionConfig;
    middle: SectionConfig;
    propagated: object;
    start: SectionConfig;
}

interface RuntimeProperties
{
    accessLevel?: AccessLevel;
}

const styles = (theme: CustomTheme) =>
{
    const responsiveStyles = {};
    for (const breakPoint of theme.visibilityBreakPoints)
    {
        responsiveStyles[`grid-1-column-${breakPoint}`] =
            {
                [theme.breakpoints.only(breakPoint)]:
                {
                    gridTemplateColumns: '1fr',
                },
            };
        responsiveStyles[`grid-2-column-${breakPoint}`] =
            {
                [theme.breakpoints.only(breakPoint)]:
                {
                    gridTemplateColumns: 'auto auto',
                },
            };
        responsiveStyles[`grid-3-column-${breakPoint}`] =
            {
                [theme.breakpoints.only(breakPoint)]:
                {
                    gridTemplateColumns: '1fr auto 1fr',
                },
            };
        responsiveStyles[`spacer-visible-${breakPoint}`] =
            {
                [theme.breakpoints.only(breakPoint)]:
                {
                    display: 'block',
                },
            };
        responsiveStyles[`root-visible-${breakPoint}`] =
            {
                [theme.breakpoints.only(breakPoint)]:
                {
                    display: 'grid',
                },
            };
    }

    const sectionSpaceStyle = {};
    for (const breakPoint of theme.spacingBreakPoints)
    {
        const sectionSpacing = theme.freeflow.section.spacing[breakPoint];

        sectionSpaceStyle[theme.breakpoints.up(breakPoint)] =
            {
                gridColumnGap: `${sectionSpacing.horizontal}px`,
            };
    }

    const result =
        {
            root:
            {
                alignItems: 'center',
                display: 'none',
                ...sectionSpaceStyle,
            } as CSSProperties,
            spacer:
            {
                display: 'none',
            },
            ...responsiveStyles,
        };

    return createStyles(result);
};

@observer
class Freeflow extends
    React.Component<ConfigProperties & WithStyles<typeof styles> & WithTheme>
{
    public render(): React.ReactNode
    {
        const rootClasses: string[] = [this.props.classes.root];
        const startSpacerClasses: string[] = [this.props.classes.spacer];
        const endSpacerClasses: string[] = [this.props.classes.spacer];
        const theme = this.props.theme as CustomTheme;

        for (const breakPoint of theme.visibilityBreakPoints)
        {
            const startVisible = FreeflowContainerSectionBase.isVisible(
                this.props.dataId, this.props.start, breakPoint);
            const middleVisible = FreeflowContainerSectionBase.isVisible(
                this.props.dataId, this.props.middle, breakPoint);
            const endVisible = FreeflowContainerSectionBase.isVisible(
                this.props.dataId, this.props.end, breakPoint);

            const numberVisible = (startVisible ? 1 : 0)
                + (middleVisible ? 1 : 0)
                + (endVisible ? 1 : 0);

            if (numberVisible === 0)
            {
                continue;
            }

            rootClasses.push(this.props.classes[`root-visible-${breakPoint}`]);

            if (numberVisible === 1)
            {
                const grid1Column = `grid-1-column-${breakPoint}`;
                rootClasses.push(this.props.classes[grid1Column]);

                continue;
            }

            if (!middleVisible)
            {
                const grid2Column = `grid-2-column-${breakPoint}`;
                rootClasses.push(this.props.classes[grid2Column]);

                continue;
            }

            const grid3Column = `grid-3-column-${breakPoint}`;
            rootClasses.push(this.props.classes[grid3Column]);

            const spacerVisible = `spacer-visible-${breakPoint}`;
            if (!startVisible)
            {
                startSpacerClasses.push(this.props.classes[spacerVisible]);
            }

            if (!endVisible)
            {
                endSpacerClasses.push(this.props.classes[spacerVisible]);
            }
        }

        return (
            <div className={rootClasses.join(' ')}>
                <div className={startSpacerClasses.join(' ')} />
                <FreeflowContainerSection
                    {...this.props.start}
                    dataId={this.props.dataId}
                    justify="start"
                    propagated={this.props.propagated}
                />
                <FreeflowContainerSection
                    {...this.props.middle}
                    dataId={this.props.dataId}
                    justify="middle"
                    propagated={this.props.propagated}
                />
                <FreeflowContainerSection
                    {...this.props.end}
                    dataId={this.props.dataId}
                    justify="end"
                    propagated={this.props.propagated}
                />
                <div className={endSpacerClasses.join(' ')} />
            </div>);
    }
}

export default withStyles(styles)(withTheme(Freeflow));
