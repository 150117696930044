import { createStyles, makeStyles, Theme, WithStyles, withStyles }
    from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { observer } from 'mobx-react';
import * as React from 'react';
import RequestPromise from '../../core/RequestPromise';
import Sys from '../../core/Sys';
import Button from '../../coreui/Button';
import Icon from '../../coreui/Icon';
import { Menu as MenuBase } from '../../coreui/Menu';
import MenuItem from '../../coreui/MenuItem';
import Presentation from '../../coreui/Presentation';
import { TableChildProps } from '../../coreui/Table';
import PaneRow from '../../models/PaneRow';
import { AccessLevel } from '../../mustangui/Api';
import ActionButtonService, { RoundTripProps }
    from '../../services/ActionButtonService';
import { ActionButtonProps, ActionButtonRuntimeProps } from '../ActionButton';
import ApiButton from '../ApiButton';
import { MenuItemProps } from '../MenuItem';

interface Props extends ActionButtonProps
{
}

const styles = (theme: Theme) => createStyles(
    {
        fabIcon:
        {
            width: '100%',
        },
        input:
        {
            cursor: 'pointer',
            height: '100%',
            left: 0,
            opacity: 0,
            position: 'absolute',
            top: 0,
            width: '100%',
        },
    });

const menuStyles = makeStyles((theme: Theme) => (
{
    input:
    {
        cursor: 'pointer',
        height: '100%',
        left: 0,
        opacity: 0,
        position: 'absolute',
        top: 0,
        width: '100%',
    } as CSSProperties,
}));

@observer
export class NewRowButton extends
    React.Component<Props & WithStyles<typeof styles>>
{
    private onClickPromise: RequestPromise<void>;

    private static onChange(
        propagated: TableChildProps,
        input: HTMLInputElement
        )
    {
        if (!input.files || input.files.length <= 0)
        {
            return;
        }

        propagated.parentTable.uploadFiles(input.files).then(() =>
        {
            input.value = '';
        });
    }

    private static onClick(
        config: RoundTripProps & { propagated: TableChildProps }
        ): RequestPromise<void>
    {
        if (config.propagated.parentTable.isVerticalLayout)
        {
            config.propagated.parentTable.getApi().paginationGoToFirstPage();
        }

        const observable = Presentation.getObservable(config)! as PaneRow;
        return ActionButtonService.onNewRow(
            observable.rowKey,
            config.dataId,
            config.name,
            config.propagated);
    }

    public static renderMenuItem(props: MenuItemProps): JSX.Element
    {
        const configProps = props.config as Props;
        const runtimeProps = props.runtime as ActionButtonRuntimeProps;

        if (props.runtime.accessLevel <= AccessLevel.readOnly)
        {
            return (
                <MenuItem
                    disabled={
                        props.runtime.accessLevel === AccessLevel.disabled}
                    iconName={configProps.iconName}
                    indent={props.config.propagated
                        ? props.config.propagated.indent : 0}
                >
                    {runtimeProps.label}
                </MenuItem>
            );
        }

        if (configProps.uploadOnNew)
        {
            const classes = menuStyles();

            const onChange = (
                event: React.ChangeEvent<HTMLInputElement>) =>
            {
                NewRowButton.onChange(configProps.propagated, event.target);
                MenuBase.closeAll();
            };

            const extensions: string | undefined =
                configProps.propagated.parentTable.validExtensions?.
                    map(extension => `.${extension}`).join(',');

            return (
                <MenuItem
                    disabled={
                        props.runtime.accessLevel === AccessLevel.disabled}
                    iconName={configProps.iconName}
                    indent={props.config.propagated
                        ? props.config.propagated.indent : 0}
                    onFocus={(e: React.FocusEvent<HTMLLIElement>) =>
                    {
                        if (e.target.nodeName !== 'INPUT')
                        {
                            const input: HTMLInputElement =
                                e.target.querySelector('input')!;
                            input.focus();
                        }
                    }}
                    tabIndex={-1}
                >
                    <React.Fragment>
                        {runtimeProps.label}
                        <input
                            accept={extensions}
                            className={classes.input}
                            multiple={true}
                            onChange={onChange}
                            tabIndex={0}
                            type="file"
                        />
                    </React.Fragment>
                </MenuItem>
            );
        }

        return (
            <MenuItem
                iconName={configProps.iconName}
                indent={props.config.propagated
                    ? props.config.propagated.indent : 0}
                onClick={() =>
                {
                    NewRowButton.onClick(configProps);
                    MenuBase.closeAll();
                }}
            >
                {runtimeProps.label}
            </MenuItem>
        );
    }

    private onChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    {
        NewRowButton.onChange(this.props.propagated, event.target);
    };

    private onClick = () =>
    {
        this.onClickPromise = NewRowButton.onClick(this.props);
    };

    public componentWillUnmount()
    {
        if (this.onClickPromise)
        {
            this.onClickPromise.abort();
        }
    }

    public render(): React.ReactNode
    {
        const runtimeProperties = PaneRow.getWidgetProperties(
            this.props.dataId, this.props.name) as ActionButtonRuntimeProps;

        if (runtimeProperties.accessLevel === undefined)
        {
            return null;
        }

        if (runtimeProperties.accessLevel === AccessLevel.hidden)
        {
            return null;
        }

        if (this.props.uploadOnNew
            && runtimeProperties.accessLevel >= AccessLevel.actionable)
        {
            const extensions: string | undefined =
                this.props.propagated.parentTable.validExtensions?.
                    map(extension => `.${extension}`).join(',');

            let buttonColor = undefined;
            if (this.props.buttonColor !== 'Default')
            {
                buttonColor = this.props.buttonColor.toLowerCase();
            }

            const uploadInput = (
                <input
                    accept={extensions}
                    className={this.props.classes.input}
                    multiple={true}
                    onChange={this.onChange}
                    tabIndex={-1}
                    title={Sys.getTranslation('Choose File')}
                    type="file"
                />
            );

            if (runtimeProperties.label)
            {
                return (
                    <Button
                        aria-label={Sys.getTranslation('Choose File')}
                        color={buttonColor}
                        component="span"
                        icon={this.props.iconName}
                        size="small"
                        tabIndex={-1}
                    >
                        {runtimeProperties.label}
                        {uploadInput}
                    </Button>
                );
            }

            return (
                <Button
                    aria-label={this.props.alternateText}
                    color={buttonColor}
                    component="span"
                    fab
                    size="small"
                    tabIndex={-1}
                >
                    <Icon
                        className={this.props.classes.fabIcon}
                        icon={this.props.iconName}
                    />
                    {uploadInput}
                </Button>
            );
        }

        return (
            <ApiButton
                alternateText={this.props.alternateText}
                buttonColor={this.props.buttonColor}
                dataId={this.props.dataId}
                disabledHelpText={this.props.disabledHelpText}
                iconName={this.props.iconName}
                label={runtimeProperties.label}
                name={this.props.name}
                onClick={this.onClick}
                size="small"
                tabIndex={-1}
            />
        );
    }
}

export default withStyles(styles)(NewRowButton);
