import { createStyles, Theme, WithStyles, withStyles }
    from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import * as React from 'react';
import Sys from '../core/Sys';
import KeyboardNavigationGroup from '../coreui/KeyboardNavigationGroup';
import Step from '../coreui/Step';
import { AccessLevel } from './Api';
import { StepProps, WidgetProperties, WizardControl as WizardControlBase }
    from './WizardControl';

interface Props
{
}

const styles = (theme: Theme) => createStyles(
    {
        root:
        {
            display: 'flex',
            flexWrap: 'wrap',
        },
    });

@observer
export class WizardStepsDisplay extends
    React.Component<Props & WithStyles<typeof styles>>
{
    private renderStepButton(
        step: StepProps, index: number, widgetProperties: WidgetProperties):
        React.ReactNode
    {
        const stepWidgetProps = widgetProperties.steps.find(
            s => s.paneUseKey === step.paneUseKey)!;

        if (!stepWidgetProps)
        {
            return null;
        }

        if (stepWidgetProps.accessLevel === AccessLevel.hidden)
        {
            return null;
        }

        return (
            <Step
                complete={stepWidgetProps.stepComplete}
                disabled={stepWidgetProps.accessLevel === AccessLevel.disabled}
                hasBusinessErrors={
                    Sys.hasBusinessErrors(step.paneUseKey)}
                key={step.paneUseKey}
                label={step.label}
                last={(index + 1) === widgetProperties.steps.length}
                onClick={() => { WizardControlBase.gotoStep(step.paneUseKey); }}
                selected={step.paneUseKey === widgetProperties.selectedPaneUseKey}
                step={WizardControlBase.getStepNumber(index)}
                tabIndex={-1}
                value={step.paneUseKey}
            />);
    }

    public render(): React.ReactNode
    {
        const _props = { ...this.props };
        const widgetProperties = WizardControlBase.getWidgetProperties();
        const steps = WizardControlBase.getSteps();

        if (!widgetProperties || !steps)
        {
            return null;
        }

        if (widgetProperties.accessLevel === AccessLevel.hidden)
        {
            return null;
        }

        return (
            <KeyboardNavigationGroup
                childSelector="a"
                className={_props.classes.root}
            >
                {steps.map((step, index) =>
                    this.renderStepButton(step, index, widgetProperties))}
            </KeyboardNavigationGroup>);
    }
}

export default withStyles(styles)(WizardStepsDisplay);
