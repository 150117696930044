import { createStyles, Theme, WithStyles, withStyles }
    from '@material-ui/core/styles';
import withWidth, { WithWidth } from '@material-ui/core/withWidth';
import { GridApi } from 'ag-grid-community';
import { observer } from 'mobx-react';
import * as React from 'react';
import Sys from '../core/Sys';
import Button from '../coreui/Button';
import Icon from '../coreui/Icon';
import Menu, { Menu as MenuBase } from '../coreui/Menu';
import MenuItem from '../coreui/MenuItem';
import { TableChildProps } from '../coreui/Table';
import Typography from '../coreui/Typography';

interface Props extends WithWidth
{
    dataId: string;
    name: string;
    propagated: TableChildProps;
}

interface State
{
    menuButtonRef?: HTMLButtonElement;
    pageMessage?: string;
}

const styles = (theme: Theme) => createStyles(
    {
        menuButton:
        {
            fontSize: 12,
            fontWeight: 400,
            letterSpacing: 'normal',
            marginLeft: 20,
            marginRight: 20,
            minWidth: 0,
            paddingLeft: 4,
            paddingRight: 4,
            textTransform: 'none',
        },
        root:
        {
        },
    });

@observer
export class GridPager extends
    React.Component<Props & WithStyles<typeof styles>, State>
{
    private readonly allPageSize = 999999;
    private currentPageCount = 0;
    private currentPageNumber = 0;
    private readonly gridApi: GridApi;
    private mounted: boolean = false;
    private nextButtonRef: HTMLButtonElement;
    private pageSizes: { label: string; onClick: () => void; size: number }[];
    private prevButtonRef: HTMLButtonElement;

    public constructor(props: Props & WithStyles<typeof styles>)
    {
        super(props);

        this.state = {};

        this.gridApi = props.propagated.parentTable.getApi();

        this.gridApi.paginationSetPageSize(
            props.propagated.parentTable.initialPageSize[props.width]);
        this.setPageSizes();

        this.gridApi.addEventListener(
            'paginationChanged',
            this.setCurrentPageInfo);
    }

    private changePage(next: boolean)
    {
        if (next)
        {
            this.gridApi.paginationGoToNextPage();
        }
        else
        {
            this.gridApi.paginationGoToPreviousPage();
        }

        setTimeout(() =>
        {
            // If the button that was clicked is now disabled, focus the other
            // button. If this isn't done the control loses focus altogether
            const currentPage = this.gridApi.paginationGetCurrentPage();
            if (currentPage <= 0 && this.nextButtonRef)
            {
                this.nextButtonRef.focus();
            }
            if (currentPage >= this.gridApi.paginationGetTotalPages() - 1
                && this.prevButtonRef)
            {
                this.prevButtonRef.focus();
            }

            this.props.propagated.parentTable.scrollToTop();
        });
    }

    private setCurrentPageInfo = () =>
    {
        const count = this.gridApi.paginationGetRowCount();
        const first = Math.min(count, this.gridApi.getFirstDisplayedRow() + 1);
        const last = this.gridApi.getLastDisplayedRow() + 1;
        let pageMessage: string;

        this.currentPageNumber = this.gridApi.paginationGetCurrentPage();
        this.currentPageCount = this.gridApi.paginationGetTotalPages();

        if (this.gridApi.paginationGetPageSize() === 1)
        {
            pageMessage = Sys.getTranslation(
                '{currentRow} of {totalRows}', 'DataTable');
            pageMessage = pageMessage.replace(
                '{currentRow}', first.toString());
            pageMessage = pageMessage.replace(
                '{totalRows}', count.toString());
        }
        else
        {
            pageMessage = Sys.getTranslation(
                '{currentPageFirstRow}-{currentPageLastRow} of {totalRows}',
                'DataTable');
            pageMessage = pageMessage.replace(
                '{currentPageFirstRow}', first.toString());
            pageMessage = pageMessage.replace(
                '{currentPageLastRow}', last.toString());
            pageMessage = pageMessage.replace(
                '{totalRows}', count.toString());
        }

        if (this.mounted)
        {
            this.setState({ pageMessage });
        }
    };

    private setMenuButtonRef = (ref: HTMLButtonElement) =>
    {
        this.setState((prevState) =>
        {
            if (prevState.menuButtonRef !== ref)
            {
                return { menuButtonRef: ref };
            }

            return {};
        });
    };

    private setPageSizes = () =>
    {
        this.pageSizes = [];
        let sizes: number[];

        if (this.props.propagated.parentTable.isVerticalLayout)
        {
            sizes = [1, 5, 10, 20, this.allPageSize];
        }
        else
        {
            sizes = [5, 10, 20, this.allPageSize];
        }

        sizes.forEach((size: number) =>
        {
            this.pageSizes.push(
                {
                    label: size === this.allPageSize ? 'All' : size.toString(),
                    onClick: () =>
                    {
                        const focusedCell = this.gridApi.getFocusedCell();

                        this.gridApi.paginationSetPageSize(size);
                        this.gridApi.paginationGoToFirstPage();

                        if (focusedCell && focusedCell.rowIndex > (size - 1))
                        {
                            this.gridApi.clearFocusedCell();
                        }

                        MenuBase.closeAll();
                    },
                    size,
                });
        });
    };

    public componentDidMount()
    {
        this.mounted = true;
        this.setCurrentPageInfo();
    }

    public componentWillUnmount()
    {
        this.mounted = false;
        this.gridApi.removeEventListener(
            'paginationChanged',
            this.setCurrentPageInfo);
    }

    public render()
    {
        const _props = { ...this.props };

        const menuName = `${this.props.dataId}.${this.props.name}`;
        const pageSize = this.gridApi.paginationGetPageSize();
        const isFullWidth = this.props.propagated.parentTable.isVerticalLayout;

        const leftButton = (
            <Button
                aria-label={Sys.getTranslation('Previous Page')}
                buttonRef={r => this.prevButtonRef = r as HTMLButtonElement}
                disabled={this.currentPageNumber === 0}
                icon="fas fa-chevron-left"
                onClick={() => this.changePage(false)}
                size="small"
                style={
                {
                    marginLeft: isFullWidth ? 0 : 24,
                    marginRight: isFullWidth ? 24 : 8,
                }}
                tabIndex={-1}
            />);

        return (
            <div
                style={{
                    display: 'flex',
                }}
            >
                {isFullWidth ? leftButton : null}
                <div
                    style={{
                        display: 'flex',
                        flex: isFullWidth ? 'auto' : undefined,
                        justifyContent: isFullWidth ? 'center' : undefined,
                    }}
                >
                    <Typography
                        style={
                            {
                                lineHeight: '26px',
                                whiteSpace: 'nowrap',
                            }}
                        variant="caption"
                    >
                        {Sys.getTranslation('Rows per page:', 'DataTable')}
                    </Typography>
                    <Button
                        aria-label={Sys.getTranslation('Change Page Size')}
                        buttonRef={this.setMenuButtonRef}
                        className={_props.classes.menuButton}
                        onClick={() =>
                            MenuBase.open(menuName, this.state.menuButtonRef)}
                        size="small"
                        tabIndex={-1}
                        variant="text"
                    >
                        {pageSize === this.allPageSize ? 'All' : pageSize}
                        <Icon
                            icon="fas fa-caret-down"
                            style={{ fontSize: 16, marginLeft: '.4em' }}
                        />
                    </Button>
                    <Menu
                        anchorEl={this.state.menuButtonRef}
                        name={menuName}
                        onExited={() => this.state.menuButtonRef!.focus()}
                        open={false}
                    >
                        {this.pageSizes.map((p) =>
                        {
                            return (
                                <MenuItem
                                    key={p.label}
                                    onClick={p.onClick}
                                    selected={p.size === pageSize}
                                >
                                    {p.label}
                                </MenuItem>);
                        })}
                    </Menu>
                    <Typography
                        style={{ lineHeight: '26px', whiteSpace: 'nowrap' }}
                        variant="caption"
                    >
                        {this.state.pageMessage}
                    </Typography>
                </div>
                {!isFullWidth ? leftButton : null}
                <Button
                    aria-label={Sys.getTranslation('Next Page')}
                    buttonRef={r => this.nextButtonRef = r as HTMLButtonElement}
                    disabled={
                        this.currentPageNumber >= (this.currentPageCount - 1)}
                    icon="fas fa-chevron-right"
                    onClick={() => this.changePage(true)}
                    size="small"
                    style={
                        {
                            marginLeft: isFullWidth ? 24 : 0,
                        }}
                    tabIndex={-1}
                />
            </div>);
    }
}

export default withStyles(styles)(withWidth()(GridPager));
