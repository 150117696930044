import { action, observable } from 'mobx';
import TrackableModel, { ITrackable } from './TrackableModel';

export default class TrackableCollection extends Array<TrackableModel>
    implements ITrackable
{
    // Id for the data.
    readonly dataId: string | null = null;
    // Indicates if pending changes should be ignored.
    public ignoreChanges: boolean = false;
    // Indicates if the model has been loaded.
    public isLoaded: boolean = false;
    // Indicates if only the first load request should be processed.
    public loadOnce: boolean = false;
    // Name of the models in the collection.
    readonly modelName: string | null = null;
    // Reference to the observableCollection wrapper.
    public observableCollection: TrackableModel[] | null = null;

    public static deleteRow(dataId: string, rowKey: string)
    {
        const collection = TrackableModel.models.get(dataId) as
            TrackableCollection;

        const model: TrackableModel = collection.find(
            m => m.getPrimaryKey() === rowKey)!;

        collection.delete(model);
    }

    constructor(modelName: string | null, dataId?: string | null)
    {
        super();

        // Only process if a model name is passed to the constructor.
        // Manipulating the collection creates a new instance.
        if (typeof modelName === 'string')
        {
            this.modelName = modelName;
            this.dataId = dataId || `${this.modelName}List`;
            this.observableCollection = observable.array(this);

            TrackableModel.register(this);
        }
    }

    // Reset all state flags, discard deleted models and set initial values to the
    // current value of each trackable property.
    public acceptChanges(): void
    {
        return TrackableModel.acceptChanges(this);
    }

    // Creates a model with the specified data and adds it to the collection.
    public add(data: object, isNew: boolean = true): void
    {
        const trackable = TrackableModel.create(
            this.modelName, this.dataId, data) as TrackableModel;

        trackable.isTrackableNew = isNew;

        // Add the model to the collection.
        this.push(trackable);

        if (this.observableCollection)
        {
            this.observableCollection.push(trackable);
        }
    }

    // Clear the contents of this collection.
    public clear(): void
    {
        if (!this.loadOnce)
        {
            this.clearDeleted();
            this.length = 0;

            // Clear the contents of the observable collection.
            if (this.observableCollection)
            {
                this.observableCollection.length = 0;
            }
        }
    }

    // Discard any deleted models of the type stored in this collection.
    public clearDeleted(): void
    {
        TrackableModel.clearDeleted(this.dataId);
    }

    // Track the specified model in the deleted collection for the model's type.
    // and remove it from this list.
    public delete(models: TrackableModel | TrackableModel[]): void
    {
        if (models instanceof TrackableModel)
        {
            models.delete(this);
        }
        else
        {
            models.forEach((model) =>
            {
                model.delete();

                // Remove the model from the specified collection.
                if (this.indexOf(model) > -1)
                {
                    this.splice(this.indexOf(model), 1);
                }
            });

            // Remove the models from the observable collection.
            if (this.observableCollection)
            {
                this.observableCollection.length = 0;

                if (this.length > 0)
                {
                    this.observableCollection.push(...this);
                }
            }
        }
    }

    // Returns a list of deleted models of the type stored in this collection.
    public getDeleted(): Set<TrackableModel>
    {
        return TrackableModel.getDeleted(this.dataId);
    }

    // Indicates if this model/collection has pending changes.
    public hasChanges(): boolean
    {
        return TrackableModel.hasChanges(this);
    }

    // Creates a model with the specified data and inserts it
    // at the start of the collection.
    public insert(data: object, isNew: boolean = true): void
    {
        const trackable = TrackableModel.create(
            this.modelName, this.dataId, data) as TrackableModel;

        trackable.isTrackableNew = isNew;

        // Insert the model at the start of the collection.
        this.unshift(trackable);

        if (this.observableCollection)
        {
            this.observableCollection.unshift(trackable);
        }
    }

    // Populates the model array with the specified data.
    @action
    public load(
        items?: object,
        clear: boolean = true
        ): TrackableCollection
    {
        if (!this.loadOnce || !this.isLoaded)
        {
            if (items)
            {
                if (clear)
                {
                    this.clearDeleted();
                }

                this.isLoaded = true;

                const itemList = items as object[];

                if (this.observableCollection)
                {
                    const primaryKeyName =
                        TrackableModel.getPrimaryKeyName(this.modelName!);

                    // If the same item exists at the same index, load the data
                    // in place.  Otherwise replace / add the item.
                    // We are not attempting to find each item by key regardless
                    // of position.  We are only interested in the scenario where
                    // the same rows are in the same position.  This avoids
                    // unnecessary renders when the sorted array items have
                    // not changed.
                    itemList.forEach((item, i) =>
                    {
                        if (this.observableCollection!.length > i &&
                            this.observableCollection![i].getPrimaryKey() ===
                                item[primaryKeyName])
                        {
                            this.observableCollection![i].load(item);
                        }
                        else
                        {
                            const trackable = TrackableModel.create(
                                this.modelName, this.dataId, item) as
                                TrackableModel;
                            if (this.observableCollection!.length > i)
                            {
                                this[i] = trackable;
                                this.observableCollection![i] = trackable;
                            }
                            else
                            {
                                this.push(trackable);
                                this.observableCollection!.push(trackable);
                            }
                        }
                    });

                    // Remove any extra items from the collection
                    this.observableCollection.length = itemList.length;
                }
            }
            else if (clear)
            {
                this.clear();
            }
        }

        return this;
    }

    // Reset IsModified, and set the value of each trackable property to
    // it's initial value.
    public revertValues(): void
    {
        return TrackableModel.revertValues(this);
    }
}
