export default class MaskingStore
{
    private static numberOpen: number = 0;

    public static maskClosed()
    {
        MaskingStore.numberOpen--;

        if (MaskingStore.numberOpen < 0)
        {
            // Reset count so the store does not enter an invalid state if the
            // thrown exception is handled.
            MaskingStore.numberOpen = 0;

            if (process.env.NODE_ENV !== 'production')
            {
                throw 'Mask closed when it was never opened';
            }
        }

        if (MaskingStore.numberOpen === 0)
        {
            document.body.classList.add('disableDialogNoScroll');
        }
    }

    public static maskOpened()
    {
        if (MaskingStore.numberOpen === 0)
        {
            document.body.classList.remove('disableDialogNoScroll');
        }

        MaskingStore.numberOpen++;
    }
}
