import * as muiSnackbar from '@material-ui/core/Snackbar';
import { createStyles, Theme, WithStyles, withStyles }
    from '@material-ui/core/styles';
import * as React from 'react';
import Button from './Button';
import Icon from './Icon';
import Presentation from './Presentation';
import Snackbar from './Snackbar';
import SnackbarContent from './SnackbarContent';

interface Props extends muiSnackbar.SnackbarProps
{
    closeHandler: Function;
    icon?: string;
    light?: boolean;
}

const styles = (theme: Theme) => createStyles(
    {
        light:
        {
            backgroundColor: theme.palette.grey[100],
            color: theme.palette.grey[800],
        },
        root:
        {
            zIndex: 1500,
        },
    });

export class Toast extends
    React.PureComponent<Props & WithStyles<typeof styles>>
{
    static current: Toast | null;

    public constructor(props: Props & WithStyles<typeof styles>)
    {
        super(props);

        this.state = { open: true };
    }

    public close()
    {
        if (Toast.current && this.state['open'])
        {
            this.setState({ open: false });
            Toast.current = null;
            Presentation.unmountComponent(this);
        }
    }

    public render()
    {
        const _props = { ...this.props };
        let action: React.ReactNode = undefined;
        let contentClassName: string | undefined = undefined;

        _props.className = `${_props.classes.root} `;

        if ('light' in _props)
        {
            if (_props.light)
            {
                contentClassName = `${_props.classes.light}`;
            }

            delete _props.light;
        }

        if (this.state['open'])
        {
            Toast.current = this;
        }
        else
        {
            Toast.current = null;
        }

        if (!_props.autoHideDuration)
        {
            action =
                <Button
                    fab
                    icon="fas fa-times"
                    onClick={() =>
                    {
                        if (this.props.closeHandler)
                        {
                            this.props.closeHandler();
                        }

                        this.close();
                    }}
                    size="small"
                />;

            _props.ClickAwayListenerProps =
            {
                mouseEvent: false,
                touchEvent: false,
            };
        }

        if ('icon' in _props)
        {
            if (_props.icon)
            {
                action =
                    <Icon
                        icon={_props.icon}
                    />;
            }

            delete _props.icon;
        }

        if ('closeHandler' in _props)
        {
            delete _props.closeHandler;
        }

        delete _props.classes;

        return (
            <Snackbar
                {..._props}
                open={this.state['open']}
                onClose={() => { this.close(); }}
            >
                <SnackbarContent
                    className={contentClassName}
                    message={_props.message || ''}
                    action={action}
                />
            </Snackbar>);
    }
}

export default withStyles(styles)(Toast);
