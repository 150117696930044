import * as muiFormControlLabel from '@material-ui/core/FormControlLabel';
import { createStyles, Theme, WithStyles, withStyles }
    from '@material-ui/core/styles';
import * as React from 'react';

interface Props extends muiFormControlLabel.FormControlLabelProps
{
}

const styles = (theme: Theme) => createStyles(
    {
        root:
        {
        },
    });

export class FormControlLabel extends
    React.PureComponent<Props & WithStyles<typeof styles>>
{
    public render()
    {
        const _props = { ...this.props };

        return <muiFormControlLabel.default {..._props}/>;
    }
}

export default withStyles(styles)(FormControlLabel);
