import Divider from '@material-ui/core/Divider';
import { createStyles, Theme, WithStyles, withStyles }
    from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import * as React from 'react';
import Routing from '../core/Routing';
import Sys from '../core/Sys';
import Button from '../coreui/Button';
import CircularProgress from '../coreui/CircularProgress';
import Link from '../coreui/Link';
import PasswordField from '../coreui/PasswordField';
import Presentation from '../coreui/Presentation';
import Typography from '../coreui/Typography';
import Grid from '../mustangui/Grid';
import GridItem from '../mustangui/GridItem';
import SLTextEdit from '../mustangui/SLTextEdit';
import UserService, { ExternalAuthenticator } from '../services/UserService';
import RequestsStore from '../stores/RequestsStore';

interface Props
{
    forgotPasswordUrl: string | null;
    title: string;
}

interface State
{
    externalAuthenticators?: ExternalAuthenticator[];
    isLoadingExternalAuthenticators?: boolean;
}

const styles = (theme: Theme) => createStyles(
    {
        divider:
        {
            backgroundColor: theme.palette.grey[300],
            border: 'none',
            height: 1,
            marginBottom: 24,
            marginTop: 24,
        },
        root:
        {
        },
    });

@observer
export class Logon extends
    React.Component<Props & WithStyles<typeof styles>, State>
{
    public constructor(props: Props & WithStyles<typeof styles>)
    {
        super(props);

        this.state = {
            externalAuthenticators: [],
            isLoadingExternalAuthenticators: true,
        };

        UserService.getExternalAuthenticators().then((response) =>
        {
            this.setState({
                externalAuthenticators: response,
                isLoadingExternalAuthenticators: false,
            });
        });
    }

    private handleCredentialMessages = (): void =>
    {
        if (Sys.currentCredentials.Message)
        {
            Sys.showErrors([Sys.currentCredentials.Message!]);
            Sys.currentCredentials.Message = null;
        }
    };

    private logon = (): void =>
    {
        Sys.clearErrors();
        RequestsStore.instance.processingStarted();

        if (Sys.getRouteToken('signin', 0) === 'signin')
        {
            Sys.setHash('', false, true);
        }

        UserService.validateCredentials(Sys.currentCredentials)
            .then(() =>
            {
                Routing.processHash();

                setTimeout(() =>
                {
                    RequestsStore.instance.processingStopped();

                    Sys.currentCredentials.UserName = null;
                    Sys.currentCredentials.Password = null;
                });
            }).catch(() =>
            {
                this.handleCredentialMessages();
                RequestsStore.instance.processingStopped();
            });
    };

    private onAuthenticatorClick = (name: string): void =>
    {
        if (Sys.getRouteToken('signin', 0) === 'signin')
        {
            Sys.setHash('', false, true);
        }

        Sys.setCookie('authenticatorProviderName', name);
        Sys.setCookie('authenticatorHref', window.location.href);
    };

    private onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void =>
    {
        if ('nodeName' in event.target && event.target['nodeName'] === 'BUTTON')
        {
            return;
        }

        if (event.key === 'Enter')
        {
            this.logon();
        }
    };

    public componentDidUpdate(prevProps: Props, prevState: State): void
    {
        this.handleCredentialMessages();
    }

    public render(): React.ReactNode
    {
        const _props = { ...this.props };
        const enableEmailAuthentication: boolean =
            Sys.settings.enableEmailAuthentication;
        let emailAuthentication: React.ReactNode = null;
        let externalAuthentication: React.ReactNode = null;
        let focusUserName: boolean = false;
        let focusPassword: boolean = false;
        let title: React.ReactNode = null;

        title =
            <GridItem xl={1} lg={1} md={1} sm={1} xs={1}>
                <Typography
                    variant="h4"
                >
                    {this.props.title}
                </Typography>
            </GridItem>;

        if (Sys.currentCredentials.UserName)
        {
            focusPassword = true;
        }
        else
        {
            focusUserName = true;
        }

        if (enableEmailAuthentication)
        {
            // FUTURE 7.4.1
            // Should use a lower level component so the interface of the
            // SLTextEdit component may remain specific to the requirements of the
            // configured widget.
            const emailAddressField: React.ReactNode = (
                <SLTextEdit
                    dataCase="Any"
                    dataId="Credentials"
                    dataSize={4000}
                    disabledHelpText=""
                    helperText=""
                    isPassword={false}
                    label={Sys.getTranslation('Email Address', 'Logon')}
                    maxSizeError=""
                    name="UserName"
                    {...{
                        // Using the spread operator allows properties that are not
                        // declared on SLTextEdit to be passed to the component.
                        autoComplete: 'username',
                        autoFocus: focusUserName,
                        inputProps: { spellCheck: false },
                        startIcon: 'fas fa-user',
                    }}
                />);

            const passwordField: React.ReactNode = (
                <PasswordField
                    autoComplete="off"
                    autoFocus={focusPassword}
                    label={Sys.getTranslation('Password', 'Logon')}
                    name="Password"
                    onChange={(value) =>
                    {
                        const props =
                            { dataId: 'Credentials', name: 'Password' };

                        Sys.clearBusinessErrors(props.dataId, props.name);
                        Presentation.setValue(props, value);
                    }}
                />);

            let forgotPasswordLink: React.ReactNode = null;

            if (this.props.forgotPasswordUrl)
            {
                forgotPasswordLink = (
                    <Link
                        href={this.props.forgotPasswordUrl}
                        style={{
                            fontSize: 12,
                            lineHeight: '16px',
                            paddingLeft: 16,
                            paddingRight: 16,
                            paddingTop: 8,
                        }}
                    >
                        {Sys.getTranslation('Forgot Password?', 'Logon')}
                    </Link>);
            }

            emailAuthentication =
                <React.Fragment>
                    <GridItem xl={1} lg={1} md={1} sm={1} xs={1}>
                        {emailAddressField}
                    </GridItem>
                    <GridItem xl={1} lg={1} md={1} sm={1} xs={1}>
                        <div>
                            {passwordField}
                            {forgotPasswordLink}
                        </div>
                    </GridItem>
                    <GridItem xl={1} lg={1} md={1} sm={1} xs={1}>
                        <Button
                            color="dark"
                            key="button"
                            onClick={this.logon}
                        >
                            {Sys.getTranslation('Sign In', 'Logon')}
                        </Button>
                    </GridItem>
                </React.Fragment>;
        }

        if (this.state.externalAuthenticators!.length)
        {
            const authenticators = this.state.externalAuthenticators!;
            const buttons: [React.ReactNode] = [null];

            authenticators.forEach((authenticator, index: number) =>
            {
                buttons.push(
                    <Button
                        aria-label={authenticator.description}
                        color='dark'
                        fullWidth={!enableEmailAuthentication}
                        href={authenticator.url}
                        key={authenticator.providerName}
                        icon={authenticator.iconName}
                        label={enableEmailAuthentication
                            ? undefined : authenticator.description}
                        onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                        {
                            this.onAuthenticatorClick(authenticator.providerName);
                        }}
                        style={{
                            marginBottom: !enableEmailAuthentication
                                && index < authenticators.length - 1 ? 24 : 0,
                            marginRight: enableEmailAuthentication
                                && index < authenticators.length - 1
                                && ((index + 1) % 5 !== 0) ? 24 : 0,
                            marginTop: enableEmailAuthentication
                                && index >= 5 ? 24 : 0,
                        }}
                    />
                );
            });

            if (enableEmailAuthentication)
            {
                externalAuthentication =
                    <React.Fragment>
                        <Divider
                            style={{ marginBottom: 24, marginTop: 24 }}
                        />
                        <Typography
                            style={{ marginBottom: 24, textAlign: 'center' }}>
                            {Sys.getTranslation('Or sign in with', 'Logon')}
                        </Typography>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <div style={{ maxWidth: 296 }}>
                                {buttons}
                            </div>
                        </div>
                    </React.Fragment>;
            }
            else
            {
                externalAuthentication =
                    <div style={{ marginTop: 24 }}>
                        {buttons}
                    </div>;
            }
        }

        return (
            <div className={_props.classes.root} onKeyDown={this.onKeyDown}>
                <Grid grouping="Closely Related" xs={1}>
                    {title}
                    {emailAuthentication}
                </Grid>
                {externalAuthentication}
                {this.state.isLoadingExternalAuthenticators
                    ? (
                        <CircularProgress
                            size="mini"
                            style={{ marginTop: 24 }}
                        />
                    )
                    : null}
            </div>);
    }
}

export default withStyles(styles)(Logon);
