import { createStyles, Theme, WithStyles, withStyles, WithTheme, withTheme }
    from '@material-ui/core/styles';
import withWidth, { WithWidth } from '@material-ui/core/withWidth';
import { observer } from 'mobx-react';
import * as React from 'react';
import Sys from '../core/Sys';
import ComboBoxOption from '../coreui/ComboBoxOption';
import FormControl, { FormControlProps } from '../coreui/FormControl';
import FormLabel from '../coreui/FormLabel';
import InformationBadge from '../coreui/InformationBadge';
import Presentation from '../coreui/Presentation';
import RadioGroup from '../coreui/RadioGroup';
import Typography from '../coreui/Typography';
import Api, { AccessLevel } from './Api';
import ApiRadio from './ApiRadio';

interface Props
{
    controlledGroupName: string;
    controlledPaneKeysByValue: object | null;
    dataId: string;
    disabledHelpText: string;
    helperText: string;
    isPaneController: boolean;
    label: string;
    name: string;
    roundTripOnChange: boolean;
}

interface WidgetProperties
{
    accessLevel: AccessLevel;
    businessErrors: string[];
    options: ComboBoxOption[];
    selectedDisplayValue: string;
    selectedValue: string | null;
    showAsMandatory: boolean;
    showDisabledHelp: boolean;
}

const styles = (theme: Theme) => createStyles(
    {
        root:
        {
            '&:hover': { backgroundColor: 'transparent' },
            backgroundColor: 'transparent',
        },
    });

@observer
export class RadioSelect extends
    React.Component<Props & WithTheme & WithStyles<typeof styles> & WithWidth>
{
    private readonly componentId: string;
    private readonly helperTextId: string;
    private readonly labelId: string;
    protected muiProps: FormControlProps;

    public constructor(
        props: Props & WithTheme & WithStyles<typeof styles> & WithWidth)
    {
        super(props);

        this.componentId = `radio-select-${Sys.nextId}`;
        this.helperTextId = `${this.componentId}-helper-text`;
        this.labelId = `${this.componentId}-label`;

        this.muiProps = {};
        this.muiProps.FormHelperTextProps =
            {
                id: this.helperTextId,
            };
        this.muiProps.fullWidth = true;
    }

    public render()
    {
        const _props = { ...this.props };
        const widgetProperties: WidgetProperties =
            Api.getWidgetProperties(_props) as WidgetProperties;
        let result: React.ReactNode = null;

        if (!widgetProperties)
        {
            return null;
        }

        this.muiProps.className = `${_props.classes.root} `;

        if (Api.setAccessLevel(_props, widgetProperties))
        {
            const options = [...widgetProperties.options];
            if (widgetProperties.selectedValue)
            {
                // Add the initially selected value as a historic option if is
                // not among the current candidates
                const optionInList = options.find(
                    o => o.value === widgetProperties.selectedValue);

                if (optionInList === undefined)
                {
                    const historicOption: ComboBoxOption =
                    {
                        display: widgetProperties.selectedDisplayValue,
                        historic: true,
                        value: widgetProperties.selectedValue,
                    };

                    options.push(historicOption);
                }
            }

            let label: React.ReactNode = null;
            if (_props.label)
            {
                label = (
                    <FormLabel
                        id={this.labelId}
                        style={{ color: _props.theme.palette.grey[800] }}
                    >
                        {_props.label}
                    </FormLabel>);
            }

            let radioGroup: React.ReactNode = (
                <RadioGroup
                    aria-describedby={this.helperTextId}
                    aria-labelledby={this.labelId}
                    id={this.componentId}
                    name={_props.name}
                    row={_props.width !== 'xs'}
                >
                    {
                        options.map((o, index) =>(
                            <ApiRadio
                                {..._props}
                                key={o.value ? o.value : ''}
                                label={o.display}
                                margin={index < options.length - 1}
                                value={o.value}
                            />))
                    }
                </RadioGroup>);

            if (_props['disabled'])
            {
                this.muiProps.disabled = true;
                this.muiProps.required = false;
                this.muiProps.helperText = null;

                if (widgetProperties['showDisabledHelp'])
                {
                    result =
                        <FormControl {...this.muiProps} fullWidth={false}>
                            <InformationBadge message={_props.disabledHelpText}>
                                {label}
                            </InformationBadge>
                            {radioGroup}
                        </FormControl>;
                }
                else
                {
                    result =
                        <FormControl {...this.muiProps}>
                            {label}
                            {radioGroup}
                        </FormControl>;
                }
            }
            else
            {
                this.muiProps.disabled = false;
                this.muiProps.required = widgetProperties.showAsMandatory;

                Api.setHelperText(
                    this,
                    widgetProperties.businessErrors,
                    this.muiProps);

                if (_props['readOnly'])
                {
                    const value = Presentation.getValue(_props, null);
                    const item = widgetProperties.options.find(_item =>
                        _item.value === value);

                    label = null;

                    radioGroup =
                        <div>
                            <Typography
                                component="div"
                                ellipsis
                                style={{ marginBottom: 8 }}
                                variant="caption"
                            >
                                {_props.label}
                            </Typography>
                            <Typography component="div">
                                {item ? item.display : '-'}
                            </Typography>
                        </div>;
                }

                result =
                    <FormControl {...this.muiProps}>
                        {label}
                        {radioGroup}
                    </FormControl>;
            }
        }

        return result;
    }
}

export default withStyles(styles)(withTheme(withWidth()(RadioSelect)));
