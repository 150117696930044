import * as muiBadge from '@material-ui/core/Badge';
import { createStyles, Theme, WithStyles, withStyles }
    from '@material-ui/core/styles';
import * as muiTab from '@material-ui/core/Tab';
import * as React from 'react';
import Api from '../mustangui/Api';
import Icon from './Icon';
import InformationBadge from './InformationBadge';

interface Props extends muiTab.TabProps
{
    badge?: 'contents' | 'errors';
    disabledHelpText?: string;
    showDisabledHelp?: boolean;
}

const styles = (theme: Theme) => createStyles(
    {
        badge:
        {
            right: -12,
            top: 5,
        },
        disabled:
        {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            opacity: '1 !important' as any,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            pointerEvents: 'auto !important' as any,
        },
        errorIcon:
        {
            color: theme.palette.error.main,
            fontSize: '16px',
        },
        labelDisabled:
        {
            color: theme.palette.grey[300],
        },
        root:
        {
            [theme.breakpoints.up('md')]:
            {
                paddingLeft: 40,
                paddingRight: 40,
            },
            boxSizing: 'border-box',
            display: 'inline-block',
            maxWidth: '100%',
            overflow: 'hidden',
            padding: 0,
            paddingBottom: 16,
            paddingLeft: 24,
            paddingRight: 24,
            paddingTop: 16,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
    });

export class Tab extends
    React.PureComponent<Props & WithStyles<typeof styles>>
{
    public render()
    {
        const {
            badge,
            classes,
            className,
            disabledHelpText,
            onChange,
            onClick,
            showDisabledHelp,
            ...tabProps
        } = this.props;

        let labelClasses: string = '';
        if (tabProps.disabled)
        {
            labelClasses += ` ${classes.labelDisabled}`;
        }

        if (tabProps.disabled)
        {
            if (showDisabledHelp)
            {
                tabProps.label = (
                    <InformationBadge
                        classes={{ badge: classes.badge, root: labelClasses }}
                        fullWidth={false}
                        message={disabledHelpText}
                        tabIndex={-1}
                    >
                        {tabProps.label}
                    </InformationBadge>
                );
            }
        }
        else if (badge)
        {
            let badgeContent: React.ReactNode;
            if (badge === 'contents')
            {
                badgeContent = (
                    <Icon
                        fixedWidth
                        icon="far fa-exclamation-circle"
                        style={{
                            color: Api.getSystemColor('information'),
                            fontSize: 16,
                            lineHeight: '24px',
                        }}
                    />);
            }
            else
            {
                badgeContent = (
                    <span className="fa-layers fa-fw">
                        <Icon
                            className={classes.errorIcon}
                            fixedWidth
                            icon="fas fa-exclamation"
                            style={{ fontSize: '.8em' }}
                        />
                        <Icon
                            className={classes.errorIcon}
                            fixedWidth
                            icon="far fa-octagon"
                        />
                    </span>);
            }

            tabProps.label = (
                <muiBadge.default
                    badgeContent={badgeContent}
                    classes={{ badge: classes.badge, root: labelClasses }}
                >
                    {tabProps.label}
                </muiBadge.default>
            );
        }
        else
        {
            tabProps.label = (
                <span className={labelClasses}>{tabProps.label}</span>
            );
        }

        return (
            <muiTab.default
                classes={{
                    disabled: classes.disabled,
                    root: classes.root,
                }}
                onChange={tabProps.disabled ? undefined : onChange}
                onClick={tabProps.disabled ? undefined : onClick}
                {...tabProps}
            />);
    }
}

export default withStyles(styles)(Tab);
