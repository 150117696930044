import { createStyles, Theme, WithStyles, withStyles }
    from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import * as React from 'react';
import AppServer, { State as AppServerState } from '../../core/AppServer';
import Sys, { BusinessError } from '../../core/Sys';
import TrackableCollection from '../../core/TrackableCollection';
import TrackableModel from '../../core/TrackableModel';
import Button from '../../coreui/Button';
import Dialog, { BreakPointColumn } from '../../coreui/Dialog';
import DialogActions from '../../coreui/DialogActions';
import DialogContent from '../../coreui/DialogContent';
import Presentation, { CreatedReactElement } from '../../coreui/Presentation';
import ProcessingMask from '../../coreui/ProcessingMask';
import PaneRow from '../../models/PaneRow';
import { ConfigForDialogResponse } from '../../services/PresentationService';
import PaneDataStore, { PaneDataByDataId } from '../../stores/PaneDataStore';
import RequestsStore from '../../stores/RequestsStore';
import { AccessLevel } from '../Api';

export interface AcceptResponse
{
    appServerState: AppServerState;
    businessErrors: BusinessError[];
    paneDataByDataId: PaneDataByDataId;
    validationErrors: string[];
}

export interface OpenResponse extends ConfigForDialogResponse
{
    appServerState: AppServerState;
    layoutId: string;
    paneDataByDataId: PaneDataByDataId;
    validationErrors: string[];
}

interface ConfigProperties
{
    contentDataId: string;
    dataId: string;
    isFirstOpenOfNewRow?: boolean;
    name: string;
    onAccept?: (parentRowKey: string) => Promise<AcceptResponse>;
    onClose: (accepted: boolean) => void;
    onDeleteRow: () => void;
    onExited?: () => void;
    onOpen: (parentRowKey: string) => Promise<OpenResponse>;
    parentRowKey?: string;
}

interface State
{
    breakPointColumns?: BreakPointColumn[];
    isDialogOpen?: boolean;
    isProcessing?: boolean;
}

interface RuntimeProperties
{
    accessLevel: AccessLevel;
}
const styles = (theme: Theme) => createStyles(
    {
        dialogContent:
        {
            '& > div':
            {
                [theme.breakpoints.only('xs')]:
                {
                    paddingLeft: 0,
                    paddingRight: 0,
                },
            },
        },
    });

@observer
export class TableEditDialog extends
    React.Component<ConfigProperties & WithStyles<typeof styles>, State>
{
    private dialogContents: CreatedReactElement;

    public constructor(props: ConfigProperties & WithStyles<typeof styles>)
    {
        super(props);

        this.state = { isDialogOpen: false, isProcessing: false };
    }

    private onAccept = () =>
    {
        if (!this.props.onAccept)
        {
            throw 'onAccept is required to accept the dialog';
        }

        this.setState({ isProcessing: true });

        this.props.onAccept(this.props.parentRowKey!).then((
            response: AcceptResponse) =>
        {
            if (response.validationErrors
                && response.validationErrors.length > 0)
            {
                Sys.showErrors(response.validationErrors);
                return;
            }

            AppServer.setState(response.appServerState);

            if (response.businessErrors.length > 0)
            {
                Sys.clearBusinessErrors();
            }
            else
            {
                Sys.clearBusinessErrors(
                    this.props.contentDataId, undefined, this.props.parentRowKey);
            }

            PaneDataStore.loadResponse(response.paneDataByDataId);

            if (!Sys.setBusinessErrors(response.businessErrors, false))
            {
                AppServer.clearStateRecoveryPoint();
                this.closeDialog(true);
            }
        }).finally(() => this.setState({ isProcessing: false }));
    };

    private onCancel = () =>
    {
        if (this.props.isFirstOpenOfNewRow)
        {
            const collection = TrackableModel.models.get(
                this.props.contentDataId) as TrackableCollection;

            const model: TrackableModel = collection.find(
                m => m.getPrimaryKey() === this.props.parentRowKey)!;

            collection.delete(model);

            // Remove from the deleted collection as we're restoring the
            // app server state to before the new row was added
            collection.getDeleted().delete(model);
            this.props.onDeleteRow();
        }

        Sys.clearBusinessErrors(
            this.props.contentDataId, undefined, this.props.parentRowKey);

        AppServer.recoverStateFromPoint();
        this.closeDialog(false);
    };

    private openDialog = () =>
    {
        RequestsStore.instance.processingStarted();

        this.props.onOpen(
            this.props.parentRowKey!
        ).then((dialogOpenResponse) =>
        {
            const validationErrors = dialogOpenResponse.validationErrors;
            if (validationErrors && validationErrors.length > 0)
            {
                Sys.showErrors(validationErrors);
                AppServer.clearStateRecoveryPoint();
                this.props.onClose(false);

                return;
            }

            const scripts: object = dialogOpenResponse.scriptsByDataId;

            Presentation.setPanes(dialogOpenResponse.layoutId, scripts);
            Sys.loadScripts(scripts);

            this.dialogContents = Presentation.create(
                dialogOpenResponse.presentationConfig)!;

            AppServer.setState(dialogOpenResponse.appServerState);
            PaneDataStore.loadResponse(dialogOpenResponse.paneDataByDataId);

            if (!this.props.isFirstOpenOfNewRow)
            {
                AppServer.createStateRecoveryPoint();
            }

            this.setState({
                breakPointColumns: dialogOpenResponse.breakPoints,
                isDialogOpen: true,
            });
        }
        ).finally(() => RequestsStore.instance.processingStopped());
    };

    public closeDialog = (accepted: boolean) =>
    {
        this.setState({ isDialogOpen: false });
        this.props.onClose(accepted);
    };

    public componentDidUpdate(prevProps: ConfigProperties)
    {
        if (this.props.parentRowKey !== prevProps.parentRowKey
            && this.props.parentRowKey && !this.state.isDialogOpen)
        {
            this.openDialog();
        }
    }

    public render()
    {
        const parentTableWidgetProperties = PaneRow.getWidgetProperties(
            this.props.dataId, this.props.name) as RuntimeProperties;

        if (!parentTableWidgetProperties)
        {
            return null;
        }

        const isEnterable =
            parentTableWidgetProperties.accessLevel >= AccessLevel.enterable;

        return (
            <Dialog
                breakPointColumns={this.state.breakPointColumns}
                disableBackdropClick={true}
                open={this.state.isDialogOpen! && !!this.props.parentRowKey}
                onClose={this.onCancel}
                onExited={this.props.onExited}
            >
                <DialogContent
                    classes={{ root: this.props.classes.dialogContent }}
                >
                    <form>
                        {this.dialogContents}
                    </form>
                </DialogContent>
                <DialogActions>
                    {isEnterable ? (
                        <Button onClick={this.onAccept}>
                            OK
                        </Button>
                    ) : null}
                    <Button onClick={this.onCancel} style={{ marginLeft: 40 }}>
                        {isEnterable ? 'Cancel' : 'Close'}
                    </Button>
                </DialogActions>
                <ProcessingMask
                    isOpen={this.state.isProcessing!}
                    trapFocus={true}
                />
            </Dialog>);
    }
}

export default withStyles(styles)(TableEditDialog);
