import { createStyles, Theme, WithStyles, withStyles }
    from '@material-ui/core/styles';
import withWidth, { WithWidth } from '@material-ui/core/withWidth';
import { observer } from 'mobx-react';
import * as React from 'react';
import TrackableModel from '../core/TrackableModel';
import Presentation from '../coreui/Presentation';
import Table, { TableChildProps, TableProps } from '../coreui/Table';
import AsyncData, { GetDataResponse } from '../coreui/table/AsyncData';
import PaneRow from '../models/PaneRow';
import RoundTripService from '../services/RoundTripService';
import PaneDataStore from '../stores/PaneDataStore';
import GridColumn, { GridColumnConfigProperties } from './Columns/GridColumn';
import ErrorBoundary from './ErrorBoundary';
import { SelectChildProps } from './SelectControl';

interface ConfigProperties extends WithWidth
{
    cardDepth: number;
    columns: GridColumnConfigProperties[];
    contentDataId: string;
    data?: object;
    dataId: string;
    footerToolbar?: object;
    headerToolbar?: object;
    name: string;
    propagated?: SelectChildProps;
    selectionToolbar?: object;
    tableKey: string;
    verticalLayout?: object;
}

const styles = (theme: Theme) => createStyles(
    {
    });

@observer
export class SelectControlSelectedGrid extends
    React.Component<ConfigProperties & WithWidth & WithStyles<typeof styles>>
{
    private populate: ((rows: TrackableModel[]) => void) | null = null;
    private propagated: TableChildProps & SelectChildProps;
    private tableProps: TableProps;

    public constructor(
        props: ConfigProperties & WithWidth & WithStyles<typeof styles>)
    {
        super(props);

        this.propagated = {
            parentTable:
            {
                cardDepth: props.cardDepth,
                columns: props.columns,
                configProps:
                {
                    contentDataId: props.contentDataId,
                    data: props.data,
                    dataId: props.dataId,
                    name: props.name,
                },
                isDocumentGrid: false,
                populateData: () => this.populateData(),
            },
            ...props.propagated,
        } as TableChildProps & SelectChildProps;

        this.tableProps = {
            cardDepth: props.cardDepth,
            cellEdit: true,
            columns: [],
            contentDataId: props.contentDataId,
            dataId: props.dataId,
            footerToolbarChild: props.footerToolbar,
            headerToolbarChild: props.headerToolbar,
            isColumnFlex: (colId: string) =>
                GridColumn.isColumnFlex(props.columns, colId),
            isColumnVisible: (colId: string, breakpoint: string) =>
                GridColumn.isColumnVisible(
                    props.columns, colId, breakpoint),
            minRowHeight:
                GridColumn.getColumnsMinRowHeight(props.columns),
            name: props.name,
            propagated: this.propagated,
            rowSelection: 'multiple',
            selectToolbarChild: props.selectionToolbar,
            setPopulate: populate => this.populate = populate,
        };
    }

    private getData = () =>
    {
        return RoundTripService.partialDataRetrevial<GetDataResponse>(
            `SelectControl/GetRowsData/${this.getRowKey()}`
                + `/${this.props.dataId}/${this.props.name}`
        );
    };

    private getRowKey(): string
    {
        const observable = Presentation.getObservable(this.props)! as PaneRow;
        return observable.rowKey;
    }

    private populateData = () =>
    {
        const rows: PaneRow[] =
            PaneDataStore.getPaneCollection(this.props.contentDataId);

        if (this.populate !== null)
        {
            this.populate(rows);
        }
    };

    public componentDidMount()
    {
        for (const column of this.props.columns)
        {
            this.tableProps.columns.push(GridColumn.getColumnDef(
                column,
                this.props.columns,
                this.propagated));
        }
    }

    public componentDidUpdate(prevProps: ConfigProperties)
    {
        if (prevProps.width !== this.props.width)
        {
            setTimeout(() => this.populateData());
        }
    }

    public render()
    {
        return (
            <ErrorBoundary title={this.props.name}>
                <div style={{ position: 'relative' }}>
                    <AsyncData
                        contentDataId={this.props.contentDataId}
                        dataId={this.props.dataId}
                        getData={this.getData}
                        populateData={this.populateData}
                    />
                    <Table
                        {...this.tableProps}
                        fullWidthChild={this.props.verticalLayout}
                        tableKey={this.props.tableKey}
                    />
                </div>
            </ErrorBoundary>);
    }
}

export default withStyles(styles)(withWidth()(SelectControlSelectedGrid));
