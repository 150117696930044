import { createStyles, Theme, WithStyles, withStyles }
    from '@material-ui/core/styles';
import * as muiTextField from '@material-ui/core/TextField';
import * as React from 'react';
import Sys from '../core/Sys';
import Api from '../mustangui/Api';
import Icon from './Icon';
import IconButton from './IconButton';
import InputAdornment from './InputAdornment';

interface Props
{
    autoComplete: string;
    autoFocus?: boolean;
    errors?: string[];
    helperText?: string;
    label?: string;
    name?: string;
    onChange: (value: string) => void;
    required?: boolean;
}

interface State
{
    showValue?: boolean;
    value?: string;
}

const styles = (theme: Theme) => createStyles(
    {
        root:
        {
            '& label, & input':
            {
                marginLeft: 22,
            },
        },
        startIcon:
        {
            color: theme.palette.text.primary,
            left: 16,
            position: 'absolute',
        },
    });

export class PasswordField extends
    React.PureComponent<Props & WithStyles<typeof styles>, State>
{
    public constructor(props: Props & WithStyles<typeof styles>)
    {
        super(props);

        this.state = { showValue: false, value: '' };
    }

    private onChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    {
        this.setState({ value: event.target.value });
        this.props.onChange(event.target.value);
    };

    private onToggleShowValue = () =>
    {
        this.setState((prevState: State) =>
        {
            return { showValue: !prevState.showValue };
        });
    };

    public render(): React.ReactNode
    {
        const helperTextProps = { error: false, helperText: '' };
        if (this.props.errors)
        {
            Api.setHelperText(this, this.props.errors, helperTextProps);
        }

        return (
            <muiTextField.default
                autoComplete={this.props.autoComplete}
                className={this.props.classes.root}
                error={helperTextProps.error}
                FormHelperTextProps={{
                    component: 'div',
                    style:
                    {
                        marginLeft: 16,
                        marginRight: 16,
                    },
                }}
                fullWidth={true}
                helperText={helperTextProps.helperText}
                inputProps={{ spellCheck: false }}
                InputProps={{
                    // End adornment is used instead of start since when start
                    // is set the field moves the label above the input
                    endAdornment: (
                        <InputAdornment position="end">
                            <Icon
                                classes={{ root: this.props.classes.startIcon }}
                                icon="fas fa-lock"
                            />
                            <IconButton
                                aria-label={Sys.getTranslation(
                                    'Toggle password visibility')}
                                icon={this.state.showValue
                                    ? 'fas fa-eye-slash' : 'fas fa-eye'}
                                onClick={this.onToggleShowValue}
                                small={true}
                            />
                        </InputAdornment>),
                }}
                label={this.props.label}
                name={this.props.name}
                onChange={this.onChange}
                required={this.props.required}
                type={this.state.showValue ? 'text' : 'password'}
                value={this.state.value}
                variant="filled"
            />);
    }
}

export default withStyles(styles)(PasswordField);
