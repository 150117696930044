import { ButtonBase } from '@material-ui/core';
import { createStyles, Theme, WithStyles, withStyles }
    from '@material-ui/core/styles';
import * as React from 'react';
import Button from './Button';
import Icon from './Icon';

interface Props
{
    alternateText: string;
    disableFocusRipple?: boolean;
    height?: number;
    href: string;
    icon: string;
    linkRef?: (r: HTMLElement | null) => void;
    tabIndex?: number;
    width?: number;
}

const styles = (theme: Theme) => createStyles(
    {
        button:
        {
            bottom: 4,
            pointerEvents: 'none',
            position: 'absolute',
            right: 4,
        },
        icon:
        {
            color: theme.palette.grey[500],
            height: '75%',
            width: '75%',
        },
        ripple:
        {
            height: 'calc(100% + 30px)',
            margin: '-15px',
            width: 'calc(100% + 30px)',
        },
        root:
        {
            background: theme.palette.grey[200],
            display: 'flex',
            height: 96,
            outline: 'none',
            overflow: 'hidden',
            position: 'relative',
            width: 96,
        },
    });

export class IconWithLink extends
    React.PureComponent<Props & WithStyles<typeof styles>>
{
    public render()
    {
        return (
            <ButtonBase
                aria-label={this.props.alternateText}
                buttonRef={
                    r => this.props.linkRef
                        ? this.props.linkRef(r as HTMLElement) : null
                }
                className={this.props.classes.root}
                focusRipple={!this.props.disableFocusRipple}
                href={this.props.href}
                style={
                    this.props.height
                        ? { height: this.props.height, width: this.props.width }
                        : {}}
                tabIndex={this.props.tabIndex}
                target="_blank"
                TouchRippleProps={{ className: this.props.classes.ripple }}
            >
                <Icon
                    className={this.props.classes.icon}
                    fullHeight
                    icon={this.props.icon}
                />
                <Button
                    className={this.props.classes.button}
                    customSize="tiny"
                    icon="fas fa-download"
                    tabIndex={-1}
                />
            </ButtonBase>);
    }
}

export default withStyles(styles)(IconWithLink);
