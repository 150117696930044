import { createStyles, WithStyles, withStyles, WithTheme, withTheme }
    from '@material-ui/core/styles';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { observer } from 'mobx-react';
import * as React from 'react';
import PaneRow from '../models/PaneRow';
import { CustomTheme } from '../muiTheme';
import { AccessLevel, ValueByBreakpoint } from '../mustangui/Api';
import
    FreeflowContainerGroup,
    {
        FreeflowContainerGroup as FreeflowContainerGroupBase,
        ConfigProperties as GroupConfig,
    }
    from './FreeflowContainerGroup';

export interface ConfigProperties
{
    groups: GroupConfig[];
}

interface Props extends ConfigProperties
{
    dataId: string;
    justify: 'start' | 'middle' | 'end';
    propagated: object;
}

interface RuntimeProperties
{
    accessLevel?: AccessLevel;
}

const styles = (theme: CustomTheme) =>
{
    const visibilityStyles = {};
    for (const breakPoint of theme.visibilityBreakPoints)
    {
        visibilityStyles[`root-visible-${breakPoint}`] =
            {
                [theme.breakpoints.only(breakPoint)]:
                {
                    display: 'flex',
                },
            };
    }

    const sectionSpaceStyle = {};
    for (const breakPoint of theme.spacingBreakPoints)
    {
        const groupSpacing = theme.freeflow.group.spacing[breakPoint];
        const sectionSpacing = theme.freeflow.section.spacing[breakPoint];

        sectionSpaceStyle[theme.breakpoints.up(breakPoint)] =
            {
                marginBottom: -groupSpacing.vertical * 0.5,
                marginLeft: -groupSpacing.horizontal * 0.5,
                marginRight: -groupSpacing.horizontal * 0.5,
                marginTop: -groupSpacing.vertical * 0.5,
            };
    }

    const result =
        {
            'justify-end':
            {
                justifyContent: 'flex-end',
            },
            'justify-middle':
            {
                justifyContent: 'center',
            },
            'justify-start':
            {
                justifyContent: 'flex-start',
            },
            root:
            {
                alignItems: 'center',
                display: 'none',
                flexWrap: 'wrap',
                ...sectionSpaceStyle,
            } as CSSProperties,
            ...visibilityStyles,
        };

    return createStyles(result);
};

@observer
export class FreeflowContainerSection extends
    React.Component<Props & WithStyles<typeof styles> & WithTheme>
{
    public static isVisible(
        dataId: string,
        section: ConfigProperties,
        breakPoint: Breakpoint
        ): boolean
    {
        return section.groups.some(
            g => FreeflowContainerGroupBase.isVisible(dataId, g, breakPoint));
    }

    public render(): React.ReactNode
    {
        const classes: string[] = [this.props.classes.root];
        const theme = this.props.theme as CustomTheme;

        for (const breakPoint of theme.visibilityBreakPoints)
        {
            if (FreeflowContainerSection.isVisible(
                this.props.dataId, this.props, breakPoint))
            {
                const className = `root-visible-${breakPoint}`;
                classes.push(this.props.classes[className]);
            }
        }

        classes.push(this.props.classes[`justify-${this.props.justify}`]);

        return (
            <div className={classes.join(' ')}>
                {
                    this.props.groups.map(
                        (group: GroupConfig, index: number) => (
                            <FreeflowContainerGroup
                                {...group}
                                dataId={this.props.dataId}
                                key={index}
                                propagated={this.props.propagated}
                            />))
                }
            </div>
        );
    }
}

export default withStyles(styles)(withTheme(FreeflowContainerSection));
