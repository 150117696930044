import * as Modal from '@material-ui/core/Modal/Modal';
import { createStyles, Theme, WithStyles, withStyles }
    from '@material-ui/core/styles';
import withWidth, { WithWidth } from '@material-ui/core/withWidth';
import { observer } from 'mobx-react';
import * as React from 'react';
import Logging from '../core/Logging';
import Sys from '../core/Sys';
import Hidden from '../coreui/Hidden';
import Presentation from '../coreui/Presentation';
import RoundTripService from '../services/RoundTripService';
import RequestsStore from '../stores/RequestsStore';
import Api, { AccessLevel } from './Api';

interface ConfigurationProps
{
    addOnConfiguration: string;
    addOnDescription: string;
    addOnName: string;
    breakPoints: BreakPoints;
    componentTypeName: string;
    dataId: string;
    expansionSize: 'Dialog' | 'Full';
    name: string;
}

interface BreakPoints
{
    lg: { height: number; visible: boolean };
    md: { height: number; visible: boolean };
    sm: { height: number; visible: boolean };
    xl: { height: number; visible: boolean };
    xs: { height: number; visible: boolean };
}

interface PosseApi
{
    _isLoaded: () => boolean;
    _refresh: () => void;
    _roundTripStarting: () => void;
    _setConfiguration: (name: string) => void;
    _setReadOnly: (readOnly: boolean) => void;
    _showData: () => void;
}

interface RuntimeProperties
{
    accessLevel: AccessLevel;
    addOnUrl: string;
    objectHandle: string;
    processHandle: string;
}

interface State
{
    expanded: boolean;
}

const styles = (theme: Theme) => createStyles(
    {
        addon:
        {
            borderWidth: 0,
            flex: 'auto',
            height: '100%',
        },
        dialog:
        {
            [theme.breakpoints.only('xs')]:
            {
                height: '100vh !important',
                left: '0px !important',
                maxHeight: 'none',
                top: '0px !important',
                width: '100% !important',
            },
            [theme.breakpoints.up('sm')]:
            {
                // eslint-disable-next-line max-len
                boxShadow: '0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12)',
                transform: 'translate(-50%, -50%)',
                transformStyle: 'preserve-3d',
            },
            left: '50vw !important',
            maxHeight: '88vh',
            top: '50vh !important',
            width: '600px !important',
        },
        fixed:
        {
            marginTop: '0px !important',
            position: 'fixed',
            zIndex: 1500,
        },
        full:
        {
            [theme.breakpoints.only('xs')]:
            {
                height: '100vh !important',
                left: '0px !important',
                maxHeight: 'none',
                top: '0px !important',
                width: '100% !important',
            },
            [theme.breakpoints.up('sm')]:
            {
                // eslint-disable-next-line max-len
                boxShadow: '0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12)',
            },
            height: '88vh !important',
            left: '6vw !important',
            top: '6vh !important',
            width: '88vw !important',
        },
        root:
        {
            backgroundColor: theme.palette.common.white,
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            // eslint-disable-next-line max-len
            transition: 'box-shadow 1500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, height 500ms, left 500ms, top 500ms, transform 500ms, width 500ms',
        },
    });

@observer
export class EmbeddedAddOn extends
    React.Component<
        ConfigurationProps & WithStyles<typeof styles> & WithWidth, State>
{
    public static instances = new Map<string, EmbeddedAddOn>();
    private addOnConfiguration: string;
    private addOnUrl: string;
    private api: PosseApi;
    private breakPoints: BreakPoints;
    private callDeferTimeout: number = 30000;
    private deferredCalls = new Map<string, number>();
    private expanded: boolean = false;
    private hostId: string;
    private lastObjectHandle: string | null = null;
    private lastState: State;
    private queryArgs: string;
    private scrollTop: number;
    public callDeferPeriod: number = 100;
    public filler: React.RefObject<HTMLDivElement>;
    public host: React.RefObject<HTMLDivElement>;

    public constructor(
        props: ConfigurationProps & WithStyles<typeof styles> & WithWidth)
    {
        super(props);
        this.state = { expanded: false };
        this.lastState = { ...this.state };
        this.breakPoints = { ...props.breakPoints };

        if (!window['mustang']['embeddedAddOns'])
        {
            window['mustang']['embeddedAddOns'] = EmbeddedAddOn.instances;
        }

        this.addOnConfiguration = props.addOnConfiguration;
        this.filler = React.createRef<HTMLDivElement>();
        this.host = React.createRef<HTMLDivElement>();

        this.hostId = `${props.dataId}:${props.name}`;
        this.queryArgs =
            `PosseAddOnName=${encodeURIComponent(props.addOnName)}`
            + '&PosseAddOnDescription='
            + `${encodeURIComponent(props.addOnDescription)}`
            + `&PosseAddOnHostId=${encodeURIComponent(this.hostId)}`;

        EmbeddedAddOn.instances.set(this.hostId, this);
    }

    private clearDefer(callName: string)
    {
        if (this.deferredCalls.has(callName))
        {
            this.deferredCalls.delete(callName);
        }
    }

    private getApi(): PosseApi | null
    {
        let result: PosseApi | null = null;

        // eslint-disable-next-line no-underscore-dangle
        if (this.api && this.api._isLoaded())
        {
            result = this.api;
        }

        return result;
    }

    private refresh()
    {
        const callName: string = `refresh for ${this.props.name}`;
        const api: PosseApi | null = this.getApi();

        if (api)
        {
            this.clearDefer(callName);
            // eslint-disable-next-line no-underscore-dangle
            api._refresh();
        }
        else
        {
            this.defer(this.refresh, arguments, callName);
        }
    }

    private setReadOnly(readOnly: boolean)
    {
        const callName: string = `setReadOnly for ${this.props.name}`;
        const api: PosseApi | null = this.getApi();

        if (api)
        {
            this.clearDefer(callName);
            // eslint-disable-next-line no-underscore-dangle
            api._setReadOnly(readOnly);
        }
        else
        {
            this.defer(this.setReadOnly, arguments, callName);
        }
    }

    private showData()
    {
        const callName: string = `showData for ${this.props.name}`;
        const api: PosseApi | null = this.getApi();

        if (api)
        {
            this.clearDefer(callName);
            // eslint-disable-next-line no-underscore-dangle
            api._showData();
        }
        else
        {
            this.defer(this.showData, arguments, callName);
        }
    }

    public addOnLoaded(api: PosseApi)
    {
        this.api = api;
        // eslint-disable-next-line no-underscore-dangle
        this.getApi()!._setConfiguration(this.addOnConfiguration);
    }

    public collapse()
    {
        if (!this.expanded)
        {
            return;
        }

        this.expanded = false;

        if (this.host.current && this.filler.current)
        {
            const rect = this.filler.current.getBoundingClientRect();
            const height = this.breakPoints[this.props.width].height;

            this.filler.current.style.height = `${height || rect.height}px`;
            this.host.current.style.height = `${rect.height}px`;
            this.host.current.style.left = `${this.filler.current.offsetLeft}px`;
            this.host.current.style.top =
                `${this.filler.current.offsetTop - this.scrollTop}px`;
            this.host.current.style.width = `${rect.width}px`;

            setTimeout(
                () =>
                {
                    document.body.classList.add('disableDialogNoScroll');

                    if (this.host.current)
                    {
                        if (this.props.expansionSize === 'Dialog')
                        {
                            this.host.current.classList.remove(
                                this.props.classes.dialog);
                        }
                        else
                        {
                            this.host.current.classList.remove(
                                this.props.classes.full);
                        }

                        // Must run after animation is complete.
                        setTimeout(
                            () =>
                            {
                                this.setState({ expanded: false });

                                if (this.host.current)
                                {
                                    this.host.current.classList.remove(
                                        this.props.classes.fixed);
                                    this.host.current.style.width = '';
                                }
                            },
                            600);
                    }
                },
                100);
        }
    }

    public componentWillUnmount()
    {
        if (EmbeddedAddOn.instances.has(this.hostId))
        {
            EmbeddedAddOn.instances.delete(this.hostId);
        }
    }

    public configurationChanged(configurationName: string)
    {
        this.addOnConfiguration = configurationName;
    }

    public defer(method: Function, args: IArguments, callName: string)
    {
        let ok: boolean = true;

        if (callName)
        {
            if (this.deferredCalls.has(callName))
            {
                if ((new Date().getTime() - this.deferredCalls.get(callName)!) >
                    this.callDeferTimeout)
                {
                    this.clearDefer(callName);
                    ok = false;
                    Sys.showErrors(
                        [`Deferred function call ${callName} timed out`]);
                }
            }
            else
            {
                this.deferredCalls.set(callName, new Date().getTime());
            }
        }

        if (ok)
        {
            Sys.defer(method, this.callDeferPeriod, this, args);
        }
    }

    public expand()
    {
        if (this.expanded)
        {
            return;
        }

        this.expanded = true;

        if (this.host.current && this.filler.current)
        {
            const rect = this.host.current.getBoundingClientRect();

            this.scrollTop = document.body.scrollTop
                || document.documentElement!.scrollTop;
            this.filler.current.style.height = `${rect.height}px`;
            this.host.current.style.height = `${rect.height}px`;
            this.host.current.style.left = `${this.host.current.offsetLeft}px`;
            this.host.current.style.top =
                `${this.host.current.offsetTop - this.scrollTop}px`;
            this.host.current.style.width = `${rect.width}px`;

            setTimeout(
                () =>
                {
                    document.body.classList.remove('disableDialogNoScroll');

                    if (this.host.current)
                    {
                        this.setState({ expanded: true });

                        if (this.props.expansionSize === 'Dialog')
                        {
                            this.host.current.classList.add(
                                this.props.classes.dialog,
                                this.props.classes.fixed);
                        }
                        else
                        {
                            this.host.current.classList.add(
                                this.props.classes.full,
                                this.props.classes.fixed);
                        }
                    }
                },
                100);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getDataBuffer(): any
    {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let result: any = Presentation.getValue(this.props);

        if (result && typeof result === 'string')
        {
            try
            {
                result = JSON.parse(result);
            }
            catch
            {
                /* Do nothing */
            }
        }

        return result;
    }

    public getObjectBuffer(): object[] | null
    {
        const runtimeProperties =
            Api.getWidgetProperties(this.props) as RuntimeProperties;
        let result: object[] | null = null;
        if (runtimeProperties)
        {
            result =
            [
                {
                    componentTypeName: this.props.componentTypeName,
                    objectHandle: runtimeProperties.objectHandle,
                    processHandle: runtimeProperties.processHandle,
                },
            ];
        }

        return result;
    }

    public isExpanded(): boolean
    {
        return this.expanded;
    }

    public render()
    {
        const _props = { ...this.props };
        const runtimeProperties =
            Api.getWidgetProperties(_props) as RuntimeProperties;

        if (runtimeProperties.addOnUrl.includes('?'))
        {
            this.addOnUrl = `${runtimeProperties.addOnUrl}&${this.queryArgs}`;
        }
        else
        {
            this.addOnUrl = `${runtimeProperties.addOnUrl}?${this.queryArgs}`;
        }

        if (!runtimeProperties)
        {
            return null;
        }

        if (runtimeProperties.accessLevel === AccessLevel.hidden)
        {
            return null;
        }

        // Force a render when the pane data is refreshed.
        Presentation.getValue(_props);

        if (this.lastState.expanded === this.state.expanded)
        {
            // Don't execute if we are changing the expanded state.
            this.setReadOnly(runtimeProperties.accessLevel
                === AccessLevel.readOnly);

            // Call showData the first time an object is rendered.
            if (this.lastObjectHandle === runtimeProperties.objectHandle)
            {
                this.refresh();
            }
            else
            {
                this.lastObjectHandle = runtimeProperties.objectHandle;
                this.showData();
            }
        }
        else
        {
            this.lastState = { ...this.state };
        }

        const height = this.breakPoints[this.props.width].height;
        const standbox = 'allow-forms allow-modals allow-popups '
            + 'allow-popups-to-escape-sandbox allow-same-origin allow-scripts '
            + 'allow-top-navigation-by-user-activation';

        return (
            <React.Fragment>
                <div
                    className={_props.classes.root}
                    ref={this.host}
                    style={{ height }}
                >
                    <iframe
                        className={_props.classes.addon}
                        sandbox={standbox}
                        src={this.addOnUrl}
                        title={_props.addOnConfiguration}
                    />
                </div>
                <Hidden implementation="css" xsUp={!this.state.expanded}>
                    <div ref={this.filler} />
                </Hidden>
                <Modal.default
                    disableAutoFocus
                    open={this.state.expanded
                        && this.breakPoints[_props.width].visible}
                >
                    <div />
                </Modal.default>
            </React.Fragment>);
    }

    public roundTrip()
    {
        return RoundTripService.standardRoundTrip(
            'EmbeddedAddOn/OnRoundTrip',
            this.props
        ).catch((response) =>
        {
            Logging.error(response);
            return response;
        });
    }

    public roundTripStarting()
    {
        const api: PosseApi | null = this.getApi();
        const runtimeProperties =
            Api.getWidgetProperties(this.props) as RuntimeProperties;

        // If the addon is not available then there won't be any pending changes.
        if (api && runtimeProperties.accessLevel >= AccessLevel.enterable)
        {
            // eslint-disable-next-line no-underscore-dangle
            api._roundTripStarting();
        }
    }

    public setBusy(busy: boolean)
    {
        if (busy)
        {
            setTimeout(() =>
            {
                RequestsStore.instance.processingStarted();
            });
        }
        else
        {
            setTimeout(() =>
            {
                if (RequestsStore.instance.processingInfo.isProcessing)
                {
                    RequestsStore.instance.processingStopped();
                }
            });
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public setDataBuffer(value: any)
    {
        Presentation.setValue(this.props, value ?
            JSON.stringify(value) : value);
    }

    public setHeight(height: number)
    {
        this.breakPoints.xl.height = height;
        this.breakPoints.lg.height = height;
        this.breakPoints.md.height = height;
        this.breakPoints.sm.height = height;
        this.breakPoints.xs.height = height;

        if (this.host.current)
        {
            this.host.current.style.height = `${height}px`;
        }
    }
}

export default withStyles(styles)(withWidth()(EmbeddedAddOn));
