import { createStyles, Theme, WithStyles, withStyles }
    from '@material-ui/core/styles';
import { autorun, IReactionDisposer, observable } from 'mobx';
import * as React from 'react';
import Logging from '../core/Logging';
import RequestPromise from '../core/RequestPromise';
import Sys from '../core/Sys';
import Paper from '../coreui/Paper';
import Presentation from '../coreui/Presentation';
import NewObjectService from '../services/NewObjectService';
import RoundTripService, { RoundTripResponse }
    from '../services/RoundTripService';
import LayoutStateStore from '../stores/LayoutStateStore';
import PaneDataStore, { PaneData, PaneDataByDataId }
    from '../stores/PaneDataStore';
import RequestsStore from '../stores/RequestsStore';
import { ActionButton as ActionButtonBase } from './ActionButton';
import Api, { AccessLevel } from './Api';
import ErrorBoundary from './ErrorBoundary';

interface GetDataResponse
{
    paneDataByDataId: PaneDataByDataId;
    validationErrors: string[];
}

interface OnNavigateResponse extends RoundTripResponse
{
    layoutId?: string;
    newObjectId?: string;
}

interface Props
{
    dataId: string;
    name: string;
    steps: StepProps[];
}

interface State
{
    selectedPaneUseKey?: string;
}

export interface StepProps
{
    dataId: string;
    label: string;
    paneUse: object;
    paneUseKey: string;
}

export interface StepWidgetProperties
{
    accessLevel: AccessLevel;
    paneUseKey: string;
    rowKey: string;
    stepComplete: boolean;
}

export interface WidgetProperties
{
    accessLevel: AccessLevel;
    selectedPaneUseKey: string | null;
    steps: StepWidgetProperties[];
}

const styles = (theme: Theme) => createStyles(
    {
        paper:
        {
            boxSizing: 'border-box',
            minHeight: 80 + 8 * 2,  // Loading animation + padding
            position: 'relative',
        },
    });

export class WizardControl extends
    React.PureComponent<Props & WithStyles<typeof styles>, State>
{
    @observable
    private static widgetProperties: WidgetProperties | null = null;
    public static instance: WizardControl | null = null;
    private dataMonitorDisposer: IReactionDisposer;
    private lastRetrievedPaneDate: Date | undefined;
    private loadedPaneUseKeys: string[] = [];
    private retrieveDataPromises: RequestPromise<GetDataResponse>[] = [];

    private static finishNavigate(
        response: RoundTripResponse,
        scrollToPageTop: boolean)
    {
        if (response
            && (!response.businessErrors
                || response.businessErrors.length <= 0)
            && (!response.validationErrors
                || response.validationErrors.length <= 0))
        {
            // Future 7.4.1: This should be moved to a more appropriate place
            PaneDataStore.clearAllDataChanges();

            if (WizardControl.isOnLastStep())
            {
                if (response.newObjectId)
                {
                    NewObjectService.navigateToNewObject(
                        response.newObjectId, response.layoutId!);
                }

                Sys.clearBusinessErrors();
                ActionButtonBase.showSavedToast('fas fa-save');
            }

            if (scrollToPageTop)
            {
                window.scrollTo(0, 0);
            }
        }
    }

    public static getStepCount(): number
    {
        let result: number = 0;

        if (WizardControl.widgetProperties)
        {
            result = WizardControl.widgetProperties.steps.length - 1;

            for (const step of WizardControl.widgetProperties.steps)
            {
                if (step.accessLevel === AccessLevel.hidden)
                {
                    result--;
                }
            }
        }

        return result;
    }

    public static getStepNumber(step: number): number
    {
        let result: number = step + 1;

        if (WizardControl.widgetProperties)
        {
            for (let index = 0; index <= step; index++)
            {
                if (WizardControl.widgetProperties.steps[index].accessLevel ===
                    AccessLevel.hidden)
                {
                    result--;
                }
            }
        }

        return result;
    }

    public static getSteps(): StepProps[] | null
    {
        const instance = WizardControl.instance;
        let result: StepProps[] | null = null;

        if (instance)
        {
            result = instance.props.steps;
        }

        return result;
    }

    public static getWidgetProperties(): WidgetProperties | null
    {
        let result: WidgetProperties | null = null;

        if (WizardControl.widgetProperties)
        {
            result = WizardControl.widgetProperties;
        }
        else
        {
            result =
            {
                accessLevel: AccessLevel.enterable,
                selectedPaneUseKey: null,
                steps: [],
            };
        }

        return result;
    }

    public static async gotoNextStep(): Promise<OnNavigateResponse | null>
    {
        const instance = WizardControl.instance;
        if (!instance)
        {
            return null;
        }

        RequestsStore.instance.processingStarted(null, false, true);

        const response: RoundTripResponse =
            await RoundTripService.standardRoundTrip(
                'WizardControl/OnNavigate',
                instance.props,
                { navigationDirection: 'next' },
                true)
                .catch((_response) =>
                {
                    Logging.error(_response);
                    return _response;
                });

        RequestsStore.instance.processingStopped();

        WizardControl.finishNavigate(response, true);

        return response;
    }

    public static async gotoPreviousStep(): Promise<RoundTripResponse | null>
    {
        const instance = WizardControl.instance;
        if (!instance)
        {
            return null;
        }

        RequestsStore.instance.processingStarted(null, false, true);

        const response: RoundTripResponse =
            await RoundTripService.standardRoundTrip(
                'WizardControl/OnNavigate',
                instance.props,
                { navigationDirection: 'previous' },
                true)
                .catch((_response) =>
                {
                    Logging.error(_response);
                    return _response;
                });

        RequestsStore.instance.processingStopped();

        WizardControl.finishNavigate(response, true);

        return response;
    }

    public static async gotoStep(
        navigationPaneUseKey: string
        ): Promise<RoundTripResponse | null>
    {
        const instance = WizardControl.instance;
        if (!instance)
        {
            return null;
        }

        const response: RoundTripResponse =
            await RoundTripService.standardRoundTrip(
                'WizardControl/OnNavigate',
                instance.props,
                { navigationPaneUseKey })
                .catch((_response) =>
                {
                    Logging.error(_response);
                    return _response;
                });

        WizardControl.finishNavigate(response, false);

        return response;
    }

    public static isOnLastStep(): boolean
    {
        const instance = WizardControl.instance;

        if (instance)
        {
            const index = instance.props.steps.findIndex(
                s => s.paneUseKey === instance.state.selectedPaneUseKey);

            return index === instance.props.steps.length - 1;
        }

        return false;
    }

    public constructor(props: Props & WithStyles<typeof styles>)
    {
        super(props);

        this.state = {
            selectedPaneUseKey: undefined,
        };

        WizardControl.instance = this;
    }

    private dataMonitor = () =>
    {
        const parentPane: PaneData =
            PaneDataStore.instance.getPane(this.props.dataId)!;

        if (!parentPane.lastRetrieved)
        {
            return;
        }

        if (this.lastRetrievedPaneDate
            && this.lastRetrievedPaneDate >= parentPane.lastRetrieved)
        {
            return;
        }

        for (const retrievePromise of this.retrieveDataPromises)
        {
            retrievePromise.abort();
        }

        WizardControl.widgetProperties =
            Api.getWidgetProperties(this.props) as WidgetProperties;

        const paneUseKey: string | null =
            WizardControl.widgetProperties.selectedPaneUseKey;

        LayoutStateStore.setSelectedStep(paneUseKey);

        if (paneUseKey)
        {
            const activeStep: StepWidgetProperties | undefined =
                WizardControl.widgetProperties.steps.find(
                    t => t.paneUseKey === paneUseKey);

            if (!activeStep)
            {
                throw new Error(
                    `No step with pane use key of ${paneUseKey} found`);
            }

            this.loadedPaneUseKeys = [activeStep.paneUseKey];

            this.setState({
                selectedPaneUseKey: activeStep.paneUseKey,
            });
        }
        else
        {
            this.loadedPaneUseKeys = [];
            this.setState({
                selectedPaneUseKey: undefined,
            });
        }

        this.lastRetrievedPaneDate = parentPane.lastRetrieved;
    };

    private renderSelectedStepPane(
        widgetProperties: WidgetProperties): React.ReactNode
    {
        if (widgetProperties.accessLevel <= AccessLevel.disabled)
        {
            return null;
        }

        const selectedStep: StepProps = this.props.steps.find(s =>
            s.paneUseKey === this.state.selectedPaneUseKey)!;

        let stepContents: React.ReactNode = null;
        if (this.loadedPaneUseKeys.indexOf(selectedStep.paneUseKey) >= 0)
        {
            // Key attribute tells React to recreate the component from
            // scratch when the selected step changes. Without this child
            // components are reused and constructors are not called
            stepContents = (
                <div key={selectedStep.paneUseKey}>
                    {Presentation.create(selectedStep.paneUse)}
                </div>
            );
        }

        return (
            <Paper
                card={false}
                cardDepth={0}
                className={this.props.classes.paper}
                elevation={0}
                margin={false}
                role="tabpanel"
            >
                {stepContents}
            </Paper>);
    }

    public componentDidMount()
    {
        this.dataMonitorDisposer = autorun(this.dataMonitor);
    }

    public componentWillUnmount()
    {
        WizardControl.instance = null;

        for (const retrievePromise of this.retrieveDataPromises)
        {
            retrievePromise.abort();
        }

        this.dataMonitorDisposer();

        LayoutStateStore.setSelectedStep(null);
    }

    public render(): React.ReactNode
    {
        if (!this.state.selectedPaneUseKey || this.props.steps.length <= 0)
        {
            return null;
        }

        if (!WizardControl.widgetProperties)
        {
            return null;
        }

        if (WizardControl.widgetProperties.accessLevel === AccessLevel.hidden)
        {
            return null;
        }

        return (
            <ErrorBoundary title={this.props.name}>
                {this.renderSelectedStepPane(WizardControl.widgetProperties)}
            </ErrorBoundary>);
    }
}

export default withStyles(styles)(WizardControl);
