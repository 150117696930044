import * as colors from '@material-ui/core/colors';
// VERSION_WARNING Material-UI 4.9.1
// Currently a MaterialUI API break, there is a ticket to make
// it public https://github.com/mui-org/material-ui/issues/13039
import { darken } from '@material-ui/core/styles/colorManipulator';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import createMuiTheme, { Theme, ThemeOptions }
    from '@material-ui/core/styles/createMuiTheme';
import { TypographyStyleOptions }
    from '@material-ui/core/styles/createTypography';

interface FreeflowBreakPointSpacing
{
    lg: { horizontal: number; vertical: number };
    md: { horizontal: number; vertical: number };
    sm: { horizontal: number; vertical: number };
    xs: { horizontal: number; vertical: number };
}

interface GridBreakPointSpacing
{
    lg: number;
    md: number;
    sm: number;
    xs: number;
}

interface CustomOptions
{
    container:
    {
        maxWidth: { lg: number; md: number; sm: number };
        verticalSpacing: { lg: number; md: number; sm: number; xs: number };
    };
    dialog:
    {
        maxColumns: { lg: number; md: number; sm: number };
        maxWidths: { lg: number; md: number; sm: number };
        padding: { sm: number; xs: number };
    };
    freeflow:
    {
        group:
        {
            spacing: FreeflowBreakPointSpacing;
        };
        item:
        {
            spacing: FreeflowBreakPointSpacing;
        };
        section:
        {
            spacing: FreeflowBreakPointSpacing;
        };
    };
    horizontalSpacing:
    {
        closelyRelated: GridBreakPointSpacing;
        related: GridBreakPointSpacing;
        widget:
        {
            closelyRelated: number;
            related: number;
        };
    };
    isHeaderOrFooter: boolean;
    links:
    {
        color: string | null;
        disabledColor: string;
        fontWeight: number;
        hoverColor: string | null;
        hoverTextDecoration: string;
        rippleColor: string;
        textDecoration: string;
    };
    panel:
    {
        maxColumns: { lg: number; md: number; sm: number };
        maxWidths: { lg: number; md: number; sm: number };
    };
    paper:
    {
        padding: { lg: number; md: number; sm: number; xs: number };
    };
    spacingBreakPoints: Breakpoint[];
    verticalSpacing:
    {
        closelyRelated: GridBreakPointSpacing;
        related: GridBreakPointSpacing;
        unrelated: GridBreakPointSpacing;
        tight: GridBreakPointSpacing;
    };
    visibilityBreakPoints: Breakpoint[];
    widthBreakPoints: Breakpoint[];
}

export interface CustomTheme extends Theme, CustomOptions
{
}

interface CustomThemeOptions extends ThemeOptions, CustomOptions
{
}

export enum ForegroundColorType
{
    Grey,
    White,
};

export interface PanelMeasurements
{
    columnWidth: number;
    gutterWidth: number;
}

export function getPanelMeasurements(
    theme: CustomTheme,
    breakPoint: Breakpoint
    ): PanelMeasurements
{
    const maxColumns: number = theme.panel.maxColumns[breakPoint];
    const maxWidth: number = theme.panel.maxWidths[breakPoint];
    const gutterWidth: number = theme.horizontalSpacing.related[breakPoint];
    const columnWidth: number =
        (maxWidth - gutterWidth * (maxColumns - 1)) / maxColumns;

    return { columnWidth, gutterWidth };
}

export default function createTheme(
    baseForegroundColor: ForegroundColorType | 'grey' | 'white',
    isHeaderOrFooter: boolean)
{
    let buttonBackgroundColor: string;
    let lighterForegroundColor: string;
    let linkDisabledForegroundColor: string;
    let normalForegroundColor: string;

    if (baseForegroundColor === ForegroundColorType.Grey
        || baseForegroundColor === 'grey')
    {
        buttonBackgroundColor = colors.grey[300];
        lighterForegroundColor = colors.grey[300];
        linkDisabledForegroundColor = colors.grey[300];
        normalForegroundColor = colors.grey[800];
    }
    else if (baseForegroundColor === ForegroundColorType.White
        || baseForegroundColor === 'white')
    {
        buttonBackgroundColor = colors.common.white;
        lighterForegroundColor = colors.common.white;
        linkDisabledForegroundColor = 'rgba(255, 255, 255, 0.2)';
        normalForegroundColor = colors.common.white;
    }
    else
    {
        throw new Error(
            'Base foreground color must be either "grey" or "white"');
    }

    const hoverForegroundColor = darken(normalForegroundColor, 0.1);
    const buttonHoverBackgroundColor = darken(buttonBackgroundColor, 0.1);

    const theme: CustomThemeOptions =
        {
            breakpoints:
            {
                values:
                {
                    lg: 1281,
                    md: 961,
                    sm: 601,
                    xl: 1920,
                    xs: 0,
                },
            },
            container:
            {
                maxWidth: { lg: 1200, md: 912, sm: 568 },
                verticalSpacing: { lg: 80, md: 60, sm: 40, xs: 40 },
            },
            dialog:
            {
                maxColumns: { lg: 6, md: 8, sm: 8 },
                maxWidths: { lg: 660, md: 648, sm: 600 },
                padding: { sm: 40, xs: 16 },
            },
            freeflow:
            {
                group:
                {
                    spacing:
                    {
                        lg: { horizontal: 40, vertical: 40 },
                        md: { horizontal: 24, vertical: 24 },
                        sm: { horizontal: 24, vertical: 16 },
                        xs: { horizontal: 24, vertical: 16 },
                    },
                },
                item:
                {
                    spacing:
                    {
                        lg: { horizontal: 24, vertical: 24 },
                        md: { horizontal: 24, vertical: 24 },
                        sm: { horizontal: 16, vertical: 16 },
                        xs: { horizontal: 16, vertical: 16 },
                    },
                },
                section:
                {
                    spacing:
                    {
                        lg: { horizontal: 40, vertical: 0 },
                        md: { horizontal: 24, vertical: 0 },
                        sm: { horizontal: 16, vertical: 0 },
                        xs: { horizontal: 8, vertical: 0 },
                    },
                },
            },
            horizontalSpacing:
            {
                closelyRelated: { lg: 24, md: 24, sm: 16, xs: 16 },
                related: { lg: 40, md: 24, sm: 16, xs: 16 },
                widget:
                {
                    closelyRelated: 8,
                    related: 16,
                },
            },
            isHeaderOrFooter,
            links:
            {
                color: isHeaderOrFooter ? normalForegroundColor : null,
                disabledColor: linkDisabledForegroundColor,
                fontWeight: isHeaderOrFooter ? 400 : 700,
                hoverColor: isHeaderOrFooter ? hoverForegroundColor : null,
                hoverTextDecoration: isHeaderOrFooter ? 'underline' : 'none',
                rippleColor: normalForegroundColor,
                textDecoration: isHeaderOrFooter ? 'none' : 'underline',
            },
            overrides:
            {
                MuiButton:
                {
                    contained:
                    {
                        '&$disabled': { backgroundColor: colors.grey[100] },
                        '&:hover': { backgroundColor: buttonHoverBackgroundColor },
                        backgroundColor: buttonBackgroundColor,
                        boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.5)',
                        color: colors.grey[800],
                    },
                    label:
                    {
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                    },
                    root:
                    {
                        '&:hover': { backgroundColor: buttonHoverBackgroundColor },
                        borderRadius: 0,
                        fontSize: 14,
                        fontWeight: 900,
                        letterSpacing: 1,
                        maxWidth: '100%',
                        minHeight: 40,
                        paddingBottom: 10,
                        paddingLeft: 40,
                        paddingRight: 40,
                        paddingTop: 10,
                    },
                    sizeLarge:
                    {
                        borderRadius: 0,
                        fontSize: 18,
                        fontWeight: 900,
                        letterSpacing: 1,
                        minHeight: 60,
                        paddingBottom: 17,
                        paddingLeft: 60,
                        paddingRight: 60,
                        paddingTop: 17,
                    },
                    sizeSmall:
                    {
                        borderRadius: 0,
                        fontSize: 9,
                        fontWeight: 900,
                        letterSpacing: 1,
                        minHeight: 24,
                        paddingBottom: 3,
                        paddingLeft: 24,
                        paddingRight: 24,
                        paddingTop: 3,
                    },
                    text:
                    {
                        paddingLeft: 40,
                        paddingRight: 40,
                    },
                },
                MuiCardContent:
                {
                    root:
                    {
                        '&:last-child': { paddingBottom: 8 },
                        paddingTop: 8,
                    },
                },
                MuiCardHeader:
                {
                    action:
                    {
                        marginRight: 0,
                        marginTop: 4,
                    },
                },
                MuiCheckbox:
                {
                    root:
                    {
                        fontSize: 24,
                        height: 40,
                        marginLeft: -1,
                        padding: 0,
                        width: 40,
                    },
                },
                MuiChip:
                {
                    label:
                    {
                        paddingLeft: 8,
                        paddingRight: 8,
                    },
                    root:
                    {
                        color: normalForegroundColor,
                        fontSize: 16,
                        height: 24,
                    },
                },
                MuiCollapse:
                {
                    entered:
                    {
                        overflow: 'visible',
                    },
                },
                MuiDialog:
                {
                    paperScrollPaper:
                    {
                        maxHeight: '88vh',
                    },
                },
                MuiDialogActions:
                {
                    root:
                    {
                        padding: 0,
                    },
                },
                MuiDialogContent:
                {
                    root:
                    {
                        overflow: 'hidden',
                    },
                },
                MuiDivider:
                {
                    root:
                    {
                        backgroundColor: colors.grey[300],
                    },
                },
                MuiDrawer:
                {
                    paper:
                    {
                        '@media(min-width:601px)':
                        {
                            minWidth: 320,
                        },
                    },
                },
                MuiExpansionPanel:
                {
                    root:
                    {
                        '&:first-child':
                        {
                            borderTopLeftRadius: 0,
                            borderTopRightRadius: 0,
                        },
                        backgroundColor: colors.grey[100],
                    },
                },
                MuiExpansionPanelSummary:
                {
                    content:
                    {
                        '& > :last-child': { paddingRight: 0 },
                        '&$expanded': { margin: '12px 0px' },
                    },
                    root:
                    {
                        '&$expanded': { minHeight: 48 },
                        '&$focused': { backgroundColor: 'transparent' },
                    },
                },
                MuiFab:
                {
                    root:
                    {
                        '&:hover': { backgroundColor: buttonHoverBackgroundColor },
                        backgroundColor: buttonBackgroundColor,
                        boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.5)',
                        fontSize: 24,
                        height: 40,
                        width: 40,
                    },
                    sizeMedium:
                    {
                        height: 40,
                        width: 40,
                    },
                    sizeSmall:
                    {
                        fontSize: 14,
                        height: 24,
                        minHeight: 24,
                        minWidth: 24,
                        width: 24,
                    },
                },
                MuiFilledInput:
                {
                    adornedEnd:
                    {
                        paddingRight: 16,
                    },
                    adornedStart:
                    {
                        paddingLeft: 16,
                    },
                    input:
                    {
                        paddingBottom: 8,
                        paddingLeft: 16,
                        paddingRight: 16,
                        paddingTop: 24,
                    },
                    multiline:
                    {
                        paddingBottom: 0,
                        paddingLeft: 16,
                        paddingRight: 16,
                        paddingTop: 24,
                    },
                    root:
                    {
                        '&$disabled':
                        {
                            backgroundColor: colors.grey[100],
                            color: colors.grey[300],
                        },
                        '&:hover':
                        {
                            backgroundColor: darken(colors.grey[200], 0.1),
                        },
                        backgroundColor: colors.grey[200],
                        borderTopLeftRadius: 0,
                        borderTopRightRadius: 0,
                        boxSizing: 'border-box',
                        color: colors.grey[800],
                        fontSize: 16,
                        height: 52,
                        lineHeight: '20px',
                    },
                    underline:
                    {
                        '&$disabled:before':
                        {
                            borderBottomColor: colors.grey[300],
                            borderBottomStyle: 'solid',
                            borderBottomWidth: 2,
                        },
                        '&:after':
                        {
                            borderBottomColor: colors.grey[700],
                            borderBottomStyle: 'solid',
                            borderBottomWidth: 2,
                        },
                        '&:before':
                        {
                            borderBottomColor: colors.grey[300],
                            borderBottomStyle: 'solid',
                            borderBottomWidth: 2,
                        },
                        '&:hover:not($disabled):not($focused):not($error):before':
                        {
                            borderBottomColor: colors.grey[300],
                            borderBottomStyle: 'solid',
                            borderBottomWidth: 2,
                        },
                    },
                },
                MuiFormControlLabel:
                {
                    label:
                    {
                        fontSize: 14,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    },
                    root:
                    {
                        marginLeft: -8,
                        marginRight: 8,
                        maxWidth: '100%',
                    },
                },
                MuiFormHelperText:
                {
                    root:
                    {
                        color: colors.grey[700],
                        marginTop: 8,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    },
                },
                MuiFormLabel:
                {
                    asterisk:
                    {
                        color: colors.red[900],
                    },
                    root:
                    {
                        '&$focused': { color: colors.grey[700] },
                        color: colors.grey[700],
                        fontSize: 16,
                        lineHeight: '20px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    },
                },
                MuiIcon:
                {
                    colorPrimary:
                    {
                        color: lighterForegroundColor,
                    },
                    colorSecondary:
                    {
                        color: colors.red[800],
                    },
                    root:
                    {
                        fontSize: 'inherit',
                        height: 'inherit',
                    },
                },
                MuiIconButton:
                {
                    root:
                    {
                        fontSize: 'inherit',
                        height: 'inherit',
                    },
                },
                MuiInput:
                {
                    root:
                    {
                        fontSize: 16,
                        lineHeight: '20px',
                    },
                    underline:
                    {
                        '&$disabled:before':
                        {
                            borderBottomColor: colors.grey[300],
                            borderBottomStyle: 'solid',
                            borderBottomWidth: 2,
                        },
                        '&:after':
                        {
                            borderBottomColor: colors.grey[700],
                            borderBottomStyle: 'solid',
                            borderBottomWidth: 2,
                        },
                        '&:before':
                        {
                            borderBottomColor: colors.grey[300],
                            borderBottomStyle: 'solid',
                            borderBottomWidth: 2,
                        },
                        '&:hover:not($disabled):not($focused):not($error):before':
                        {
                            borderBottomColor: colors.grey[300],
                            borderBottomStyle: 'solid',
                            borderBottomWidth: 2,
                        },
                        left: -1,
                        right: 1,
                    },
                },
                MuiInputAdornment:
                {
                    root:
                    {
                        '& button':
                        {
                            padding: 0,
                        },

                        height: 16,
                    },
                },
                MuiInputBase:
                {
                    root:
                    {
                        boxSizing: 'border-box',
                    },
                },
                MuiInputLabel:
                {
                    filled:
                    {
                        '&$shrink':
                        {
                            transform: 'translate(16px, 8px) scale(0.75)',
                        },
                        transform: 'translate(16px, 16px) scale(1)',
                    },
                    root:
                    {
                        pointerEvents: 'none',
                    },
                },
                MuiMenuItem:
                {
                    /* eslint-disable sort-keys */
                    gutters:
                    {
                        '@media(min-width:1281px)':
                            { paddingLeft: 40, paddingRight: 40 },
                        '@media(max-width:1280px)':
                            { paddingLeft: 24, paddingRight: 24 },
                        '@media(max-width:960px)':
                            { paddingLeft: 16, paddingRight: 16 },
                        paddingBottom: 10,
                        paddingTop: 10,
                    },
                    /* eslint-enable sort-keys */
                    root:
                    {
                        '&$selected':
                        {
                            '& > p, & > span':
                            {
                                color: colors.common.white,
                            },
                            '&:hover':
                            {
                                backgroundColor: darken(colors.grey[800], 0.1),
                            },
                            backgroundColor: colors.grey[800],
                        },
                        '&:hover':
                        {
                            backgroundColor: darken(colors.common.white, 0.1),
                        },
                        '@media (min-width: 601px)':
                        {
                            minHeight: 40,
                        },
                        minHeight: 40,
                        minWidth: 192,
                    },
                },
                MuiRadio:
                {
                    root:
                    {
                        fontSize: 24,
                        padding: 8,
                    },
                },
                MuiSelect:
                {
                    icon:
                    {
                        color: 'inherit',
                        paddingRight: 16,
                    },
                    select:
                    {
                        '&:focus': { backgroundColor: 'transparent' },
                        color: normalForegroundColor,
                    },
                },
                MuiSnackbar:
                {
                    root:
                    {
                        justifyContent: 'initial',
                    },
                },
                MuiSnackbarContent:
                {
                    action:
                    {
                        alignSelf: 'flex-start',
                        marginRight: 0,
                        marginTop: 6,
                    },
                    root:
                    {
                        '@media (min-width: 601px)':
                        {
                            maxWidth: 520,
                            minWidth: 240,
                        },
                        backgroundColor: colors.grey[800],
                        borderRadius: 0,
                        maxHeight: '95vh',
                        overflow: 'hidden',
                        paddingLeft: 24,
                        paddingRight: 24,
                    },
                },
                MuiTab:
                {
                    root:
                    {
                        '&$selected':
                        {
                            backgroundColor: colors.grey[100],
                            fontWeight: 900,
                        },
                        '@media(min-width:601px)':
                        {
                            minWidth: 'initial',
                        },
                        '@media(min-width:961px)':
                        {
                            fontSize: 14,
                            minWidth: 'initial',
                        },
                        color: colors.grey[800],
                        fontSize: 14,
                        fontWeight: 400,
                        letterSpacing: '1px',
                        minHeight: 56,
                        padding: 0,
                        position: 'relative',
                        zIndex: 1,
                    },
                    textColorInherit:
                    {
                        opacity: 1,
                    },
                },
                MuiTabs:
                {
                    indicator:
                    {
                        backgroundColor: colors.grey[800],
                        top: 0,
                        zIndex: 1,
                    },
                    root:
                    {
                        height: 56,
                    },
                },
                MuiTooltip:
                {
                    popper:
                    {
                        opacity: 1,
                    },
                    tooltip:
                    {
                        backgroundColor: colors.grey[800],
                        borderRadius: 0,
                        color: colors.common.white,
                        fontSize: 12,
                        fontWeight: 400,
                        lineHeight: '16px',
                        maxWidth: 320,
                        overflow: 'hidden',
                    },
                    tooltipPlacementBottom:
                    {
                        '@media(min-width:601px)':
                        {
                            marginBottom: 0,
                            marginLeft: 0,
                            marginRight: 0,
                            marginTop: 8,
                        },
                    },
                    tooltipPlacementTop:
                    {
                        '@media(min-width:601px)':
                        {
                            marginBottom: 8,
                            marginLeft: 0,
                            marginRight: 0,
                            marginTop: 0,
                        },
                    },
                },
                MuiTouchRipple:
                {
                    rippleVisible:
                    {
                        animation: 'cx-ripple-enter 550ms ease-in-out',
                        opacity: 0.4,
                    },
                },
                MuiTypography:
                {
                    root:
                    {
                        color: normalForegroundColor,
                    },
                },
            },
            palette:
            {
                action:
                {
                    disabled: colors.grey[300],
                    disabledBackground: colors.grey[100],
                },
                error: { main: colors.red[900] },
                primary: { main: colors.red[800] },
                secondary: { main: colors.blueGrey[900] },
                text:
                {
                    disabled: colors.grey[300],
                    primary: normalForegroundColor,
                    secondary: normalForegroundColor,
                },
            },
            panel:
            {
                maxColumns: { lg: 12, md: 12, sm: 8 },
                maxWidths: { lg: 1200, md: 912, sm: 568 },
            },
            paper:
            {
                padding: { lg: 40, md: 24, sm: 24, xs: 16 },
            },
            props:
            {
                MuiPaper:
                {
                    square: true,
                },
                MuiTypography:
                {
                    variantMapping:
                    {
                        /* eslint-disable sort-keys */
                        caption: 'p',
                        srOnly: 'p',
                        button: 'p',
                        /* eslint-enable sort-keys */
                    },
                },
            },
            spacingBreakPoints: ['xs', 'sm', 'md', 'lg'],
            typography:
            {
                fontSize: 16,
                /* eslint-disable sort-keys */
                h1:
                {
                    '@media(min-width:1281px)':
                        { fontSize: 56, lineHeight: '72px' },
                    '@media(max-width:1280px)':
                        { fontSize: 45, lineHeight: '60px' },
                    '@media(max-width:600px)':
                        { fontSize: 34, lineHeight: '44px' },
                    fontWeight: 700,
                } as TypographyStyleOptions,
                h2:
                {
                    '@media(min-width:1281px)':
                        { fontSize: 45, lineHeight: '60px' },
                    '@media(max-width:1280px)':
                        { fontSize: 34, lineHeight: '44px' },
                    '@media(max-width:600px)':
                        { fontSize: 24, lineHeight: '32px' },
                    fontWeight: 700,
                } as TypographyStyleOptions,
                h3:
                {
                    '@media(min-width:1281px)':
                        { fontSize: 34, lineHeight: '44px' },
                    '@media(max-width:1280px)':
                        { fontSize: 24, lineHeight: '32px' },
                    '@media(max-width:600px)':
                        { fontSize: 21, lineHeight: '28px' },
                    fontWeight: 700,
                } as TypographyStyleOptions,
                h4:
                {
                    '@media(min-width:1281px)':
                        { fontSize: 24, lineHeight: '32px' },
                    '@media(max-width:1280px)':
                        { fontSize: 21, lineHeight: '28px' },
                    '@media(max-width:600px)':
                        { fontSize: 16, lineHeight: '20px' },
                    fontWeight: 700,
                } as TypographyStyleOptions,
                h5:
                {
                    '@media(min-width:1281px)':
                        { fontSize: 21, lineHeight: '28px' },
                    '@media(max-width:1280px)':
                        { fontSize: 16, lineHeight: '20px' },
                    fontWeight: 700,
                } as TypographyStyleOptions,
                h6:
                {
                    fontSize: 16,
                    fontWeight: 700,
                    lineHeight: '20px',
                } as TypographyStyleOptions,
                caption:
                {
                    fontSize: 12,
                    fontWeight: 400,
                    lineHeight: '16px',
                },
                body1:
                {
                    fontSize: 16,
                    fontWeight: 400,
                    lineHeight: '20px',
                },
                body2:
                {
                    fontSize: 16,
                    fontWeight: 700,
                    lineHeight: '20px',
                },
                button:
                {
                    color: colors.grey[800],
                    fontSize: 16,
                    fontWeight: 700,
                },
                /* eslint-enable sort-keys */
            },
            verticalSpacing:
            {
                closelyRelated: { lg: 24, md: 24, sm: 24, xs: 24 },
                related: { lg: 40,  md: 24, sm: 24, xs: 24 },
                tight: { lg: 8, md: 8, sm: 8, xs: 8 },
                unrelated: { lg: 60, md: 40, sm: 40, xs: 40 },
            },
            visibilityBreakPoints: ['xs', 'sm', 'md', 'lg', 'xl'],
            widthBreakPoints: ['sm', 'md', 'lg'],
        };

    return createMuiTheme(theme);
}
