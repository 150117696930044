import { createStyles, WithStyles, withStyles, WithTheme, withTheme }
    from '@material-ui/core/styles';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import { observer } from 'mobx-react';
import * as React from 'react';
import Presentation from '../coreui/Presentation';
import PaneRow from '../models/PaneRow';
import { CustomTheme } from '../muiTheme';
import { AccessLevel, ValueByBreakpoint } from '../mustangui/Api';

export interface ConfigProperties
{
    childWidget: object;
    childWidgetName: string;
    isLayoutOnlyWidget: boolean;
    isVisible: ValueByBreakpoint<boolean>;
}

interface Props extends ConfigProperties
{
    dataId: string;
    propagated: object;
}

interface RuntimeProperties
{
    accessLevel?: AccessLevel;
}

const styles = (theme: CustomTheme) =>
{
    const visibilityStyles = {};
    for (const breakPoint of theme.visibilityBreakPoints)
    {
        visibilityStyles[`root-visible-${breakPoint}`] =
            {
                [theme.breakpoints.only(breakPoint)]:
                {
                    display: 'block',
                },
            };
    }

    const itemSpaceStyle = {};
    for (const breakPoint of theme.spacingBreakPoints)
    {
        const itemSpacing = theme.freeflow.item.spacing[breakPoint];

        itemSpaceStyle[theme.breakpoints.up(breakPoint)] =
            {
                paddingBottom: itemSpacing.vertical * 0.5,
                paddingLeft: itemSpacing.horizontal * 0.5,
                paddingRight: itemSpacing.horizontal * 0.5,
                paddingTop: itemSpacing.vertical * 0.5,
            };
    }

    const result =
        {
            root:
            {
                display: 'none',
                minWidth: 0,
                width: 'auto',
                ...itemSpaceStyle,
            },
            ...visibilityStyles,
        };

    return createStyles(result);
};

@observer
export class FreeflowContainerItem extends
    React.Component<Props & WithStyles<typeof styles> & WithTheme>
{
    public static isVisible(
        dataId: string,
        item: ConfigProperties,
        breakPoint: Breakpoint
        ): boolean
    {
        if (!item.isVisible[breakPoint])
        {
            return false;
        }

        if (item.isLayoutOnlyWidget)
        {
            return true;
        }

        const widgetProperties = PaneRow.getWidgetProperties(
            dataId, item.childWidgetName) as RuntimeProperties;

        if (widgetProperties.accessLevel === undefined)
        {
            return true;
        }

        return widgetProperties.accessLevel >= AccessLevel.disabled;
    }

    public render(): React.ReactNode
    {
        const classes: string[] = [this.props.classes.root];
        const theme = this.props.theme as CustomTheme;

        for (const breakPoint of theme.visibilityBreakPoints)
        {
            if (FreeflowContainerItem.isVisible(
                this.props.dataId, this.props, breakPoint))
            {
                const className = `root-visible-${breakPoint}`;
                classes.push(this.props.classes[className]);
            }
        }

        return (
            <div className={classes.join(' ')}>
                {
                    Presentation.create(
                        this.props.childWidget,
                        this.props.propagated)
                }
            </div>
        );
    }
}

export default withStyles(styles)(withTheme(FreeflowContainerItem));
