import { ColDef, Column, ColumnApi, RowNode } from 'ag-grid-community';

import
{
    CellClassParams,
    GetQuickFilterTextParams,
    IsColumnFuncParams,
    ValueGetterParams,
    ValueSetterParams,
} from 'ag-grid-community/dist/lib/entities/colDef';

import { TableChildProps, TableContext } from '../../coreui/Table';
import PaneRow from '../../models/PaneRow';
import Api, { AccessLevel, ConfigProps } from '../Api';
import CheckBoxColumn, { CheckBoxColumn as CheckBoxColumnBase }
    from './CheckBoxColumn';
import ComponentTypeDisplayColumn from './ComponentTypeDisplayColumn';
import DataImageDisplayColumn from './DataImageDisplayColumn';
import DataLinkColumn from './DataLinkColumn';
import DateEditColumn from './DateEditColumn';
import DateEditColumnEdit from './DateEditColumnEdit';
import DomainComboBoxColumnDisplay from './DomainComboBoxColumnDisplay';
import DomainComboBoxColumnEdit from './DomainComboBoxColumnEdit';
import IconDisplayColumn from './IconDisplayColumn';
import ManualLinkColumn from './ManualLinkColumn';
import MLTextEditColumnEdit from './MLTextEditColumnEdit';
import NumericEditColumn from './NumericEditColumn';
import NumericEditColumnEdit from './NumericEditColumnEdit';
import RelationshipComboBoxColumnDisplay
    from './RelationshipComboBoxColumnDisplay';
import RelationshipComboBoxColumnEdit from './RelationshipComboBoxColumnEdit';
import SLTextEditColumnEdit from './SLTextEditColumnEdit';
import TextColumn, { TextColumn as TextColumnBase } from './TextColumn';

interface CellWidgetProperties
{
    accessLevel: AccessLevel;
}

export interface GridColumnConfigProperties
{
    flex: boolean;
    header: string;
    lg: boolean;
    md: boolean;
    name: string;
    required: boolean;
    sm: boolean;
    sortDescending: boolean;
    sortEnabled: boolean;
    widgetProps: object;
    widgetType: string;
    width?: number;
    xs: boolean;
}

export default class GridColumn
{
    public static suppressEdit: boolean = false;

    private static comparator(
        column: GridColumnConfigProperties,
        rowA: PaneRow,
        rowB: PaneRow): number
    {
        const widgetPropertiesA = rowA.getWidget(column.name).properties;
        const sortIndexA = widgetPropertiesA['sortIndex'] as number;

        const widgetPropertiesB = rowB.getWidget(column.name).properties;
        const sortIndexB = widgetPropertiesB['sortIndex'] as number;

        return sortIndexA - sortIndexB;
    }

    private static getColumnHeaderClass = (
        params: CellClassParams,
        columns: GridColumnConfigProperties[],
        configProps: ConfigProps
        ): string =>
    {
        const column: GridColumnConfigProperties | undefined =
            columns.find(c => c.name === params.colDef.colId);
        const showRightBorder: boolean =
            GridColumn.shouldShowRightBorderOnColumn(params);
        const isRight: boolean =
            !!column && column.widgetProps['justification'] === 'Right';

        const runtimeProperties =
            Api.getWidgetProperties(configProps) as {accessLevel: AccessLevel};

        const parentGridIsReadOnly =
            runtimeProperties.accessLevel <= AccessLevel.readOnly;
        const required = column && column.required && !parentGridIsReadOnly;
        return (required ? 'cx-header-required ' : '')
            + (showRightBorder ? '' : 'cx-header-last ')
            + (isRight ? 'cx-header-right ' : '');
    };

    private static isColumnEditable(
        params: IsColumnFuncParams, column: GridColumnConfigProperties)
    {
        // Error badge must suppress editing on click.
        if (!params.colDef.field
            || column.widgetType === 'CheckBoxColumn'
            || GridColumn.suppressEdit)
        {
            GridColumn.suppressEdit = false;

            return false;
        }

        const props = column.widgetProps;
        const runtimeProperties =
            Api.getWidgetProperties(props, params.data) as CellWidgetProperties;

        return runtimeProperties.accessLevel >= AccessLevel.enterable;
    }

    private static shouldShowRightBorderOnColumn(
        params: CellClassParams
        ): boolean
    {
        const context = params.context as TableContext;
        let visibleCols = context.getColumnApi().getDisplayedCenterColumns(
            ).filter(c => c.getColId() !== '_fillerFixed');

        // If there is a horizontal scroll bar on the table, don't show the
        // filler column border
        if (context.hasHorizontalScrollBar())
        {
            visibleCols = visibleCols.filter(c => c.getColId() !== '_filler');
        }

        const lastColumn: Column | null =
            visibleCols.length > 0 ? visibleCols[visibleCols.length - 1] : null;

        return !!lastColumn && lastColumn.getColId() !== params.colDef.colId!;
    }

    public static getColumnDef(
        column: GridColumnConfigProperties,
        allColumns: GridColumnConfigProperties[],
        propagated: TableChildProps
        ): ColDef
    {
        const cellHorizontalPadding: number = 48;
        let editorComponent: object | null = null;
        let minWidth = 60;
        let getQuickFilterText: (params: GetQuickFilterTextParams) => string;
        let rendererComponent: object | null = null;

        switch (column.widgetType)
        {
            case 'CheckBoxColumn':
                rendererComponent = CheckBoxColumn;
                getQuickFilterText = CheckBoxColumnBase.getQuickFilterText;
                break;
            case 'ComponentTypeDisplayColumn':
                rendererComponent = ComponentTypeDisplayColumn;
                getQuickFilterText = ComponentTypeDisplayColumn.getQuickFilterText;
                break;
            case 'DataImageDisplayColumn':
                rendererComponent = DataImageDisplayColumn;
                getQuickFilterText = () => { return ''; };
                minWidth = column.widgetProps['imageWidth']
                    + cellHorizontalPadding;
                break;
            case 'DataLinkColumn':
                rendererComponent = DataLinkColumn;
                getQuickFilterText = DataLinkColumn.getQuickFilterText;
                break;
            case 'DateEditColumn':
                rendererComponent = DateEditColumn;
                editorComponent = DateEditColumnEdit;
                getQuickFilterText = DateEditColumnEdit.getQuickFilterText;
                break;
            case 'DomainComboBoxColumn':
                rendererComponent = DomainComboBoxColumnDisplay;
                editorComponent = DomainComboBoxColumnEdit;
                getQuickFilterText =
                    DomainComboBoxColumnDisplay.getQuickFilterText;
                break;
            case 'IconDisplayColumn':
                rendererComponent = IconDisplayColumn;
                getQuickFilterText = () => { return ''; };
                minWidth = cellHorizontalPadding + 16;
                break;
            case 'ManualLinkColumn':
                rendererComponent = ManualLinkColumn;
                getQuickFilterText = ManualLinkColumn.getQuickFilterText;
                break;
            case 'MLTextEditColumn':
                rendererComponent = TextColumn;
                editorComponent = MLTextEditColumnEdit;
                getQuickFilterText = TextColumnBase.getQuickFilterText;
                break;
            case 'NumericEditColumn':
                rendererComponent = NumericEditColumn;
                editorComponent = NumericEditColumnEdit;
                getQuickFilterText = NumericEditColumn.getQuickFilterText;
                break;
            case 'RelationshipComboBoxColumn':
                rendererComponent = RelationshipComboBoxColumnDisplay;
                editorComponent = RelationshipComboBoxColumnEdit;
                getQuickFilterText =
                    RelationshipComboBoxColumnDisplay.getQuickFilterText;
                break;
            case 'SLTextEditColumn':
                rendererComponent = TextColumn;
                editorComponent = SLTextEditColumnEdit;
                getQuickFilterText = TextColumnBase.getQuickFilterText;
                break;
            case 'TextDisplayColumn':
                rendererComponent = TextColumn;
                getQuickFilterText = TextColumnBase.getQuickFilterText;
                break;
            default:
                throw new Error('Unable to create column with widgetType '
                    + `${column.widgetType}.`);
        }

        const getVisibleQuickFilterText = (params: GetQuickFilterTextParams) =>
        {
            if (!params.column.isVisible())
            {
                return '';
            }

            return getQuickFilterText(params);
        };

        const sortingOrder =
            column.sortDescending
                ? ['desc', 'asc']
                : ['asc', 'desc'];

        if (column.flex && column.width
            && column.width + cellHorizontalPadding > minWidth)
        {
            minWidth = column.width + cellHorizontalPadding;
        }

        const result: ColDef =
            {
                autoHeight: false,
                cellClass: 'cx-cell',
                cellClassRules: {
                    'cx-cell-last': (params: CellClassParams) =>
                        !GridColumn.shouldShowRightBorderOnColumn(params),
                },
                cellEditorFramework: editorComponent,
                cellEditorParams: { ...column.widgetProps, propagated },
                cellRendererFramework: rendererComponent,
                cellRendererParams:  { ...column.widgetProps, propagated },
                cellStyle:
                {
                    overflow: 'visible',
                    padding: '0px',
                },
                colId: column.name,
                comparator: (
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    valueA: any,
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    valueB: any,
                    nodeA?: RowNode,
                    nodeB?: RowNode,
                    isInverted?: boolean): number =>
                {
                    const rowA = nodeA!.data as PaneRow;
                    const rowB = nodeB!.data as PaneRow;

                    return GridColumn.comparator(column, rowA, rowB);
                },
                editable: p => GridColumn.isColumnEditable(p, column),
                field: column.name,
                getQuickFilterText: getVisibleQuickFilterText,
                headerClass: params => GridColumn.getColumnHeaderClass(
                    params,
                    allColumns,
                    propagated.parentTable.configProps),
                headerName: column.header || '',
                hide: true,
                lockPinned: true,
                minWidth,
                resizable: !column.flex,
                sortable: column.sortEnabled,
                sortingOrder,
                suppressSizeToFit: !column.flex || !column.width,
                valueGetter: (v: ValueGetterParams) =>
                {
                    const row = v.data as PaneRow;

                    return row.getWidget(v.colDef.field!).value;
                },
                valueSetter: (v: ValueSetterParams) =>
                {
                    const row = v.data as PaneRow;
                    row.setProperty(v.colDef.field!, v.newValue);

                    return true;
                },
                width: column.width
                    ? column.width + cellHorizontalPadding
                    : undefined,
            };

        return result;
    }

    public static getColumnsMinRowHeight(
        columns: GridColumnConfigProperties[]): number
    {
        const cellVerticalPadding = 24;

        let rowHeight = 48;

        for (const column of columns)
        {
            let colRowHeight = 0;
            switch (column.widgetType)
            {
                case 'DataImageDisplayColumn':
                    colRowHeight = column.widgetProps['imageHeight']
                        + cellVerticalPadding;
                    break;
                default:
                    colRowHeight = 0;
            }

            if (colRowHeight > rowHeight)
            {
                rowHeight = colRowHeight;
            }
        }

        return rowHeight;
    }

    public static isColumnFlex(
        columns: GridColumnConfigProperties[],
        colId: string
        ): boolean
    {
        const column = columns.find(c => c.name === colId);
        return column !== undefined && column.flex;
    }

    public static isColumnVisible(
        columns: GridColumnConfigProperties[],
        colId: string,
        breakpoint: string
        ): boolean
    {
        const column = columns.find(c => c.name === colId);
        return column !== undefined && column[breakpoint];
    }
}
