import { computed, observable } from 'mobx';

export interface ProcessingInfo
{
    disableRestoreFocus: boolean;
    isNew: boolean;
    isProcessing: boolean;
    message: string | null;
}

export default class RequestsStore
{
    private static staticInstance: RequestsStore;

    @observable private processing =
        {
            disableRestoreFocus: false,
            isNew: false,
            message: null as string | null,
            pendingRequestCount: 0,
        };

    public static get instance()
    {
        if (!RequestsStore.staticInstance)
        {
            RequestsStore.staticInstance = new RequestsStore();
        }

        return RequestsStore.staticInstance;
    }

    @computed public get processingInfo(): ProcessingInfo
    {
        return {
            disableRestoreFocus: this.processing.disableRestoreFocus,
            isNew: this.processing.isNew,
            isProcessing: this.processing.pendingRequestCount > 0,
            message: this.processing.message,
        };
    }

    public clearAllProcessing()
    {
        this.processing.pendingRequestCount = 0;
    }

    public processingStarted(
        message: string | null = null,
        isNewPage: boolean = false,
        disableRestoreFocus: boolean = false
        ): void
    {
        if (this.processing.pendingRequestCount === 0)
        {
            this.processing.disableRestoreFocus = disableRestoreFocus;
            this.processing.isNew = isNewPage;
            this.processing.message = message;
        }
        this.processing.pendingRequestCount++;
    }

    public processingStopped(): void
    {
        this.processing.pendingRequestCount--;

        if (this.processing.pendingRequestCount < 0)
        {
            // Reset count so the requests store does not enter an invalid
            // state if the thrown exception is handled.
            this.processing.pendingRequestCount = 0;

            if (process.env.NODE_ENV !== 'production')
            {
                throw 'Processing stopped when it was never started';
            }
        }
    }
}
