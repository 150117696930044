import * as muiInputAdornment from '@material-ui/core/InputAdornment';
import { createStyles, Theme, WithStyles, withStyles }
    from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import * as React from 'react';
import Sys from '../core/Sys';
import Button from '../coreui/Button';
import Presentation from '../coreui/Presentation';
import TextField from '../coreui/TextField';
import Api, { AccessLevel } from './Api';
import { SelectChildProps } from './SelectControl';

interface ConfigProperties
{
    dataId: string;
    helperText: string;
    label: string;
    name: string;
    propagated: SelectChildProps;
}

interface RuntimeProperties
{
    accessLevel: AccessLevel;
    businessErrors: string[];
    showAsMandatory: boolean;
}

const styles = (theme: Theme) => createStyles(
    {
    });

@observer
export class TextCriteria extends
    React.Component<ConfigProperties & WithStyles<typeof styles>>
{
    private getErrors = (value: string): string[] =>
    {
        const runtimeProperties =
            Api.getWidgetProperties(this.props) as RuntimeProperties;

        if (!runtimeProperties)
        {
            return [];
        }

        return runtimeProperties.businessErrors;
    };

    private onClickSearchButton = () =>
    {
        this.props.propagated.parentSelect.search(this.props.name);
    };

    private onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) =>
    {
        if (event.key !== 'Enter')
        {
            return;
        }

        event.preventDefault();
        event.stopPropagation();
        this.props.propagated.parentSelect.search(this.props.name);
    };

    private onValueChange = (value: string) =>
    {
        Sys.clearBusinessErrors(this.props.dataId, this.props.name);
        Presentation.setValue(this.props, value);
    };

    public render()
    {
        const runtimeProperties =
            Api.getWidgetProperties(this.props) as RuntimeProperties;

        if (!runtimeProperties)
        {
            return null;
        }

        if (runtimeProperties.accessLevel === AccessLevel.hidden)
        {
            return null;
        }

        if (runtimeProperties.accessLevel === AccessLevel.disabled)
        {
            return (
                <TextField
                    disabled={true}
                    label={this.props.label}
                    name={this.props.name}
                    type="text"
                    value=""
                    variant="filled"
                />
            );
        }

        const value = Presentation.getValue(this.props) as string;

        return (
            <TextField
                getErrors={this.getErrors}
                helperText={this.props.helperText}
                InputProps={{
                    endAdornment: (
                        <muiInputAdornment.default
                            position="end"
                            style={{ marginTop: -4 }}
                        >
                            <Button
                                aria-label={Sys.getTranslation('Search')}
                                icon="fas fa-search"
                                onClick={this.onClickSearchButton}
                                size="small"
                            />
                        </muiInputAdornment.default>
                    ),
                }}
                label={this.props.label}
                name={this.props.name}
                onKeyPress={this.onKeyPress}
                onValueChange={this.onValueChange}
                readOnly={
                    runtimeProperties.accessLevel === AccessLevel.readOnly}
                required={runtimeProperties.showAsMandatory}
                value={
                    runtimeProperties.accessLevel === AccessLevel.readOnly
                    ? value ? value : '-' : value}
                variant="filled"
            />
        );
    }
}

export default withStyles(styles)(TextCriteria);
