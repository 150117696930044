import grey from '@material-ui/core/colors/grey';
import { createStyles, Theme, WithStyles, withStyles }
    from '@material-ui/core/styles';
import * as muiTextField from '@material-ui/core/TextField';
import { observer } from 'mobx-react';
import * as React from 'react';
import Sys from '../core/Sys';
import ComboBoxOption from '../coreui/ComboBoxOption';
import Icon from '../coreui/Icon';
import InformationBadge from '../coreui/InformationBadge';
import MenuItem from '../coreui/MenuItem';
import Presentation from '../coreui/Presentation';
import { TableVerticalLayoutProps } from '../coreui/Table';
import Typography from '../coreui/Typography';
import Api, { AccessLevel } from './Api';

export interface ApiSelectProps
{
    accessLevel: AccessLevel;
    businessErrors: string[];
    dataId: string;
    disabledHelpText: string;
    helperText: string;
    label: string;
    name: string;
    onSelect?: React.ChangeEventHandler<
        HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>;
    options: ComboBoxOption[];
    propagated?: TableVerticalLayoutProps;
    roundTripOnChange: boolean;
    showAsMandatory: boolean;
    showDisabledHelp: boolean;
}

interface State
{
    isOpen?: boolean;
}

const styles = (theme: Theme) => createStyles(
    {
        inputLabelRoot:
        {
            width: 'calc(100% - 57px)',
        },
        inputLabelShrink:
        {
            width: 'calc((100% - 57px) * 1.333)',
        },
        root:
        {
        },
    });

@observer
export class ApiSelect extends
    React.Component<ApiSelectProps & WithStyles<typeof styles>, State>
{
    private static openInstance: ApiSelect | null;
    protected muiProps: muiTextField.TextFieldProps;

    public static closeAll()
    {
        if (ApiSelect.openInstance)
        {
            ApiSelect.openInstance.close(true);
        }
    }

    public constructor(props: ApiSelectProps & WithStyles<typeof styles>)
    {
        super(props);

        this.state = { isOpen: false };

        this.muiProps =
        {
            autoFocus: props['autoFocus'],
            // Required so that when the help is an ul the dom is valid.
            FormHelperTextProps:
            {
                component: 'div',
                style:
                {
                    marginLeft: 16,
                    marginRight: 16,
                },
            },
            fullWidth: true,
            id: `select-${Sys.nextId}`,
            label: props.label,
            name: props.name,
            onChange: props.onSelect,
            select: true,
            variant: 'filled',
        };

        this.muiProps.SelectProps =
        {
            autoWidth: true,
            displayEmpty: true,
            IconComponent: () => (
                <Icon
                    icon="fas fa-caret-down"
                    style={
                        {
                            cursor: 'pointer',
                            fontSize: 16,
                            pointerEvents: 'none',
                            position: 'absolute',
                            right: 12,
                            top: 20,
                        }}
                />),
            MenuProps:
            {
                anchorOrigin: { horizontal: 'left', vertical: 'bottom' },
                getContentAnchorEl: undefined,
            },
            onClose: () => this.close(false),
            onOpen: () => this.open(),
        };
    }

    public close(disableRestoreFocus: boolean)
    {
        this.muiProps.SelectProps!.MenuProps!.disableRestoreFocus =
            disableRestoreFocus;
        ApiSelect.openInstance = null;
        this.setState({ isOpen: false });
    }

    public open()
    {
        if (this.props.options.length > 0)
        {
            ApiSelect.openInstance = this;
            this.setState({ isOpen: true });
        }
    }

    public render()
    {
        const _props = { ...this.props };

        if (this.props.accessLevel === AccessLevel.hidden)
        {
            return null;
        }

        this.muiProps.className = `${_props.classes.root} `;
        this.muiProps.SelectProps!.open = this.state.isOpen;

        this.muiProps.InputLabelProps = {
            classes:
            {
                root: _props.classes.inputLabelRoot,
                shrink: _props.classes.inputLabelShrink,
            },
        };

        if (this.props.accessLevel === AccessLevel.disabled)
        {
            this.muiProps.disabled = true;
            this.muiProps.required = false;
            this.muiProps.helperText = null;

            const _muiProps = { ...this.muiProps };
            _muiProps.SelectProps = { ..._muiProps.SelectProps };
            _muiProps.SelectProps.value = '';

            if (this.props.showDisabledHelp)
            {
                return (
                    <InformationBadge message={_props.disabledHelpText}>
                        <muiTextField.default {..._muiProps}>
                            {[]}
                        </muiTextField.default>
                    </InformationBadge>);
            }

            return (
                <muiTextField.default {..._muiProps}>
                    {[]}
                </muiTextField.default>);
        }

        const value = Presentation.getValue(_props);
        if (this.props.accessLevel === AccessLevel.readOnly)
        {
            const option = this.props.options.find(o => o.value === value);

            return (
                <div>
                    <Typography
                        component="div"
                        ellipsis
                        style={{ marginBottom: 8 }}
                        variant="caption"
                    >
                        {_props.label}
                    </Typography>
                    <Typography component="div">
                        {option ? option.display : '-'}
                    </Typography>
                </div>);
        }

        this.muiProps.disabled = false;
        this.muiProps.required = this.props.showAsMandatory;
        this.muiProps.style = undefined;
        this.muiProps['value'] = value;

        Api.setHelperText(this, this.props.businessErrors, this.muiProps);

        return (
            <muiTextField.default {...this.muiProps}>
                {
                    this.props.options.map(o => (
                        <MenuItem
                            disabled={o.historic}
                            key={o.value ? o.value : ''}
                            value={o.value ? o.value : ''}
                        >
                            {(o.value || this.state.isOpen) ? o.display : null}
                        </MenuItem>))
                }
            </muiTextField.default>);
    }
}

export default withStyles(styles)(ApiSelect);
