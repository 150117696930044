import * as muiButton from '@material-ui/core/Button';
import { createStyles, WithStyles, withStyles }
    from '@material-ui/core/styles';
import { darken } from '@material-ui/core/styles/colorManipulator';
import * as React from 'react';
import { CustomTheme } from '../muiTheme';
import Api from '../mustangui/Api';
import Icon from './Icon';

export interface LinkProps extends muiButton.ButtonProps
{
    component?: string;
    iconName?: string;
    target?: string;
}

const styles = (theme: CustomTheme) => createStyles(
    {
        label:
        {
        },
        ripple:
        {
            '& > span > span':
            {
                backgroundColor: theme.links.rippleColor,
            },
            height: 'calc(100% + 10px)',
            margin: '-5px -20px',
            width: 'calc(100% + 40px)',
        },
        root:
        {
            '& span':
            {
                overflow: 'hidden',
                textDecoration: theme.links.textDecoration,
                textOverflow: 'ellipsis',
            },
            '&:disabled':
            {
                color: theme.links.disabledColor,
            },
            '&:hover':
            {
                '& > span':
                {
                    textDecoration: theme.links.hoverTextDecoration,
                },
                backgroundColor: 'transparent',
                color: theme.links.hoverColor ? theme.links.hoverColor :
                    darken(Api.getSystemColor('information'), 0.1),
            },

            color: theme.links.color ?
                theme.links.color : Api.getSystemColor('information'),
            fontSize: 16,
            fontWeight: theme.links.fontWeight,
            justifyContent: 'left',
            letterSpacing: 'normal',
            lineHeight: '20px',
            minHeight: 0,
            minWidth: 0,
            padding: 0,
            textTransform: 'none',
        },
    });

export class Link extends
    React.PureComponent<LinkProps & WithStyles<typeof styles>>
{
    protected muiProps:  muiButton.ButtonProps;

    public render()
    {
        const _props = { ...this.props };
        if (!_props.className)
        {
            _props.className = `${_props.classes.root} `;
        }
        _props.TouchRippleProps = { className: _props.classes.ripple };
        delete _props.classes;

        if (typeof _props.children === 'string')
        {
            _props.children = <span>{_props.children}</span>;
        }

        if (_props.iconName)
        {
            _props.children =
                <React.Fragment>
                    <Icon
                        icon={_props.iconName}
                        style={
                            {
                                display: 'inline-block',
                                marginRight: _props.children ? '.4em' : 0,
                                verticalAlign: '-.15em',
                            }}
                    />
                    {_props.children}
                </React.Fragment>;
        }
        delete _props.iconName;

        return (
            <muiButton.default
                classes={{ label: this.props.classes.label }}
                {..._props}
            />);
    }
}

export default withStyles(styles)(Link);
