import * as muiChip from '@material-ui/core/Chip';
import { createStyles, Theme, WithStyles, withStyles }
    from '@material-ui/core/styles';
import * as React from 'react';

interface Props extends muiChip.ChipProps
{
}

const styles = (theme: Theme) => createStyles(
    {
        root:
        {
        },
    });

export class Chip extends
    React.PureComponent<Props & WithStyles<typeof styles>>
{
    public render()
    {
        const _props = { ...this.props };

        return <muiChip.default {..._props}/>;
    }
}

export default withStyles(styles)(Chip);
