import { createStyles, Theme, WithStyles, withStyles }
    from '@material-ui/core/styles';
import { autorun, IReactionDisposer } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import AppServer from '../core/AppServer';
import RequestPromise from '../core/RequestPromise';
import Sys from '../core/Sys';
import ExpansionPanel from '../coreui/ExpansionPanel';
import Hidden, { BreakpointRanges } from '../coreui/Hidden';
import Paper from '../coreui/Paper';
import Presentation from '../coreui/Presentation';
import ProcessingMask from '../coreui/ProcessingMask';
import Tab from '../coreui/Tab';
import Tabs from '../coreui/Tabs';
import BaseService from '../services/BaseService';
import RoundTripService from '../services/RoundTripService';
import LayoutStateStore from '../stores/LayoutStateStore';
import PaneDataStore, { PaneData, PaneDataByDataId }
    from '../stores/PaneDataStore';
import Api, { AccessLevel } from './Api';
import ErrorBoundary from './ErrorBoundary';

interface GetDataResponse
{
    paneDataByDataId: PaneDataByDataId;
    paneState: string;
    validationErrors: string[];
}

interface ConfigProperties
{
    dataId: string;
    name: string;
    tabs: TabProps[];
    uniqueId: string;
}

interface State
{
    accordianCollapsed?: boolean;
    loadingPaneUseKeys?: string[];
    selectedPaneUseKey?: string;
    switchingTab?: boolean;
}

interface TabProps
{
    dataId: string;
    disabledHelpText?: string;
    label: string;
    paneUse: object;
    paneUseKey: string;
}

interface TabRuntimeProperties
{
    accessLevel: AccessLevel;
    contentsExist: boolean;
    paneUseKey: string;
    rowKey: string;
    showDisabledHelp: boolean;
}

interface RuntimeProperties
{
    accessLevel: AccessLevel;
    selectedPaneUseKey: string | null;
    tabs: TabRuntimeProperties[];
}

const styles = (theme: Theme) => createStyles(
    {
        paper:
        {
            boxSizing: 'border-box',
            minHeight: 80 + 8 * 2,  // Loading animation + padding
            position: 'relative',
        },
    });

@observer
export class TabControl extends
    React.Component<ConfigProperties & WithStyles<typeof styles>, State>
{
    private dataMonitorDisposer: IReactionDisposer;
    private lastRetrievedPaneDate: Date | undefined;
    private loadedPaneUseKeys: string[] = [];
    private retrieveDataPromises: RequestPromise<GetDataResponse>[] = [];

    public constructor(props: ConfigProperties & WithStyles<typeof styles>)
    {
        super(props);

        this.state = {
            accordianCollapsed: false,
            loadingPaneUseKeys: [],
            switchingTab: false,
        };
    }

    private dataMonitor = () =>
    {
        const parentPane: PaneData =
            PaneDataStore.instance.getPane(this.props.dataId)!;

        if (!parentPane.lastRetrieved)
        {
            return;
        }

        if (this.lastRetrievedPaneDate
            && this.lastRetrievedPaneDate >= parentPane.lastRetrieved)
        {
            return;
        }

        for (const retrievePromise of this.retrieveDataPromises)
        {
            retrievePromise.abort();
        }

        const runtimeProperties =
            Api.getWidgetProperties(this.props) as RuntimeProperties;
        const paneUseKey: string | null =
            runtimeProperties.selectedPaneUseKey;

        if (paneUseKey)
        {
            const activeTab: TabRuntimeProperties | undefined =
                runtimeProperties.tabs.find(t => t.paneUseKey === paneUseKey);

            if (!activeTab)
            {
                throw new Error(
                    `No tab with pane use key of ${paneUseKey} found`);
            }

            this.loadedPaneUseKeys = [activeTab.paneUseKey];

            this.setState({
                loadingPaneUseKeys: [],
                selectedPaneUseKey: activeTab.paneUseKey,
            });
        }
        else
        {
            this.loadedPaneUseKeys = [];
            this.setState({
                loadingPaneUseKeys: [],
                selectedPaneUseKey: undefined,
            });
        }

        // Future 7.4.1: This should be moved to a more appropriate place
        PaneDataStore.clearAllDataChanges();

        this.lastRetrievedPaneDate = parentPane.lastRetrieved;
    };

    private getTabId(tab: TabProps, content: boolean): string
    {
        return `${this.props.uniqueId}_${content ? 'content' : 'tab'}`
            + `_${tab.paneUseKey}`;
    }

    private loadSelectedTab(): void
    {
        const runtimeProperties =
            Api.getWidgetProperties(this.props) as RuntimeProperties;
        const tabRuntimeProps: TabRuntimeProperties =
            runtimeProperties.tabs.find(
                t => t.paneUseKey === this.state.selectedPaneUseKey)!;
        const paneUseKey: string = this.state.selectedPaneUseKey!;

        this.setState((prevState) =>
        {
            const loadingPaneUseKeys: string[] =
                prevState.loadingPaneUseKeys!.slice();
            loadingPaneUseKeys.push(paneUseKey);

            return { loadingPaneUseKeys, switchingTab: false };
        });

        const requestPromise: RequestPromise<GetDataResponse> =
            RoundTripService.partialDataRetrevial(
                'TabControl/GetData/{rowKey}/{dataId}/{paneUseKey}',
                {
                    dataId: this.props.dataId,
                    paneUseKey,
                    rowKey: tabRuntimeProps.rowKey,
                }
            );

        this.retrieveDataPromises.push(requestPromise);

        requestPromise.then((response: GetDataResponse) =>
        {
            this.retrieveDataPromises =
                this.retrieveDataPromises.filter(p => p !== requestPromise);

            if (response.validationErrors
                && response.validationErrors.length > 0)
            {
                Sys.showErrors(response.validationErrors);
                if (this.loadedPaneUseKeys.length > 0)
                {
                    this.updateSelectedTab(null, this.loadedPaneUseKeys[0]);
                }
            }
            else
            {
                PaneDataStore.loadResponse(response.paneDataByDataId);
                Sys.setBusinessErrors();

                this.loadedPaneUseKeys.push(paneUseKey);
                AppServer.addPane(response.paneState);
            }

            this.setState((prevState) =>
            {
                const loadingPaneUseKeys: string[] =
                    prevState.loadingPaneUseKeys!.filter(k => k !== paneUseKey);

                return { loadingPaneUseKeys, switchingTab: false };
            });
        }).catch((request) =>
        {
            const message: string | null =
                BaseService.getRequestExceptionMessage(request);
            if (message)
            {
                Sys.showErrors([message]);
            }
            if (this.loadedPaneUseKeys.length > 0)
            {
                this.updateSelectedTab(null, this.loadedPaneUseKeys[0]);
            }
        });
    }

    private renderExpansionPanel(
        tab: TabProps, runtimeProperties: RuntimeProperties): React.ReactNode
    {
        const tabRuntimeProps = runtimeProperties.tabs.find(
            t => t.paneUseKey === tab.paneUseKey)!;

        if (!tabRuntimeProps)
        {
            return null;
        }

        if (tabRuntimeProps.accessLevel === AccessLevel.hidden)
        {
            return null;
        }

        return (
            <ExpansionPanel
                badge={Sys.hasBusinessErrors(tab.paneUseKey)
                    ? 'errors'
                    : (tabRuntimeProps.contentsExist ? 'contents' : undefined)}
                disabled={tabRuntimeProps.accessLevel === AccessLevel.disabled}
                disabledHelpText={tab.disabledHelpText}
                expanded={!this.state.accordianCollapsed
                    && tab.paneUseKey === this.state.selectedPaneUseKey}
                key={tab.paneUseKey}
                label={tab.label}
                onChange={this.updateSelectedTab}
                showDisabledHelp={tabRuntimeProps.showDisabledHelp}
                value={tab.paneUseKey}
            >
                <ErrorBoundary title={this.props.name}>
                    {tab.paneUseKey === this.state.selectedPaneUseKey
                        ? this.renderSelectedTabPane(
                            runtimeProperties, BreakpointRanges.smUp)
                        : null}
                </ErrorBoundary>
            </ExpansionPanel>);
    }

    private renderSelectedTabPane(
        runtimeProperties: RuntimeProperties,
        nestedIn: BreakpointRanges
        ): React.ReactNode
    {
        if (runtimeProperties.accessLevel <= AccessLevel.disabled)
        {
            return null;
        }

        const selectedTab: TabProps = this.props.tabs.find(t =>
            t.paneUseKey === this.state.selectedPaneUseKey)!;
        const isLoadingTab: boolean = this.state.loadingPaneUseKeys!.indexOf(
            this.state.selectedPaneUseKey!) >= 0;

        let tabContents: React.ReactNode = null;
        if (this.loadedPaneUseKeys.indexOf(selectedTab.paneUseKey) >= 0)
        {
            // Key attribute tells React to recreate the component from
            // scratch when the selected tab changes. Without this child
            // components are reused and constructors are not called
            tabContents = (
                <div key={selectedTab.paneUseKey}>
                    {Presentation.create(selectedTab.paneUse, { nestedIn })}
                </div>
            );
        }

        return (
            <Paper
                aria-labelledby={this.getTabId(selectedTab, false)}
                card={true}
                cardDepth={0}
                className={this.props.classes.paper}
                elevation={0}
                id={this.getTabId(selectedTab, true)}
                margin={true}
                role="tabpanel"
            >
                {!this.state.switchingTab || isLoadingTab ? (
                    <ProcessingMask isOpen={isLoadingTab} />
                ) : null}
                {tabContents}
            </Paper>);
    }

    private renderTabButton(
        tab: TabProps, runtimeProperties: RuntimeProperties): React.ReactNode
    {
        const tabRuntimeProps = runtimeProperties.tabs.find(
            t => t.paneUseKey === tab.paneUseKey)!;

        if (!tabRuntimeProps)
        {
            return null;
        }

        if (tabRuntimeProps.accessLevel === AccessLevel.hidden)
        {
            return null;
        }

        return (
            <Tab
                aria-controls={this.getTabId(tab, true)}
                badge={Sys.hasBusinessErrors(tabRuntimeProps.paneUseKey)
                    ? 'errors'
                    : (tabRuntimeProps.contentsExist ? 'contents' : undefined)}
                disabled={tabRuntimeProps.accessLevel === AccessLevel.disabled}
                disabledHelpText={tab.disabledHelpText}
                id={this.getTabId(tab, false)}
                key={tab.paneUseKey}
                label={tab.label}
                showDisabledHelp={tabRuntimeProps.showDisabledHelp}
                tabIndex={-1}
                value={tab.paneUseKey}
            />);
    }

    private updateSelectedTab = (
        event: React.ChangeEvent<{}> | null,
        value: string
        ) =>
    {
        this.setState(
            (prevState) =>
            {
                return {
                    accordianCollapsed: !prevState.accordianCollapsed
                        && value === prevState.selectedPaneUseKey,
                    selectedPaneUseKey: value,
                    switchingTab: true,
                };
            },
            () =>
            {
                LayoutStateStore.setSelectedTab(
                    this.props.dataId,
                    this.props.name,
                    this.state.selectedPaneUseKey!);

                if (this.loadedPaneUseKeys.indexOf(
                    this.state.selectedPaneUseKey!) < 0)
                {
                    this.loadSelectedTab();
                }
            });
    };

    public componentDidMount()
    {
        this.dataMonitorDisposer = autorun(this.dataMonitor);
    }

    public componentWillUnmount()
    {
        for (const retrievePromise of this.retrieveDataPromises)
        {
            retrievePromise.abort();
        }

        if (this.dataMonitorDisposer)
        {
            this.dataMonitorDisposer();
        }

        LayoutStateStore.setSelectedTab(
            this.props.dataId,
            this.props.name,
            null);
    }

    public render(): React.ReactNode
    {
        if (!this.state.selectedPaneUseKey || this.props.tabs.length <= 0)
        {
            return null;
        }

        const runtimeProperties =
            Api.getWidgetProperties(this.props) as RuntimeProperties;

        if (!runtimeProperties)
        {
            return null;
        }

        if (runtimeProperties.accessLevel === AccessLevel.hidden)
        {
            return null;
        }

        return (
            <div>
                <Hidden xsDown>
                    <Tabs
                        onChange={this.updateSelectedTab as
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            (((event: React.ChangeEvent<{}>, value: any) => void)
                            & ((event: React.FormEvent<HTMLButtonElement>) =>
                                void))}
                        value={this.state.selectedPaneUseKey}
                    >
                        {this.props.tabs.map(
                            t => this.renderTabButton(t, runtimeProperties))}
                    </Tabs>
                    <ErrorBoundary title={this.props.name}>
                        {this.renderSelectedTabPane(
                            runtimeProperties, BreakpointRanges.xsDown)}
                    </ErrorBoundary>
                </Hidden>
                <Hidden smUp>
                    {this.props.tabs.map(t =>
                        this.renderExpansionPanel(t, runtimeProperties)
                    )}
                </Hidden>
            </div>);
    }
}

export default withStyles(styles)(TabControl);
