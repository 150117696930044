import { createStyles, Theme, WithStyles, withStyles }
    from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import * as React from 'react';
import Sys from '../core/Sys';
import Button from '../coreui/Button';
import Api, { AccessLevel } from './Api';
import { RuntimeProperties, SelectChildProps } from './SelectControl';

interface ConfigProperties
{
    propagated: SelectChildProps;
}

const styles = (theme: Theme) => createStyles(
    {
    });

@observer
export class SearchButton extends
    React.Component<ConfigProperties & WithStyles<typeof styles>>
{
    private onClick = () =>
    {
        this.props.propagated.parentSelect.search(null);
    };

    public render()
    {
        const selectProps = this.props.propagated.parentSelect.configProps;
        const runtimeProperties =
            Api.getWidgetProperties(selectProps) as RuntimeProperties;

        if (!runtimeProperties)
        {
            return null;
        }

        if (runtimeProperties.accessLevel === AccessLevel.hidden)
        {
            return null;
        }

        if (runtimeProperties.accessLevel === AccessLevel.readOnly)
        {
            return null;
        }

        if (!runtimeProperties.canAddRemoveRows)
        {
            return null;
        }

        return (
            <Button
                disabled={
                    runtimeProperties.accessLevel === AccessLevel.disabled}
                icon="fas fa-search"
                label={Sys.getTranslation('Search')}
                onClick={this.onClick}
            />
        );
    }
}

export default withStyles(styles)(SearchButton);
