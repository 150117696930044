import Logging from '../core/Logging';
import Sys from '../core/Sys';
import BaseService, { Response } from './BaseService';

export interface DocumentUploadResponse
{
    clientKey: string;
    pendingDocumentId: number;
    pendingThumbnailId: number;
    uploadErrors: string[];
}

export default class DocumentService
{
    public static getDocumentUrl(
        documentHandle: string | null,
        pendingDocumentId: number | null,
        documentName: string
    ): string
    {
        // DocumentName is only passed to make the url more human readable
        return 'dynamic/Document/GetDocument'
            + `/${documentHandle}`
            + `/${pendingDocumentId}`
            + `/${documentName}`;
    }

    public static getThumbnailUrl(
        documentHandle: string | null,
        pendingDocumentId: number | null,
        pendingThumbnailId: number | null,
        thumbnailType: string
    ): string
    {
        // Future 7.4.1: the defeat cache (dc) parameter is currently required
        // to make sure the latest thumbnail is displayed (prevent browser
        // from caching it). This will probably change once we implement
        // image caching via the logical transaction id
        return 'dynamic/Document/GetThumbnail'
            + `/${documentHandle}`
            + `/${pendingDocumentId}/${pendingThumbnailId}`
            + `/${thumbnailType}?_dc=${new Date().getTime()}`;
    }

    public static uploadFiles(
        dataId: string,
        widgetName: string,
        files: File[],
        progress?: Function,
        error?: Function
        ): Promise<DocumentUploadResponse>[]
    {
        const batch: Promise<DocumentUploadResponse>[] = [];

        for (const file of files)
        {
            if (file['hasError'])
            {
                continue;
            }

            const formData: FormData | null = new FormData();
            const request: XMLHttpRequest = new XMLHttpRequest();

            formData.append('file', file);
            request.upload.onprogress = (event: ProgressEvent) =>
            {
                if (progress)
                {
                    progress(file, event);
                }
            };

            // Because the form data is binary the args can only be
            // appended to the url.
            const result: Promise<DocumentUploadResponse> = BaseService.request(
                `Document/Upload/${dataId}/${widgetName}/${file['rowKey']}`,
                { formData },
                'POST',
                true,
                request
            ).then((response: Response) =>
            {
                const responseData = JSON.parse(
                    response.responseText) as DocumentUploadResponse;
                const errors: string[] = responseData.uploadErrors;

                if (errors.length)
                {
                    if (error)
                    {
                        error(file, errors);
                    }

                    Logging.log(`${file.name} upload failed ${errors}`);
                }
                else
                {
                    const duration: number =
                        response['config']['duration'];
                }

                return responseData;
            }).catch(() =>
            {
                const message = 'A unexpected error occured';
                if (error)
                {
                    error(file, [Sys.getTranslation(message)]);
                }

                Logging.log(`${file.name} upload failed ${message}`);

                return {
                    clientKey: file['rowKey'],
                    pendingDocumentId: -1,
                    pendingThumbnailId: -1,
                    uploadErrors: [Sys.getTranslation(message)],
                } as DocumentUploadResponse;
            });

            batch.push(result);
        }

        return batch;
    }
}
