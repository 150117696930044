import { createStyles, Theme, WithStyles, withStyles }
    from '@material-ui/core/styles';
import { autorun, IReactionDisposer } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import TrackableModel from '../core/TrackableModel';
import Table, { TableChildProps, TableProps } from '../coreui/Table';
import PaneRow from '../models/PaneRow';
import PaneDataStore from '../stores/PaneDataStore';
import GridColumn, { GridColumnConfigProperties } from './Columns/GridColumn';
import ErrorBoundary from './ErrorBoundary';
import { SelectChildProps } from './SelectControl';

interface ConfigProperties
{
    columns: GridColumnConfigProperties[];
    contentDataId: string;
    data?: object;
    footerToolbar: object;
    headerToolbar: object;
    propagated: SelectChildProps;
    tableKey: string;
}

const styles = (theme: Theme) => createStyles(
    {
    });

@observer
export class SelectDialogResultsGrid extends
    React.Component<ConfigProperties & WithStyles<typeof styles>>
{
    private dataMonitorDisposer: IReactionDisposer;
    private populate: ((rows: TrackableModel[]) => void) | null = null;
    private propagated: TableChildProps;
    private tableProps: TableProps;

    public constructor(props: ConfigProperties & WithStyles<typeof styles>)
    {
        super(props);

        const parentSelect = props.propagated.parentSelect;

        this.propagated = {
            parentTable:
            {
                cardDepth: 0,
                columns: props.columns,
                configProps:
                {
                    contentDataId: props.contentDataId,
                    data: props.data,
                    dataId: parentSelect.configProps.dataId,
                    name: parentSelect.configProps.name,
                },
                isDocumentGrid: false,
                populateData: () => this.populateData(),
            },
        } as TableChildProps;

        this.tableProps = {
            cardDepth: 0,
            cellEdit: true,
            columns: [],
            contentDataId: props.contentDataId,
            dataId: parentSelect.configProps.dataId,
            disableSelectAll: true,
            footerToolbarChild: props.footerToolbar,
            getSelectedRowKeys: parentSelect.getSelectedRowObjectHandles,
            headerToolbarChild: props.headerToolbar,
            ignoreBusinessErrors: true,
            initialPageSize: {
                lg: 5,
                md: 5,
                sm: 5,
                xl: 5,
                xs: 5,
            },
            isColumnFlex: (colId: string) =>
                GridColumn.isColumnFlex(props.columns, colId),
            isColumnVisible: (colId: string, breakpoint: string) =>
                GridColumn.isColumnVisible(
                    props.columns, colId, breakpoint),
            minRowHeight:
                GridColumn.getColumnsMinRowHeight(props.columns),
            name: parentSelect.configProps.name,
            onRowSelected: parentSelect.onRowSelected,
            onRowUnselected: parentSelect.onRowUnselected,
            propagated: this.propagated,
            resetPageOnPopulate: true,
            rowSelection: parentSelect.configProps.isSingleSelect
                ? 'single' : 'multiple',
            setPopulate: populate => this.populate = populate,
            showNoData: true,
        };
    }

    private populateData = () =>
    {
        const rows: PaneRow[] =
            PaneDataStore.getPaneCollection(this.props.contentDataId);

        if (this.populate !== null)
        {
            this.populate(rows);
        }
    };

    public componentDidMount()
    {
        for (const column of this.props.columns)
        {
            this.tableProps.columns.push(GridColumn.getColumnDef(
                column,
                this.props.columns,
                this.propagated));
        }

        setTimeout(() =>
        {
            this.dataMonitorDisposer = autorun(() => this.populateData());
        });
    }

    public componentWillUnmount()
    {
        if (this.dataMonitorDisposer)
        {
            this.dataMonitorDisposer();
        }
    }

    public render()
    {
        return (
            <ErrorBoundary>
                <Table
                    {...this.tableProps}
                    disableScrollOnPageChange={true}
                    keepHeaderOnSelect={true}
                    tableKey={this.props.tableKey}
                />
            </ErrorBoundary>);
    }
}

export default withStyles(styles)(SelectDialogResultsGrid);
