import { createStyles, Theme, WithStyles, withStyles }
    from '@material-ui/core/styles';
import * as muiTooltip from '@material-ui/core/Tooltip';
import * as React from 'react';
import Api from '../mustangui/Api';

interface Props extends muiTooltip.TooltipProps
{
}

const styles = (theme: Theme) => createStyles(
    {
        tooltip:
        {
            backgroundColor: theme.palette.grey['100'],
            color: Api.getSystemColor('danger'),
        },
        tooltipPlacementBottom:
        {
            '@media(min-width:601px)':
            {
                marginTop: 16,
            },
        },
    });

export class ErrorTooltip extends
    React.PureComponent<Props & WithStyles<typeof styles>>
{
    public render()
    {
        const _props = { ...this.props };

        return <muiTooltip.default {..._props}/>;
    }
}

export default withStyles(styles)(ErrorTooltip);
