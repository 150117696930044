import { GetQuickFilterTextParams, ICellRendererParams }
    from 'ag-grid-community';
import * as React from 'react';
import * as Link from '../../coreui/Link';
import { CellUtil } from '../../coreui/table/CellUtil';
import { RuntimeWidget } from '../../models/PaneRow';
import Api, { AccessLevel } from '../Api';
import { FunctionName } from '../TableSummary';

interface ConfigProperties extends ICellRendererParams
{
    dataId: string;
    iconName: string;
    name: string;
}

interface RuntimeProperties
{
    accessLevel: AccessLevel;
    anchorText: string;
    objectId: number;
    presentationId: number;
}

export class DataLinkColumn extends
    React.PureComponent<ConfigProperties>
{
    private linkRef: HTMLLinkElement;
    protected muiProps: Link.LinkProps;

    public static getQuickFilterText(params: GetQuickFilterTextParams): string
    {
        const runtimeProperties: object | null =
            Api.getWidgetProperties(
                { name: params.colDef.field! },
                params.data);

        return runtimeProperties ? runtimeProperties['text'] : '';
    }

    public static getSummaryValue(
        runtimeData: RuntimeWidget[],
        configProperties: ConfigProperties,
        functionName: FunctionName
        ): string | null
    {
        let result = 0;
        for (const data of runtimeData)
        {
            const runtimeProperties = data.properties as RuntimeProperties;

            if (runtimeProperties.accessLevel >= AccessLevel.readOnly
                && runtimeProperties.anchorText)
            {
                result += 1;
            }
        }

        return result.toString();
    }

    public constructor(props: ConfigProperties)
    {
        super(props);

        this.muiProps =
        {
            buttonRef: r => this.linkRef = r as HTMLLinkElement,
            focusRipple: false,
            iconName: props.iconName,
            tabIndex: -1,
            target: '_self',
        };

        CellUtil.setReadOnlyAttribute(props.eGridCell, true);
        props.eGridCell.addEventListener('keydown', this.onCellKeyDown);
        props.eGridCell.addEventListener('focus', this.onCellFocus);
    }

    private buildUrl(runtimeProperties: RuntimeProperties): string
    {
        return `#/object/${runtimeProperties.objectId}`
            + `/${runtimeProperties.presentationId}`;
    }

    private onCellFocus = (): void =>
    {
        if (this.linkRef)
        {
            this.linkRef.focus();
        }
    };

    private onCellKeyDown = (event: KeyboardEvent): void =>
    {
        CellUtil.customizeGridNavigation(event, this.props);
    };

    public componentWillUnmount(): void
    {
        this.props.eGridCell.removeEventListener('focus', this.onCellFocus);
        this.props.eGridCell.removeEventListener('keydown', this.onCellKeyDown);
    }

    public render(): React.ReactNode
    {
        const _props = { ...this.props };
        const runtimeProperties =
            Api.getWidgetProperties(_props, _props.data) as RuntimeProperties;
        let text: React.ReactNode = <div />;
        let result: React.ReactNode = null;

        if (runtimeProperties.anchorText)
        {
            text =
                <div
                    style={
                        {
                            fontSize: 14,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            width: '100%',
                        }}
                >
                    {runtimeProperties.anchorText}
                </div>;
        }

        switch (runtimeProperties.accessLevel)
        {
            case AccessLevel.actionable:
                this.muiProps.href = this.buildUrl(runtimeProperties);
                this.muiProps.children = text;

                result = <Link.default {...this.muiProps} />;
                break;
            case AccessLevel.readOnly:
                result = text;
                break;
            default:
                break;
        }

        if (result)
        {
            result =
                <div
                    style={
                        {
                            alignItems: 'center',
                            display: 'flex',
                            height: '100%',
                            margin: '0 24px',
                        }}
                >
                    {result}
                </div>;
        }

        return result;
    }
}

export default DataLinkColumn;
