import { createStyles, Theme, WithStyles, withStyles }
    from '@material-ui/core/styles';
import withWidth, { WithWidth } from '@material-ui/core/withWidth';
import { observer } from 'mobx-react';
import * as React from 'react';
import { LayoutChildProps } from '../config/Layout';
import { Drawer } from '../coreui/Drawer';
import FormData from '../coreui/FormData';
import Link from '../coreui/Link';
import { Menu as MenuBase } from '../coreui/Menu';
import MenuItem from '../coreui/MenuItem';
import Api, { AccessLevel } from './Api';
import ApiButton from './ApiButton';
import { MenuItemProps } from './MenuItem';
import { ToolbarChildProps } from './Toolbar';

interface Props
{
    dataId: string;
    displayType: {
        lg: 'Link' | 'Small Button' | 'Medium Button' | 'Large Button';
        md: 'Link' | 'Small Button' | 'Medium Button' | 'Large Button';
        sm: 'Link' | 'Small Button' | 'Medium Button' | 'Large Button';
        xl: 'Link' | 'Small Button' | 'Medium Button' | 'Large Button';
        xs: 'Link' | 'Small Button' | 'Medium Button' | 'Large Button';
    };
    iconName: string;
    label?: string;
    linkType: 'External' | 'Internal';
    name: string;
    propagated?: ToolbarChildProps & LayoutChildProps;
}

interface RuntimeProperties
{
    accessLevel: AccessLevel;
    anchorText: string;
    url: string;
}

const styles = (theme: Theme) => createStyles(
    {
        root:
        {
        },
    });

@observer
export class ManualLink extends
    React.Component<Props & WithWidth & WithStyles<typeof styles>>
{
    public static renderMenuItem(props: MenuItemProps): JSX.Element
    {
        const configProps = props.config as Props;
        const runtimeProps = props.runtime as RuntimeProperties;

        const onClick = () =>
        {
            if (runtimeProps.accessLevel >= AccessLevel.actionable)
            {
                window.open(
                    runtimeProps.url,
                    configProps.linkType === 'Internal' ? '_self' : '_blank'
                );
                MenuBase.closeAll();
                Drawer.closeAll();
            }
        };

        return (
            <MenuItem
                disabled={runtimeProps.accessLevel === AccessLevel.disabled}
                iconName={configProps.iconName}
                indent={props.config.propagated
                    ? props.config.propagated.indent : 0}
                onClick={onClick}
            >
                {runtimeProps.anchorText}
            </MenuItem>
        );
    }

    public render()
    {
        const _props = { ...this.props };
        const runtimeProperties =
            Api.getWidgetProperties(_props) as RuntimeProperties;

        const tabIndex: number = _props.propagated
            && (_props.propagated.hasParentToolbar
                || _props.propagated.isInPageFooter) ? -1 : 0;

        if (_props.displayType[this.props.width] === 'Link')
        {
            if (!runtimeProperties)
            {
                return null;
            }

            if (runtimeProperties.accessLevel === AccessLevel.hidden)
            {
                return null;
            }

            let result: React.ReactNode = null;
            const link =
                <Link
                    children={runtimeProperties.anchorText}
                    disabled={
                        runtimeProperties.accessLevel <= AccessLevel.readOnly}
                    iconName={_props.iconName}
                    href={runtimeProperties.url}
                    tabIndex={tabIndex}
                    target={_props.linkType === 'Internal' ? '_self' : '_blank'}
                />;

            if (_props.label)
            {
                result = <FormData {..._props}>{link}</FormData>;
            }
            else
            {
                result = link;
            }

            return result;
        }

        let displayType: 'large' | 'medium' | 'small' | undefined = undefined;
        if (this.props.width in _props.displayType)
        {
            switch (_props.displayType[this.props.width])
            {
                case 'Small Button':
                    displayType = 'small';
                    break;
                case 'Medium Button':
                    displayType = 'medium';
                    break;
                case 'Large Button':
                    displayType = 'large';
                    break;
                default:
                    throw new Error('Unknown manual link display type'
                        + `${_props.displayType[this.props.width]}`);
            }
        }

        return (
            <ApiButton
                buttonColor="Default"
                children={runtimeProperties.anchorText}
                dataId={_props.dataId}
                disabledHelpText=""
                href={runtimeProperties.url}
                iconName={_props.iconName}
                name={_props.name}
                size={displayType}
                tabIndex={tabIndex}
                target={_props.linkType === 'Internal' ? '_self' : '_blank'}
            />
        );
    }
}

export default withStyles(styles)(withWidth()(ManualLink));
