import { PopoverOrigin } from '@material-ui/core/Popover';
import
    {
        createStyles,
        makeStyles,
        Theme,
        useTheme,
        WithStyles,
        withStyles,
        withTheme,
    } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { observer } from 'mobx-react';
import * as React from 'react';
import Sys from '../core/Sys';
import Icon from '../coreui/Icon';
import Menu, { Menu as MenuBase } from '../coreui/Menu';
import MenuItem from '../coreui/MenuItem';
import MenuItemDisplay from '../coreui/MenuItemDisplay';
import Presentation from '../coreui/Presentation';
import Typography from '../coreui/Typography';
import { ButtonSize } from './ActionButton';
import Api, { AccessLevel } from './Api';
import ApiButton from './ApiButton';
import { MenuItemProps } from './MenuItem';
import { ToolbarChildProps } from './Toolbar';
import { ToolbarOverflowChild } from './ToolbarContainerOverflowSection';

interface ConfigProperties
{
    dataId: string;
    iconName?: string;
    menuPane: object;
    name: string;
    propagated: { indent?: number } & ToolbarChildProps & object;
    renderAsLink: boolean;
    size: ButtonSize;
}

interface RuntimeProperties
{
    accessLevel: AccessLevel;
    label: string;
}

interface State
{
    buttonRef?: HTMLElement;
}

const menuStyles = makeStyles((theme: Theme) => (
{
    subMenuHeader: (props: { isDisabled: boolean }) => (
    {
        color: theme.palette.grey[props.isDisabled ? 300 : 800],
        cursor: 'default',
    }),
}));

const styles = (theme: Theme) => createStyles(
    {
    });

@observer
export class MenuButton extends
    React.Component<ConfigProperties & WithStyles<typeof styles>, State>
{
    private readonly menuName: string;

    public static renderFlatMenu(
        props:
            {
                children: React.ReactNode;
                header: string;
                iconName: string | undefined;
                indent: number;
                isDisabled: boolean;
            }
        )
    {
        const classes = menuStyles({ isDisabled: props.isDisabled });

        return (
            <React.Fragment>
                <MenuItemDisplay indent={props.indent}>
                    <Typography
                        classes={{ root: classes.subMenuHeader }}
                        ellipsis
                        variant="body2"
                    >
                        {props.iconName ? (
                            <Icon
                                fixedWidth
                                icon={props.iconName}
                                style={{ marginRight: '.4em' }}
                            />
                        ) : null}
                        {props.header}
                    </Typography>
                </MenuItemDisplay>
                {!props.isDisabled ? props.children : null}
            </React.Fragment>
        );
    }

    public static renderMenuItem(props: MenuItemProps): JSX.Element
    {
        const configProps = props.config as ConfigProperties;
        const runtimeProps = props.runtime as RuntimeProperties;
        const propagated =
            configProps.propagated as ToolbarOverflowChild & { indent?: number };

        const indent = propagated && propagated.indent ? propagated.indent : 0;
        const isDrawerMenu = propagated ? propagated.isDrawerMenu : false;
        const gridSize = Sys.settings.baselineGridSize;
        const isDisabled: boolean =
            runtimeProps.accessLevel === AccessLevel.disabled;
        const [menuItemRef, setMenuItemRef] = React.useState<HTMLElement>();
        const menuName = `${configProps.dataId}.${configProps.name}`;

        const openMenu = () =>
        {
            if (runtimeProps.accessLevel >= AccessLevel.actionable)
            {
                MenuBase.open(menuName, menuItemRef);
            }
        };

        function setItemRef(ref: HTMLElement | null)
        {
            if (ref && ref !== menuItemRef)
            {
                setMenuItemRef(ref);
            }
        }

        const theme: Theme = useTheme();
        const isLargeBreakPoint: boolean =
            useMediaQuery(theme.breakpoints.up('lg'));

        if (isDrawerMenu || !isLargeBreakPoint)
        {
            return (
                <MenuButton.renderFlatMenu
                    header={runtimeProps.label}
                    iconName={configProps.iconName}
                    indent={indent}
                    isDisabled={isDisabled}
                >
                    {Presentation.create(
                        configProps.menuPane,
                        {
                            ...propagated,
                            indent: indent + gridSize * 6,
                        }
                    )}
                </MenuButton.renderFlatMenu>);
        }

        return (
            <React.Fragment>
                <MenuItem
                    disabled={isDisabled}
                    iconName={configProps.iconName}
                    indent={indent}
                    onClick={openMenu}
                    ref={(r: HTMLElement | null) => setItemRef(r)}
                >
                        <div style={{ display: 'flex', flex: 'auto' }}>
                            <div style={{ flex: 'auto' }}>
                                {runtimeProps.label}
                            </div>
                            <Icon
                                fixedWidth
                                icon="fas fa-caret-right"
                                style={{ textAlign: 'center' }}
                            />
                        </div>
                </MenuItem>
                <Menu
                    anchorEl={menuItemRef}
                    anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                    name={menuName}
                    open={false}
                >
                    {Presentation.create(
                        configProps.menuPane, { ...propagated, indent: 0 })}
                </Menu>
            </React.Fragment>);
    }

    public constructor(props: ConfigProperties & WithStyles<typeof styles>)
    {
        super(props);

        this.state = {};
        this.menuName = `${props.dataId}.${props.name}`;
    }

    private openMenu = () =>
    {
        MenuBase.open(this.menuName, this.state.buttonRef);
    };

    private setButtonRef(ref: HTMLElement | null)
    {
        if (ref && ref !== this.state.buttonRef)
        {
            this.setState({ buttonRef: ref });
        }
    }

    public render(): React.ReactNode
    {
        const runtimeProperties =
            Api.getWidgetProperties(this.props) as RuntimeProperties;


        let anchorOrigin: PopoverOrigin =
            { horizontal: 'left', vertical: 'bottom' };
        let ariaLabel: string | undefined = undefined;
        let iconName: string | undefined = this.props.iconName;
        let endIcon: string | undefined = 'fas fa-caret-down';

        if (runtimeProperties.label === null)
        {
            anchorOrigin = { horizontal: 'left', vertical: 48 };
            ariaLabel = Sys.getTranslation('Menu');
            iconName = 'fas fa-caret-down';
            endIcon = undefined;
        }

        return (
            <React.Fragment>
                <ApiButton
                    alternateText={ariaLabel}
                    buttonColor="Default"
                    dataId={this.props.dataId}
                    disabledHelpText=""
                    buttonRef={r => this.setButtonRef(r)}
                    label={runtimeProperties.label}
                    endIcon={endIcon}
                    iconName={iconName}
                    name={this.props.name}
                    onClick={this.openMenu}
                    renderAsLink={this.props.renderAsLink}
                    size={this.props.size}
                    tabIndex={this.props.propagated
                        && this.props.propagated.hasParentToolbar ? -1 : 0}
                />
                <Menu
                    anchorEl={this.state.buttonRef}
                    anchorOrigin={anchorOrigin}
                    getContentAnchorEl={undefined}
                    name={this.menuName}
                    onExited={() => this.state.buttonRef!.focus()}
                    open={false}
                >
                    {Presentation.create(
                        this.props.menuPane, this.props.propagated)}
                </Menu>
            </React.Fragment>);
    }
}

export default withStyles(styles)(withTheme(MenuButton));
