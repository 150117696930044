import * as muiDialogContent from '@material-ui/core/DialogContent';
import { createStyles, Theme, WithStyles, withStyles }
    from '@material-ui/core/styles';
import { darken } from '@material-ui/core/styles/colorManipulator';
import { observer } from 'mobx-react';
import * as React from 'react';
import * as DayPicker from 'react-day-picker';
// @ts-ignore
import { LocaleUtils } from 'react-day-picker/moment';
import Sys from '../../core/Sys';
import Button from '../../coreui/Button';
import Dialog from '../../coreui/Dialog';
import Typography from '../../coreui/Typography';
import DateEditColumnEdit, { DateEditColumnEdit as DateEditColumnEditBase } from
    './DateEditColumnEdit';

interface ConfigProperties
{
    currentDate: Date;
    editor: DateEditColumnEdit;
    open: boolean;
}

interface State
{
    showYearsView?: boolean;
    yearSelected?: boolean;
}

const styles = (theme: Theme) => createStyles(
    {
        calendar:
        {
            [theme.breakpoints.down('xs')]:
            {
                padding: 16,
            },

            '& .DayPicker-Body':
            {
                display: 'table-row-group',
            },

            '& .DayPicker-Caption':
            {
                display: 'table-caption',
                marginBottom: '0.5rem',
                maxHeight: 40,
                padding: '0 42px',
                textAlign: 'center',
            },
            '& .DayPicker-Caption button':
            {
                justifyContent: 'flex-start',
                maxWidth: 215,
                textTransform: 'none',
            },
            '& .DayPicker-Caption button span':
            {
                fontSize: 16,
                fontWeight: 700,
                letterSpacing: '0.01071em',
                lineHeight: '20px',
                whiteSpace: 'normal',
            },

            '& .DayPicker-Day':
            {
                display: 'table-cell',
                outline: 'none',
                textAlign: 'center',
            },
            '& .DayPicker-Day button':
            {
                borderRadius: '50%',
                fontWeight: 400,
                minWidth: '40px',
                padding: '0.5rem',
            },
            '& .DayPicker-Day--outside button':
            {
                color: theme.palette.grey['300'],
            },
            '& .DayPicker-Day--selected button':
            {
                background: `${theme.palette.grey['800']} !important`,
                color: theme.palette.getContrastText(theme.palette.grey['800']),
            },
            '& .DayPicker-Day--selected button:hover':
            {
                background: darken(theme.palette.grey['800'], 0.1),
            },
            '& .DayPicker-Day--selected:focus button':
            {
                background: theme.palette.grey['800'],
            },
            '& .DayPicker-Day--selected:focus button:hover':
            {
                background: darken(theme.palette.grey['800'], 0.1),
            },
            '& .DayPicker-Day:focus button':
            {
                background: theme.palette.grey['400'],
            },
            '& .DayPicker-Day:focus button:hover':
            {
                background: darken(theme.palette.grey['400'], 0.1),
            },

            '& .DayPicker-Month':
            {
                display: 'table',
                width: '100%',
            },

            '& .DayPicker-NavButton':
            {
                position: 'absolute',
                top: '5px',
            },
            '& .DayPicker-NavButton > button':
            {
                height: 24,
                padding: 0,
                width: 24,
            },
            '& .DayPicker-NavButton--next':
            {
                right: '0.5rem',
            },
            '& .DayPicker-NavButton--prev':
            {
                left: '0.5rem',
            },

            '& .DayPicker-Week':
            {
                display: 'table-row',
            },
            '& .DayPicker-Weekday':
            {
                color: theme.palette.grey['500'],
                display: 'table-cell',
                fontSize: 12,
                lineHeight: '1.42857',
                padding: '0.5rem',
                textAlign: 'center',
            },
            '& .DayPicker-Weekdays':
            {
                display: 'table-header-group',
            },
            '& .DayPicker-WeekdaysRow':
            {
                display: 'table-row',
            },

            '& .DayPicker-wrapper':
            {
                outline: 'none',
                position: 'relative',
            },

            padding: 40,
        },

        dialogContainer:
        {
            '& .CancelButton':
            {
                bottom: 16,
                display: 'none',
                position: 'absolute',
                right: 16,

                [theme.breakpoints.down('xs')]:
                {
                    display: 'inline-block',
                },
            },
            '&:first-child':
            {
                paddingTop: 0,
            },

            height: 405,
            margin: '0 auto',
            maxWidth: '100%',
            padding: 0,
            width: 380,
        },

        switchYearBtn:
        {
            justifyContent: 'center !important',
            minWidth: 150,
            paddingLeft: 0,
            paddingRight: 0,
            position: 'static',  // Fix for Safari not firing click event
        },

        switchYearBtnRipple:
        {
            background: 'transparent',
            margin: '0 auto',
            maxHeight: 40,
            maxWidth: 150,
        },

        yearSelector:
        {
            '& .YearSelector-Year':
            {
                fontSize: 16,
                fontWeight: 400,
                padding: '12, 40',
                width: '100%',
            },
            '& .YearSelector-Year span':
            {
                justifyContent: 'flex-start',
            },
            '& .YearSelector-Year--selected':
            {
                background: theme.palette.grey['800'],
                color: theme.palette.getContrastText(theme.palette.grey['800']),
            },
            '& .YearSelector-Year--selected:hover':
            {
                background: darken(theme.palette.grey['800'], 0.1),
            },
            '& .YearSelector-Year:hover':
            {
                background: darken(theme.palette.common.white, 0.1),
            },

            padding: '8px 0',
        },
    });

@observer
export class DateEditColumnDialog extends
    React.Component<ConfigProperties & WithStyles<typeof styles>, State>
{
    private calendarVisibleMonth?: Date;
    private currentDate: Date;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private dialogContentRef?: any;

    public constructor(props: ConfigProperties & WithStyles<typeof styles>)
    {
        super(props);

        this.state =
        {
            showYearsView: false,
            yearSelected: false,
        };
    }

    private closeCalendar = () =>
    {
        this.setState({ showYearsView: false, yearSelected: false });
        this.props.editor.closeCalendar();
    };

    private focusCalendarSelectedDay = () =>
    {
        const selected = this.dialogContentRef.querySelector(
            '.DayPicker-Day--selected');

        if (selected)
        {
            selected.focus();
        }
        else
        {
            this.dialogContentRef.querySelector(
                '.DayPicker-Day[tabindex="0"]').focus();
        }
    };

    private getCalendarContent()
    {
        const editor = this.props.editor;

        return (
            <DayPicker.default
                className={this.props.classes.calendar}
                initialMonth={this.calendarVisibleMonth}
                locale={DateEditColumnEditBase.localeName}
                localeUtils={LocaleUtils}
                selectedDays={this.currentDate}
                showOutsideDays
                onDayClick={(date) =>
                {
                    editor.setFormattedValue(date);
                    this.closeCalendar();
                }}
                captionElement={(props) =>
                {
                    const classes = this.props.classes;

                    return (
                        <div className={props.classNames.caption}>
                            <Button
                                arial-label={
                                    Sys.getTranslation('Switch year', 'DateEdit')}
                                className={classes.switchYearBtn}
                                variant="text"
                                onClick={this.switchToYearsView}
                                TouchRippleProps={{
                                    className: classes.switchYearBtnRipple,
                                }}
                            >
                                {props.localeUtils.formatMonthTitle(
                                    props.date, props.locale)}
                            </Button>
                        </div>);
                }}
                navbarElement={(props) =>
                {
                    return (
                        <div className={props.className}>
                            <span className={props.classNames.navButtonPrev}>
                                <Button
                                    arial-label={
                                        Sys.getTranslation(
                                            'Previous month', 'DateEdit')}
                                    icon="fas fa-chevron-left"
                                    size="small"
                                    onClick={() => props.onPreviousClick()}
                                />
                            </span>
                            <span className={props.classNames.navButtonNext}>
                                <Button
                                    arial-label={
                                        Sys.getTranslation(
                                            'Next month', 'DateEdit')}
                                    icon="fas fa-chevron-right"
                                    size="small"
                                    onClick={() => props.onNextClick()}
                                />
                            </span>
                        </div>
                    );
                }}
                renderDay={(day, modifiers) =>
                {
                    return (
                        <Button variant="text" tabIndex={-1}>
                            {day.getDate()}
                        </Button>);
                }}
                weekdayElement={(props) =>
                {
                    return (
                        <Typography
                            classes={{ root: props.className }}
                            component="span"
                        >
                            {props.localeUtils.formatWeekdayShort(
                                props.weekday, props.locale)}
                        </Typography>
                    );
                }}
            />
        );
    }

    private getYearClass(year: number)
    {
        return `YearSelector-Year
            ${year === this.calendarVisibleMonth!.getFullYear() ?
                ' YearSelector-Year--selected' : ''}`;
    }

    private getYearTabIndex(year: number)
    {
        return year === this.calendarVisibleMonth!.getFullYear() ? 0 : -1;
    }

    private navigateYears = (event: React.KeyboardEvent<HTMLElement>) =>
    {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const targetElement = event.target as any;

        switch (event.key)
        {
            case 'ArrowDown':
                if (targetElement.nextSibling)
                {
                    targetElement.nextSibling.focus();
                    event.preventDefault();
                }
                break;
            case 'ArrowUp':
                if (targetElement.previousSibling)
                {
                    targetElement.previousSibling.focus();
                    event.preventDefault();
                }
                break;
            default:
        }
    };

    private onEntered = () =>
    {
        this.focusCalendarSelectedDay();
    };

    private selectYear(year: number, event: React.MouseEvent<HTMLElement>)
    {
        const currentMonth = this.calendarVisibleMonth!.getMonth();

        if (event.clientX === 0)  // Only focus when using keyboard
        {
            setTimeout(this.focusCalendarSelectedDay);
        }

        this.calendarVisibleMonth = new Date(year, currentMonth, 1);
        this.currentDate = this.calendarVisibleMonth;

        this.setState(
            {
                showYearsView: false,
                yearSelected: this.currentDate.getFullYear()
                    !== this.props.currentDate.getFullYear(),
            });
    }

    private switchToYearsView = () =>
    {
        this.setState({ showYearsView: true });
        setTimeout(() =>
        {
            const container = this.dialogContentRef.parentElement;
            const item = container.querySelector('.YearSelector-Year--selected');
            const top = item.offsetTop + item.offsetHeight / 2
                - container.offsetHeight / 2;

            container.scrollTop = top;
            item.focus();
        });
    };

    public render()
    {
        const editor = this.props.editor;
        const years: number[] = [];

        if (!this.state.yearSelected)
        {
            this.currentDate = this.props.currentDate;
        }

        this.calendarVisibleMonth = this.currentDate;
        const currentYear: number = this.currentDate!.getFullYear();
        for (let i = currentYear - 50; i <= currentYear + 50; i += 1)
        {
            years.push(i);
        }

        return (
            <Dialog
                onClose={this.closeCalendar}
                onEntered={this.onEntered}
                onExited={() => editor.restoreFocus()}
                open={this.props.open}
            >
                <muiDialogContent.default
                    className={this.props.classes.dialogContainer}
                >
                    <div
                        ref={r => this.dialogContentRef = r}
                        style={{ margin: '0 auto' }}
                    >
                        {this.state.showYearsView ?
                            <div
                                className={this.props.classes.yearSelector}
                            >
                                {years.map(year => (
                                    <Button
                                        className={this.getYearClass(year)}
                                        key={year}
                                        onClick={e =>
                                            this.selectYear(year, e)}
                                        onKeyDown={this.navigateYears}
                                        tabIndex={
                                            this.getYearTabIndex(year)
                                        }
                                        variant="text"
                                    >
                                        {year}
                                    </Button>
                                ))}
                            </div>
                            :
                            this.getCalendarContent()
                        }
                        {!this.state.showYearsView ? (
                            <Button
                                className="CancelButton"
                                onClick={this.closeCalendar}
                            >
                                Cancel
                            </Button>
                        ) : null}
                    </div>
                </muiDialogContent.default>
            </Dialog>);
    }
}

export default withStyles(styles)(DateEditColumnDialog);
