import common from '@material-ui/core/colors/common';
import grey from '@material-ui/core/colors/grey';
import * as muiMenu from '@material-ui/core/Menu';
import withWidth, { WithWidth } from '@material-ui/core/withWidth';
import * as React from 'react';
import Drawer, { Drawer as DrawerBase, DrawerProps } from './Drawer';

interface Props extends muiMenu.MenuProps
{
    anchor?: 'left' | 'right';
    drawerMenu?: boolean;
    name?: string | null;
}

export class Menu extends React.PureComponent<Props & WithWidth>
{
    // Dictionary of menu components, keyed by name.
    public static instances: Map<string | null, Set<Menu>> =
        new Map<string | null, Set<Menu>>();

    protected muiDrawerProps: DrawerProps;
    protected muiProps: muiMenu.MenuProps;

    public static closeAll()
    {
        let done: boolean = false;

        Menu.instances.forEach((value: Set<Menu>) =>
        {
            value.forEach((menu: Menu) =>
            {
                menu.close(true);
                done = true;
            });
        });

        if (!done)
        {
            DrawerBase.closeAll();
        }
    }

    public static open(name?: string | null, anchorElement?: HTMLElement)
    {
        let done: boolean = false;

        if (name && Menu.instances.has(name))
        {
            Menu.instances.get(name)!.forEach((menu) =>
            {
                if (!anchorElement || menu.props.anchorEl === anchorElement)
                {
                    if (anchorElement && anchorElement.tagName === 'LI')
                    {
                        anchorElement.style.color = common.white;
                        anchorElement.style.backgroundColor = grey[800];
                    }

                    menu.open();
                    done = true;
                }
            });
        }

        if (!done)
        {
            DrawerBase.open(name, anchorElement);
        }
    }

    public static toggle(name?: string | null)
    {
        if (name && Menu.instances.has(name))
        {
            Menu.instances.get(name)!.forEach((menu) =>
            {
                menu.toggle();
            });
        }
        else
        {
            DrawerBase.toggle(name);
        }
    }

    constructor(props: Props & WithWidth)
    {
        super(props);

        if ('open' in props)
        {
            this.state = { open: props.open };
        }
        else
        {
            this.state = { open: false };
        }

        if (props.drawerMenu)
        {
            this.muiDrawerProps = { open: false };
            this.muiDrawerProps.anchor = props.anchor;
            this.muiDrawerProps.name = props.name;
        }
        else
        {
            this.muiProps =
            {
                getContentAnchorEl: undefined,
                onExited: props.onExited,
                open: false,
            };

            this.muiProps.onKeyDown = (event) =>
            {
                // Don't propagate keyboard events to parent menus
                event.stopPropagation();

                if (event.key === 'Escape')
                {
                    this.close(false);
                }
            };

            if (props.name)
            {
                if (Menu.instances.has(props.name))
                {
                    Menu.instances.get(props.name)!.add(this);
                }
                else
                {
                    Menu.instances.set(props.name, new Set<Menu>([this]));
                }
            }
        }
    }

    public close(disableRestoreFocus: boolean)
    {
        if (this.state['open'])
        {
            this.muiProps.disableRestoreFocus = disableRestoreFocus;

            if (this.props.anchorEl)
            {
                const anchorElement = this.props.anchorEl as HTMLElement;

                if (anchorElement.tagName === 'LI')
                {
                    anchorElement.style.color = 'inherit';
                    anchorElement.style.backgroundColor = 'transparent';
                }
            }

            this.setState({ open: false });
        }
    }

    public componentWillUnmount()
    {
        if (this.props.name && Menu.instances.has(this.props.name))
        {
            Menu.instances.get(this.props.name)!.delete(this);

            if (Menu.instances.get(this.props.name)!.size === 0)
            {
                Menu.instances.delete(this.props.name);
            }
        }
    }

    public open()
    {
        if (!this.state['open'])
        {
            this.setState({ open: true });
        }
    }

    public render()
    {
        const _props = { ...this.props };
        let result: React.ReactNode = null;

        if (_props.drawerMenu)
        {
            result =
                <Drawer {...this.muiDrawerProps} anchorEl={_props.anchorEl}>
                    {_props.children}
                </Drawer>;
        }
        else
        {
            this.muiProps.anchorEl = _props.anchorEl;
            this.muiProps.children = _props.children;

            if (_props.width === 'xs')
            {
                this.muiProps.anchorOrigin =
                    { horizontal: 'center', vertical: 'bottom' };
                this.muiProps.transformOrigin =
                    { horizontal: 'center', vertical: 'top' };
            }
            else
            {
                if (_props.anchorOrigin)
                {
                    this.muiProps.anchorOrigin = _props.anchorOrigin;
                }
                else
                {
                    this.muiProps.anchorOrigin =
                        { horizontal: 'left', vertical: 'bottom' };
                }

                this.muiProps.transformOrigin =
                    { horizontal: 'left', vertical: 'top' };
            }

            result =
                <muiMenu.default
                    {...this.muiProps}
                    MenuListProps={{ autoFocus: true }}
                    onClose={() => { this.close(false); }}
                    open={this.state['open']}
                />;
        }

        return result;
    }

    public toggle()
    {
        this.setState({ open: !this.state['open'] });
    }
}

export default withWidth()(Menu);
