import Logging from '../core/Logging';
import RequestPromise from '../core/RequestPromise';
import Routing from '../core/Routing';
import Sys from '../core/Sys';

export interface Response
{
    responseText: string;
}

export default class BaseService
{
    private static readonly errorMessageByStatus: { [status: number]: string } =
        {
            408: 'Request Timeout',
            413: 'Upload Limit Exceeded',
        };
    private static renderSignInAttempts: number = 0;

    // Dictionary of requests, keyed by id.
    public static requests: Map<string, XMLHttpRequest> =
        new Map<string, XMLHttpRequest>();
    public static requestTimeoutMilliseconds: number = 30000;

    private static abortRequest(args: object)
    {
        BaseService.requests.forEach((request) =>
        {
            if (request['config']['args'])
            {
                if (Sys.hasAll(request['config']['args'], args))
                {
                    request.abort();
                }
            }
        });
    }

    private static getRequestException(request: XMLHttpRequest): object | null
    {
        if (!request.responseText)
        {
            return null;
        }

        try
        {
            return JSON.parse(request.responseText);
        }
        catch
        {
            return null;
        }
    }

    private static handleRequestError(request: XMLHttpRequest)
    {
        Logging.log(`Request Error ${request['config']['url']}`
            + ` ${JSON.stringify(request['config']['args'])}`);

        Routing.goToErrorPage(
            request.status.toString(),
            `${request.status} Error`,
            request.status);
    }

    private static handleRequestException(request: XMLHttpRequest)
    {
        const response: object | null = BaseService.getRequestException(request);
        let handled = false;

        function handleMustangException()
        {
            if (!response)
            {
                return;
            }

            Logging.log(
                `${request.status} ${request['config']['url']} `
                    + `${JSON.stringify(request['config']['args'])}`,
                `Request Exception ${response['errorMessage']}`);
            Logging.log(response['errorDetails'], 'Details');
            const status: string = request.status.toString();
            Routing.goToErrorPage(
                status,
                `${status} Error - ${response['errorMessage']}`,
                request.status);
            BaseService.renderSignInAttempts = 0;
            handled = true;
        }

        if (response)
        {
            switch (response['exceptionType'])
            {
                case 'AuthenticationRequiredException':
                    Logging.log(
                        `${request.status} ${request['config']['url']} `
                        + `${JSON.stringify(request['config']['args'])}`,
                        `Request Exception ${response['errorMessage']}`);
                    Logging.log(response['errorDetails'], 'Details');
                    Sys.currentCredentials.Message =
                        response['errorMessage'];

                    Routing.renderSignIn();
                    handled = true;
                    break;
                case 'SessionExpiredException':
                    // Prevent infinite loops to sign-in if something
                    // unexpected happens to sessions
                    BaseService.renderSignInAttempts++;
                    if (BaseService.renderSignInAttempts <= 3)
                    {
                        Sys.deleteCookie(Sys.guestSessionTokenCookie);
                        Sys.deleteCookie(Sys.sessionTokenCookie);
                        Sys.currentCredentials.Message = Sys.getTranslation(
                            Routing.sessionExpiredErrorMessage);
                        Routing.renderSignIn();
                    }
                    else
                    {
                        Logging.log(
                            `${request.status} ${request['config']['url']} `
                            + `${JSON.stringify(request['config']['args'])}`,
                            `Request Exception ${response['errorMessage']}`);
                        Logging.log('Expired Session Sign-In rendering stopped '
                            + ' after 3 attempts - Try clearing cookies',
                            'Details');
                        handleMustangException();
                    }

                    handled = true;
                    break;
                case 'MustangException':
                    handleMustangException();
                    break;
                default:
                    if ('errorMessage' in response)
                    {
                        handleMustangException();
                    }
                    break;
            }
        }

        if (!handled)
        {
            Logging.log(`Request Exception ${request.status} `
                + `${request['config']['url']} `
                + `${JSON.stringify(request['config']['args'])}`);
            Logging.log(request.responseText, 'Details');

            let message: string | null = null;
            if (request.status in BaseService.errorMessageByStatus)
            {
                message = Sys.getTranslation(
                    BaseService.errorMessageByStatus[request.status],
                    'Request Errors');
            }
            else if (request.statusText)
            {
                message = Sys.getTranslation(
                    request.statusText,
                    'Request Errors');
            }

            Routing.goToErrorPage(
                request.status.toString(),
                `${request.status} Error${message ? ` - ${message}` : ''}`,
                request.status);
        }
    }

    private static handleRequestTimeout(request: XMLHttpRequest)
    {
        Logging.log(`Request Timeout 408 ${request['config']['url']} `
            + `${JSON.stringify(request['config']['args'])}`);
        Routing.goToErrorPage(
            '408',
            '408 Error - '
                + `${Sys.getTranslation(
                    BaseService.errorMessageByStatus[408],
                    'Request Errors')}`,
            408);
    }

    public static getRequestExceptionMessage(
        request: XMLHttpRequest
        ): string | null
    {
        if (!request)
        {
            return Sys.getTranslation(
                'Unexpected error occurred', 'Request Errors');
        }

        if (request['config'] && request['config']['timeout'])
        {
            return Sys.getTranslation(
                BaseService.errorMessageByStatus[408],
                'Request Errors');
        }

        const exception: object | null = BaseService.getRequestException(request);
        if (exception && exception['errorMessage'])
        {
            if (exception['errorDetails'])
            {
                Logging.log(`Request Exception ${exception['errorMessage']}`);
                Logging.log(exception['errorDetails'], 'Details');
            }

            return exception['errorMessage'];
        }

        if (request.status in BaseService.errorMessageByStatus)
        {
            return Sys.getTranslation(
                BaseService.errorMessageByStatus[request.status],
                'Request Errors');
        }

        if (request.statusText)
        {
            return Sys.getTranslation(request.statusText, 'Request Errors');
        }

        return Sys.getTranslation('Unexpected error occurred', 'Request Errors');
    }

    public static request(
        url: string,
        args?: object,
        method: string = 'POST',
        redirect: boolean = true,
        request: XMLHttpRequest = new XMLHttpRequest()
        ): RequestPromise<Response>
    {
        return new RequestPromise<Response>(
        (resolve, reject) =>
        {
            let done: boolean = false;

            request.timeout = BaseService.requestTimeoutMilliseconds;

            request.onabort = () =>
            {
                BaseService.requests.delete(request['config']['id']);
                request['config']['duration'] =
                    new Date().getTime() - request['config']['started'];
                request['config']['aborted'] = true;

                reject(request);
            };

            request.onerror = () =>
            {
                BaseService.requests.delete(request['config']['id']);
                request['config']['duration'] =
                    new Date().getTime() - request['config']['started'];
                request['config']['error'] = true;

                if (redirect)
                {
                    BaseService.handleRequestError(request);
                }
                reject(request);
            };

            request.ontimeout = () =>
            {
                BaseService.requests.delete(request['config']['id']);
                request['config']['duration'] =
                    new Date().getTime() - request['config']['started'];
                request['config']['timeout'] = true;

                if (redirect)
                {
                    BaseService.handleRequestTimeout(request);
                }
                reject(request);
            };

            request.onreadystatechange = (event: Event) =>
            {
                if (request.readyState === XMLHttpRequest.DONE)
                {
                    BaseService.requests.delete(request['config']['id']);
                    request['config']['duration'] =
                        new Date().getTime() - request['config']['started'];
                    request['config']['responseText'] = request.responseText;

                    if (request.status === 200)
                    {
                        Sys.registerRequest(request);
                        resolve(request);
                    }
                    else if (request.status > 0)
                    {
                        if (redirect)
                        {
                            BaseService.handleRequestException(request);
                        }
                        else
                        {
                            reject(request);
                        }
                    }
                }
            };

            request['config'] = Object.create(null);
            request['config']['started'] = new Date().getTime();
            request['config']['url'] = url;

            if (args)
            {
                request['config']['args'] = args;

                if (Sys.requestId && Sys.requestId in args)
                {
                    request['config']['id'] = args[Sys.requestId];
                }
                else
                {
                    request['config']['id'] = Sys.nextId;
                }
            }
            else
            {
                request['config']['id'] = Sys.nextId;
            }

            // If a pending request exists with the same id, abort it.
            if (BaseService.requests.has(request['config']['id'])
                && request['config']['args'])
            {
                BaseService.abortRequest(request['config']['args']);
            }

            if (args && args['cacheRequest'])
            {
                const requestCacheKey: string =
                    Sys.getRequestCacheKey(request['config']);

                if (Sys.hasRequestCacheItem(requestCacheKey))
                {
                    done = true;
                    const response: Response = Object.create(null);

                    response['config'] = request['config'];
                    response['config']['duration'] =
                        new Date().getTime() - request['config']['started'];
                    response['config']['cached'] = true;
                    response.responseText =
                        Sys.getRequestCacheItem(requestCacheKey)!;
                    request['config']['responseText'] = response.responseText;

                    resolve(response);
                }
            }

            if (!done)
            {
                BaseService.requests.set(request['config']['id'], request);

                if (url && url.toLowerCase().startsWith('http'))
                {
                    request.open(method, url, true);
                }
                else
                {
                    request.open(method, `${Sys.baseUrl}${url}`, true);
                }

                if (args && (method === 'POST' || method === 'PUT'))
                {
                    if ('jsonData' in args)
                    {
                        request.setRequestHeader(
                            'Content-type', 'application/json');
                        request.send(args['jsonData']);
                    }
                    else if ('formData' in args)
                    {
                        // Content type must be set by send to get the boundary
                        // value.
                        request.send(args['formData']);
                    }
                    else
                    {
                        request.setRequestHeader(
                            'Content-type',
                            'application/x-www-form-urlencoded');
                        request.send(Sys.objectToQueryString(args));
                    }
                }
                else
                {
                    request.send();
                }
            }

            if (Sys.monitor)
            {
                Sys.monitor.addRequest(request['config']);
            }
        },
        () =>
        {
            request.abort();
        });
    }

    public static requestObject<T>(
        url: string,
        urlParams?: object | null,
        formData?: object | null,
        jsonData?: object | null,
        method: string = 'POST',
        redirect: boolean = true
        ): RequestPromise<T>
    {
        let request: RequestPromise<Response>;

        const args = { ...formData };

        if (jsonData)
        {
            args['jsonData'] = JSON.stringify(jsonData);
        }

        return new RequestPromise<T>(
            (resolve, reject) =>
            {
                request = BaseService.request(
                    Sys.getUrl(url, urlParams), args, method, redirect
                );
                request.then((response) =>
                {
                    resolve(JSON.parse(response.responseText));
                }
                ).catch(response => reject(response));
            },
            () =>
            {
                request.abort();
            });
    }
}
