import { createStyles, Theme, WithStyles, withStyles }
    from '@material-ui/core/styles';
import { GridApi, GridSizeChangedEvent } from 'ag-grid-community';
import * as React from 'react';
import Sys from '../core/Sys';
import Button from '../coreui/Button';
import Grow from '../coreui/Grow';
import Icon from '../coreui/Icon';
import { TableChildProps } from '../coreui/Table';
import TextField from '../coreui/TextField';
import ToolbarInputWrapper from './ToolbarInputWrapper';

interface Props
{
    dataId: string;
    name: string;
    propagated: TableChildProps;
}

interface State
{
    filterValue: string;
    filterVisible: boolean;
}

const styles = (theme: Theme) => createStyles(
    {
        root:
        {
        },
    });

export class GridFilter extends
    React.PureComponent<Props & WithStyles<typeof styles>, State>
{
    private buttonRef: HTMLButtonElement;
    private readonly gridApi: GridApi;
    private inputElement: HTMLInputElement;
    private lastTableWidth: number = 0;
    private setFilterTimeout: number;

    public constructor(props: Props & WithStyles<typeof styles>)
    {
        super(props);

        this.gridApi = props.propagated.parentTable.getApi();

        this.state =
        {
            filterValue: '',
            filterVisible: false,
        };

        if (!props.propagated.parentTable.isVerticalLayout)
        {
            this.gridApi.addEventListener(
                'gridSizeChanged',
                this.onGridSizeChanged);
        }
    }

    private buttonOnClick = () =>
    {
        if (this.state.filterVisible)
        {
            this.close();
        }
        else
        {
            this.setState({ filterVisible: true });
        }
    };

    private close()
    {
        this.gridApi.setQuickFilter('');
        this.setState({ filterValue: '' });
        this.setState({ filterVisible: false });
    }

    private onGridSizeChanged = (event: GridSizeChangedEvent) =>
    {
        if (!this.state.filterVisible
            || this.lastTableWidth === event.clientWidth)
        {
            return;
        }

        this.lastTableWidth = event.clientWidth;

        // Filter processing is deferred so that the visible columns are up to
        // date before the filter is reapplied.
        setTimeout(
            () =>
            {
                // Must filter on a different value to force the filter to
                // reevaluate the value.  Using a value to exclude
                // all rows for a cleaner display.
                this.gridApi.setQuickFilter('$does_not_exist$');
                this.gridApi.setQuickFilter(this.state.filterValue);
            });
    };

    private onGrowEnd = (node: HTMLElement): void =>
    {
        this.inputElement.focus();
    };

    private onGrowStart = (node: HTMLElement, isAppearing?: boolean): void =>
    {
        node.style.visibility = 'visible';
        this.inputElement.focus();
    };

    private onShrinkEnd = (node: HTMLElement): void =>
    {
        node.style.visibility = 'hidden';
    };

    private textFieldOnChange = (
        event: React.ChangeEvent<HTMLInputElement>) =>
    {
        const value: string = event.currentTarget.value;

        this.setState({ filterValue: value });

        window.clearTimeout(this.setFilterTimeout);
        this.setFilterTimeout = window.setTimeout(
            () =>
            {
                this.gridApi.setQuickFilter(value);
            },
            300);
    };

    public componentWillUnmount()
    {
        if (!this.props.propagated.parentTable.isVerticalLayout)
        {
            this.gridApi.removeEventListener(
                'gridSizeChanged',
                this.onGridSizeChanged);
        }
    }

    public render()
    {
        const depth: number = this.props.propagated.parentTable.cardDepth;

        return (
            <div
                style={
                    {
                        alignItems: 'center',
                        display: 'flex',
                        position: 'relative',
                    }}
            >
                <Grow
                    in={this.state.filterVisible}
                    onEnter={this.onGrowStart}
                    onEntered={this.onGrowEnd}
                    onExited={this.onShrinkEnd}
                    style={{ visibility: 'hidden' }}
                    timeout={500}
                >
                    <ToolbarInputWrapper
                        inputElement={this.inputElement}
                        onClose={() =>
                        {
                            this.close();

                            if (this.buttonRef)
                            {
                                this.buttonRef.focus();
                            }
                        }}
                    >
                        <TextField
                            disabled={!this.state.filterVisible}
                            icon="fas fa-filter"
                            inputProps={{ spellCheck: false, tabIndex: -1 }}
                            inputRef={element => this.inputElement = element}
                            label={Sys.getTranslation('Filter', 'DataTable')}
                            onChange={this.textFieldOnChange}
                            style={
                                {
                                    width: this.props.propagated.parentTable.
                                        isVerticalLayout
                                        ? `calc(100vw - ${(depth * 32) + 64}px)`
                                        : 272,
                                }}
                            value={this.state.filterValue}
                            variant="filled"
                        />
                    </ToolbarInputWrapper>
                </Grow>
                <Button
                    aria-label={Sys.getTranslation('Filter', 'DataTable')}
                    buttonRef={r => this.buttonRef = r as HTMLButtonElement}
                    fab
                    onClick={this.buttonOnClick}
                    size="small"
                    tabIndex={-1}
                >
                    <Icon
                        icon={this.state.filterVisible
                            ? 'fas fa-times' : 'fas fa-filter'}
                        style={{ marginTop: 2, width: '100%' }}
                    />
                </Button>
            </div>);
    }
}

export default withStyles(styles)(GridFilter);
