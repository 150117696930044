import * as muiBadge from '@material-ui/core/Badge';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { createStyles, Theme, WithStyles, withStyles }
    from '@material-ui/core/styles';
import { darken } from '@material-ui/core/styles/colorManipulator';
import * as React from 'react';
import Api from '../mustangui/Api';
import GridColumn from '../mustangui/Columns/GridColumn';
import Button from './Button';
import ErrorTooltip from './ErrorTooltip';

// This props does not extend badge properties because badgeContent is
// required and we don't want to have to pass a value for this type of badge.
interface Props
{
    isShort?: boolean;
    message?: React.ReactNode;
    onClose?: () => void;
    onOpen?: () => void;
    open?: boolean;
    style?: React.CSSProperties;
    suppressEdit?: boolean;
}

interface State
{
    open: boolean;
}

const styles = (theme: Theme) => createStyles(
    {
        badge:
        {
            right: 16,
            top: 'calc(100% - 16px)',
        },
        button:
        {
            '&:hover':
            {
                backgroundColor: darken(Api.getSystemColor('danger'), 0.1),
            },
            backgroundColor: Api.getSystemColor('danger'),
            color: theme.palette.getContrastText(Api.getSystemColor('danger')),
        },
        error:
        {
            borderColor: Api.getSystemColor('danger'),
        },
        normal:
        {
            borderColor: 'transparent',
        },
        ripple:
        {
            '& > span > span':
            {
                backgroundColor: Api.getSystemColor('danger'),
            },
        },
        root:
        {
            alignItems: 'center',
            borderStyle: 'solid',
            borderWidth: 1,
            height: 'calc(100% - 2px)',
            marginLeft: -2,
            marginTop: 1,
            width: 'calc(100% + 2px)',
            zIndex: 1,
        },
        short:
        {
            borderColor: 'transparent',
            height: 'calc(100% - 3px)',
            marginTop: 2,
        },
        shortError:
        {
            borderColor: Api.getSystemColor('danger'),
            height: 'calc(100% - 3px)',
            marginTop: 2,
        },
    });

export class ErrorBadge extends
    React.PureComponent<Props & WithStyles<typeof styles>, State>
{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private buttonRef: any;

    public constructor(props: Props & WithStyles<typeof styles>)
    {
        super(props);

        this.state = { open: false };
    }

    public render()
    {
        const _props = { ...this.props };
        const message = _props.message;
        const suppressEdit = _props.suppressEdit;
        const children = _props.children;
        let content: React.ReactNode = <div/>;

        delete _props.children;
        delete _props.classes;
        delete _props.message;
        delete _props.onClose;
        delete _props.onOpen;
        delete _props.open;
        delete _props.suppressEdit;

        if (message)
        {
            content =
                <ClickAwayListener
                    mouseEvent="onMouseDown"
                    onClickAway={() => this.setState({ open: false })}
                >
                    <ErrorTooltip
                        disableHoverListener
                        enterTouchDelay={0}
                        onClose={this.props.onClose}
                        onOpen={this.props.onOpen}
                        open={this.state.open || !!this.props.open}
                        title={message}
                    >
                        <Button
                            badge
                            buttonRef={r => this.buttonRef = r}
                            classes={{ root: this.props.classes.button }}
                            icon="fas fa-exclamation"
                            onClick={() =>
                            {
                                // Fix for Safari not opening tooltip
                                this.buttonRef.focus();
                                this.setState({ open: true });
                            }}
                            onMouseDown={suppressEdit
                                ? () => { GridColumn.suppressEdit = true; }
                                : undefined}
                            TouchRippleProps={{
                                className: this.props.classes.ripple,
                            }}
                        />
                    </ErrorTooltip>
                </ClickAwayListener>;
        }

        let className = `${this.props.classes.root} `;
        if (_props.isShort)
        {
            className += message
                ? this.props.classes.shortError : this.props.classes.short;
        }
        else
        {
            className += message
                ? this.props.classes.error : this.props.classes.normal;
        }

        delete _props.isShort;

        return (
            <muiBadge.default
                {..._props}
                badgeContent={content}
                classes={{ badge: this.props.classes.badge }}
                className={className}
            >
                {children}
            </muiBadge.default>);
    }
}

export default withStyles(styles)(ErrorBadge);
