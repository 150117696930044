import * as muiCheckbox from '@material-ui/core/Checkbox';
import { createStyles, Theme, WithStyles, withStyles, WithTheme, withTheme }
    from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import * as React from 'react';
import Sys from '../core/Sys';
import FormControl, { FormControlProps } from '../coreui/FormControl';
import FormControlLabel from '../coreui/FormControlLabel';
import Icon from '../coreui/Icon';
import InformationBadge from '../coreui/InformationBadge';
import { Paper as PaperBase } from '../coreui/Paper';
import Presentation from '../coreui/Presentation';
import Typography from '../coreui/Typography';
import RoundTripService from '../services/RoundTripService';
import Api, { AccessLevel } from './Api';

interface Props
{
    controlledPaneName: string | null;
    dataId: string;
    disabledHelpText: string;
    helperText: string;
    isPaneController: boolean;
    label: string;
    name: string;
    roundTripOnChange: boolean;
}

interface WidgetProperties
{
    accessLevel: AccessLevel;
    businessErrors: string[];
    roundTripOnChange: boolean;
    showDisabledHelp: boolean;
}

const styles = (theme: Theme) => createStyles(
    {
        ripple:
        {
            overflow: 'visible',
        },
        root:
        {
            '&:hover': { backgroundColor: 'transparent' },
            backgroundColor: 'transparent',
            display: 'inline-block',
        },
    });

@observer
export class Checkbox extends
    React.Component<Props & WithTheme & WithStyles<typeof styles>>
{
    protected checkboxProps: muiCheckbox.CheckboxProps;
    protected muiProps: FormControlProps;

    public constructor(props: Props & WithTheme & WithStyles<typeof styles>)
    {
        super(props);

        this.muiProps = {};

        this.muiProps.fullWidth = true;

        this.checkboxProps =
        {
            checkedIcon: <Icon icon="fas fa-check-square" />,
            color: 'default',
            icon: <Icon icon="far fa-square" />,
            TouchRippleProps: { className: props.classes.ripple },
        };
    }

    protected showSubPane()
    {
        const _props = { ...this.props };

        if (!_props.isPaneController)
        {
            return;
        }

        const rowKey =
            _props['propagated'] ? _props['propagated']['rowKey'] : null;
        let paneName = _props.controlledPaneName;

        if (rowKey)
        {
            paneName += `_${rowKey}`;
        }

        if (Presentation.getValue(_props, false))
        {
            PaperBase.show(paneName);
        }
        else
        {
            PaperBase.hide(paneName);
        }
    }

    public componentDidMount()
    {
        this.showSubPane();
    }

    public componentDidUpdate()
    {
        this.showSubPane();
    }

    public render()
    {
        const _props = { ...this.props };
        const widgetProperties =
            Api.getWidgetProperties(_props) as WidgetProperties;
        let result: React.ReactNode = null;

        if (!widgetProperties)
        {
            return null;
        }

        this.muiProps.className = `${_props.classes.root} `;

        if (Api.setAccessLevel(_props, widgetProperties))
        {
            if (_props['disabled'])
            {
                this.muiProps.disabled = true;
                this.muiProps.helperText = null;
                this.checkboxProps.checked = undefined;

                if (widgetProperties['showDisabledHelp'])
                {
                    result =
                        <InformationBadge
                            fullWidth={false}
                            message={_props.disabledHelpText}
                        >
                            <FormControl {...this.muiProps}>
                                <FormControlLabel
                                    control={
                                        <muiCheckbox.default
                                            {...this.checkboxProps}
                                        />}
                                    label={_props.label}
                                />
                            </FormControl>
                        </InformationBadge>;
                }
                else
                {
                    result =
                       <FormControl {...this.muiProps}>
                            <FormControlLabel
                                control={
                                    <muiCheckbox.default
                                        {...this.checkboxProps}
                                    />}
                                label={_props.label}
                            />
                        </FormControl>;
                }
            }
            else if (_props['readOnly'])
            {
                Presentation.setBinding(this, this.checkboxProps);

                result =
                    <div>
                        <Typography
                            component="div"
                            ellipsis
                            style={{ marginBottom: 8 }}
                            variant="caption"
                        >
                            {_props.label}
                        </Typography>
                        <Typography component="div">
                            {this.checkboxProps.checked ? 'Yes' : '-'}
                        </Typography>
                    </div>;
            }
            else
            {
                this.muiProps.disabled = false;

                Presentation.setBinding(this, this.checkboxProps);
                Api.setHelperText(
                    this,
                    widgetProperties['businessErrors'],
                    this.muiProps);

                if (_props.roundTripOnChange)
                {
                    this.checkboxProps.onChange = (
                        event: React.ChangeEvent<HTMLInputElement>,
                        checked: boolean) =>
                    {
                        Sys.clearBusinessErrors(
                            this.props.dataId, this.props.name);
                        Presentation.setValue(this.props, checked);

                        RoundTripService.standardRoundTrip(
                            'CheckBox/OnChange', this.props
                        ).catch(() =>
                        {
                            // If the round trip fails, undo the value change.
                            Presentation.setValue(this.props, !checked);
                        });
                    };
                }

                result =
                    <FormControl {...this.muiProps}>
                        <FormControlLabel
                            control={
                                <muiCheckbox.default
                                    {...this.checkboxProps}
                                />}
                            label={_props.label}
                        />
                    </FormControl>;
            }
        }

        return result;
    }
}

export default withStyles(styles)(withTheme(Checkbox));
