import * as React from 'react';

export interface WithForwardedRefProps
{
    forwardedRef?: (r: HTMLElement | null) => void;
}

const withForwardedRef =
    // eslint-disable-next-line arrow-parens
    <T extends WithForwardedRefProps>(Component: React.ComponentType<T>) =>
    {
        const handle = (props: T, ref: React.Ref<HTMLElement>) =>
            <Component {...props} forwardedRef={ref} />;

        const name = Component.displayName || Component.name;
        handle.displayName = `withForwardedRef(${name})`;

        return React.forwardRef(handle);
    };

export default withForwardedRef;
