import { createStyles, Theme, WithStyles, withStyles }
    from '@material-ui/core/styles';
import * as muiTabs from '@material-ui/core/Tabs';
import * as React from 'react';
import KeyboardNavigationGroup from './KeyboardNavigationGroup';
import TabScrollButton from './TabScrollButton';

interface Props extends muiTabs.TabsProps
{
}

const styles = (theme: Theme) => createStyles(
    {
        flexContainer:
        {
            outline: 'none',
        },
    });

export class Tabs extends
    React.PureComponent<Props & WithStyles<typeof styles>>
{
    private readonly rootRef = React.createRef<HTMLButtonElement>();

    public componentDidMount()
    {
        // Fix for Firefox focusing the tab scroller div & Safari showing it's
        // focus outline
        const tabScroller: HTMLElement = this.rootRef.current!.querySelector(
            '[role="tablist"]')!.parentElement!;
        tabScroller.tabIndex = -1;
        tabScroller.style.outline = 'none';
    }

    public render()
    {
        const { classes, ...other } = this.props;

        return (
            <KeyboardNavigationGroup
                childSelector="a,button"
                rootSelector={'[role="tablist"]'}
            >
                <muiTabs.default
                    classes={{ flexContainer: classes.flexContainer }}
                    ref={this.rootRef}
                    ScrollButtonComponent={TabScrollButton}
                    scrollButtons="auto"
                    variant="scrollable"
                    {...other}
                />
            </KeyboardNavigationGroup>);
    }
}

export default withStyles(styles)(Tabs);
