import { autorun, IReactionDisposer } from 'mobx';
import * as React from 'react';
import AppServer from '../../core/AppServer';
import RequestPromise from '../../core/RequestPromise';
import Sys from '../../core/Sys';
import TrackableCollection from '../../core/TrackableCollection';
import TrackableModel from '../../core/TrackableModel';
import BaseService from '../../services/BaseService';
import PaneDataStore, { PaneData, PaneDataByDataId }
    from '../../stores/PaneDataStore';
import ProcessingMask from '../ProcessingMask';

export interface GetDataResponse
{
    paneDataByDataId: PaneDataByDataId;
    paneStates: string[];
}

interface Props
{
    contentDataId: string;
    dataId: string;
    getData: () => RequestPromise<GetDataResponse>;
    populateData: () => void;
}

interface State
{
    isLoadingData?: boolean;
    isPopulatingData?: boolean;
}

export class AsyncData extends
    React.PureComponent<Props, State>
{
    private dataMonitorDisposer: IReactionDisposer;
    private initializeDataTimeout: number;
    private lastPopulatedContentPaneDate: Date | undefined = undefined;
    private retrieveDataPromise: RequestPromise<GetDataResponse>;

    public static async processResponse(
        promise: RequestPromise<GetDataResponse>
        ): Promise<boolean>
    {
        try
        {
            const response: GetDataResponse = await promise;

            PaneDataStore.loadResponse(response.paneDataByDataId);
            for (const paneState of response.paneStates)
            {
                AppServer.addPane(paneState);
            }

            Sys.setBusinessErrors();

            return false;
        }
        catch (request)
        {
            if (request?.config?.aborted)
            {
                return true;
            }

            const message: string | null =
                BaseService.getRequestExceptionMessage(request);
            if (message)
            {
                Sys.showErrors([message]);
            }

            throw request;
        }
    }

    public constructor(props: Props)
    {
        super(props);

        this.state =
        {
            isLoadingData: false,
            isPopulatingData: true,
        };
    }

    private dataMonitor = () =>
    {
        const contentPane: PaneData =
            PaneDataStore.instance.getPane(this.props.contentDataId)!;
        const parentPane: PaneData =
            PaneDataStore.instance.getPane(this.props.dataId)!;

        if (!contentPane.lastRetrieved || !parentPane.lastRetrieved)
        {
            this.setState({ isPopulatingData: false });
            return;
        }

        if (contentPane.lastRetrieved < parentPane.lastRetrieved)
        {
            if (contentPane.lastLoadingToMatchDate! < parentPane.lastRetrieved)
            {
                this.retrieveData(parentPane.lastRetrieved);
            }
            else
            {
                this.setState({ isPopulatingData: true });
            }

            return;
        }

        if (!this.lastPopulatedContentPaneDate
            || this.lastPopulatedContentPaneDate < contentPane.lastRetrieved)
        {
            this.populateData();
        }
    };

    private initializeData()
    {
        let contentPane: PaneData | undefined =
            PaneDataStore.instance.getPane(this.props.contentDataId);
        const parentPane: PaneData =
            PaneDataStore.instance.getPane(this.props.dataId)!;

        if (!contentPane)
        {
            if (!TrackableModel.models.has(this.props.contentDataId))
            {
                TrackableModel.register(new TrackableCollection(
                    'PaneRow', this.props.contentDataId));
            }
            contentPane = PaneDataStore.instance.addPane(
                this.props.contentDataId, parentPane.lastRetrieved!);

            this.retrieveData(parentPane.lastRetrieved!);
        }
        else if (contentPane.lastRetrieved && parentPane.lastRetrieved
            && contentPane.lastRetrieved >= parentPane.lastRetrieved)
        {
            this.populateData();
        }

        this.dataMonitorDisposer = autorun(this.dataMonitor);
    }

    private populateData(): void
    {
        const contentPane: PaneData =
            PaneDataStore.instance.getPane(this.props.contentDataId)!;

        this.lastPopulatedContentPaneDate = contentPane.lastRetrieved;

        this.setState({ isPopulatingData: false });

        this.props.populateData();
    }

    private async retrieveData(toMatchDate: Date): Promise<void>
    {
        if (this.retrieveDataPromise)
        {
            this.retrieveDataPromise.abort();
        }

        PaneDataStore.instance.setLoadingToMatchDate(
            this.props.contentDataId, toMatchDate);

        this.setState({ isLoadingData: true });

        this.retrieveDataPromise = this.props.getData();

        try
        {
            const wasAborted: boolean =
                await AsyncData.processResponse(this.retrieveDataPromise);

            if (wasAborted)
            {
                const contentPane: PaneData | undefined =
                    PaneDataStore.instance.getPane(this.props.contentDataId);
                if (contentPane)
                {
                    contentPane.lastRetrieved = new Date('0001-01-01T00:00:00Z');
                    contentPane.lastLoadingToMatchDate =
                        contentPane.lastRetrieved;
                }
            }
            else
            {
                this.setState({ isLoadingData: false });
            }
        }
        catch
        {
            this.setState({ isLoadingData: false, isPopulatingData: false });
        }
    }

    public componentDidMount()
    {
        // Separate the initial page load from the data table rows load by
        // waiting for the grid to render first
        this.initializeDataTimeout = setTimeout(() => this.initializeData());
    }

    public componentWillUnmount()
    {
        window.clearTimeout(this.initializeDataTimeout);

        if (this.dataMonitorDisposer)
        {
            this.dataMonitorDisposer();
        }

        if (this.retrieveDataPromise)
        {
            this.retrieveDataPromise.abort();
        }
    }

    public render()
    {
        return (
            <ProcessingMask
                isOpen={this.state.isLoadingData!
                    || this.state.isPopulatingData!}
            />
        );
    }
}

export default AsyncData;
