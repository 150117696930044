import { createStyles, Theme, WithStyles, withStyles }
    from '@material-ui/core/styles';
import { observable } from 'mobx';
import * as React from 'react';
import Sys from '../core/Sys';

interface Props
{
}

const styles = (theme: Theme) => createStyles(
    {
        root:
        {
        },
    });

export class CaptchaControl extends
    React.PureComponent<Props & WithStyles<typeof styles>>
{
    @observable
    public static responseToken: string = '';
    private isGuest: boolean = false;

    public static isValid()
    {
        return CaptchaControl.responseToken !== '';
    }

    public static reset()
    {
        CaptchaControl.responseToken = '';
        window['grecaptcha'].reset();
    }

    public constructor(props: Props & WithStyles<typeof styles>)
    {
        super(props);

        this.isGuest = Sys.getCookie(Sys.guestSessionTokenCookie) !== null;

        if (this.isGuest)
        {
            const languageCode: string = Sys.settings.currentLanguageCode;
            const reCaptcha: HTMLScriptElement = document.createElement('script');

            reCaptcha.setAttribute('type', 'text/javascript');
            reCaptcha.setAttribute(
                'src',
                `https://www.google.com/recaptcha/api.js?hl=${languageCode}`);

            document.head.appendChild(reCaptcha);
            window['mustangReCaptchaCallBack'] =
                (token: string) =>
                {
                    CaptchaControl.responseToken = token;
                };
            window['mustangReCaptchaExpiredCallBack'] =
                (token: string) =>
                {
                    CaptchaControl.responseToken = '';
                };
        }
    }

    public componentWillUnmount()
    {
        const scripts = document.head.querySelectorAll(
            'script[src*="recaptcha"]');
        scripts.forEach(script => script.remove());

        const iframes = document.querySelectorAll('iframe[src*="recaptcha"]');
        iframes.forEach(iframe => iframe.remove());
    }

    public render()
    {
        const siteKey: string = Sys.settings.reCaptchaSiteKey;
        let result: React.ReactNode = null;

        if (this.isGuest)
        {
            result =
                <div
                    className="g-recaptcha"
                    data-callback="mustangReCaptchaCallBack"
                    data-expired-callback="mustangReCaptchaExpiredCallBack"
                    data-sitekey={siteKey}
                />;
        }

        return result;
    }
}

export default withStyles(styles)(CaptchaControl);
