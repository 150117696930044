import * as muiBackdrop from '@material-ui/core/Backdrop';
import * as muiModal from '@material-ui/core/Modal';
import { createStyles, Theme, WithStyles, withStyles }
    from '@material-ui/core/styles';
import * as React from 'react';
import MaskingStore from '../stores/MaskingStore';
import CircularProgress from './CircularProgress';
import Fade from './Fade';
import Typography from './Typography';

interface Props
{
    disableBackground?: boolean;
    disableRestoreFocus?: boolean;
    isFixed?: boolean;
    isOpen: boolean;
    message?: string | null;
    shortFade?: boolean;
    style?: React.CSSProperties;
    trapFocus?: boolean;
}

interface State
{
    isOpen?: boolean;
}

const styles = (theme: Theme) => createStyles(
    {
        content:
        {
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'center',
            outline: 'none',
            textAlign: 'center',
        },
        root:
        {
            bottom: 0,
            left: 0,
            right: 0,
            top: 0,
            zIndex: 9999,
        },
        rootAbsolute:
        {
            position: 'absolute',
        },
        rootClickThrough:
        {
            pointerEvents: 'none',
        },
        rootFixed:
        {
            position: 'fixed',
        },
        text:
        {
            color: theme.palette.common.white,
            marginTop: 12,
        },
    });

export class ProcessingMask extends
    React.PureComponent<Props & WithStyles<typeof styles>, State>
{
    private closeTimeout: number;
    private isClosing: boolean = false;
    private readonly minMillisecondsOpen = 500;
    private openedOn: Date | null = null;
    private openTimeout: number;

    public constructor(props: Props & WithStyles<typeof styles>)
    {
        super(props);

        this.state = { isOpen: props.isOpen };
    }

    private onClose = () =>
    {
        if (this.props.trapFocus && this.openedOn !== null)
        {
            MaskingStore.maskClosed();
        }
    };

    private onOpen = () =>
    {
        if (this.props.trapFocus)
        {
            MaskingStore.maskOpened();
        }
        this.openedOn = new Date();
    };

    private restoreScrollPosition = () =>
    {
        if (!this.props.trapFocus)
        {
            return;
        }

        const position = document.documentElement!.scrollTop;
        // Setting a timeout to make sure to restore scroll position
        // after render finishes
        window.setTimeout(() => window.scrollTo(0, position));
    };

    public componentDidMount()
    {
        if (this.props.isOpen)
        {
            this.onOpen();
        }
    }

    public componentDidUpdate(prevProps: Props)
    {
        if (this.props.isOpen === prevProps.isOpen)
        {
            return;
        }

        if (this.isClosing)
        {
            window.clearTimeout(this.closeTimeout);
            this.isClosing = false;

            return;
        }

        if (this.props.isOpen)
        {
            this.openedOn = null;
            this.setState(
                { isOpen: true },
                () => this.openTimeout = window.setTimeout(this.onOpen));

            return;
        }

        if (this.openedOn === null)
        {
            window.clearTimeout(this.openTimeout);
            this.setState({ isOpen: false });

            return;
        }

        const millisecondsOpen: number =
            new Date().getTime() - this.openedOn.getTime();

        if (millisecondsOpen > this.minMillisecondsOpen)
        {
            this.setState({ isOpen: false }, this.onClose);
        }
        else
        {
            this.isClosing = true;
            this.closeTimeout = window.setTimeout(
                () =>
                {
                    this.setState({ isOpen: false }, this.onClose);
                    this.isClosing = false;
                },
                this.minMillisecondsOpen - millisecondsOpen);
        }
    }

    public componentWillUnmount()
    {
        window.clearTimeout(this.openTimeout);
        window.clearTimeout(this.closeTimeout);
        if (this.state.isOpen)
        {
            this.onClose();
        }
    }

    public render()
    {
        const classes: string[] = [this.props.classes.root];

        if (!this.state.isOpen)
        {
            classes.push(this.props.classes.rootClickThrough);
        }

        if (this.props.isFixed)
        {
            classes.push(this.props.classes.rootFixed);
        }
        else
        {
            classes.push(this.props.classes.rootAbsolute);
        }

        const content = (
            <div className={this.props.classes.content}>
                <CircularProgress />
                {this.props.message ? (
                    <Typography
                        className={this.props.classes.text}
                        variant="body1"
                    >
                        {this.props.message}
                    </Typography>
                ) : null}
            </div>
        );

        const transitionDuration =
        {
            enter: this.props.shortFade ? 0 : 500,
            exit: this.props.shortFade ? 500 : 1000,
        };

        if (!this.props.disableBackground)
        {
            return (
                <div
                    className={classes.join(' ')}
                    style={this.props.style}
                >
                    {
                        // VERSION_WARNING Material-UI 4.9.1
                        // Fade child is required to make returning focus work
                        // https://github.com/mui-org/material-ui/issues/19149
                    }
                    <muiModal.default
                        BackdropComponent={muiBackdrop.default}
                        BackdropProps={{
                            style: { position: 'absolute' },
                            transitionDuration,
                        }}
                        disableAutoFocus={!this.props.trapFocus}
                        disableEnforceFocus={!this.props.trapFocus}
                        disablePortal={true}
                        disableRestoreFocus={!this.props.trapFocus
                            || this.props.disableRestoreFocus}
                        disableScrollLock={!this.props.trapFocus}
                        open={this.state.isOpen!}
                        style={{ position: 'absolute' }}
                    >
                        <Fade
                            in={this.state.isOpen!}
                            timeout={transitionDuration}
                        >
                            {content}
                        </Fade>
                    </muiModal.default>
                </div>
            );
        }

        return (
            <Fade
                in={this.state.isOpen}
                onExited={this.restoreScrollPosition}
                timeout={transitionDuration}
                unmountOnExit
            >
                <div
                    className={classes.join(' ')}
                    style={this.props.style}
                >
                    {content}
                </div>
            </Fade>
        );
    }
}

export default withStyles(styles)(ProcessingMask);
