import { ButtonBase } from '@material-ui/core';
import { createStyles, Theme, WithStyles, withStyles }
    from '@material-ui/core/styles';
import * as React from 'react';
import Button from './Button';

interface Props
{
    alternateText: string;
    disableFocusRipple?: boolean;
    fit: 'cover' | 'contain';
    height: number;
    href: string;
    imgSrc: string;
    linkRef?: (r: HTMLElement | null) => void;
    tabIndex?: number;
    width: number;
}

const styles = (theme: Theme) => createStyles(
    {
        button:
        {
            bottom: 4,
            pointerEvents: 'none',
            position: 'absolute',
            right: 4,
        },
        image:
        {
            height: '100%',
            width: '100%',
        },
        ripple:
        {
            height: 'calc(100% + 30px)',
            margin: '-15px',
            width: 'calc(100% + 30px)',
        },
        root:
        {
            display: 'inline-block',
            outline: 'none',
            overflow: 'hidden',
            position: 'relative',
            [theme.breakpoints.only('xs')]:
            {
                maxWidth: '100%',
            },
        },
    });

export class ImageWithLink extends
    React.PureComponent<Props & WithStyles<typeof styles>>
{
    public render()
    {
        return (
            <ButtonBase
                aria-label={this.props.alternateText}
                buttonRef={
                    r => this.props.linkRef
                        ? this.props.linkRef(r as HTMLElement) : null
                }
                className={this.props.classes.root}
                focusRipple={!this.props.disableFocusRipple}
                href={this.props.href}
                style={{ height: this.props.height }}
                tabIndex={this.props.tabIndex}
                target="_blank"
                TouchRippleProps={{ className: this.props.classes.ripple }}
            >
                <img
                    className={this.props.classes.image}
                    src={this.props.imgSrc}
                    style={
                        {
                            maxWidth: '100%',
                            objectFit: this.props.fit,
                            width: this.props.width,
                        }}
                />
                <Button
                    className={this.props.classes.button}
                    customSize="tiny"
                    icon="fas fa-download"
                    tabIndex={-1}
                />
            </ButtonBase>);
    }
}

export default withStyles(styles)(ImageWithLink);
