import { GetQuickFilterTextParams, ICellRendererParams }
    from 'ag-grid-community';
import * as React from 'react';
import { TableChildProps } from '../../coreui/Table';
import { RuntimeWidget } from '../../models/PaneRow';
import { NumericEdit } from '../NumericEdit';
import { FunctionName } from '../TableSummary';
import TextColumn from './TextColumn';

interface ConfigProperties extends ICellRendererParams
{
    dataId: string;
    justification: 'Left' | 'Right';
    name: string;
    propagated: TableChildProps;
    scale: number | null;
}

export class NumericEditColumn extends
    React.PureComponent<ConfigProperties>
{
    public static getQuickFilterText(params: GetQuickFilterTextParams): string
    {
        if (!params.value)
        {
            return '';
        }

        const parsed = parseFloat(params.value);
        const scale = params.colDef.cellRendererParams.scale || null;
        const filter = NumericEdit.formatNumericValue(parsed, true, scale);

        return filter !== null ? filter : '';
    }

    public static getSummaryValue(
        runtimeData: RuntimeWidget[],
        configProperties: ConfigProperties,
        functionName: FunctionName
        ): string | null
    {
        let result = 0;
        let count = 0;
        let isFirst = true;
        for (const data of runtimeData)
        {
            if (data.value === null)
            {
                continue;
            }
            if (typeof (data.value) !== 'string')
            {
                throw new Error(`Unexpected data type ${typeof (data.value)}`);
            }

            const value: number = parseFloat(data.value);

            switch (functionName)
            {
                case 'Average':
                    result += value;
                    count += 1;
                    break;
                case 'Count':
                    count += 1;
                    break;
                case 'Maximum':
                    result = isFirst || value > result ? value : result;
                    isFirst = false;
                    break;
                case 'Minimum':
                    result = isFirst || value < result ? value : result;
                    isFirst = false;
                    break;
                case 'Sum':
                    result += value;
                    break;
                default:
                    throw new Error(`Unknown summary function ${functionName}`);
            }
        }

        if (functionName === 'Count')
        {
            return count.toString();
        }

        if (functionName === 'Average' && count > 0)
        {
            result = result / count;
        }

        return NumericEdit.formatNumericValue(
            result,
            true,
            configProperties.scale);
    }

    public render()
    {
        const _props = { ...this.props };

        _props.value = NumericEdit.formatNumericValue(
            _props.value ? parseFloat(_props.value) : null,
            true,
            _props.scale);

        return <TextColumn showEllipsis {..._props} />;
    }
}

export default NumericEditColumn;
