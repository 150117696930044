import * as React from 'react';
import Button from './Button';
import IconWithLink from './IconWithLink';
import ImageWithLink from './ImageWithLink';

interface Props
{
    alternateText: string;
    disableFocusRipple?: boolean;
    downloadUrl: string;
    hasImage: boolean;
    imageHeight: number;
    imageWidth: number;
    iconName: string;
    imgSrc: string;
    inGrid?: boolean;
    linkRef?: (r: HTMLElement | null) => void;
    tabIndex?: number;
    thumbnailType: ThumbnailTypes;
}

export type ThumbnailTypes =
    'Download Link Only'
    | 'Large Thumbnail'
    | 'Medium Thumbnail';

export class Thumbnail extends
    React.PureComponent<Props>
{
    private setLinkRef = (ref: HTMLLinkElement | null) =>
    {
        if (this.props.linkRef)
        {
            this.props.linkRef(ref);
        }
    };

    public render()
    {
        switch (this.props.thumbnailType)
        {
            case 'Download Link Only':
                const additionalDownloadLinkProps = {};
                if (this.props.inGrid)
                {
                    additionalDownloadLinkProps['customSize'] = 'tiny';
                }

                return (
                    <Button
                        aria-label={this.props.alternateText}
                        buttonRef={this.setLinkRef}
                        disableFocusRipple={this.props.disableFocusRipple}
                        href={this.props.downloadUrl}
                        icon="fas fa-download"
                        size="small"
                        style={{ height: 'auto' }}
                        tabIndex={this.props.tabIndex}
                        target="_blank"
                        {...additionalDownloadLinkProps}
                    />
                );

            case 'Large Thumbnail':
                if (this.props.hasImage)
                {
                    return (
                        <ImageWithLink
                            alternateText={this.props.alternateText}
                            disableFocusRipple={this.props.disableFocusRipple}
                            fit="cover"
                            height={this.props.imageHeight}
                            href={this.props.downloadUrl}
                            imgSrc={this.props.imgSrc}
                            linkRef={this.setLinkRef}
                            tabIndex={this.props.tabIndex}
                            width={this.props.imageWidth}
                        />
                    );
                }

                if (!this.props.iconName)
                {
                    return null;
                }

                return (
                    <IconWithLink
                        alternateText={this.props.alternateText}
                        disableFocusRipple={this.props.disableFocusRipple}
                        height={this.props.imageHeight}
                        href={this.props.downloadUrl}
                        icon={this.props.iconName}
                        linkRef={this.setLinkRef}
                        tabIndex={this.props.tabIndex}
                        width={this.props.imageWidth}
                    />
                );
            case 'Medium Thumbnail':
                if (this.props.hasImage)
                {
                    return (
                        <ImageWithLink
                            alternateText={this.props.alternateText}
                            disableFocusRipple={this.props.disableFocusRipple}
                            fit="cover"
                            height={this.props.imageHeight}
                            href={this.props.downloadUrl}
                            imgSrc={this.props.imgSrc}
                            linkRef={this.setLinkRef}
                            tabIndex={this.props.tabIndex}
                            width={this.props.imageWidth}
                        />
                    );
                }

                if (!this.props.iconName)
                {
                    return null;
                }

                return (
                    <IconWithLink
                        alternateText={this.props.alternateText}
                        disableFocusRipple={this.props.disableFocusRipple}
                        height={this.props.imageHeight}
                        href={this.props.downloadUrl}
                        icon={this.props.iconName}
                        linkRef={this.setLinkRef}
                        tabIndex={this.props.tabIndex}
                        width={this.props.imageWidth}
                    />
                );
            default:
                throw new Error(
                    `Unknown thumbnail type ${this.props.thumbnailType}`);
        }
    }
}

export default Thumbnail;
