import Logging from '../core/Logging';

export default class CustomIconService
{
    public static async getIcon(name: string): Promise<string | null>
    {
        let response: Response | null = null;
        try
        {
            response = await fetch(`CustomIcons/${name}`);
        }
        catch (error)
        {
            Logging.error(error);
        }

        if (response === null || response.status === 404)
        {
            return null;
        }

        return await response.text();
    }
}
