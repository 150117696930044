import { observer } from 'mobx-react';
import * as React from 'react';
import FormData from '../coreui/FormData';
import Icon from '../coreui/Icon';
import Api, { AccessLevel } from './Api';

interface ConfigProperties
{
    dataId: string;
    header: string;
    name: string;
}

interface RuntimeProperties
{
    accessLevel: AccessLevel;
    iconName: string;
    label: string;
}

@observer
export class ComponentTypeDisplay extends
    React.Component<ConfigProperties>
{
    public render()
    {
        const runtimeProperties =
            Api.getWidgetProperties(this.props) as RuntimeProperties;

        if (!runtimeProperties)
        {
            return null;
        }

        if (runtimeProperties.accessLevel === AccessLevel.hidden)
        {
            return null;
        }

        return (
            <FormData label={this.props.header}>
                <div style={{ alignItems: 'center', display: 'flex' }}>
                    <Icon
                        fixedWidth
                        icon={runtimeProperties.iconName}
                        style={
                            {
                                fontSize: 16,
                                height: 'auto',
                                marginRight: '.4em',
                            }}
                    />
                    <div style={{ lineHeight: 'normal' }}>
                        {runtimeProperties.label}
                    </div>
                </div>
            </FormData>
        );
    }
}

export default (ComponentTypeDisplay);
