import { createStyles, Theme, WithStyles, withStyles }
    from '@material-ui/core/styles';
import withWidth, { WithWidth } from '@material-ui/core/withWidth';
import { observer } from 'mobx-react';
import * as React from 'react';
import Icon, { IconProps } from '../coreui/Icon';
import Api, { AccessLevel } from './Api';

interface Size
{
    lg: number | undefined;
    md: number | undefined;
    sm: number | undefined;
    xl: number | undefined;
    xs: number | undefined;
}

interface ConfigProperties
{
    alternateText: string;
    dataId: string;
    imageHeight: Size;
    imageUrl: string;
    imageWidth: Size;
    name: string;
}

interface RuntimeProperties
{
    accessLevel: AccessLevel;
}

const styles = (theme: Theme) => createStyles(
    {
        root:
        {
            display: 'block',
            maxWidth: '100%',
        },
    });

@observer
export class BrandingImageDisplay extends
    React.Component<ConfigProperties & WithStyles<typeof styles> & WithWidth>
{
    public render()
    {
        const runtimeProperties =
            Api.getWidgetProperties(this.props) as RuntimeProperties;

        if (!runtimeProperties)
        {
            return null;
        }

        if (runtimeProperties.accessLevel === AccessLevel.hidden)
        {
            return null;
        }

        const height = this.props.imageHeight[this.props.width];
        const width = this.props.imageWidth[this.props.width];

        if (!height && !width)
        {
            return null;
        }

        return (
            <img
                aria-hidden={!this.props.alternateText || undefined}
                aria-label={this.props.alternateText || undefined}
                className={this.props.classes.root}
                src={this.props.imageUrl}
                style={{ height, width }}
            />
        );
    }
}

export default withStyles(styles)(withWidth()(BrandingImageDisplay));
