import * as muiRadio from '@material-ui/core/Radio';
import { createStyles, Theme, WithStyles, withStyles, WithTheme, withTheme }
    from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import * as React from 'react';
import Sys from '../core/Sys';
import FormControlLabel from '../coreui/FormControlLabel';
import Icon from '../coreui/Icon';
import { Paper as PaperBase } from '../coreui/Paper';
import Presentation from '../coreui/Presentation';
import Typography from '../coreui/Typography';
import RoundTripService from '../services/RoundTripService';
import Api, { AccessLevel } from './Api';

interface Props
{
    controlledGroupName: string;
    controlledPaneKeysByValue: object | null;
    dataId: string;
    isPaneController: boolean;
    label: string;
    margin: boolean;
    name: string;
    roundTripOnChange: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any;
}

interface WidgetProperties
{
    accessLevel: AccessLevel;
}

const styles = (theme: Theme) => createStyles(
    {
        icon:
        {
            // Makes the icon square
            marginRight: -1.1,
        },
        ripple:
        {
            overflow: 'visible',
        },
        root:
        {
        },
    });

@observer
export class ApiRadio extends
    React.Component<Props & WithTheme & WithStyles<typeof styles>>
{
    protected muiProps: muiRadio.RadioProps;

    public constructor(props: Props & WithTheme & WithStyles<typeof styles>)
    {
        super(props);

        this.muiProps =
        {
            checkedIcon:
                <Icon
                    icon="far fa-dot-circle"
                    className={props.classes.icon}
                />,
            color: 'default',
            icon:
                <Icon
                    icon="far fa-circle"
                    className={props.classes.icon}
                />,
            TouchRippleProps: { className: props.classes.ripple },
        };
    }

    private setBinding()
    {
        this.muiProps.onChange = () =>
        {
            Sys.clearBusinessErrors(this.props.dataId, this.props.name);
            Presentation.setValue(this.props, this.props.value);
        };

        this.muiProps.checked = (
            Presentation.getValue(this.props) ===
            (this.props.value || ''));

        this.muiProps.value = Presentation.getValue(this.props);
    }

    protected showSubPane()
    {
        const _props = { ...this.props };

        if (!_props.isPaneController)
        {
            return;
        }

        const value = Presentation.getValue(_props);
        const controlledPaneKey = _props.controlledPaneKeysByValue![value];

        const rowKey =
            _props['propagated'] ? _props['propagated']['rowKey'] : null;

        if (controlledPaneKey)
        {
            let paneKey = controlledPaneKey;
            if (rowKey)
            {
                paneKey += `_${rowKey}`;
            }

            PaperBase.show(paneKey);
        }
        else
        {
            let groupName = _props.controlledGroupName;
            if (rowKey)
            {
                groupName += `_${rowKey}`;
            }

            PaperBase.hideGroup(groupName);
        }
    }

    public componentDidMount()
    {
        this.showSubPane();
    }

    public componentDidUpdate()
    {
        this.showSubPane();
    }

    public render()
    {
        const _props = { ...this.props };
        const widgetProperties =
            Api.getWidgetProperties(_props) as WidgetProperties;

        if (!widgetProperties)
        {
            return null;
        }

        if (!Api.setAccessLevel(_props, widgetProperties))
        {
            return null;
        }

        this.muiProps.className = `${_props.classes.root} `;

        let style: React.CSSProperties = _props.margin ? { marginRight: 24 } : {};

        if (_props['disabled'])
        {
            this.muiProps.disabled = true;
            this.muiProps.checked = undefined;
            this.muiProps.value = '';

            return (
                <FormControlLabel
                    control={<muiRadio.default {...this.muiProps} />}
                    label={_props.label}
                    style={style}
                />);
        }

        if (_props['readOnly'])
        {
            this.setBinding();

            style =
            {
                ...style,
                display: 'inline-flex',
                fontSize: 24,
                lineHeight: '24px',
                marginBottom: 8,
                marginTop: 8,
            };

            return (
                <Typography
                    style={style}
                    component="div"
                >
                    <Icon
                        fixedWidth
                        icon={this.muiProps.checked ? 'fas fa-circle' :
                            'far fa-circle'}
                        style={
                            this.muiProps.checked ?
                                {
                                    fontSize: 12,
                                    marginRight: 14,
                                } :
                                {
                                    color: _props.theme.palette.grey[300],
                                    marginRight: 8,
                                }}
                    />
                    <Typography
                        ellipsis
                        style={{ fontSize: 14, lineHeight: '24px' }}
                    >
                        {_props.label}
                    </Typography>
                </Typography>);
        }

        this.muiProps.disabled = false;
        this.setBinding();

        if (_props.roundTripOnChange)
        {
            this.muiProps.onChange = () =>
            {
                const value = Presentation.getValue(this.props);

                Sys.clearBusinessErrors(this.props.dataId, this.props.name);
                Presentation.setValue(this.props, this.props.value);

                RoundTripService.standardRoundTrip(
                    'RadioSelect/OnChange', this.props
                ).catch(() =>
                {
                    // If the round trip fails, undo the value change.
                    Presentation.setValue(this.props, value);
                });
            };
        }

        return (
            <FormControlLabel
                control={<muiRadio.default {...this.muiProps} />}
                label={_props.label}
                style={style}
            />);
    }
}

export default withStyles(styles)(withTheme(ApiRadio));
