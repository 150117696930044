import { createStyles, Theme, WithStyles, withStyles }
    from '@material-ui/core/styles';
import withWidth, { WithWidth } from '@material-ui/core/withWidth';
import { observer } from 'mobx-react';
import * as React from 'react';
import { State as AppServerState } from '../core/AppServer';
import Sys from '../core/Sys';
import TrackableModel from '../core/TrackableModel';
import ComboBoxOption from '../coreui/ComboBoxOption';
import Presentation from '../coreui/Presentation';
import Table, { TableChildProps, TableProps } from '../coreui/Table';
import AsyncData, { GetDataResponse } from '../coreui/table/AsyncData';
import DocumentDropArea from '../coreui/table/DocumentDropArea';
import PaneRow from '../models/PaneRow';
import PresentationService from '../services/PresentationService';
import RoundTripService from '../services/RoundTripService';
import DocumentStore,
{ CompleteDocumentUploadResponse, CreateDocumentRowsResponse }
    from '../stores/DocumentStore';
import PaneDataStore, { PaneDataByDataId } from '../stores/PaneDataStore';
import Api, { AccessLevel } from './Api';
import GridColumn, { GridColumnConfigProperties } from './Columns/GridColumn';
import TableEditDialog, { AcceptResponse } from './Columns/TableEditDialog';
import ErrorBoundary from './ErrorBoundary';
import { GridRelatedEditButton } from './GridRelatedEditButton';

interface ConfigProperties extends WithWidth
{
    cardDepth: number;
    columns: GridColumnConfigProperties[];
    contentDataId: string;
    data?: object;
    dataId: string;
    dialogLayoutId?: number;
    footerToolbar?: object;
    headerToolbar?: object;
    isDocumentGrid: boolean;
    maxFileBytes: number | null;
    maxFileBytesError: string;
    name: string;
    rowSelection: boolean;
    selectionToolbar?: object;
    summaryToolbar?: object;
    tableKey: string;
    validExtensions: string[];
    validExtensionsError: string;
    verticalLayout?: object;
}

interface DialogCloseResponse extends AcceptResponse
{
}

interface DialogOpenResponse
{
    appServerState: AppServerState;
    paneDataByDataId: PaneDataByDataId;
    validationErrors: string[];
}

interface RuntimeProperties
{
    accessLevel: AccessLevel;
    relationshipComboBoxOptions: { name: string; options: ComboBoxOption[] }[];
}

interface State
{
    editDialogInfo?: { isFirstOpenOfNewRow: boolean; rowKey: string };
}

const styles = (theme: Theme) => createStyles(
    {
    });

@observer
export class SimpleGridControl extends
    React.Component<
        ConfigProperties & WithWidth & WithStyles<typeof styles>, State>
{
    private documentStore: DocumentStore;
    private populate: ((rows: TrackableModel[]) => void) | null = null;
    private propagated: TableChildProps;
    private restoreLostFocus: (() => void) | null;
    private scrollToRow: ((rowKey: string) => void) | null;
    private tableProps: TableProps;

    public constructor(
        props: ConfigProperties & WithWidth & WithStyles<typeof styles>)
    {
        super(props);

        this.state = {};

        this.propagated = {
            parentTable:
            {
                cardDepth: props.cardDepth,
                columns: props.columns,
                configProps:
                {
                    contentDataId: props.contentDataId,
                    data: props.data,
                    dataId: props.dataId,
                    name: props.name,
                },
                getRelationshipComboBoxOptions:
                    this.getRelationshipComboBoxOptions,
                hasRelatedEditDialog: props.dialogLayoutId ? true : false,
                isDocumentGrid: props.isDocumentGrid,
                openRowEditDialog: (r, i) => this.openRowEditDialog(r, i),
                populateData: () => this.populateData(),
                uploadFiles: this.onUploadFiles,
                validExtensions: props.validExtensions,
            },
        } as TableChildProps;

        this.tableProps = {
            cardDepth: props.cardDepth,
            cellEdit: true,
            columns: [],
            contentDataId: props.contentDataId,
            dataId: props.dataId,
            footerToolbarChild: props.footerToolbar,
            headerToolbarChild: props.headerToolbar,
            isColumnFlex: (colId: string) =>
                GridColumn.isColumnFlex(props.columns, colId),
            isColumnVisible: (colId: string, breakpoint: string) =>
                GridColumn.isColumnVisible(
                    props.columns, colId, breakpoint),
            isDocumentGrid: props.isDocumentGrid!,
            minRowHeight:
                GridColumn.getColumnsMinRowHeight(props.columns),
            name: props.name,
            propagated: this.propagated,
            rowSelection: props.rowSelection ? 'multiple' : undefined,
            selectToolbarChild: props.selectionToolbar,
            setPopulate: populate => this.populate = populate,
            setRestoreLostFocus:
                restoreFocus => this.restoreLostFocus = restoreFocus,
            setScrollToRow: scrollToRow => this.scrollToRow = scrollToRow,
            summaryToolbarChild: props.summaryToolbar,
        };

        this.documentStore = new DocumentStore(
            props.contentDataId,
            this.completeUpload,
            this.createRows,
            this.populateData
        );
    }

    private completeUpload = (uploadedFiles: object[]) =>
    {
        return RoundTripService.customRoundTrip<CompleteDocumentUploadResponse>(
            `SimpleGridControl/CompleteDocumentUpload/${this.getRowKey()}`
                + `/${this.props.dataId}/${this.props.name}`,
            undefined,
            { uploadedFiles }
        );
    };

    private createRows = (fileInfo: object[]) =>
    {
        return RoundTripService.customRoundTrip<CreateDocumentRowsResponse>(
            `SimpleGridControl/CreateDocumentRows/${this.getRowKey()}/`
                + `${this.props.dataId}/${this.props.name}`,
            undefined,
            { fileInfo }
        );
    };

    private getData = () =>
    {
        return RoundTripService.partialDataRetrevial<GetDataResponse>(
            `SimpleGridControl/GetRowsData/${this.getRowKey()}`
                + `/${this.props.dataId}/${this.props.name}`
        );
    };

    private getRelationshipComboBoxOptions = (
        widgetName: string,
        selectedOption: ComboBoxOption
        ): ComboBoxOption[] =>
    {
        const row = PaneRow.get(this.props.dataId);
        if (!row)
        {
            return [];
        }

        const widget = row.getWidget(this.props.name);
        const runtimeProps = widget.properties as RuntimeProperties;

        const options =
            [
                ...runtimeProps.relationshipComboBoxOptions.find(
                    o => o.name === widgetName)!.options,
            ];

        if (selectedOption.value)
        {
            const optionInList = options.find(
                o => o.value === selectedOption.value);

            if (optionInList === undefined)
            {
                const historicOption = { ...selectedOption };
                historicOption.historic = true;
                options.push(historicOption);
            }
        }

        return options;
    };

    private getRowKey(): string
    {
        const observable = Presentation.getObservable(this.props)! as PaneRow;
        return observable.rowKey;
    }

    private onDialogAccept = (parentRowKey: string) =>
    {
        return RoundTripService.customRoundTrip<DialogCloseResponse>(
            `SimpleGridControl/OnDialogClose/${this.getRowKey()}`
                + `/${this.props.dataId}/${this.props.name}`,
            undefined,
            { dialogRowKey: parentRowKey }
        );
    };

    private onDialogClose = (accepted: boolean) =>
    {
        if (accepted && this.state.editDialogInfo!.isFirstOpenOfNewRow)
        {
            this.scrollToRow!(this.state.editDialogInfo!.rowKey);
        }

        this.setState({ editDialogInfo: undefined });
    };

    private onDialogOpen = (parentRowKey: string) =>
    {
        const layoutId = this.props.dialogLayoutId!.toString();

        const configRequest = PresentationService.getConfigForDialog(layoutId);

        const dialogOpenRequest =
            RoundTripService.customRoundTrip<DialogOpenResponse>(
                `SimpleGridControl/OnDialogOpen/${this.getRowKey()}`
                + `/${this.props.dataId}/${this.props.name}`,
                undefined,
                { dialogRowKey: parentRowKey }
            );

        return Promise.all(
            [dialogOpenRequest, configRequest]
        ).then(([dialogOpenResponse, configResponse]) =>
        {
            return { ...dialogOpenResponse, ...configResponse, layoutId };
        });
    };

    private onUploadFiles = async (files: FileList): Promise<void> =>
    {
        const validFiles: File[] = [];

        for (let i = 0; i < files.length; i++)
        {
            const file: File = files[i];
            const fullFileName: string = file.name;
            const index: number = fullFileName.lastIndexOf('.');
            const extension: string =
                index > 0 ? fullFileName.substring(index + 1) : '';

            const errors: string[] = [];

            if (this.props.validExtensions?.indexOf(
                extension.toLowerCase()) < 0)
            {
                errors.push(this.props.validExtensionsError.replace(
                    '{extension}', extension));
            }

            if (this.props.maxFileBytes !== null
                && file.size > this.props.maxFileBytes)
            {
                errors.push(this.props.maxFileBytesError);
            }

            if (errors.length === 0)
            {
                validFiles.push(file);
            }
            else
            {
                Sys.showErrors(errors.map(error => `${file.name} - ${error}`));
            }
        }

        if (validFiles.length > 0)
        {
            await this.documentStore.uploadFiles(
                this.props.dataId,
                this.props.name,
                validFiles);
        }
    };

    private openRowEditDialog(rowKey: string, isFirstOpenOfNewRow: boolean)
    {
        this.setState({ editDialogInfo: { isFirstOpenOfNewRow, rowKey } });
    }

    private populateData = () =>
    {
        const rows: PaneRow[] =
            PaneDataStore.getPaneCollection(this.props.contentDataId);

        if (this.populate !== null)
        {
            this.populate(rows);
        }
    };

    public componentDidMount()
    {
        for (const column of this.props.columns)
        {
            this.tableProps.columns.push(GridColumn.getColumnDef(
                column,
                this.props.columns,
                this.propagated));
        }

        if (this.props.dialogLayoutId)
        {
            this.tableProps.columns.push(GridRelatedEditButton.createColDef(
                this.propagated));
        }
    }

    public componentDidUpdate(prevProps: ConfigProperties)
    {
        if (prevProps.width !== this.props.width)
        {
            setTimeout(() => this.populateData());
        }
    }

    public render()
    {
        const runtimeProperties =
            Api.getWidgetProperties(this.props) as RuntimeProperties;

        if (!runtimeProperties)
        {
            return null;
        }

        if (runtimeProperties.accessLevel === AccessLevel.hidden)
        {
            return null;
        }

        this.tableProps.hideEmptyDocument = this.props.isDocumentGrid
            && runtimeProperties.accessLevel >= AccessLevel.enterable;

        const dropProps =
        {
            dataId: this.props.contentDataId,
            enabled: this.tableProps.hideEmptyDocument,
            files: this.documentStore.documents,
            uploadFiles: this.onUploadFiles,
        };

        return (
            <ErrorBoundary title={this.props.name}>
                <div style={{ position: 'relative' }}>
                    <AsyncData
                        contentDataId={this.props.contentDataId}
                        dataId={this.props.dataId}
                        getData={this.getData}
                        populateData={this.populateData}
                    />
                    <Table
                        {...this.tableProps}
                        dropAreaChild={
                            <DocumentDropArea
                                renderProgressDialog={
                                    this.props.verticalLayout ? true : false}
                                {...dropProps}
                            />
                        }
                        fullWidthChild={this.props.verticalLayout}
                        tableKey={this.props.tableKey}
                        uploadFiles={this.documentStore.documents}
                    />
                    <TableEditDialog
                        contentDataId={this.props.contentDataId}
                        dataId={this.props.dataId}
                        isFirstOpenOfNewRow={this.state.editDialogInfo
                            ? this.state.editDialogInfo.isFirstOpenOfNewRow
                            : false}
                        name={this.props.name}
                        onAccept={this.onDialogAccept}
                        onClose={this.onDialogClose}
                        onDeleteRow={() => this.populateData()}
                        onExited={() => this.restoreLostFocus!()}
                        onOpen={this.onDialogOpen}
                        parentRowKey={this.state.editDialogInfo
                            ? this.state.editDialogInfo.rowKey : undefined}
                    />
                </div>
            </ErrorBoundary>);
    }
}

export default withStyles(styles)(withWidth()(SimpleGridControl));
