export default class SubscribeEvent
{
    callbacks: (() => void)[] = [];

    public addEventListener(callback: () => void)
    {
        this.callbacks.push(callback);
    }

    public dispatchEvent()
    {
        this.callbacks.forEach((callback) =>
        {
            callback();
        });
    }

    public removeEventListener(callback: () => void)
    {
        this.callbacks.splice(this.callbacks.indexOf(callback), 1);
    }
}
