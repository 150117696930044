import * as muiFormControl from '@material-ui/core/FormControl';
import { createStyles, Theme, WithStyles, withStyles }
    from '@material-ui/core/styles';
import * as React from 'react';
import FormHelperText from './FormHelperText';

export interface FormControlProps extends muiFormControl.FormControlProps
{
    FormHelperTextProps?: object;
    helperText?: React.ReactNode;
}

const styles = (theme: Theme) => createStyles(
    {
        root:
        {
        },
    });

export class FormControl extends
    React.PureComponent<FormControlProps & WithStyles<typeof styles>>
{
    public render()
    {
        const _props = { ...this.props };
        const formHelperTextProps = _props.FormHelperTextProps;

        if ('FormHelperTextProps' in _props)
        {
            delete _props.FormHelperTextProps;
        }

        if ('helperText' in _props)
        {
            if (_props.helperText)
            {
                _props.children =
                    <React.Fragment>
                        {_props.children}
                        <FormHelperText
                            {...formHelperTextProps}
                        >
                            {_props.helperText}
                        </FormHelperText>
                    </React.Fragment>;
            }

            delete _props.helperText;
        }

        return <muiFormControl.default {..._props} />;
    }
}

export default withStyles(styles)(FormControl);
