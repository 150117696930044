import AppServer, { State as AppServerState } from '../core/AppServer';
import RequestPromise from '../core/RequestPromise';
import Sys, { BusinessError } from '../core/Sys';
import BaseService from './BaseService';

interface ForIdExternalResponse
{
    appServerState: AppServerState;
    businessErrors: BusinessError[];
    newObjectHandle: string;
    validationErrors: string[];
}

interface ForIdInternalResponse
{
    appServerState: AppServerState;
    businessErrors: BusinessError[];
    newObjectHandle: string;
    validationErrors: string[];
}

interface ForInContextEditNameResponse
{
    appServerState: AppServerState;
    businessErrors: BusinessError[];
    endPointId: number;
    layoutId: number;
    objectDefId: number;
    objectHandle: string;
    validationErrors: string[];
}

interface ForJobProcessResponse {
    appServerState: AppServerState;
    businessErrors: BusinessError[];
    newObjectDefId: number;
    newObjectHandle: string;
    presentationId: number;
    validationErrors: string[];
}

export interface ForNameResponse
{
    appServerState: AppServerState;
    businessErrors: BusinessError[];
    endPointId: number | null;
    newObjectDefId: number;
    newObjectHandle: string;
    presentationId: number;
    validationErrors: string[];
}

export default class NewObjectService
{
    public static forIdExternal(
        newObjectDefId: number,
        presentationId: number,
        fromObjectId: number | null,
        endPointId: number | null,
        parameters: string | null
        ): RequestPromise<ForIdExternalResponse>
    {
        return BaseService.requestObject(
            `NewObject/ForIdExternal/${newObjectDefId}/${presentationId}`,
            null,
            null,
            {
                endPointId,
                fromObjectId,
                parameters,
            },
            'POST');
    }

    public static forIdInternal(
        fromPaneUseKey: string,
        fromRowKey: string,
        fromWidgetId: number,
        newObjectHandle: string
        ): RequestPromise<ForIdInternalResponse>
    {
        return BaseService.requestObject(
            'NewObject/ForIdInternal',
            null,
            null,
            {
                appServerState: AppServer.getState(),
                fromPaneUseKey,
                fromRowKey,
                fromWidgetId,
                newObjectHandle,
            },
            'POST');
    }

    public static forJobProcessById(
        processTypeId: number,
        presentationId: number,
        jobId: number,
        parameters: string | null
    ): RequestPromise<ForJobProcessResponse>
    {
        return BaseService.requestObject(
            `NewObject/ForJobProcessById/${processTypeId}/${presentationId}`,
            null,
            null,
            {
                jobId,
                parameters,
            },
            'POST');
    }

    public static forJobProcessByName(
        processTypeName: string,
        presentationName: string,
        jobId: number,
        parameters: string | null
        ): RequestPromise<ForJobProcessResponse>
    {
        return BaseService.requestObject(
            `NewObject/ForJobProcessByName/${processTypeName}/${presentationName}`,
            null,
            null,
            {
                jobId,
                parameters,
            },
            'POST');
    }

    public static forName(
        objectDefName: string,
        presentationName: string,
        fromObjectId: number | null,
        endPointName: string | null,
        parameters: string | null
        ): RequestPromise<ForNameResponse>
    {
        return BaseService.requestObject(
            `NewObject/ForName/${objectDefName}/${presentationName}`,
            null,
            null,
            {
                endPointName,
                fromObjectId,
                parameters,
            },
            'POST');
    }

    public static navigateToNewObject(newObjectId: string, layoutId: string)
    {
        Sys.ignoreChanges = true;
        const url = `/object/${newObjectId}/${layoutId}`;

        // Don't refresh the page so state like current wizard step is preserved
        Sys.setHash(url, false, false, true);
    }
}
