import { createStyles, Theme, WithStyles, withStyles, WithTheme, withTheme }
    from '@material-ui/core/styles';
import * as React from 'react';
import Sys from '../core/Sys';
import Button from '../coreui/Button';
import Icon from '../coreui/Icon';
import Menu, { Menu as MenuBase } from '../coreui/Menu';
import MenuItem from '../coreui/MenuItem';
import { TableChildProps } from '../coreui/Table';
import { GridColumnConfigProperties } from './Columns/GridColumn';

interface Props
{
    dataId: string;
    name: string;
    propagated: TableChildProps;
}

interface State
{
    anchorElement: HTMLElement | null;
    sortModel: { colId: string; sort: string };
}

const styles = (theme: Theme) => createStyles(
    {
        root:
        {
        },
    });

export class TableSort extends
    React.PureComponent<Props & WithTheme & WithStyles<typeof styles>, State>
{
    private readonly sortColumns: GridColumnConfigProperties[];

    public constructor(props: Props & WithTheme & WithStyles<typeof styles>)
    {
        super(props);

        this.state =
        {
            anchorElement: null,
            sortModel: { colId: '', sort: '' },
        };

        this.sortColumns = [];
        for (const column of this.props.propagated.parentTable.columns)
        {
            const gridColumn = column as GridColumnConfigProperties;
            if (gridColumn.xs)
            {
                this.sortColumns.push(gridColumn);
            }
        }
    }

    private openMenu(event: React.MouseEvent<HTMLElement>, menuName: string)
    {
        const anchorElement = event.currentTarget;

        // The anchorElement must be applied before open
        // is called so we use a callback.
        this.setState(
            { anchorElement },
            () => { MenuBase.open(menuName, anchorElement); }
        );
    }

    private sort(colId: string, direction: string)
    {
        const sortModel = { colId, sort: direction };

        this.props.propagated.parentTable.getApi().setSortModel([sortModel]);
        this.setState({ sortModel });
        this.props.propagated.parentTable.updateRowHeight();

        MenuBase.closeAll();
    }

    public render(): React.ReactNode
    {
        const menuItems: React.ReactNode[] = [];
        for (const column of this.sortColumns)
        {
            const sortModel = this.state.sortModel;
            const isSelected = sortModel.colId === column.name;

            let direction = column.sortDescending ? 'desc' : 'asc';
            let iconColor = this.props.theme.palette.grey[200];
            let icon = 'fas fa-sort';

            if (isSelected)
            {
                direction = sortModel.sort === 'asc' ? 'desc' : 'asc';
                iconColor = this.props.theme.palette.grey[800];
                icon = sortModel.sort === 'asc'
                    ? 'fas fa-sort-up'
                    : 'fas fa-sort-down';
            }

            menuItems.push(
                <MenuItem
                    key={column.name}
                    onClick={() => this.sort(column.name, direction)}
                    selected={false}
                >
                    <Icon
                        fixedWidth
                        icon={icon}
                        style={
                            {
                                color: iconColor,
                                marginRight: '.4em',
                            }}
                    />
                    {column.header}
                </MenuItem>
            );
        }

        const menuName =
            `${this.props.propagated.parentTable.configProps.tableKey}`
                + `.${this.props.name}`;

        return (
            <React.Fragment>
                <Button
                    aria-label={Sys.getTranslation('Sort table', 'DataTable')}
                    icon="fas fa-sort"
                    size="small"
                    onClick={e => this.openMenu(e, menuName)}
                    tabIndex={-1}
                />
                <Menu
                    anchorEl={this.state.anchorElement}
                    name={menuName}
                    open={false}
                    children={menuItems}
                />
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(withTheme(TableSort));
