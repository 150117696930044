import { createStyles, WithStyles, withStyles, WithTheme, withTheme }
    from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import * as React from 'react';
import { TableChildProps } from '../coreui/Table';
import PaneRow from '../models/PaneRow';
import { CustomTheme } from '../muiTheme';
import { AccessLevel } from '../mustangui/Api';

interface Props
{
    childLayoutWidgetName: string;
    children: React.ReactNode;
    childWidgetName: string;
    dataId: string;
    lg: boolean;
    md: boolean;
    propagated: TableChildProps;
    sm: boolean;
    toolbarType: 'header' | 'footer' | 'selection' | 'summary';
    xl: boolean;
    xs: boolean;
}

interface WidgetProperties
{
    accessLevel?: AccessLevel;
}

const styles = (theme: CustomTheme) =>
{
    const visibilityStyles = {};
    for (const breakPoint of theme.visibilityBreakPoints)
    {
        visibilityStyles[`toolbarcontaineritem-${breakPoint}`] =
            {
                [theme.breakpoints.only(breakPoint)]:
                {
                    display: 'block',
                },
            };
    }

    const itemSpaceStyle = {};
    for (const breakPoint of theme.spacingBreakPoints)
    {
        const itemSpacing = theme.freeflow.item.spacing[breakPoint];

        itemSpaceStyle[theme.breakpoints.up(breakPoint)] =
            {
                paddingLeft: itemSpacing.horizontal * 0.5,
                paddingRight: itemSpacing.horizontal * 0.5,
            };
    }

    const result =
        {
            footerVerticalLayout:
            {
                paddingBottom: 0,
            },
            headerVerticalLayout:
            {
                paddingBottom: 24,
            },
            root:
            {
                display: 'none',
                minWidth: 0,
                paddingTop: 0,
                width: 'auto',

                ...itemSpaceStyle,
            },
            selectionVerticalLayout:
            {
                paddingBottom: 24,
                paddingTop: 0,
            },
            ...visibilityStyles,
        };

    return createStyles(result);
};

@observer
export class ToolbarContainerItem extends
    React.Component<Props & WithStyles<typeof styles> & WithTheme>
{
    private shouldRender(): boolean
    {
        if (this.props.childLayoutWidgetName || !this.props.dataId)
        {
            return true;
        }

        const widgetProperties = PaneRow.getWidgetProperties(
            this.props.dataId, this.props.childWidgetName) as WidgetProperties;

        if (widgetProperties.accessLevel === undefined)
        {
            return true;
        }

        return widgetProperties.accessLevel >= AccessLevel.disabled;
    }

    public render()
    {
        if (!this.shouldRender())
        {
            return null;
        }

        const classes: string[] = [this.props.classes.root];
        let flex = undefined;
        if (this.props.propagated)
        {
            if (this.props.propagated.parentRowKey)
            {
                classes.push(this.props.classes.selectionVerticalLayout);
            }
            else if (this.props.propagated.parentTable
                && this.props.propagated.parentTable.isVerticalLayout)
            {
                switch (this.props.toolbarType)
                {
                    case 'footer':
                        // When table is in vertical layout the footer toolbar
                        // container will render full width since there is only
                        // one full width section.
                        flex = 'auto';
                        classes.push(this.props.classes.footerVerticalLayout);
                        break;
                    case 'header':
                        classes.push(this.props.classes.headerVerticalLayout);
                        break;
                    default:
                        break;
                }
            }
        }
        const theme = this.props.theme as CustomTheme;

        for (const breakPoint of theme.visibilityBreakPoints)
        {
            if (this.props[breakPoint])
            {
                classes.push(
                    this.props.classes[`toolbarcontaineritem-${breakPoint}`]);
            }
        }

        return (
            <div
                className={classes.join(' ')}
                style={{
                    flex,
                }}
            >
                {this.props.children}
            </div>
        );
    }
}

export default withStyles(styles)(withTheme(ToolbarContainerItem));
