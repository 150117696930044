import { observable } from 'mobx';
import * as moment from 'moment';
import TrackableCollection from '../core/TrackableCollection';
import TrackableModel, { ITrackable } from '../core/TrackableModel';
import PaneRow, { RuntimeWidget, WidgetValue } from '../models/PaneRow';

class DataChanges
{
    changedRecords: RecordChange[];
    dataId: string;
    deletedRowKeys:  string[];
}

export type PaneData =
{
    dataId: string;
    lastLoadingToMatchDate: Date | undefined;
    lastRetrieved: Date | undefined;
    paneUseKey: string;
    rows: PaneDataRow[];
};

export type PaneDataByDataId =
{
    [dataId: string]: PaneData;
};

interface PaneDataRow
{
    isNew: boolean;
    rowKey: string;
    widgets: { [id: string]: RuntimeWidget };
}

class RecordChange
{
    rowKey: string;
    values: { [id: string]: WidgetValue };
}

export default class PaneDataStore
{
    private static staticInstance: PaneDataStore;

    @observable private panesByDataId: Map<string, PaneData> = new Map();

    // Returns the pending changes for the model as json.
    private static getDataChanges(model: ITrackable): DataChanges
    {
        const result: DataChanges =
            {
                changedRecords: [],
                dataId: model.dataId!,
                deletedRowKeys: [],
            };

        const deleted = TrackableModel.getDeleted(model.dataId);
        deleted.forEach((m: TrackableModel) =>
        {
            result.deletedRowKeys.push(m.getPrimaryKey());
        });

        let allModels: TrackableModel[] = [];
        if (model instanceof Array)
        {
            allModels = model;
        }
        else
        {
            allModels = [model as TrackableModel];
        }

        allModels.forEach((m: TrackableModel) =>
        {
            if (result.deletedRowKeys.indexOf(m.dataId!) < 0)
            {
                if (m.isModified)
                {
                    const record = new RecordChange();
                    record.rowKey = m.getPrimaryKey();
                    record.values = m.getModifiedPropertyValues();

                    result.changedRecords.push(record);
                }
            }
        });

        return result;
    }

    public static get instance()
    {
        if (!PaneDataStore.staticInstance)
        {
            PaneDataStore.staticInstance = new PaneDataStore();
        }

        return PaneDataStore.staticInstance;
    }

    public static clearAllDataChanges()
    {
        const itemsToCheck = Array.from(TrackableModel.models.values());

        for (const item of itemsToCheck)
        {
            if (item && !item.ignoreChanges && item.hasChanges())
            {
                item.clear();
            }
        }
    }

    // Called when changes were applied in a round trip and no data is being
    // loaded, but the deleted rows still need to be cleared since they were
    // handled in the round trip
    public static clearDeletedRows(): void
    {
        const trackables = Array.from(TrackableModel.models.values());
        for (const item of trackables)
        {
            if (item instanceof TrackableCollection)
            {
                item.clearDeleted();
            }
        }
    }

    // Returns the pending changes for the trackable objects as json.
    public static getChanges(): DataChanges[]
    {
        const result: DataChanges[] = [];

        const itemsToCheck = Array.from(TrackableModel.models.values());

        for (const item of itemsToCheck)
        {
            if (item && !item.ignoreChanges && item.hasChanges())
            {
                result.push(PaneDataStore.getDataChanges(item));
            }
        }

        return result;
    }

    public static getPaneCollection(dataId: string): PaneRow[]
    {
        let trackableCollection: TrackableCollection | undefined;

        if (TrackableModel.models.has(dataId))
        {
            trackableCollection =
                TrackableModel.models.get(dataId) as TrackableCollection;
        }

        if (!trackableCollection || !trackableCollection.observableCollection)
        {
            throw new Error(`Collection for ${dataId} not found`);
        }

        return trackableCollection.observableCollection as PaneRow[];
    }

    // Populates the models identified in the specified response.
    public static loadResponse(paneData: PaneDataByDataId): void
    {
        // If only a single pane on a presentation is being loaded, any
        // deletions from other panes were still processed in the round trip,
        // so clear them all
        PaneDataStore.clearDeletedRows();

        for (const key of Object.keys(paneData))
        {
            if (!TrackableModel.models.has(key))
            {
                throw Error(
                    `No model has been created for pane data with key ${key}.`);
            }

            TrackableModel.models.get(key)!.load(paneData[key].rows);

            let existingPane: PaneData | undefined = undefined;
            if (PaneDataStore.instance.panesByDataId.has(key))
            {
                existingPane = PaneDataStore.instance.panesByDataId.get(key)!;
            }

            PaneDataStore.instance.panesByDataId.set(
                key,
                {
                    dataId: paneData[key].dataId,
                    lastLoadingToMatchDate: existingPane
                        ? existingPane.lastLoadingToMatchDate : undefined,
                    lastRetrieved: paneData[key].lastRetrieved
                        ? moment(paneData[key].lastRetrieved).toDate()
                        : undefined,
                    paneUseKey: paneData[key].paneUseKey,
                    // Future 7.4.1: load rows into here instead of TrackableModel
                    rows: [],
                });
        }
    }

    public static loadWidgetData(
        dataId: string, data: { [id: string]: RuntimeWidget }): void
    {
        if (!TrackableModel.models.has(dataId))
        {
            throw Error(`Could not find collection with dataId ${dataId}`);
        }

        PaneDataStore.clearDeletedRows();

        const collection =
            TrackableModel.models.get(dataId) as TrackableCollection;

        const observableCollection = collection.observableCollection
            ? collection.observableCollection
            : collection;

        const paneRow = observableCollection[0] as PaneRow;

        for (const widgetName in data)
        {
            if (data.hasOwnProperty(widgetName))
            {
                paneRow.updateWidget(widgetName, data[widgetName]);
            }
        }
    }

    public addPane(dataId: string, toMatchDate: Date): PaneData | undefined
    {
        this.panesByDataId.set(
            dataId,
            {
                dataId,
                lastLoadingToMatchDate: toMatchDate,
                lastRetrieved: undefined,
                paneUseKey: '',
                rows: [],
            });

        return this.panesByDataId.get(dataId);
    }

    public getPane(dataId: string): PaneData | undefined
    {
        return this.panesByDataId.get(dataId);
    }

    public setLoadingToMatchDate(dataId: string, toMatchDate: Date): void
    {
        const paneData = this.panesByDataId.get(dataId)!;
        paneData.lastLoadingToMatchDate = toMatchDate;
        if (!paneData.lastRetrieved)
        {
            paneData.lastRetrieved = new Date('0001-01-01T00:00:00Z');
        }
    }
}
