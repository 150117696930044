import { createStyles, Theme, WithStyles, withStyles }
    from '@material-ui/core/styles';
import withWidth, { WithWidth } from '@material-ui/core/withWidth';
import { observer } from 'mobx-react';
import * as React from 'react';
import TrackableModel from '../core/TrackableModel';
import Presentation from '../coreui/Presentation';
import ProcessingMask from '../coreui/ProcessingMask';
import Table, { TableChildProps, TableProps } from '../coreui/Table';
import AsyncData, { AsyncData as AsyncDataBase, GetDataResponse }
    from '../coreui/table/AsyncData';
import PaneRow from '../models/PaneRow';
import RoundTripService from '../services/RoundTripService';
import PaneDataStore from '../stores/PaneDataStore';
import Api, { AccessLevel } from './Api';
import GridColumn, { GridColumnConfigProperties } from './Columns/GridColumn';
import { DashboardCriteria as DashboardCriteriaBase } from './DashboardCriteria';
import ErrorBoundary from './ErrorBoundary';

interface ConfigProperties extends WithWidth
{
    cardDepth: number;
    columns: GridColumnConfigProperties[];
    contentDataId: string;
    data?: object;
    dataId: string;
    footerToolbar?: object;
    headerToolbar?: object;
    name: string;
    searches: { description: string; name: string }[];
    summaryToolbar?: object;
    tableKey: string;
    verticalLayout?: object;
}

export interface DashboardChildProps
{
    parentDashboard:
    {
        initialSearch: string;
        search: (search: string) => Promise<void>;
        searches: Search[];
    };
}

interface RuntimeProperties
{
    accessLevel: AccessLevel;
    initialSearch: string;
}

export interface Search
{
    description: string;
    name: string;
}

interface State
{
    isSearching?: boolean;
}

const styles = (theme: Theme) => createStyles(
    {
    });

@observer
export class DashboardGridControl extends
    React.Component<
        ConfigProperties & WithWidth & WithStyles<typeof styles>, State>
{
    private currentSearchName: string;
    private populate: ((rows: TrackableModel[]) => void) | null = null;
    private propagated: TableChildProps & DashboardChildProps;
    private tableProps: TableProps;

    public constructor(
        props: ConfigProperties & WithWidth & WithStyles<typeof styles>)
    {
        super(props);

        this.state = { isSearching: false };

        const runtimeProperties =
            Api.getWidgetProperties(this.props) as RuntimeProperties;

        if (DashboardCriteriaBase.values.has(this.props.contentDataId))
        {
            this.currentSearchName =
                DashboardCriteriaBase.values.get(this.props.contentDataId)!;
        }
        else
        {
            this.currentSearchName = runtimeProperties.initialSearch;
        }

        const tableChildProps =
        {
            parentTable:
            {
                cardDepth: props.cardDepth,
                columns: props.columns,
                configProps:
                {
                    contentDataId: props.contentDataId,
                    data: props.data,
                    dataId: props.dataId,
                    name: props.name,
                    tableKey: props.tableKey,
                },
                hasRelatedEditDialog: false,
                isDocumentGrid: false,
                populateData: () => this.populateData(),
            },
        } as TableChildProps;

        this.propagated =
        {
            parentDashboard:
            {
                initialSearch: runtimeProperties.initialSearch,
                search: this.search,
                searches: props.searches,
            },
            ...tableChildProps,
        };

        this.tableProps = {
            cardDepth: props.cardDepth,
            cellEdit: false,
            columns: [],
            contentDataId: props.contentDataId,
            dataId: props.dataId,
            footerToolbarChild: props.footerToolbar,
            headerToolbarChild: props.headerToolbar,
            isColumnFlex: (colId: string) =>
                GridColumn.isColumnFlex(props.columns, colId),
            isColumnVisible: (colId: string, breakpoint: string) =>
                GridColumn.isColumnVisible(props.columns, colId, breakpoint),
            minRowHeight:
                GridColumn.getColumnsMinRowHeight(props.columns),
            name: props.name,
            propagated: this.propagated,
            resetPageOnPopulate: true,
            setPopulate: populate => this.populate = populate,
            showNoData: true,
            summaryToolbarChild: props.summaryToolbar,
        };
    }

    private getData = (searchName?: string) =>
    {
        return RoundTripService.partialDataRetrevial<GetDataResponse>(
            `DashboardGridControl/GetRowsData/${this.getRowKey()}`
            + `/${this.props.dataId}/${this.props.name}`,
            undefined,
            { SearchName: searchName ? searchName : this.currentSearchName });
    };

    private getRowKey(): string
    {
        const observable = Presentation.getObservable(this.props)! as PaneRow;
        return observable.rowKey;
    }

    private populateData = () =>
    {
        const rows: PaneRow[] =
            PaneDataStore.getPaneCollection(this.props.contentDataId);

        if (this.populate !== null)
        {
            this.populate(rows);
        }
    };

    private search = async (search: string) =>
    {
        this.setState({ isSearching: true });

        try
        {
            await AsyncDataBase.processResponse(this.getData(search));
            this.currentSearchName = search;
        }
        finally
        {
            this.setState({ isSearching: false });
        }
    };

    public componentDidMount()
    {
        for (const column of this.props.columns)
        {
            this.tableProps.columns.push(GridColumn.getColumnDef(
                column,
                this.props.columns,
                this.propagated));
        }
    }

    public componentDidUpdate(prevProps: ConfigProperties)
    {
        if (prevProps.width !== this.props.width)
        {
            setTimeout(() => this.populateData());
        }
    }

    public render()
    {
        const runtimeProperties =
            Api.getWidgetProperties(this.props) as RuntimeProperties;

        if (!runtimeProperties)
        {
            return null;
        }

        if (runtimeProperties.accessLevel === AccessLevel.hidden)
        {
            return null;
        }

        return (
            <ErrorBoundary title={this.props.name}>
                <div style={{ position: 'relative' }}>
                    <AsyncData
                        contentDataId={this.props.contentDataId}
                        dataId={this.props.dataId}
                        getData={this.getData}
                        populateData={this.populateData}
                    />
                    <ProcessingMask isOpen={this.state.isSearching!} />
                    <Table
                        {...this.tableProps}
                        fullWidthChild={this.props.verticalLayout}
                        tableKey={this.props.tableKey}
                    />
                </div>
            </ErrorBoundary>);
    }
}

export default withStyles(styles)(withWidth()(DashboardGridControl));
