import { createStyles, Theme, WithStyles, withStyles }
    from '@material-ui/core/styles';
import * as React from 'react';
import Sys from '../../core/Sys';

const styles = (theme: Theme) => createStyles(
    {
        svg:
        {
            color: theme.palette.grey[800],
            height: 'calc(100% + 1px)',
            position: 'absolute',
            right: 0,
            top: 0,
        },
        title:
        {
            boxSizing: 'border-box',
            display: 'inline-block',
            fontFamily: 'SegoeScript-Bold, Segoe Script',
            fontSize: '19.47px',
            fontWeight: 700,
            height: 40,
            maxWidth: '100%',
            overflow: 'hidden',
            paddingLeft: 24,
            paddingRight: 36,
            paddingTop: 6,
            position: 'relative',
            textOverflow: 'ellipsis',
            textTransform: 'uppercase',
            whiteSpace: 'nowrap',
        },
        titleBackground:
        {
            backgroundColor: theme.palette.grey[800],
            height: '100%',
            left: 0,
            position: 'absolute',
            right: 15,
            top: 0,
        },
        wrapper:
        {
            display: 'flex',
            maxWidth: '100%',
            position: 'relative',
        },
    });

export class EnvironmentBanner extends
    React.PureComponent<WithStyles<typeof styles>>
{
    private readonly bannerColor: string | null = null;
    private readonly bannerTitle: string | null = null;

    public constructor(props: WithStyles<typeof styles>)
    {
        super(props);

        this.bannerColor = Sys.settings.environmentBannerColor;
        this.bannerTitle = Sys.settings.nonProdEnvironment;
    }

    public render()
    {
        if (!this.bannerColor || !this.bannerTitle)
        {
            return null;
        }

        return (
            <div
                style={{
                    backgroundColor: this.bannerColor,
                    display: 'flex',
                    overflow: 'hidden',
                }}
            >
                <span className={this.props.classes.wrapper}>
                    <span className={this.props.classes.titleBackground} />
                    <svg
                        className={this.props.classes.svg}
                        viewBox="0 0 103.59 40"
                    >
                        <polygon
                            fill="currentColor"
                            points="103.59 40 0 40 0 0 91.59 0 103.59 40"
                        />
                    </svg>
                    <span
                        className={this.props.classes.title}
                        style={{ color: this.bannerColor }}
                    >
                        {this.bannerTitle}
                    </span>
                </span>
            </div>
        );
    }
}

export default withStyles(styles)(EnvironmentBanner);
