import { createStyles, Theme, WithStyles, withStyles }
    from '@material-ui/core/styles';
import * as React from 'react';

interface Props extends React.HTMLAttributes<HTMLDivElement>
{
    childSelector: string;
    rootSelector?: string;
}

interface State
{
    isFocusable?: boolean;
}

const styles = (theme: Theme) => createStyles(
    {
        root:
        {
            outline: 'none',
        },
    });

export class KeyboardNavigationGroup extends
    React.PureComponent<Props & WithStyles<typeof styles>, State>
{
    private readonly domObserver: MutationObserver;
    private lastAriaSelectedChild: Element | null = null;
    private lastFocusedChild: Element | null = null;
    private readonly rootRef = React.createRef<HTMLDivElement>();
    private readonly visibilityObserver: IntersectionObserver;

    private get isFocusWithinRootElement(): boolean
    {
        return document.hasFocus()
            && document.activeElement !== null
            && this.rootElement.contains(document.activeElement);
    }

    private get rootElement(): HTMLElement
    {
        if (this.props.rootSelector !== undefined)
        {
            return this.rootRef.current!.querySelector(
                this.props.rootSelector)! as HTMLElement;
        }

        return this.rootRef.current!;
    }

    private static isElementHidden(element: HTMLElement)
    {
        return element.offsetParent === null
            || element.style.visibility === 'hidden';
    }

    public constructor(props: Props & WithStyles<typeof styles>)
    {
        super(props);

        this.state = { isFocusable: false };

        this.domObserver = new MutationObserver(this.onDomChange);
        this.visibilityObserver =
            new IntersectionObserver(this.onVisibilityChange);
    }

    private getAriaSelectedChild(children: Element[]): Element | undefined
    {
        const ariaSelectedElement: Element | null =
            this.rootElement.querySelector('[aria-selected="true"]');

        if (ariaSelectedElement)
        {
            return children.find(c =>
                c === ariaSelectedElement || ariaSelectedElement.contains(c));
        }

        return undefined;
    }

    private getFocusableChildren(): Element[]
    {
        const children = Array.from(
            this.rootElement.querySelectorAll(this.props.childSelector));

        return children.filter(c =>
            !c.hasAttribute('disabled')
            && !KeyboardNavigationGroup.isElementHidden(c as HTMLElement));
    }

    private onBlur = (event: FocusEvent) =>
    {
        const currentTarget: HTMLElement | null =
            event.currentTarget as HTMLElement;

        if (currentTarget
            && !currentTarget.contains(event.relatedTarget as Node)
            && currentTarget.contains(event.target as Node))
        {
            this.updateIsFocusable(true);
        }
    };

    private onDomChange = () =>
    {
        if (!this.isFocusWithinRootElement)
        {
            this.updateIsFocusable(true);
        }
    };

    private onFocus = (event: FocusEvent) =>
    {
        const currentTarget: HTMLElement | null =
            event.currentTarget as HTMLElement;

        if (!currentTarget || !currentTarget.contains(event.target as Node))
        {
            return;
        }

        if (!currentTarget.contains(event.relatedTarget as Node))
        {
            this.updateIsFocusable(false);
        }
    };

    private onFocusTabbableElement = (event: React.FocusEvent<HTMLDivElement>) =>
    {
        if (!event.currentTarget.contains(event.target as Node))
        {
            return;
        }

        if (!event.currentTarget.contains(event.relatedTarget as Node)
            || this.lastFocusedChild !== event.target)
        {
            this.updateIsFocusable(false);
            this.setFocus('current');
        }
    };

    private onKeyDown = (event: KeyboardEvent) =>
    {
        const currentTarget: HTMLElement | null =
            event.currentTarget as HTMLElement;

        const focusedElement = event.target as HTMLElement;
        if (!currentTarget || !currentTarget.contains(focusedElement))
        {
            return;
        }
        if (focusedElement.tagName === 'INPUT'
            && (focusedElement as HTMLInputElement).type !== 'file')
        {
            return;
        }

        switch (event.keyCode)
        {
            case 37:  // Left arrow
                this.setFocus('prev');
                event.preventDefault();
                break;

            case 39:  // Right arrow
                this.setFocus('next');
                event.preventDefault();
                break;
            default:
        }
    };

    private onVisibilityChange = (entries: IntersectionObserverEntry[]) =>
    {
        if (entries.some(e => e.intersectionRatio > 0)
            && !this.isFocusWithinRootElement)
        {
            this.updateIsFocusable(true);
            this.visibilityObserver.disconnect();
        }
    };

    private setFocus(move: 'current' | 'prev' | 'next')
    {
        const children: Element[] = this.getFocusableChildren();

        if (children.length <= 0)
        {
            return;
        }

        const ariaSelectedChild: Element | undefined =
            this.getAriaSelectedChild(children);

        let selectedChild = this.lastFocusedChild;

        if (ariaSelectedChild)
        {
            if (ariaSelectedChild !== this.lastAriaSelectedChild)
            {
                selectedChild = ariaSelectedChild;
            }

            this.lastAriaSelectedChild = ariaSelectedChild;
        }

        let selectChildIndex = 0;
        if (selectedChild)
        {
            const childIndex = children.indexOf(selectedChild);
            if (childIndex >= 0)
            {
                switch (move)
                {
                    case 'current':
                        selectChildIndex = childIndex;
                        break;
                    case 'prev':
                        selectChildIndex = childIndex - 1;
                        break;
                    case 'next':
                        selectChildIndex = childIndex + 1;
                        break;
                    default:
                        throw new Error(`Unexpected move ${move}`);
                }
            }
        }

        if (selectChildIndex >= 0 && selectChildIndex < children.length)
        {
            this.lastFocusedChild = children[selectChildIndex];
            (this.lastFocusedChild as HTMLElement).focus();
        }
    }

    private updateIsFocusable(isFocusable: boolean)
    {
        const hasFocusableChild: boolean =
            this.getFocusableChildren().length > 0;

        this.setState({ isFocusable: hasFocusableChild && isFocusable });
    }

    public componentDidMount()
    {
        const rootElement: HTMLElement = this.rootElement;

        this.updateIsFocusable(true);
        this.domObserver.observe(
            rootElement,
            { childList: true, subtree: true });

        // Watch for when the root element is first made visible. For example,
        // after the select control finishes loading
        this.visibilityObserver.observe(rootElement);

        rootElement.addEventListener('focusin', this.onFocus);
        rootElement.addEventListener('focusout', this.onBlur);
        rootElement.addEventListener('keydown', this.onKeyDown);
    }

    public componentWillUnmount()
    {
        this.domObserver.disconnect();
        this.visibilityObserver.disconnect();

        const rootElement: HTMLElement = this.rootElement;
        rootElement.removeEventListener('focusin', this.onFocus);
        rootElement.removeEventListener('focusout', this.onBlur);
        rootElement.removeEventListener('keydown', this.onKeyDown);
    }

    public render(): JSX.Element | null
    {
        const {
            children,
            childSelector,
            classes,
            className,
            rootSelector,
            ...divProps
        } = this.props;

        const rootClasses: string[] = [classes.root];
        if (className)
        {
            rootClasses.push(className);
        }

        return (
            <div
                className={rootClasses.join(' ')}
                ref={this.rootRef}
                {...divProps}
            >
                <div
                    onFocus={this.onFocusTabbableElement}
                    tabIndex={this.state.isFocusable ? 0 : -1}
                />
                {children}
            </div>);
    }
}

export default withStyles(styles)(KeyboardNavigationGroup);
