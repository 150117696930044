import { createStyles, WithStyles, withStyles }
    from '@material-ui/core/styles';
import withMobileDialog, { WithMobileDialog }
    from '@material-ui/core/withMobileDialog';
import { observer } from 'mobx-react';
import * as React from 'react';
import AppServer, { State as AppServerState } from '../core/AppServer';
import Logging from '../core/Logging';
import Sys, { BusinessError } from '../core/Sys';
import Button from '../coreui/Button';
import Dialog, { BreakPointColumn } from '../coreui/Dialog';
import DialogActions from '../coreui/DialogActions';
import DialogContent from '../coreui/DialogContent';
import InformationBadge from '../coreui/InformationBadge';
import Presentation, { CreatedReactElement } from '../coreui/Presentation';
import ProcessingMask from '../coreui/ProcessingMask';
import Typography from '../coreui/Typography';
import PaneRow from '../models/PaneRow';
import { CustomTheme } from '../muiTheme';
import PresentationService from '../services/PresentationService';
import RoundTripService, { RoundTripResponse }
    from '../services/RoundTripService';
import PaneDataStore, { PaneDataByDataId } from '../stores/PaneDataStore';
import RequestsStore from '../stores/RequestsStore';
import Api, { AccessLevel } from './Api';
import Grid from './Grid';
import GridItem from './GridItem';

interface ConfigProperties extends WithMobileDialog
{
    criteriaPane: object;
    dataId: string;
    dialogTitle: string;
    disabledHelpText: string;
    isSingleSelect: boolean;
    name: string;
    selectDialogId: number;
    selectedDataId: string;
    selectedGrid: object;
}

interface DialogOpenResponse
{
    appServerState: AppServerState;
    businessErrors: BusinessError[];
    paneDataByDataId: PaneDataByDataId;
    validationErrors: string[];
}

export interface SelectChildProps
{
    parentRowObjectHandle?: string;
    parentSelect: {
        configProps: {
            dataId: string;
            isSingleSelect: boolean;
            name: string;
        };
        getSelectedRowObjectHandles: () => string[];
        onRowSelected: (rowKey: string) => void;
        onRowsRemoved: (rowKeys: string[]) => void;
        onRowUnselected: (rowKey: string) => void;
        search: (criteriaWidgetName: string | null) => void;
    };
}

export interface RuntimeProperties
{
    accessLevel: AccessLevel;
    businessErrors: string[];
    canAddRemoveRows: boolean;
    showDisabledHelp: boolean;
}

interface SearchResponse
{
    appServerState: AppServerState;
    businessErrors: BusinessError[];
    paneDataByDataId: PaneDataByDataId;
}

interface State
{
    breakPointColumns?: BreakPointColumn[];
    dialogContents?: CreatedReactElement;
    isDialogOpen?: boolean;
    isLoadingData?: boolean;
}

const styles = (theme: CustomTheme) =>
{
    const dialogWidths = {};
    for (const breakPoint of theme.spacingBreakPoints.filter(b => b !== 'xs'))
    {
        const maxWidth = theme.panel.maxWidths[breakPoint];

        dialogWidths[`dialog-${breakPoint}`] =
            {
                [theme.breakpoints.up(breakPoint)]:
                {
                    width: maxWidth,
                },
            };
    }

    return createStyles(
        {
            ...dialogWidths,
            errorText:
            {
                color: Api.getSystemColor('danger'),
            },
        });
};

@observer
export class SelectControl extends
    React.Component<ConfigProperties & WithStyles<typeof styles>, State>
{
    private criteriaPane: React.ReactNode | null = null;
    private propagated: SelectChildProps;
    private lastElementFocused: HTMLElement | null = null;
    private selectedGrid: React.ReactNode | null = null;
    private selectionChanges:
        { addedRowKeys: string[]; removedRowKeys: string[] };

    public constructor(props: ConfigProperties & WithStyles<typeof styles>)
    {
        super(props);

        this.state = { isDialogOpen: false, isLoadingData: false };

        this.selectionChanges = { addedRowKeys: [], removedRowKeys: [] };

        this.propagated = {
            parentSelect: {
                configProps: {
                    dataId: this.props.dataId,
                    isSingleSelect: this.props.isSingleSelect,
                    name: this.props.name,
                },
                getSelectedRowObjectHandles:
                    this.getCurrentlySelectedRowObjectHandles,
                onRowSelected: this.onRowSelected,
                onRowsRemoved: this.onRowsRemoved,
                onRowUnselected: this.onRowUnselected,
                search: this.search,
            },
        };

        this.criteriaPane =
            Presentation.create(props.criteriaPane, this.propagated);
        this.selectedGrid =
            Presentation.create(props.selectedGrid, this.propagated);
    }

    private acceptDialog = () =>
    {
        if (this.selectionChanges.addedRowKeys.length <= 0
            && this.selectionChanges.removedRowKeys.length <= 0)
        {
            this.cancelDialog();

            return;
        }

        this.setState({ isLoadingData: true });
        Sys.clearBusinessErrors(this.props.dataId, this.props.name);
        RoundTripService.standardRoundTrip(
            'SelectControl/OnDialogClose',
            this.props,
            {
                addedObjectIds: this.selectionChanges.addedRowKeys,
                removedObjectIds: this.selectionChanges.removedRowKeys,
            },
            true
        ).then((response: RoundTripResponse) =>
        {
            if (response.businessErrors && response.businessErrors.length > 0)
            {
                return;
            }

            AppServer.clearStateRecoveryPoint();
            this.setState({ isDialogOpen: false });
        })
        .catch((response) =>
        {
            Logging.error(response);
            return response;
        })
        .finally(() =>
        {
            this.setState({ isLoadingData: false });
        });
    };

    private cancelDialog = () =>
    {
        AppServer.recoverStateFromPoint();
        this.setState({ isDialogOpen: false });
    };

    private getCurrentlySelectedRowObjectHandles = (): string[] =>
    {
        let selectedObjectHandles: string[] =
            this.getInitiallySelectedRowObjectHandles();

        selectedObjectHandles = selectedObjectHandles.filter(
            h => !this.selectionChanges.removedRowKeys.includes(h));

        selectedObjectHandles =
            selectedObjectHandles.concat(this.selectionChanges.addedRowKeys);

        return selectedObjectHandles;
    };

    private getInitiallySelectedRowObjectHandles = (): string[] =>
    {
        const rows: PaneRow[] =
            PaneDataStore.getPaneCollection(this.props.selectedDataId);

        const selectedObjectHandles: string[] = [];
        for (const row of rows)
        {
            selectedObjectHandles.push(row.objectHandle);
        }

        return selectedObjectHandles;
    };

    private getRowKey(): string
    {
        const observable = Presentation.getObservable(
            this.props)! as PaneRow;

        return observable.rowKey;
    }

    private onDialogExited = () =>
    {
        Presentation.clearPaneData(this.props.selectDialogId.toString());
        this.setState({ dialogContents: undefined });

        // Manually focus the element since the processing animation interferes
        // with the dialog returning the focus
        this.lastElementFocused!.focus();
    };

    private onRowSelected = (rowKey: string) =>
    {
        const index = this.selectionChanges.removedRowKeys.indexOf(rowKey);
        if (index >= 0)
        {
            this.selectionChanges.removedRowKeys.splice(index, 1);
        }
        else if (this.selectionChanges.addedRowKeys.indexOf(rowKey) < 0)
        {
            if (this.props.isSingleSelect)
            {
                this.selectionChanges.addedRowKeys = [rowKey];

                const selectedRowKeys: string[] =
                    this.getInitiallySelectedRowObjectHandles();
                if (selectedRowKeys.length > 0
                    && !this.selectionChanges.removedRowKeys.includes(
                        selectedRowKeys[0]))
                {
                    this.selectionChanges.removedRowKeys.push(
                        selectedRowKeys[0]);
                }
            }
            else
            {
                this.selectionChanges.addedRowKeys.push(rowKey);
            }
        }
    };

    private onRowsRemoved = (rowKeys: string[]) =>
    {
        RoundTripService.standardRoundTrip(
            'SelectControl/OnRemoveRows',
            this.props,
            { removedObjectIds: rowKeys }
        ).catch((response) =>
        {
            Logging.error(response);
            return response;
        });
    };

    private onRowUnselected = (rowKey: string) =>
    {
        const index = this.selectionChanges.addedRowKeys.indexOf(rowKey);
        if (index >= 0)
        {
            this.selectionChanges.addedRowKeys.splice(index, 1);
        }
        else if (this.selectionChanges.removedRowKeys.indexOf(rowKey) < 0)
        {
            this.selectionChanges.removedRowKeys.push(rowKey);
        }
    };

    private openDialog(criteriaWidgetName: string | null)
    {
        this.lastElementFocused = document.activeElement as HTMLElement;
        RequestsStore.instance.processingStarted();

        this.selectionChanges.addedRowKeys = [];
        this.selectionChanges.removedRowKeys = [];

        const dialogOpenUrl = 'SelectControl/OnDialogOpen'
            + `/${this.getRowKey()}/${this.props.dataId}/${this.props.name}`;
        const dialogOpenPostArgs = { criteriaWidgetName };

        const dialogOpenRequest =
            RoundTripService.customRoundTrip<DialogOpenResponse>(
                dialogOpenUrl, undefined, dialogOpenPostArgs);

        const selectDialogId: string = this.props.selectDialogId.toString();
        const configRequest = PresentationService.getConfigForSelectDialog(
            selectDialogId);

        AppServer.createStateRecoveryPoint();

        Promise.all([dialogOpenRequest, configRequest]).then(
            ([dialogOpenResponse, configResponse]) =>
            {
                const validationErrors = dialogOpenResponse.validationErrors;
                if (validationErrors && validationErrors.length > 0)
                {
                    Sys.showErrors(validationErrors);
                    AppServer.clearStateRecoveryPoint();

                    return;
                }

                if (dialogOpenResponse.businessErrors.length > 0)
                {
                    Sys.setBusinessErrors(
                        dialogOpenResponse.businessErrors,
                        true);
                }

                const scripts: object = configResponse.scriptsByDataId;

                Presentation.setPanes(selectDialogId, scripts);
                Sys.loadScripts(scripts);

                AppServer.setState(dialogOpenResponse.appServerState);
                PaneDataStore.loadResponse(dialogOpenResponse.paneDataByDataId);

                const dialogContents = Presentation.create(
                    configResponse.presentationConfig, this.propagated)!;

                this.setState({
                    breakPointColumns: configResponse.breakPoints,
                    dialogContents,
                    isDialogOpen: true,
                });
            }
        ).finally(() => RequestsStore.instance.processingStopped());
    }

    private search = (criteriaWidgetName: string | null) =>
    {
        if (!this.state.isDialogOpen)
        {
            this.openDialog(criteriaWidgetName);

            return;
        }

        const url = 'SelectControl/OnSearch'
            + `/${this.getRowKey()}/${this.props.dataId}/${this.props.name}`;
        const postArgs = { criteriaWidgetName };

        this.setState({ isLoadingData: true });
        RoundTripService.customRoundTrip(url, undefined, postArgs)
            .then((response: SearchResponse) =>
            {
                if (response.businessErrors.length > 0)
                {
                    Sys.setBusinessErrors(response.businessErrors, true);
                }

                AppServer.setState(response.appServerState);
                PaneDataStore.loadResponse(response.paneDataByDataId);
            })
            .finally(() => this.setState({ isLoadingData: false }));
    };

    public render()
    {
        const runtimeProperties =
            Api.getWidgetProperties(this.props) as RuntimeProperties;

        if (!runtimeProperties)
        {
            return null;
        }

        if (runtimeProperties.accessLevel === AccessLevel.hidden)
        {
            return null;
        }

        if (runtimeProperties.accessLevel === AccessLevel.disabled)
        {
            if (runtimeProperties.showDisabledHelp)
            {
                return (
                    <InformationBadge
                        fullWidth={false}
                        message={this.props.disabledHelpText}
                    >
                        {this.criteriaPane}
                    </InformationBadge>
                );
            }

            return this.criteriaPane;
        }

        const selectedRows: PaneRow[] =
            PaneDataStore.getPaneCollection(this.props.selectedDataId);
        const noRowsSelected: boolean = selectedRows.length === 0;

        // The wrapping divs are used to prevent the Grid margin from overriding
        // the parent GridItem margin
        return (
            <div>
                <Grid grouping="Closely Related" lg={1} md={1} sm={1} xs={1}>
                    <GridItem lg={1} md={1} sm={1} xl={1} xs={1}>
                        <div>{this.criteriaPane}</div>
                        <Dialog
                            breakPointColumns={this.state.breakPointColumns}
                            onClose={this.cancelDialog}
                            onExited={this.onDialogExited}
                            open={this.state.isDialogOpen!}
                        >
                            <ProcessingMask
                                isOpen={this.state.isLoadingData!}
                                trapFocus={true}
                            />
                            <DialogContent>
                                <Grid
                                    grouping="Closely Related"
                                    lg={1}
                                    md={1}
                                    sm={1}
                                    xs={1}
                                >
                                    <GridItem lg={1} md={1} sm={1} xl={1} xs={1}>
                                        <Typography variant="h3">
                                            {this.props.dialogTitle}
                                        </Typography>
                                    </GridItem>
                                    <GridItem lg={1} md={1} sm={1} xl={1} xs={1}>
                                        {this.state.dialogContents}
                                    </GridItem>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.acceptDialog}>
                                    {Sys.getTranslation('Select')}
                                </Button>
                                <Button
                                    onClick={this.cancelDialog}
                                    style={{ marginLeft: 40 }}
                                >
                                    {Sys.getTranslation('Cancel')}
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </GridItem>
                    <GridItem
                        md={1}
                        lg={1}
                        sm={1}
                        style={{ display: noRowsSelected ? 'none' : undefined }}
                        xl={1}
                        xs={1}
                    >
                        {this.selectedGrid}
                    </GridItem>
                </Grid>
            </div>
        );
    }
}

export default withMobileDialog<ConfigProperties>({ breakpoint: 'xs' })(
    withStyles(styles)(SelectControl));
