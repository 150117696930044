import { GetQuickFilterTextParams } from 'ag-grid-community';
import { observer } from 'mobx-react';
import * as React from 'react';
import ComboBoxOption from '../../coreui/ComboBoxOption';
import ErrorBadge from '../../coreui/ErrorBadge';
import Icon from '../../coreui/Icon';
import { TableChildProps } from '../../coreui/Table';
import { CellFocusUtil, FocusCellRendererParams }
    from '../../coreui/table/CellFocusUtil';
import { CellUtil } from '../../coreui/table/CellUtil';
import PaneRow, { RuntimeWidget } from '../../models/PaneRow';
import Api, { AccessLevel } from '../Api';
import { FunctionName } from '../TableSummary';

interface ConfigProperties extends FocusCellRendererParams
{
    dataId: string;
    name: string;
    propagated: TableChildProps;
}

interface State
{
    isErrorBadgeOpen?: boolean;
}

interface RuntimeProperties
{
    accessLevel: AccessLevel;
    businessErrors: string[];
    selectedDisplayValue: string;
    selectedValue: string | null;
}

@observer
export class RelationshipComboBoxColumnDisplay extends
    React.Component<ConfigProperties, State>
{
    public static getQuickFilterText(params: GetQuickFilterTextParams): string
    {
        const row = params.node.data as PaneRow | null;
        if (!row)
        {
            return '';
        }

        const props = params.colDef.cellRendererParams as ConfigProperties;

        const widget = row.getWidget(props.name);
        const runtimeProps = widget.properties as RuntimeProperties;

        const selectedOption: ComboBoxOption =
        {
            display: runtimeProps.selectedDisplayValue,
            value: runtimeProps.selectedValue,
        };

        const table = props.propagated.parentTable;
        const options = table.getRelationshipComboBoxOptions(
            props.name,
            selectedOption);

        const currentOption = options.find(o => o.value === widget.value);

        return currentOption ? currentOption.display : '';
    }

    public static getSummaryValue(
        runtimeData: RuntimeWidget[],
        configProperties: ConfigProperties,
        functionName: FunctionName
        ): string | null
    {
        return runtimeData.filter(d => d.value).length.toString();
    }

    public constructor(props: ConfigProperties)
    {
        super(props);

        this.state = { isErrorBadgeOpen: false };

        CellFocusUtil.subscribeToCellKeyboardFocusedEvent(
            props, this.onCellFocus, this.onCellBlur);
        props.eGridCell.addEventListener('keydown', this.onCellKeyDown);
    }

    private onCellBlur = (): void =>
    {
        this.setState({ isErrorBadgeOpen: false });
    };

    private onCellFocus = (): void =>
    {
        this.setState({ isErrorBadgeOpen: true });
    };

    private onCellKeyDown = (event: KeyboardEvent): void =>
    {
        CellUtil.customizeGridNavigation(event, this.props);
    };

    private onCloseErrorBadge = (): void =>
    {
        this.setState({ isErrorBadgeOpen: false });
    };

    private onOpenErrorBadge = (): void =>
    {
        this.setState({ isErrorBadgeOpen: true });
    };

    public componentWillUnmount(): void
    {
        CellFocusUtil.unsubscribeToCellKeyboardFocusedEvent(
            this.props, this.onCellFocus, this.onCellBlur);
        this.props.eGridCell.removeEventListener('keydown', this.onCellKeyDown);
    }

    public render(): React.ReactNode
    {
        const row = this.props.node.data as PaneRow | null;
        if (!row)
        {
            return null;
        }

        const widget = row.getWidget(this.props.name);
        const runtimeProps = widget.properties as RuntimeProperties;

        const selectedOption: ComboBoxOption =
        {
            display: runtimeProps.selectedDisplayValue,
            value: runtimeProps.selectedValue,
        };

        const table = this.props.propagated.parentTable;
        const options = table.getRelationshipComboBoxOptions(
            this.props.name,
            selectedOption);

        let value = null;
        if (widget.value)
        {
            const currentOption = options.find(o => o.value === widget.value);
            value = currentOption ? currentOption.display : null;
        }

        CellUtil.setReadOnlyAttribute(
            this.props.eGridCell,
            runtimeProps.accessLevel <= AccessLevel.actionable);

        const errors: string[] = row.hasChanges(this.props.name)
            ? []
            : [...runtimeProps.businessErrors];

        let result: React.ReactNode = null;

        if (runtimeProps.accessLevel >= AccessLevel.enterable)
        {
            if (errors.length)
            {
                result =
                    <ErrorBadge
                        isShort={row.isNew && table.isDocumentGrid}
                        message={Api.getErrorMessages(errors)}
                        onClose={this.onCloseErrorBadge}
                        onOpen={this.onOpenErrorBadge}
                        open={this.state.isErrorBadgeOpen}
                        suppressEdit={true}
                    >
                        <div
                            style={
                                {
                                    alignItems: 'center',
                                    display: 'flex',
                                    flex: 'auto',
                                    margin: '0px 20px 0px 24px',
                                    overflow: 'hidden',
                                }}
                        >
                            <div
                                style={
                                    {
                                        flex: 'auto',
                                        lineHeight: 'normal',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                    }}
                            >
                                {value}
                            </div>
                            <div className="cx-print">
                                <Icon
                                    fixedWidth
                                    icon="fas fa-caret-down"
                                    style={
                                        {
                                            fontSize: 16,
                                            marginLeft: '.4em',
                                            marginRight: 1,
                                        }}
                                />
                            </div>
                        </div>
                    </ErrorBadge>;
            }
            else
            {
                result =
                    <div
                        style={
                            {
                                alignItems: 'center',
                                display: 'flex',
                                height: '100%',
                                margin: '0 21px 0 24px',
                            }}
                    >
                        <div
                            style={
                                {
                                    flex: 'auto',
                                    lineHeight: 'normal',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                }}
                        >
                            {value}
                        </div>
                        <div className="cx-print">
                            <Icon
                                fixedWidth
                                icon="fas fa-caret-down"
                                style={
                                    {
                                        fontSize: 16,
                                        height: 'auto',
                                        marginLeft: '.4em',
                                    }}
                            />
                        </div>
                    </div>;
            }
        }
        else
        {
            result =
                <div
                    style={
                        {
                            alignItems: 'center',
                            display: 'flex',
                            height: '100%',
                            margin: '0px 24px',
                        }}
                >
                    <div
                        style={
                            {
                                lineHeight: 'normal',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                width: '100%',
                            }}
                    >
                        {value}
                    </div>
                </div>;
        }

        return result;
    }
}

export default RelationshipComboBoxColumnDisplay;
