import { createStyles, Theme, WithStyles, withStyles }
    from '@material-ui/core/styles';
import * as muiTypography from '@material-ui/core/Typography';
import { ThemeProvider } from '@material-ui/styles';
import * as React from 'react';
import { Layout, LayoutConfig } from '../config/Layout';
import KeyboardNavigationGroup from '../coreui/KeyboardNavigationGroup';
import muiTheme, { CustomTheme, ForegroundColorType } from '../muiTheme';
import SessionMessageDisplay from '../mustangui/SessionMessageDisplay';
import Logon from '../pages/Logon';
import BackgroundImage from './components/BackgroundImage';
import EnvironmentBanner from './components/EnvironmentBanner';
import Logo from './components/Logo';
import PageProcessing from './components/PageProcessing';
import CenteredContentClasses from './styles/CenteredContentClasses';
import PaperClasses from './styles/PaperClasses';

interface FooterConfig
{
    backgroundColor: string;
    foreground: ForegroundColorType;
    layout: LayoutConfig;
}

interface HeaderConfig
{
    backgroundColor: string;
    bottomBorderColor: string;
    foreground: ForegroundColorType;
    logoUrl: string;
    layout: LayoutConfig;
}

export interface LandingPageTemplateConfig
{
    backgroundImageUrl: string;
    footer: FooterConfig;
    header: HeaderConfig;
    signInTitle: string;
    welcomeTitle1: string;
    welcomeTitle2: string;
}

interface Props
{
    backgroundImageUrl: string;
    footer: FooterConfig;
    forgotPasswordUrl: string | null;
    header: HeaderConfig;
    signInTitle: string;
    welcomeTitle1: string;
    welcomeTitle2: string;
}

const styles = (theme: CustomTheme) => createStyles(
    {
        footer:
        {
            [theme.breakpoints.up('lg')]:
            {
                paddingBottom: theme.paper.padding.lg,
                paddingTop: theme.paper.padding.lg,
            },
            [theme.breakpoints.only('md')]:
            {
                paddingBottom: theme.paper.padding.md,
                paddingTop: theme.paper.padding.md,
            },
            [theme.breakpoints.only('sm')]:
            {
                paddingBottom: theme.paper.padding.sm,
                paddingTop: theme.paper.padding.sm,
            },
            [theme.breakpoints.only('xs')]:
            {
                paddingBottom: theme.paper.padding.xs,
                paddingTop: theme.paper.padding.xs,
            },
            '@media print':
            {
                display: 'none',
            },
            alignItems: 'center',
            display: 'flex',
            overflow: 'hidden',
        },
        footerContent:
        {
            [theme.breakpoints.only('xs')]:
            {
                flexBasis: '100%',
            },
        },
        header:
        {
            [theme.breakpoints.only('xs')]:
            {
                height: 72,
            },
            [theme.breakpoints.only('sm')]:
            {
                height: 88,
            },
            [theme.breakpoints.only('md')]:
            {
                height: 108,
            },
            [theme.breakpoints.up('lg')]:
            {
                height: 140,
            },
            '@media print':
            {
                display: 'none',
            },
            borderBottomStyle: 'solid',
            borderBottomWidth: 4,
        },
        headerContent:
        {
            alignItems: 'center',
            boxSizing: 'border-box',
            display: 'flex',
            height: '100%',
        },
        main:
        {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
        },
        mainContent:
        {
            [theme.breakpoints.only('xs')]:
            {
                marginBottom: theme.container.verticalSpacing.xs,
                marginTop: theme.container.verticalSpacing.xs,
            },
            [theme.breakpoints.only('sm')]:
            {
                marginBottom: theme.container.verticalSpacing.sm,
                marginTop: theme.container.verticalSpacing.sm,
            },
            [theme.breakpoints.only('md')]:
            {
                marginBottom: theme.container.verticalSpacing.md,
                marginTop: theme.container.verticalSpacing.md,
            },
            [theme.breakpoints.up('lg')]:
            {
                marginBottom: theme.container.verticalSpacing.lg,
                marginTop: theme.container.verticalSpacing.lg,
            },
            flexGrow: 1,
        },
        root:
        {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
        },
        signIn:
        {
            [theme.breakpoints.up('md')]:
            {
                display: 'block',
            },
            display: 'none',
        },
        titleLight:
        {
            fontWeight: 400,
        },
        top:
        {
            position: 'relative',

            [theme.breakpoints.up('xs')]:
            {
                height: 212,
            },
            [theme.breakpoints.up('sm')]:
            {
                height: 300,
            },
            [theme.breakpoints.up('md')]:
            {
                height: 'auto',
            },
        },
        topContent:
        {
            [theme.breakpoints.down('sm')]:
            {
                height: '100%',
            },
            [theme.breakpoints.up('md')]:
            {
                marginBottom: 24,
                marginTop: 24,
            },
            [theme.breakpoints.up('lg')]:
            {
                marginBottom: 40,
                marginTop: 40,
            },
            alignItems: 'center',
            display: 'flex',
        },
        welcome:
        {
            [theme.breakpoints.up('lg')]:
            {
                marginRight: theme.horizontalSpacing.related.lg,
            },
            [theme.breakpoints.only('md')]:
            {
                marginRight: theme.horizontalSpacing.related.md,
            },
            flexGrow: 1,
        },
        welcomeTitle:
        {
            [theme.breakpoints.up('xs')]:
            {
                textAlign: 'center',
            },
            [theme.breakpoints.up('md')]:
            {
                textAlign: 'left',
            },
            color: theme.palette.common.white,
        },
        ...CenteredContentClasses.create(theme),
        ...PaperClasses.create(theme),
    });

class LandingPageTemplate extends
    React.PureComponent<Props & WithStyles<typeof styles>>
{
    private readonly footerTheme: Theme;
    private readonly headerTheme: Theme;

    public constructor(props: Props & WithStyles<typeof styles>)
    {
        super(props);

        this.footerTheme = muiTheme(this.props.footer.foreground, true);
        this.headerTheme = muiTheme(this.props.header.foreground, true);
    }

    public render(): React.ReactNode
    {
        const footerContentClasses =
            [
                this.props.classes.footerContent,
                CenteredContentClasses.get(null, this.props.classes),
            ];

        const headerContentClasses =
            [
                this.props.classes.headerContent,
                CenteredContentClasses.get(null, this.props.classes),
            ];

        const mainContentClasses =
            [
                this.props.classes.mainContent,
                CenteredContentClasses.get(null, this.props.classes),
            ];

        const signInClasses =
            [
                this.props.classes.signIn,
                PaperClasses.get('lg', 4, false, this.props.classes),
                PaperClasses.get('md', 5, false, this.props.classes),
            ];

        const topContentClasses =
            [
                this.props.classes.topContent,
                CenteredContentClasses.get(null, this.props.classes),
            ];

        const welcomeTitle1Classes =
            [
                this.props.classes.titleLight,
                this.props.classes.welcomeTitle,
            ];

        const welcomeMessage =
            `${this.props.welcomeTitle1} ${this.props.welcomeTitle2}`;

        return (
            <div className={this.props.classes.root}>
                <EnvironmentBanner />
                <PageProcessing />
                <header
                    className={this.props.classes.header}
                    style={{
                        backgroundColor: this.props.header.backgroundColor,
                        borderColor: this.props.header.bottomBorderColor,
                    }}
                >
                    <div className={headerContentClasses.join(' ')}>
                        <ThemeProvider theme={this.headerTheme}>
                            <Logo
                                href="#"
                                imageSrc={this.props.header.logoUrl}
                            />
                            <div style={{ flex: 'auto' }}>
                                <Layout
                                    config={this.props.header.layout}
                                    preventClear={true}
                                />
                            </div>
                        </ThemeProvider>
                    </div>
                </header>
                <SessionMessageDisplay />
                <main
                    aria-label={welcomeMessage}
                    className={this.props.classes.main}
                >
                    <div className={this.props.classes.top}>
                        <BackgroundImage url={this.props.backgroundImageUrl} />
                        <div className={topContentClasses.join(' ')}>
                            <div
                                aria-hidden={true}
                                className={this.props.classes.welcome}
                            >
                                <muiTypography.default
                                    className={welcomeTitle1Classes.join(' ')}
                                    variant="h3"
                                >
                                    {this.props.welcomeTitle1}
                                </muiTypography.default>
                                <muiTypography.default
                                    className={this.props.classes.welcomeTitle}
                                    variant="h1"
                                >
                                    {this.props.welcomeTitle2}
                                </muiTypography.default>
                            </div>
                            <form className={signInClasses.join(' ')}>
                                <Logon
                                    forgotPasswordUrl={
                                        this.props.forgotPasswordUrl}
                                    title={this.props.signInTitle}
                                />
                            </form>
                        </div>
                    </div>
                    <form className={mainContentClasses.join(' ')}>
                        {this.props.children}
                    </form>
                </main>
                <footer
                    className={this.props.classes.footer}
                    style={{
                        backgroundColor: this.props.footer.backgroundColor,
                    }}
                >
                    <KeyboardNavigationGroup
                        childSelector="a"
                        className={footerContentClasses.join(' ')}
                    >
                        <ThemeProvider theme={this.footerTheme}>
                            <Layout
                                config={this.props.footer.layout}
                                preventClear={true}
                                propagated={{ isInPageFooter: true }}
                            />
                        </ThemeProvider>
                    </KeyboardNavigationGroup>
                </footer>
            </div>
        );
    }
}

export default withStyles(styles)(LandingPageTemplate);
