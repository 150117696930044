import { PropTypes } from '@material-ui/core';
import * as colors from '@material-ui/core/colors';
import { Variant } from '@material-ui/core/styles/createTypography';
import * as React from 'react';
import Logging from '../core/Logging';
import Sys from '../core/Sys';
import TrackableModel from '../core/TrackableModel';
import Icon from '../coreui/Icon';
import Presentation from '../coreui/Presentation';
import Typography from '../coreui/Typography';

export enum AccessLevel
{
    hidden = 0,
    disabled = 1,
    readOnly = 2,
    actionable = 3,
    enterable = 4,
}

export interface ConfigProps
{
    dataId?: string;
    name?: string;
    node?: object;
}

export interface ValueByBreakpoint<T>
{
    lg: T;
    md: T;
    sm: T;
    xl: T;
    xs: T;
}

export default class Api
{
    private static readonly materialUiColorNameMapping =
        {
            amber: 'amber',
            blue: 'blue',
            bluegrey: 'blueGrey',
            brown: 'brown',
            cyan: 'cyan',
            deeporange: 'deepOrange',
            deeppurple: 'deepPurple',
            green: 'green',
            grey: 'grey',
            indigo: 'indigo',
            lightblue: 'lightBlue',
            lightgreen: 'lightGreen',
            lime: 'lime',
            orange: 'orange',
            pink: 'pink',
            purple: 'purple',
            red: 'red',
            teal: 'teal',
            yellow: 'yellow',
        };

    public static getAlignment(justification: string): PropTypes.Alignment
    {
        let result: string;

        switch (justification)
        {
            case 'Centered':
                result = 'center';
                break;
            case 'Full':
                result = 'justify';
                break;
            default:
                result = justification.toLowerCase();
                break;
        }

        return result as PropTypes.Alignment;
    }

    public static getErrorMessage(message: string, style?: object): React.ReactNode
    {
        let result: React.ReactNode = null;

        if (message)
        {
            result =
                <div key={Sys.nextId} style={style}>
                    <div
                        className="fa-layers"
                        style={{ float: 'left', marginTop: '.1em' }}
                    >
                        <Icon
                            fixedWidth
                            icon="fas fa-exclamation"
                            style={{ fontSize: '.6em' }}
                        />
                        <Icon
                            fixedWidth
                            icon="far fa-octagon"
                        />
                    </div>
                    <div style={{ marginLeft: '1.4em' }}>
                        {message}
                    </div>
                </div>;
        }

        return result;
    }

    public static getErrorMessages(errors: string[]): React.ReactNode
    {
        let result: React.ReactNode = null;
        const list: React.ReactNode[] = [];

        if (errors.length > 0)
        {
            errors.forEach((item: string, index: number) =>
            {
                list.push(Api.getErrorMessage(
                    item,
                    index < errors.length - 1 ? { marginBottom: 8 } : undefined));
            });

            result =
                <React.Fragment>{list}</React.Fragment>;
        }

        return result;
    }

    public static getSystemColor(colorName: string): string
    {
        if (!Sys.settings.colorPalette)
        {
            return colors.grey[800];
        }

        let color: string;
        switch (colorName)
        {
            case 'danger':
                color = Sys.settings.colorPalette.danger;
                break;
            case 'dark':
                return colors.grey[800];
            case 'information':
                color = Sys.settings.colorPalette.information;
                break;
            case 'success':
                color = Sys.settings.colorPalette.success;
                break;
            case 'warning':
                color = Sys.settings.colorPalette.warning;
                break;
            default:
                Logging.warn(`Unknown color ${colorName}`);

                return '';
        }

        return color;
    }

    public static getTextStyle(textStyle: string): Variant
    {
        let result: string;

        switch (textStyle)
        {
            case 'Level 1':
                result = 'h1';
                break;
            case 'Level 2':
                result = 'h2';
                break;
            case 'Level 3':
                result = 'h3';
                break;
            case 'Level 4':
                result = 'h4';
                break;
            case 'Level 5':
                result = 'h5';
                break;
            case 'Level 6':
                result = 'h6';
                break;
            case 'Body 1':
                result = 'body1';
                break;
            case 'Body 2':
                result = 'body2';
                break;
            default:
                result = textStyle.toLowerCase();
                break;
        }

        return result as Variant;
    }

    // Get the current value from the widget properties.
    public static getWidgetProperties(
        props: ConfigProps,
        observable: object | null = null): object | null
    {
        let result: object | null = null;
        let widgetObservable: object | null = observable;

        if (!widgetObservable)
        {
            widgetObservable = Presentation.getObservable(props);
        }

        if (widgetObservable)
        {
            const row = widgetObservable as TrackableModel;
            result = row.getReadOnlyProperties(props['name']!);
        }

        return result;
    }

    public static parseMaterialColor(color: string): string
    {
        const cleanColor: string = color.trim();

        try
        {
            const index: number = cleanColor.lastIndexOf(' ');
            if (index < 0)
            {
                Logging.warn(`Could not parse color '${cleanColor}'`);

                return colors.grey[800];
            }

            let colorName: string = cleanColor.substring(0, index);
            colorName = colorName.replace(/ /g, '').toLowerCase();
            colorName = Api.materialUiColorNameMapping[colorName];

            const colorShade: string = cleanColor.substring(index + 1);

            if (!colorName || !(colorShade in colors[colorName]))
            {
                Logging.warn(`Could not parse color '${cleanColor}'`);

                return colors.grey[800];
            }

            return colors[colorName][colorShade];
        }
        catch (error)
        {
            Logging.warn(`Could not parse color '${cleanColor}'`);
            Logging.warn(error.message);

            return colors.grey[800];
        }
    }

    // Obsolete. Use access level directly instead.
    public static setAccessLevel(
        props: ConfigProps,
        widgetProperties: object | null
        ): boolean
    {
        let result: boolean = true;
        let properties: object | null = widgetProperties;

        if (!properties)
        {
            properties = Api.getWidgetProperties(props);
        }

        if (properties && properties['accessLevel'])
        {
            switch (properties['accessLevel'])
            {
                case AccessLevel.hidden:
                    result = false;
                    break;
                case AccessLevel.disabled:
                    props['disabled'] = true;
                    break;
                case AccessLevel.readOnly:
                    props['readOnly'] = true;
                    break;
                case AccessLevel.actionable:
                    break;
                case AccessLevel.enterable:
                    break;
                default:
                    throw new Error(
                        `Unknown access level ${properties['accessLevel']}`);
            }
        }
        else
        {
            result = false;
        }

        return result;
    }

    public static setHelperText(
        component: React.Component,
        errors: string[],
        props: object)
    {
        if (errors.length === 0)
        {
            props['error'] = false;
            props['helperText'] = component.props['helperText'];
        }
        else
        {
            const errorElement: React.ReactNode =
                <div style={{
                    overflow: 'visible',
                    whiteSpace: 'normal',
                }}>
                    {Api.getErrorMessages(errors)}
                </div>;

            props['error'] = true;

            if (component.props['helperText'])
            {
                props['helperText'] =
                    <div>
                        <Typography variant="caption">
                            {component.props['helperText']}
                        </Typography>
                        {errorElement}
                    </div>;
            }
            else
            {
                props['helperText'] = errorElement;
            }
        }
    }
}
