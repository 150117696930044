import { createStyles, Theme, WithStyles, withStyles }
    from '@material-ui/core/styles';
import * as React from 'react';
import Sys from '../core/Sys';
import Icon from '../coreui/Icon';
import { TableChildProps } from '../coreui/Table';
import CustomActionButton, { CustomActionButton as CustomActionButtonBase }
    from './ActionButtons/CustomActionButton';
import DeletePresentationButton,
    { DeletePresentationButton as DeletePresentationButtonBase }
    from './ActionButtons/DeletePresentationButton';
import DeleteRowButton, { DeleteRowButton as DeleteRowButtonBase }
    from './ActionButtons/DeleteRowButton';
import NewRowButton, { NewRowButton as NewRowButtonBase }
    from './ActionButtons/NewRowButton';
import SavePresentationButton,
    { SavePresentationButton as SavePresentationButtonBase }
    from './ActionButtons/SavePresentationButton';
import WizardNextButton, { WizardNextButton as WizardNextButtonBase }
    from './ActionButtons/WizardNextButton';
import WizardPreviousButton,
    { WizardPreviousButton as WizardPreviousButtonBase }
    from './ActionButtons/WizardPreviousButton';
import { AccessLevel, ValueByBreakpoint } from './Api';
import { MenuItemProps } from './MenuItem';
import { SelectChildProps } from './SelectControl';
import { ToolbarChildProps } from './Toolbar';

export interface ActionButtonProps
{
    alternateText: string;
    buttonColor: ButtonColor;
    dataId: string;
    disabledHelpText: string;
    iconName: string;
    name: string;
    propagated: TableChildProps & SelectChildProps & ToolbarChildProps;
    size: ButtonSize;
    uploadOnNew?: boolean;
}

export type ButtonAction = 'Delete Presentation' | 'Delete Row' | 'New Row'
    | 'Save Presentation' | 'Wizard Next' | 'Wizard Previous';

export type ButtonColor = 'Danger' | 'Dark' | 'Default' | 'Success';

export interface ButtonSize
    extends ValueByBreakpoint<'large' | 'medium' | 'small'>
{
}

export interface ActionButtonRuntimeProps
{
    accessLevel: AccessLevel;
    cancelButtonText: string;
    confirmMessage: string;
    continueButtonText: string;
    label: string;
    showDisabledHelp: boolean;
    validateCaptcha: boolean;
}

interface Props extends ActionButtonProps
{
    buttonAction?: ButtonAction;
}

const styles = (theme: Theme) => createStyles(
    {
        root:
        {
        },
    });

export class ActionButton extends
    React.PureComponent<Props & WithStyles<typeof styles>>
{
    public static renderMenuItem(props: MenuItemProps): JSX.Element
    {
        const configProps = props.config as { buttonAction: ButtonAction };

        if (configProps.buttonAction === null)
        {
            return <CustomActionButtonBase.renderMenuItem {...props} />;
        }

        switch (configProps.buttonAction)
        {
            case 'Delete Presentation':
                return <DeletePresentationButtonBase.renderMenuItem {...props} />;
            case 'Delete Row':
                return <DeleteRowButtonBase.renderMenuItem {...props} />;
            case 'New Row':
                return <NewRowButtonBase.renderMenuItem {...props} />;
            case 'Save Presentation':
                return <SavePresentationButtonBase.renderMenuItem {...props} />;
            case 'Wizard Next':
                return <WizardNextButtonBase.renderMenuItem {...props} />;
            case 'Wizard Previous':
                return <WizardPreviousButtonBase.renderMenuItem {...props} />;
            default:
                throw new Error(
                    `Unknown button action ${configProps.buttonAction}`);
        }
    }

    public static showSavedToast(iconName?: string)
    {
        Sys.showToast(
            <div style={{ alignItems: 'center', display: 'flex' }}>
                {iconName ? (
                    <Icon
                        icon={iconName}
                        style={{ marginRight: '.4em' }}
                    />
                ) : null}
                Saved
            </div>,
            true,
            undefined,
            'right',
            undefined,
            5000);
    }

    public render()
    {
        const { buttonAction, classes, ...buttonProps } = this.props;

        if (this.props.buttonAction === null)
        {
            return <CustomActionButton {...buttonProps} />;
        }

        switch (this.props.buttonAction)
        {
            case 'Delete Presentation':
                return <DeletePresentationButton {...buttonProps} />;
            case 'Delete Row':
                return <DeleteRowButton {...buttonProps} />;
            case 'New Row':
                return <NewRowButton {...buttonProps} />;
            case 'Save Presentation':
                return <SavePresentationButton {...buttonProps} />;
            case 'Wizard Next':
                return <WizardNextButton {...buttonProps} />;
            case 'Wizard Previous':
                return <WizardPreviousButton {...buttonProps} />;
            default:
                throw new Error(
                    `Unknown button action ${this.props.buttonAction}`);
        }
    }
}

export default withStyles(styles)(ActionButton);
