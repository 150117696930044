import * as muiMenuItem from '@material-ui/core/MenuItem';
import { createStyles, Theme, WithStyles, withStyles, WithTheme, withTheme }
    from '@material-ui/core/styles';
import * as React from 'react';
import WithForwardedRef, { WithForwardedRefProps }
    from '../core/WithForwardedRef';
import Typography from '../coreui/Typography';
import Icon from './Icon';

export interface MenuItemProps
    extends muiMenuItem.MenuItemProps, WithForwardedRefProps
{
    button?: true;
    iconName?: string;
    indent?: number;
}

const styles = (theme: Theme) => createStyles(
    {
        disabled:
        {
            '& > p': { color: theme.palette.grey[300] },
            '& > span': { color: theme.palette.grey[300] },
            '&:hover': { backgroundColor: 'transparent' },
            cursor: 'default',
        },
        root:
        {
            '& > p': { color: theme.palette.grey[800] },
            color: theme.palette.grey[800],
        },
    });

export class MenuItem extends
    React.PureComponent<MenuItemProps & WithTheme & WithStyles<typeof styles>>
{
    public render()
    {
        const { forwardedRef, ..._props } = this.props;

        if (!_props.className)
        {
            _props.className = `${_props.classes.root} `;
        }
        else
        {
            _props.className += ' ';
        }

        if (_props.disabled)
        {
            _props.className += `${_props.classes.disabled} `;
        }

        if (_props.indent)
        {
            _props.style = { marginLeft: _props.indent };
        }

        if (typeof _props.children === 'string')
        {
            _props.children =
                <Typography ellipsis>{_props.children}</Typography>;
        }

        if (_props.iconName)
        {
            _props.children =
                <React.Fragment>
                    <Icon
                        icon={_props.iconName}
                        style={
                            {
                                marginRight: '.4em',
                            }}
                    />
                    {_props.children}
                </React.Fragment>;
        }

        delete _props.classes;
        delete _props.iconName;

        return <muiMenuItem.default {..._props} ref={forwardedRef} />;
    }
}

export default withStyles(styles)(withTheme(WithForwardedRef(MenuItem)));
