import { createStyles, Theme, WithStyles, withStyles }
    from '@material-ui/core/styles';
import withWidth, { WithWidth } from '@material-ui/core/withWidth';
import { observer } from 'mobx-react';
import * as React from 'react';
import Presentation from '../coreui/Presentation';

interface ConfigProperties
{
    horizontalLayout: object;
    propagated: object;
    verticalLayout: object;
}

const styles = (theme: Theme) => createStyles(
    {
    });

@observer
export class ResponsiveGrid extends
    React.Component<ConfigProperties & WithWidth & WithStyles<typeof styles>>
{
    private horizontalLayout: React.ReactNode | null = null;
    private verticalLayout: React.ReactNode | null = null;

    public constructor(
        props: ConfigProperties & WithWidth & WithStyles<typeof styles>)
    {
        super(props);

        if (props.horizontalLayout)
        {
            this.horizontalLayout = Presentation.create(
                props.horizontalLayout,
                props.propagated);
        }
        if (props.verticalLayout)
        {
            this.verticalLayout = Presentation.create(
                props.verticalLayout,
                props.propagated);
        }
    }

    public render()
    {
        // The key attribute tells React to recreate the component from
        // scratch when the break point changes. Without this child
        // components are reused and constructors are not called

        if (this.props.verticalLayout && this.props.width === 'xs')
        {
            return (
                <React.Fragment key="vertical">
                    {this.verticalLayout}
                </React.Fragment>
            );
        }
        if (this.props.horizontalLayout && this.props.width !== 'xs')
        {
            return (
                <React.Fragment key="horizontal">
                    {this.horizontalLayout}
                </React.Fragment>
            );
        }

        // If a grid is moved between break points so the presentation server
        // can't combine them, multiple responsive grids will be rendered
        // causing them to trigger this code path at some break points
        return null;
    }
}

export default withStyles(styles)(withWidth()(ResponsiveGrid));
