import { createStyles, Theme, WithStyles, withStyles }
    from '@material-ui/core/styles';
import * as React from 'react';
import WithForwardedRef, { WithForwardedRefProps }
    from '../core/WithForwardedRef';

interface Props extends WithForwardedRefProps
{
    className?: string;
    indent?: number;
}

const styles = (theme: Theme) => createStyles(
    {
        root:
        {
            ...theme.overrides?.MuiMenuItem?.gutters,
            boxSizing: 'border-box',
        },
    });

export class MenuItemDisplay extends
    React.PureComponent<Props & WithStyles<typeof styles>>
{
    public render()
    {
        const rootClasses: string[] = [this.props.classes.root];
        if (this.props.className)
        {
            rootClasses.push(this.props.className);
        }

        return (
            <div
                className={rootClasses.join(' ')}
                ref={this.props.forwardedRef}
                style={{ marginLeft: this.props.indent ?? 0 }}
            >
                {this.props.children}
            </div>
        );
    }
}

export default withStyles(styles)(WithForwardedRef(MenuItemDisplay));
