import * as muiButtonBase from '@material-ui/core/ButtonBase';
import { createStyles, Theme, WithStyles, withStyles }
    from '@material-ui/core/styles';
import * as React from 'react';
import Sys from '../../core/Sys';

interface Props
{
    href?: string;
    imageSrc: string;
    onClick?: () => void;
    style?: React.CSSProperties;
}

const styles = (theme: Theme) => createStyles(
    {
        ripple:
        {
            height: 'calc(100% + 30px)',
            margin: '-15px',
            width: 'calc(100% + 30px)',
        },
        root:
        {
            '& > img':
            {
                maxHeight: '100%',
                maxWidth: '100%',
            },
            [theme.breakpoints.up('xs')]:
            {
                height: 40,
                marginRight: 16,
            },
            [theme.breakpoints.up('md')]:
            {
                height: 60,
                marginRight: 24,
            },
            [theme.breakpoints.up('lg')]:
            {
                marginRight: 40,
            },
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            maxWidth: '25%',
            outline: 'none',
            overflow: 'hidden',
        },
    });

export class Logo extends
    React.PureComponent<Props & WithStyles<typeof styles>>
{
    public render()
    {
        return (
            <muiButtonBase.default
                aria-label={Sys.getTranslation('Home')}
                className={this.props.classes.root}
                focusRipple
                href={this.props.href!}
                onClick={this.props.onClick}
                TouchRippleProps={{ className: this.props.classes.ripple }}
            >
                <img aria-hidden={true} src={this.props.imageSrc} />
            </muiButtonBase.default>);
    }
}

export default withStyles(styles)(Logo);
