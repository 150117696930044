import AppServer, { State as AppServerState } from '../core/AppServer';
import RequestPromise from '../core/RequestPromise';
import Sys, { BusinessError } from '../core/Sys';
import Presentation from '../coreui/Presentation';
import PaneRow from '../models/PaneRow';
import { EmbeddedAddOn as EmbeddedAddOnBase } from '../mustangui/EmbeddedAddOn';
import LayoutStateStore from '../stores/LayoutStateStore';
import PaneDataStore, { PaneDataByDataId } from '../stores/PaneDataStore';
import RequestsStore from '../stores/RequestsStore';
import BaseService, { Response } from './BaseService';

export interface RoundTripResponse
{
    appServerState: AppServerState;
    businessErrors: BusinessError[];
    layoutId?: string;
    newObjectId?: string;
    paneDataByDataId: PaneDataByDataId;
    url?: string;
    validationErrors?: string[];
}

export default class RoundTripService
{
    public static customRoundTrip<T>(
        url: string,
        urlParams?: object,
        postArgs?: object
        ): RequestPromise<T>
    {
        EmbeddedAddOnBase.instances.forEach(
            (embeddedAddOn: EmbeddedAddOnBase) =>
            {
                embeddedAddOn.roundTripStarting();
            });

        const args = {
            jsonData: JSON.stringify(
                {
                    appServerState: AppServer.getState(),
                    dataChanges: PaneDataStore.getChanges(),
                    layoutState: LayoutStateStore.getCurrentState(),
                    ...postArgs,
                }),
        };

        Sys.clearErrors();

        let request: RequestPromise<Response>;

        return new RequestPromise<T>(
            (resolve, reject) =>
            {
                request = BaseService.request(
                    Sys.getUrl(url, urlParams), args, 'POST'
                );
                request.then(
                    response => resolve(JSON.parse(response.responseText))
                ).catch(response => reject(response));
            },
            () =>
            {
                request.abort();
            });
    }

    public static partialDataRetrevial<T>(
        url: string,
        urlParams?: object,
        postArgs?: object
        ): RequestPromise<T>
    {
        const args = {
            jsonData: JSON.stringify(
                {
                    appServerState: AppServer.getState(),
                    dataChanges: PaneDataStore.getChanges(),
                    ...postArgs,
                }),
        };

        let request: RequestPromise<Response>;

        return new RequestPromise<T>(
            (resolve, reject) =>
            {
                request = BaseService.request(
                    Sys.getUrl(url, urlParams), args, 'POST', false
                );
                request.then(
                    response => resolve(JSON.parse(response.responseText))
                ).catch(response => reject(response));
            },
            () =>
            {
                request.abort();
            });
    }

    public static standardRoundTrip(
        url: string,
        props: {
            dataId?: string;
            name?: string;
        },
        jsonData?: object,
        disableMask: boolean = false
        ): RequestPromise<RoundTripResponse>
    {
        if (!props.dataId || !props.name)
        {
            throw new Error('Invalid round trip properties');
        }

        let request: RequestPromise<Response>;

        return new RequestPromise<RoundTripResponse>(
            (resolve, reject) =>
            {
                if (!disableMask)
                {
                    RequestsStore.instance.processingStarted();
                }

                EmbeddedAddOnBase.instances.forEach(
                    (embeddedAddOn: EmbeddedAddOnBase) =>
                    {
                        embeddedAddOn.roundTripStarting();
                    });

                const paneRow = Presentation.getObservable(props)! as PaneRow;

                request = BaseService.request(
                    `${url}/${paneRow.rowKey}/${props.dataId}/${props.name}`,
                    {
                        jsonData: JSON.stringify(
                            {
                                appServerState: AppServer.getState(),
                                dataChanges: PaneDataStore.getChanges(),
                                layoutState: LayoutStateStore.getCurrentState(),
                                ...jsonData,
                            }),
                    },
                    'POST');

                request.then((response) =>
                {
                    const parsedResponse =
                        JSON.parse(response.responseText) as RoundTripResponse;

                    if (parsedResponse.validationErrors
                        && parsedResponse.validationErrors.length > 0)
                    {
                        Sys.clearErrors();
                        Sys.showErrors(parsedResponse.validationErrors);
                        reject(parsedResponse);

                        return;
                    }

                    AppServer.setState(parsedResponse.appServerState);

                    if (parsedResponse.businessErrors.length > 0)
                    {
                        Sys.clearBusinessErrors();
                    }

                    PaneDataStore.loadResponse(parsedResponse.paneDataByDataId);

                    if (Sys.setBusinessErrors(
                        parsedResponse.businessErrors, false))
                    {
                        resolve(parsedResponse);
                        return;
                    }

                    if ('url' in parsedResponse && parsedResponse.url)
                    {
                        Sys.ignoreChanges = true;

                        if (window.location.hash === parsedResponse.url)
                        {
                            window.location.reload();
                        }
                        else if (!parsedResponse.url.startsWith('#'))
                        {
                            window.location.href = parsedResponse.url;
                        }
                        else if (paneRow.isNew)
                        {
                            Sys.setHash(parsedResponse.url.substr(1));
                        }
                        else
                        {
                            window.location.assign(parsedResponse.url);
                        }
                    }

                    resolve(parsedResponse);
                }).catch(response => reject(response));
            },
            () =>
            {
                request.abort();
            }
        ).finally(() =>
        {
            if (!disableMask)
            {
                RequestsStore.instance.processingStopped();
            }
        });
    }
}
