import { createStyles, Theme, WithStyles, withStyles }
    from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import * as React from 'react';
import Sys from '../core/Sys';
import ComboBoxOption from '../coreui/ComboBoxOption';
import Presentation from '../coreui/Presentation';
import { TableVerticalLayoutProps } from '../coreui/Table';
import PaneRow from '../models/PaneRow';
import RoundTripService from '../services/RoundTripService';
import { AccessLevel } from './Api';
import ApiSelect from './ApiSelect';

interface Props
{
    dataId: string;
    disabledHelpText: string;
    helperText: string;
    label: string;
    name: string;
    propagated?: TableVerticalLayoutProps;
    roundTripOnChange: boolean;
}

interface RuntimeProperties
{
    accessLevel: AccessLevel;
    businessErrors: string[];
    options: ComboBoxOption[];
    selectedDisplayValue: string;
    selectedValue: string | null;
    showAsMandatory: boolean;
    showDisabledHelp: boolean;
}

const styles = (theme: Theme) => createStyles(
    {
        root:
        {
            minWidth: 150,
        },
    });

@observer
export class RelationshipComboBox extends
    React.Component<Props & WithStyles<typeof styles>>
{
    private getOptions(runtimeProps: RuntimeProperties)
    {
        // Check if Widget is rendered in the vertical layout of a grid
        if (this.props.propagated?.parentTable)
        {
            const selectedOption: ComboBoxOption =
            {
                display: runtimeProps.selectedDisplayValue,
                value: runtimeProps.selectedValue,
            };

            const table = this.props.propagated.parentTable;
            return table.getRelationshipComboBoxOptions(
                this.props.name,
                selectedOption);
        }

        const options = [...runtimeProps.options];

        if (runtimeProps.selectedValue)
        {
            // Add the initially selected value as a historic option if is not
            // among the current candidates
            const optionInList = options.find(
                o => o.value === runtimeProps.selectedValue);

            if (optionInList === undefined)
            {
                const historicOption: ComboBoxOption =
                {
                    display: runtimeProps.selectedDisplayValue,
                    historic: true,
                    value: runtimeProps.selectedValue,
                };

                options.push(historicOption);
            }
        }

        return options;
    }

    private onSelect = (event: React.ChangeEvent<HTMLInputElement>) =>
    {
        const value = Presentation.getValue(this.props);

        Sys.clearBusinessErrors(this.props.dataId, this.props.name);
        Presentation.setValue(this.props, event.target.value);

        if (!this.props.roundTripOnChange)
        {
            return;
        }

        RoundTripService.standardRoundTrip(
            'RelationshipComboBox/OnChange', this.props
        ).catch(() =>
        {
            // If the round trip fails, undo the value change.
            Presentation.setValue(this.props, value);
        });
    };

    public render(): React.ReactNode
    {
        let rowKey = null;
        if (this.props.propagated?.parentTable)
        {
            // Widget is rendered in the vertical layout of a grid.
            rowKey = this.props.propagated.rowKey;
        }

        const row = PaneRow.get(this.props.dataId, rowKey);
        if (!row)
        {
            return null;
        }

        const widget = row.getWidget(this.props.name);
        const runtimeProps = widget.properties as RuntimeProperties;

        const options = this.getOptions(runtimeProps);

        return (
            <ApiSelect
                accessLevel={runtimeProps.accessLevel}
                businessErrors={runtimeProps.businessErrors}
                classes={this.props.classes}
                dataId={this.props.dataId}
                disabledHelpText={this.props.disabledHelpText}
                helperText={this.props.helperText}
                label={this.props.label}
                name={this.props.name}
                onSelect={this.onSelect}
                options={options}
                propagated={this.props.propagated}
                roundTripOnChange={this.props.roundTripOnChange}
                showAsMandatory={runtimeProps.showAsMandatory}
                showDisabledHelp={runtimeProps.showDisabledHelp}
            />);
    }
}

export default withStyles(styles)(RelationshipComboBox);
