import { CellFocusedEvent, ICellRendererComp, ICellRendererParams, RowNode }
    from 'ag-grid-community';
import { TableChildProps } from '../Table';

interface CellRenderer extends ICellRendererComp
{
    getFrameworkComponentInstance: () => { props: FocusCellRendererParams };
}

export interface FocusCellRendererParams extends ICellRendererParams
{
    propagated: TableChildProps;
}

interface FocusSubscriber
{
    columnId: string;
    onBlur: () => void;
    onFocus: () => void;
    rowIndex: number;
    rowPinned: string | null;
}

export class CellFocusUtil
{
    private static focusSubscribers:
        { [tableKey: string]: FocusSubscriber[] } = {};

    private static lastFocusedSubscriber: FocusSubscriber | null = null;

    private static onCellEditingStarted()
    {
        if (CellFocusUtil.lastFocusedSubscriber)
        {
            CellFocusUtil.lastFocusedSubscriber.onBlur();
        }
    }

    private static onCellFocused(event: CellFocusedEvent)
    {
        if (event.column === null || event.rowIndex === null)
        {
            return;
        }

        if (CellFocusUtil.lastFocusedSubscriber)
        {
            CellFocusUtil.lastFocusedSubscriber.onBlur();
        }

        // Exclude mouse focus events
        if (!event.forceBrowserFocus)
        {
            return;
        }

        let row: RowNode;
        if (event.rowPinned)
        {
            row = event.api.getPinnedTopRow(event.rowIndex);
        }
        else
        {
            row = event.api.getDisplayedRowAtIndex(event.rowIndex);
        }
        if (!row)
        {
            return;
        }

        const instances = event.api.getCellRendererInstances(
            { columns: [event.column], rowNodes: [row] });

        const renderer = instances[0] as CellRenderer;
        if (!renderer)
        {
            return;
        }

        const rendererProps = renderer.getFrameworkComponentInstance().props;
        const parentTableKey =
            rendererProps.propagated.parentTable.configProps.tableKey;

        const subscribers = CellFocusUtil.focusSubscribers[parentTableKey];
        for (const subscriber of subscribers)
        {
            if (subscriber.columnId === event.column.getColId()
                && subscriber.rowIndex === event.rowIndex
                && subscriber.rowPinned === event.rowPinned)
            {
                subscriber.onFocus();
                CellFocusUtil.lastFocusedSubscriber = subscriber;
            }
        }
    }

    public static subscribeToCellKeyboardFocusedEvent(
        params: FocusCellRendererParams,
        onFocus: () => void,
        onBlur: () => void)
    {
        const parentTableKey =
            params.propagated.parentTable.configProps.tableKey;

        if (!(parentTableKey in CellFocusUtil.focusSubscribers))
        {
            CellFocusUtil.focusSubscribers[parentTableKey] = [];

            params.api.addEventListener(
                'cellFocused', CellFocusUtil.onCellFocused);
            params.api.addEventListener(
                'cellEditingStarted', CellFocusUtil.onCellEditingStarted);
        }

        CellFocusUtil.focusSubscribers[parentTableKey].push(
            {
                columnId: params.column.getColId(),
                onBlur,
                onFocus,
                rowIndex: params.rowIndex,
                rowPinned: params.data.isNew ? 'top' : null,
            });
    }

    public static unsubscribeToCellKeyboardFocusedEvent(
        params: FocusCellRendererParams,
        onFocus: () => void,
        onBlur: () => void)
    {
        const parentTableKey =
            params.propagated.parentTable.configProps.tableKey;

        CellFocusUtil.focusSubscribers[parentTableKey] =
            CellFocusUtil.focusSubscribers[parentTableKey].filter(
                s => s.onBlur !== onBlur);

        if (CellFocusUtil.lastFocusedSubscriber
            && CellFocusUtil.lastFocusedSubscriber.onBlur === onBlur)
        {
            CellFocusUtil.lastFocusedSubscriber = null;
        }

        if (CellFocusUtil.focusSubscribers[parentTableKey].length <= 0)
        {
            delete CellFocusUtil.focusSubscribers[parentTableKey];

            params.api.removeEventListener(
                'cellFocused', CellFocusUtil.onCellFocused);
            params.api.removeEventListener(
                'cellEditingStarted', CellFocusUtil.onCellEditingStarted);
        }
    }
}
