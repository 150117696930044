import { PropTypes } from '@material-ui/core';
import { createStyles, Theme, WithStyles, withStyles }
    from '@material-ui/core/styles';
import { Variant } from '@material-ui/core/styles/createTypography';
import * as muiTypography from '@material-ui/core/Typography';
import withWidth, { WithWidth } from '@material-ui/core/withWidth';
import { observer } from 'mobx-react';
import * as React from 'react';
import Sys from '../core/Sys';
import Api, { AccessLevel, ValueByBreakpoint } from './Api';

interface ConfigProperties
{
    dataId: string;
    justification?: Justification | ValueByBreakpoint<Justification>;
    listStyle: boolean;
    name: string;
    textStyle: 'Body 1' | 'Body 2' | 'Caption';
}

type Justification = 'Centered' | 'Full' | 'Left' | 'Right';

interface RuntimeProperties
{
    accessLevel: AccessLevel;
    bodyText: string[];
}

const styles = (theme: Theme) => createStyles(
    {
        list:
        {
            marginBottom: 0,
            marginTop: 0,
            paddingLeft: Sys.settings.baselineGridSize * 10,
        },
        root:
        {
            [theme.breakpoints.up('md')]:
            {
                maxWidth: '600px',
            },
            [theme.breakpoints.up('lg')]:
            {
                maxWidth: '580px',
            },
        },
        spacer:
        {
            marginTop: Sys.settings.baselineGridSize * 6,
        },
    });

@observer
export class TextDisplay extends
    React.Component<ConfigProperties & WithWidth & WithStyles<typeof styles>>
{
    public render()
    {
        const runtimeProperties: RuntimeProperties =
            Api.getWidgetProperties(this.props) as RuntimeProperties;

        if (!runtimeProperties)
        {
            return null;
        }

        if (runtimeProperties.accessLevel === AccessLevel.hidden)
        {
            return null;
        }

        const children: React.ReactNode[] = [];

        let align: PropTypes.Alignment = 'left';
        if (this.props.justification && !this.props.listStyle)
        {
            if (typeof this.props.justification === 'string')
            {
                align = Api.getAlignment(this.props.justification);
            }
            else if (this.props.width in this.props.justification)
            {
                align = Api.getAlignment(
                    this.props.justification[this.props.width]);
            }
        }

        const variant: Variant = Api.getTextStyle(this.props.textStyle);

        runtimeProperties.bodyText.forEach((item: string, index: number) =>
        {
            children.push(
                <muiTypography.default
                    align={align}
                    className={`${this.props.classes.root} `
                        + `${index > 0 ? this.props.classes.spacer : ''}`}
                    component={this.props.listStyle ? 'li' : undefined!}
                    key={index}
                    style={{
                        display: this.props.listStyle ? 'list-item' : undefined,
                    }}
                    variant={variant}
                >
                    {item}
                </muiTypography.default>
            );
        });

        if (this.props.listStyle)
        {
            return <ul className={this.props.classes.list}>{children}</ul>;
        }

        return <div>{children}</div>;
    }
}

export default withStyles(styles)(withWidth()(TextDisplay));
