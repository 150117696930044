import * as muiBadge from '@material-ui/core/Badge';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { createStyles, Theme, WithStyles, withStyles }
    from '@material-ui/core/styles';
import { darken } from '@material-ui/core/styles/colorManipulator';
import * as React from 'react';
import Api from '../mustangui/Api';
import Button from './Button';
import Tooltip from './Tooltip';

// This props does not extend badge properties because badgeContent is
// required and we don't want to have to pass a value for this type of  badge.
interface Props
{
    fullWidth?: boolean;
    message?: string;
    tabIndex?: number;
}

interface State
{
    open: boolean;
}

const styles = (theme: Theme) => createStyles(
    {
        badge:
        {
        },
        button:
        {
            '&:hover':
            {
                backgroundColor: darken(Api.getSystemColor('information'), 0.1),
            },
            backgroundColor: Api.getSystemColor('information'),
            color: theme.palette.getContrastText(
                Api.getSystemColor('information')),
        },
        root:
        {
        },
    });

export class InformationBadge extends
    React.PureComponent<Props & WithStyles<typeof styles>, State>
{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private buttonRef: any;

    public constructor(props: Props & WithStyles<typeof styles>)
    {
        super(props);

        this.state = { open: false };
    }

    public render()
    {
        const _props = { ...this.props };
        const message = _props.message;
        const children = _props.children;
        let width: string = '100%';

        delete _props.message;

        if ('fullWidth' in _props)
        {
            if (!_props.fullWidth)
            {
                width = 'auto';
            }

            delete _props.fullWidth;
        }

        delete _props.children;
        delete _props.classes;

        return (
            <muiBadge.default
                {..._props}
                classes={{
                    badge: this.props.classes.badge,
                    root: this.props.classes.root,
                }}
                style={{ width }}
                badgeContent={
                    <ClickAwayListener
                        onClickAway={() => this.setState({ open: false })}
                    >
                        <Tooltip
                            disableHoverListener
                            enterTouchDelay={0}
                            open={this.state.open}
                            title={message}
                        >
                            <Button
                                badge
                                buttonRef={r => this.buttonRef = r}
                                classes={{ root: this.props.classes.button }}
                                component="div"
                                icon="fas fa-question"
                                onClick={() =>
                                {
                                    // Fix for Safari not opening tooltip
                                    this.buttonRef.focus();
                                    this.setState({ open: true });
                                }}
                                tabIndex={_props.tabIndex}
                            />
                        </Tooltip>
                    </ClickAwayListener>}
            >
                {children}
            </muiBadge.default>);
    }
}

export default withStyles(styles)(InformationBadge);
