import * as muiPaper from '@material-ui/core/Paper';
import { createStyles, Theme, WithStyles, withStyles }
    from '@material-ui/core/styles';
import * as React from 'react';
import Logging from '../core/Logging';
import Sys from '../core/Sys';
import PaneRow from '../models/PaneRow';
import { CustomTheme } from '../muiTheme';
import Collapse, { Collapse as CollapseBase } from './Collapse';
import Fade from './Fade';

export interface PaperProps extends muiPaper.PaperProps
{
    blended?: boolean;
    card?: boolean;
    cardDepth?: number;
    collapse?: boolean;
    dataId?: string;
    fade?: number;
    group?: string | null;
    hidden?: boolean;
    margin?: boolean;
    name?: string | null;
}

interface State
{
    isSwitching?: boolean;
    visible?: boolean;
}

const styles = (theme: CustomTheme) => createStyles(
    {
        blended:
        {
            backgroundColor: 'transparent',
        },
        card:
        {
            backgroundColor: theme.palette.grey[100],
        },
        cardAlternate:
        {
            backgroundColor: theme.palette.common.white,
        },
        margin:
        {
            [theme.breakpoints.only('xs')]:
            {
                padding: theme.paper.padding.xs,
            },
            [theme.breakpoints.only('sm')]:
            {
                padding: theme.paper.padding.sm,
            },
            [theme.breakpoints.only('md')]:
            {
                padding: theme.paper.padding.md,
            },
            [theme.breakpoints.up('lg')]:
            {
                padding: theme.paper.padding.lg,
            },
        },
        root:
        {
        },
    });

export class Paper extends
    React.PureComponent<PaperProps & WithStyles<typeof styles>, State>
{
    // Dictionary of paper name lists, keyed by group.
    public static groups = new Map<string, Set<string>>();
    // Dictionary of paper components, keyed by name.
    public static instances = new Map<string, Set<Paper>>();

    private static addInstance(instance: Paper, name: string, group: string | null)
    {
        if (!Paper.instances.has(name))
        {
            Paper.instances.set(name, new Set<Paper>());
        }

        Paper.instances.get(name)!.add(instance);

        if (!group)
        {
            return;
        }

        if (!Paper.groups.get(group))
        {
            Paper.groups.set(group, new Set<string>());
        }

        Paper.groups.get(group)!.add(name);
    }

    public static hide(name?: string | null, switching: boolean = false): boolean
    {
        if (!name || !Paper.instances.has(name))
        {
            return false;
        }

        let result = false;
        Paper.instances.get(name)!.forEach((paper) =>
        {
            const hidden = paper.hide(switching);
            if (hidden)
            {
                result = true;
            }
        });

        return result;
    }

    public static hideGroup(group?: string | null)
    {
        if (!group || !Paper.groups.has(group))
        {
            return;
        }

        Paper.groups.get(group)!.forEach((name) =>
        {
            Paper.hide(name, false);
        });
    }

    public static show(name?: string | null)
    {
        if (!name || !Paper.instances.has(name))
        {
            return;
        }

        Paper.instances.get(name)!.forEach((paper) =>
        {
            paper.show();
        });
    }

    public static toggle(name?: string | null)
    {
        if (!name || !Paper.instances.has(name))
        {
            return;
        }

        Paper.instances.get(name)!.forEach((paper) =>
        {
            paper.toggle();
        });
    }

    constructor(props: PaperProps & WithStyles<typeof styles>)
    {
        super(props);

        if ('hidden' in props)
        {
            this.state = { visible: !props.hidden };
        }
        else if (props.collapse || props.group)
        {
            this.state = { visible: false };
        }
        else
        {
            this.state = { visible: true };
        }

        if (props.name)
        {
            Paper.addInstance(this, props.name, props.group || null);
        }
    }

    public componentWillUnmount()
    {
        if (!this.props.name || !Paper.instances.has(this.props.name))
        {
            return;
        }

        Paper.instances.get(this.props.name)!.delete(this);

        if (Paper.instances.get(this.props.name)!.size === 0)
        {
            Paper.instances.delete(this.props.name);
        }

        if (!this.props.group || !Paper.groups.has(this.props.group))
        {
            return;
        }

        const group: Set<string> = Paper.groups.get(this.props.group)!;

        if (group.has(this.props.name))
        {
            group.delete(this.props.name);

            if (group.size === 0)
            {
                Paper.groups.delete(this.props.group);
            }
        }
    }

    public hide(switching: boolean = false): boolean
    {
        if (!this.state.visible)
        {
            return false;
        }

        this.setState({ isSwitching: switching }, () =>
        {
            this.setState({ visible: false });

            if (this.props.collapse || this.props.fade)
            {
                CollapseBase.close(this.props.name);
            }
        });

        return true;
    }

    public render(): React.ReactNode
    {
        const {
            blended,
            card,
            cardDepth,
            children,
            classes,
            collapse,
            dataId,
            fade,
            group,
            hidden,
            margin,
            name,
            ...paperProps
        } = this.props;

        let paperChildren = children;
        if (dataId)
        {
            // FUTURE
            // This is in place for the custom pane display on the sub-pane
            // control because all sub-panes are rendered, but in the case of
            // the custom pane display, not all will have their data loaded.
            // This mechanism needs to be in Paper rather than in SubPane
            // because Paper is dynamically hidden and shown, which does not
            // cause the parent component to re-render when the Paper is shown.
            //
            // A better approach that is more in line with how React and Mob-X
            // is intended to work would be to have the sub-pane control use its
            // props to determine which sub-pane to render and then render only
            // that one; rather than using static methods on Paper to manually
            // hide or show a sub-pane as is currently the case.
            const row = PaneRow.get(dataId);

            if (!row)
            {
                paperChildren = null;
            }
            else if (!row.isLoaded)
            {
                paperChildren = null;
            }
        }

        paperProps.className = `${classes.root} `
            + `${paperProps.className ? paperProps.className : ''} `;

        if (blended)
        {
            paperProps.className += `${classes.blended} `;
        }

        if (card)
        {
            if (cardDepth === undefined)
            {
                Logging.log(this.props);
                throw new Error(`Card depth is required on ${name}`);
            }

            if (cardDepth % 2 === 0)
            {
                paperProps.className += `${classes.card} `;
            }
            else
            {
                paperProps.className += `${classes.cardAlternate} `;
            }
        }

        if (margin)
        {
            paperProps.className += `${classes.margin} `;
        }
        else
        {
            paperProps.elevation = 0;
        }

        if (collapse)
        {
            return (
                <Collapse in={this.state.visible!} name={name}>
                    <muiPaper.default {...paperProps}>
                        {paperChildren}
                    </muiPaper.default>
                </Collapse>
            );
        }

        if (fade && fade > 0)
        {
            if (this.state.isSwitching)
            {
                if (this.state.visible)
                {
                    return (
                        <Fade timeout={fade}>
                            <muiPaper.default {...paperProps}>
                                {paperChildren}
                            </muiPaper.default>
                        </Fade>
                    );
                }

                return null;
            }

            return (
                <Collapse in={this.state.visible!} name={name}>
                    <muiPaper.default {...paperProps}>
                        {paperChildren}
                    </muiPaper.default>
                </Collapse>
            );
        }

        let displayStyle = 'none';
        if (this.state.visible)
        {
            displayStyle = 'inherit';
            if (!this.props.blended)
            {
                displayStyle = 'block';
            }
        }

        return (
            <muiPaper.default
                {...paperProps}
                style={{
                    display: displayStyle,
                    flexGrow: 1,
                }}
            >
                {paperChildren}
            </muiPaper.default>);
    }

    public show()
    {
        if (this.state.visible)
        {
            return;
        }

        if (this.props.collapse)
        {
            CollapseBase.open(this.props.name);
        }
        else if (this.props.group && Paper.groups.has(this.props.group))
        {
            let paperHidden = false;
            Paper.groups.get(this.props.group)!.forEach((name) =>
            {
                if (name !== this.props.name)
                {
                    const hidden = Paper.hide(name, true);
                    if (hidden)
                    {
                        paperHidden = true;
                    }
                }
            });

            if (!paperHidden)
            {
                CollapseBase.open(this.props.name);
            }

            this.setState({ isSwitching: paperHidden });
        }

        this.setState({ visible: true });
    }

    public toggle()
    {
        if (!this.state.visible)
        {
            this.show();
        }
        else
        {
            this.hide();
        }
    }
}

export default withStyles(styles)(Paper);
