import { createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import * as React from 'react';
import Sys from '../core/Sys';
import Button from '../coreui/Button';
import Grid from '../coreui/Grid';
import GridItem from '../coreui/GridItem';
import Hidden from '../coreui/Hidden';
import Typography from '../coreui/Typography';
import { CustomTheme } from '../muiTheme';
import EnvironmentBanner from './components/EnvironmentBanner';
import Logo from './components/Logo';
import CenteredContentClasses from './styles/CenteredContentClasses';

interface FooterConfig
{
    backgroundColor: string;
}

interface HeaderConfig
{
    backgroundColor: string;
    bottomBorderColor: string;
    logoUrl: string;
}

export interface ErrorPageTemplateConfig
{
    footer: FooterConfig;
    header: HeaderConfig;
}

interface Props
{
    backgroundImageUrl?: string;
    footer?: FooterConfig;
    header?: HeaderConfig;
    message: string | null;
    title: string;
}

const styles = (theme: CustomTheme) => createStyles(
    {
        backgroundImage:
        {
            [theme.breakpoints.up('lg')]:
            {
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                height: '100%',
                position: 'fixed',
                width: '100%',
                zIndex: -1,
            },
        },
        footer:
        {
            [theme.breakpoints.up('xs')]:
            {
                height: 68,
            },
            [theme.breakpoints.up('lg')]:
            {
                height: 100,
            },
            '@media print':
            {
                display: 'none',
            },
        },
        header:
        {
            [theme.breakpoints.up('xs')]:
            {
                height: 72,
            },
            [theme.breakpoints.up('sm')]:
            {
                height: 88,
            },
            [theme.breakpoints.up('md')]:
            {
                height: 108,
            },
            [theme.breakpoints.up('lg')]:
            {
                height: 140,
            },
            '@media print':
            {
                display: 'none',
            },
            borderBottomStyle: 'solid',
            borderBottomWidth: 4,
        },
        headerContent:
        {
            alignItems: 'center',
            boxSizing: 'border-box',
            display: 'flex',
            height: '100%',
        },
        main:
        {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
        },
        mainContent:
        {
            flexGrow: 1,
            paddingLeft: 16,
            paddingRight: 16,
        },
        root:
        {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
        },
        title:
        {
            [theme.breakpoints.only('xs')]:
                { fontSize: 45, lineHeight: '60px' },
            [theme.breakpoints.up('sm')]:
                { fontSize: 56, lineHeight: '72px' },
            [theme.breakpoints.up('lg')]:
                { fontSize: 112, lineHeight: '148px' },
            fontWeight: 700,
        },
        ...CenteredContentClasses.create(theme),
    });

class ErrorPageTemplate extends
    React.PureComponent<Props & WithStyles<typeof styles>>
{
    private goHome = () =>
    {
        Sys.setHash('', false, true);
        window.location.assign(Sys.settings.rootUrl);
    };

    public render(): React.ReactNode
    {
        let backgroundImage: React.ReactNode = null;
        if (this.props.backgroundImageUrl)
        {
            backgroundImage = (
                <div
                    className={this.props.classes.backgroundImage}
                    style={{
                        backgroundImage: `url(${this.props.backgroundImageUrl})`,
                    }}
                />);
        }

        let footer: React.ReactNode = null;
        if (this.props.footer)
        {
            footer = (
                <footer
                    className={this.props.classes.footer}
                    style={{
                        backgroundColor: this.props.footer.backgroundColor,
                    }}
                />);
        }

        let header: React.ReactNode = null;
        if (this.props.header)
        {
            const headerContentClasses =
                [
                    this.props.classes.headerContent,
                    CenteredContentClasses.get(null, this.props.classes),
                ];

            header = (
                <header
                    className={this.props.classes.header}
                    style={{
                        backgroundColor: this.props.header.backgroundColor,
                        borderColor: this.props.header.bottomBorderColor,
                    }}
                >
                    <div className={headerContentClasses.join(' ')}>
                        <Logo
                            imageSrc={this.props.header.logoUrl}
                            onClick={this.goHome}
                            style={{ cursor: 'pointer' }}
                        />
                    </div>
                </header>);
        }

        let message: React.ReactNode = null;
        if (this.props.message)
        {
            message = this.props.message
                .split('\n')
                .map((line, index) => <span key={index}>{line}<br /></span>);
        }

        return (
            <div className={this.props.classes.root}>
                {backgroundImage}
                <EnvironmentBanner />
                {header}
                <main className={this.props.classes.main}>
                    <Grid
                        alignItems="center"
                        className={this.props.classes.mainContent}
                    >
                        <Hidden mdDown={true}>
                            <GridItem lg={5} />
                        </Hidden>
                        <GridItem xs={12} lg={7}>
                            <Typography className={this.props.classes.title}>
                                {this.props.title}
                            </Typography>
                            <Typography variant="h3" paragraph={true}>
                                {message}
                            </Typography>
                            <Grid>
                                <GridItem>
                                    <Button
                                        icon="fas fa-home"
                                        onClick={this.goHome}
                                    >
                                        {Sys.getTranslation('Home')}
                                    </Button>
                                </GridItem>
                            </Grid>
                        </GridItem>
                    </Grid>
                </main>
                {footer}
            </div>);
    }
}

export default withStyles(styles)(ErrorPageTemplate);
