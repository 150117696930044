import { createStyles, Theme, WithStyles, withStyles }
    from '@material-ui/core/styles';
import * as React from 'react';
import Sys from '../core/Sys';
import Button from '../coreui/Button';
import Icon from '../coreui/Icon';
import Menu, { Menu as MenuBase } from '../coreui/Menu';
import MenuItem from '../coreui/MenuItem';
import { TableChildProps } from '../coreui/Table';
import Typography from '../coreui/Typography';
import { DashboardChildProps, Search } from './DashboardGridControl';

interface Props
{
    propagated: TableChildProps & DashboardChildProps;
}

interface State
{
    buttonRef?: HTMLButtonElement;
    value?: string;
}

const styles = (theme: Theme) => createStyles(
    {
        button:
        {
            '&:focus':
            {
                boxShadow: 'none',
            },

            background: 'none',
            boxShadow: 'none',
            padding: 8,
            textAlign: 'left',
            textTransform: 'none',
        },
    });

export class DashboardCriteria extends
    React.PureComponent<Props & WithStyles<typeof styles>, State>
{
    public static values: Map<string, string> = new Map<string, string>();
    private readonly key: string;

    public constructor(props: Props & WithStyles<typeof styles>)
    {
        super(props);

        this.key = this.props.propagated.parentTable.configProps.contentDataId;

        let value: string = props.propagated.parentDashboard.initialSearch;

        if (DashboardCriteria.values.has(this.key))
        {
            value = DashboardCriteria.values.get(this.key)!;
        }
        else
        {
            DashboardCriteria.values.set(this.key, value);
        }

        this.state = { value };
    }

    private async onChange(value: string)
    {
        if (value === DashboardCriteria.values.get(this.key))
        {
            return;
        }

        MenuBase.closeAll();
        this.setState({ value });

        try
        {
            await this.props.propagated.parentDashboard.search(value);
            DashboardCriteria.values.set(this.key, value);
        }
        catch
        {
            this.setState(
                {
                    value: DashboardCriteria.values.get(this.key)!,
                });
        }
    }

    private openMenu = (event: React.MouseEvent<HTMLButtonElement>) =>
    {
        const button: HTMLButtonElement = event.currentTarget;

        this.setState({ buttonRef: button }, () =>
        {
            MenuBase.open(this.key, button);
        });
    };

    public render()
    {
        const searches: Search[] =
            this.props.propagated.parentDashboard.searches;
        const selected: Search =
            searches.find(s => s.name === this.state.value)!;

        return (
            <div>
                <Button
                    className={this.props.classes.button}
                    onClick={this.openMenu}
                    tabIndex={-1}
                >
                    <div>
                        <Typography variant="caption">
                            {Sys.getTranslation('Filter By')}
                        </Typography>
                        <Typography>
                            {selected.description}
                        </Typography>
                    </div>
                    <Icon
                        icon="fas fa-caret-down"
                        style={{ marginLeft: '.4em' }}
                    />
                </Button>
                <Menu
                    anchorEl={this.state.buttonRef}
                    anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                    getContentAnchorEl={undefined}
                    name={this.key}
                    onExited={() => this.state.buttonRef!.focus()}
                    open={false}
                >
                    {searches.map(l => (
                        <MenuItem
                            children={l.description}
                            key={l.name}
                            onClick={() => this.onChange(l.name)}
                            selected={l.name === this.state.value}
                            value={l.name}
                        />
                    ))}
                </Menu>
            </div>);
    }
}

export default withStyles(styles)(DashboardCriteria);
