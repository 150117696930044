import * as muiInputAdornment from '@material-ui/core/InputAdornment';
import { createStyles, Theme, WithStyles, withStyles }
    from '@material-ui/core/styles';
import * as React from 'react';
import Icon from './Icon';
import IconButton from './IconButton';
import { Colors } from './Presentation';

interface Props extends muiInputAdornment.InputAdornmentProps
{
    children?: React.ReactNode;
    icon?: string;
    iconButton?: object;
    iconColor?: Colors;
}

const styles = (theme: Theme) => createStyles(
    {
        root:
        {
        },
    });

export class InputAdornment extends
    React.PureComponent<Props & WithStyles<typeof styles>>
{
    public render()
    {
        const _props = { ...this.props };

        if ('icon' in _props)
        {
            _props.children =
                <Icon
                    icon={_props.icon}
                    color={_props.iconColor}
                />;

            delete _props.icon;
            delete _props.iconColor;
        }

        if ('iconButton' in _props)
        {
            _props.children = <IconButton {..._props.iconButton} />;

            delete _props.iconButton;
        }

        return <muiInputAdornment.default {..._props}/>;
    }
}

export default withStyles(styles)(InputAdornment);
