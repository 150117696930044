import { autorun, Lambda } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Paper as PaperBase } from '../coreui/Paper';
import Api, { AccessLevel } from './Api';

interface Props
{
    controlledGroupName: string;
    dataId: string;
    isPaneController: boolean;
    name: string;
}

interface WidgetProperties
{
    accessLevel: AccessLevel;
    visiblePaneName: string;
}

@observer
export class SubPaneControl extends
    React.Component<Props>
{
    protected disposeObserve: Lambda;

    protected showSubPane()
    {
        const _props = { ...this.props };

        if (!_props.isPaneController)
        {
            return;
        }

        const widgetProperties: WidgetProperties | null =
            Api.getWidgetProperties(_props) as WidgetProperties | null;

        const rowKey =
            _props['propagated'] ? _props['propagated']['rowKey'] : null;

        if (widgetProperties
            && widgetProperties.accessLevel !== AccessLevel.hidden
            && widgetProperties.visiblePaneName)
        {
            let paneName = widgetProperties.visiblePaneName;
            if (rowKey)
            {
                paneName += `_${rowKey}`;
            }

            PaperBase.show(paneName);
        }
        else
        {
            let groupName = _props.controlledGroupName;
            if (rowKey)
            {
                groupName += `_${rowKey}`;
            }

            PaperBase.hideGroup(groupName);
        }
    }

    public componentDidMount()
    {
        this.disposeObserve = autorun(() =>
        {
            this.showSubPane();
        });
    }

    public componentDidUpdate()
    {
        this.showSubPane();
    }

    public componentWillUnmount()
    {
        if (this.disposeObserve)
        {
            this.disposeObserve();
        }
    }

    public render(): React.ReactNode
    {
        const _props = { ...this.props };
        const widgetProperties: WidgetProperties | null =
            Api.getWidgetProperties(_props) as WidgetProperties | null;

        if (!widgetProperties)
        {
            return null;
        }

        if (widgetProperties.accessLevel === AccessLevel.hidden)
        {
            return null;
        }

        return _props.children;
    }
}

export default SubPaneControl;
