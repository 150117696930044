import { observer } from 'mobx-react';
import * as React from 'react';
import ProcessingMask from '../../coreui/ProcessingMask';
import RequestsStore, { ProcessingInfo } from '../../stores/RequestsStore';

interface Props
{
    enableBackground?: boolean;
}

@observer
export class PageProcessing extends
    React.Component<Props>
{
    public render(): React.ReactNode
    {
        const info: ProcessingInfo = RequestsStore.instance.processingInfo;

        return (
            <ProcessingMask
                disableBackground={info.isNew && !this.props.enableBackground}
                disableRestoreFocus={info.disableRestoreFocus}
                isFixed={true}
                isOpen={info.isProcessing}
                message={info.message}
                shortFade={info.isNew}
                trapFocus={true}
            />
        );
    }
}

export default PageProcessing;
