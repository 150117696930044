import { createStyles, Theme, WithStyles, withStyles }
    from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import * as React from 'react';
import Sys from '../core/Sys';
import { TableChildProps } from '../coreui/Table';
import Typography from '../coreui/Typography';

interface Props
{
    dataId: string;
    name: string;
    propagated: TableChildProps;
}

interface State
{
    selectedRowCount: number;
}

const styles = (theme: Theme) => createStyles(
    {
        root:
        {
        },
    });

@observer
export class GridSelectedCount extends
    React.Component<Props & WithStyles<typeof styles>, State>
{
    public constructor(props: Props & WithStyles<typeof styles>)
    {
        super(props);

        this.state = { selectedRowCount: 0 };
    }

    private updateSelectedRowCount = () =>
    {
        const parentTable = this.props.propagated.parentTable;
        this.setState(
            {
                selectedRowCount: parentTable.selection.getSelectedRows().length,
            });
    };

    public componentDidMount()
    {
        this.props.propagated.parentTable.getApi().addEventListener(
            'selectionChanged',
            this.updateSelectedRowCount);

        this.updateSelectedRowCount();
    }

    public componentWillUnmount()
    {
        const tableApi = this.props.propagated.parentTable.getApi();
        if (tableApi)
        {
            tableApi.removeEventListener(
                'selectionChanged',
                this.updateSelectedRowCount);
        }
    }

    public render()
    {
        return (
            <Typography variant="body2">
                {Sys.getTranslation('{0} Selected', 'DataTable').replace(
                    '{0}', this.state.selectedRowCount.toString())}
            </Typography>);
    }
}

export default withStyles(styles)(GridSelectedCount);
