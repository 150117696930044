import { createStyles, Theme, WithStyles, withStyles }
    from '@material-ui/core/styles';
import * as muiTextField from '@material-ui/core/TextField';
import { observer } from 'mobx-react';
import * as React from 'react';
import Sys from '../core/Sys';
import EditMask, { EditMask as EditMaskBase } from '../coreui/EditMask';
import Icon from '../coreui/Icon';
import InformationBadge from '../coreui/InformationBadge';
import InputAdornment from '../coreui/InputAdornment';
import PasswordField from '../coreui/PasswordField';
import Presentation from '../coreui/Presentation';
import Typography from '../coreui/Typography';
import Api, { AccessLevel } from './Api';

interface Props
{
    dataCase: 'lower' | 'UPPER' | 'Any';
    dataId: string;
    dataSize: number;
    disabledHelpText: string;
    editMask?: string;
    helperText: string;
    isPassword: boolean;
    label: string;
    maxSizeError: string;
    name: string;
}

interface WidgetProperties
{
    accessLevel: AccessLevel;
    businessErrors: string[];
    showAsMandatory: boolean;
    showDisabledHelp: boolean;
}

const styles = (theme: Theme) => createStyles(
    {
        inputLabelRoot:
        {
            width: 'calc(100% - 32px)',
        },
        inputLabelShrink:
        {
            width: 'calc((100% - 32px) * 1.333)',
        },
        root:
        {
        },
        rootWithStartIcon:
        {
            '& label, & input':
            {
                marginLeft: 22,
            },
        },
        startIcon:
        {
            color: theme.palette.text.primary,
            left: 16,
            position: 'absolute',
        },
    });

@observer
export class SLTextEdit extends
    React.Component<Props & WithStyles<typeof styles>>
{
    private startIcon: string;
    protected iconButton: React.Component;
    protected muiProps: muiTextField.TextFieldProps;

    public constructor(props: Props & WithStyles<typeof styles>)
    {
        super(props);

        this.muiProps = {} as muiTextField.FilledTextFieldProps;

        this.muiProps.fullWidth = true;
        this.muiProps.id = `sl-text-edit-${Sys.nextId}`;
        this.muiProps.name = props.name;
        this.muiProps.label = props.label;
        this.muiProps.autoFocus = props['autoFocus'];
        this.muiProps.InputProps = props['InputProps'] || {};
        this.muiProps.inputProps = props['inputProps'];
        this.muiProps.type = 'text';
        this.muiProps.variant = 'filled';
        this.startIcon = props['startIcon'];

        // FUTURE 7.4.1
        // AutoComplete is only used by the logon form. That form should use a
        // different component to render its fields so the SLTextEdit can
        // contain only items that are relevant to the configured widget
        this.muiProps.autoComplete = props['autoComplete'];

        // Required so that when the help is an ul the dom is valid.
        this.muiProps.FormHelperTextProps =
        {
            component: 'div',
            style:
            {
                marginLeft: 16,
                marginRight: 16,
            },
        };
    }

    public render()
    {
        const _props = { ...this.props };
        const widgetProperties =
            Api.getWidgetProperties(_props) as WidgetProperties;
        let result: React.ReactNode = null;
        let endAdornment: React.ReactNode = null;

        if (!widgetProperties)
        {
            return null;
        }

        this.muiProps.className = `${_props.classes.root} `;

        this.muiProps.placeholder = undefined;

        const businessErrors: string[] =
            [...widgetProperties.businessErrors];

        if (Api.setAccessLevel(_props, widgetProperties))
        {
            if (_props['disabled'])
            {
                this.muiProps.disabled = true;
                this.muiProps.required = false;
                this.muiProps.helperText = null;
                this.muiProps.value = '';

                if (widgetProperties.showDisabledHelp)
                {
                    result =
                        <InformationBadge message={_props.disabledHelpText}>
                            <muiTextField.default {...this.muiProps} />
                        </InformationBadge>;
                }
                else
                {
                    result = <muiTextField.default {...this.muiProps} />;
                }
            }
            else if (_props['readOnly'])
            {
                let value = Presentation.getValue(_props);

                value = _props.editMask && value
                    ? EditMaskBase.formatValue(_props.editMask, value) : value;

                result =
                    <div>
                        <Typography
                            component="div"
                            ellipsis
                            style={{ marginBottom: 8 }}
                            variant="caption"
                        >
                            {_props.label}
                        </Typography>
                        <Typography component="div">
                            {value ? value : '-'}
                        </Typography>
                    </div>;
            }
            else if (_props.editMask)
            {
                result = (
                    <EditMask
                        dataCase={_props.dataCase}
                        errors={businessErrors}
                        helperText={_props.helperText}
                        label={_props.label}
                        mask={_props.editMask}
                        name={_props.name}
                        onChange={(value) =>
                        {
                            Sys.clearBusinessErrors(
                                this.props.dataId, this.props.name);
                            Presentation.setValue(this.props, value);
                        }}
                        required={widgetProperties.showAsMandatory}
                        value={Presentation.getValue(_props)}
                        variant="filled"
                    />);
            }
            else if (_props.isPassword)
            {
                result = (
                    <PasswordField
                        autoComplete="new-password"
                        errors={businessErrors}
                        helperText={_props.helperText}
                        label={_props.label}
                        name={_props.name}
                        onChange={(value) =>
                        {
                            Sys.clearBusinessErrors(
                                this.props.dataId, this.props.name);
                            Presentation.setValue(this.props, value);
                        }}
                        required={widgetProperties.showAsMandatory}
                    />);
            }
            else
            {
                this.muiProps.disabled = false;
                this.muiProps.required = widgetProperties.showAsMandatory;
                this.muiProps.style = undefined;

                Presentation.setBinding(this, this.muiProps);

                if (_props.dataSize &&
                    this.muiProps.value &&
                    (this.muiProps.value as string).length > _props.dataSize)
                {
                    businessErrors.push(_props.maxSizeError);
                }

                Api.setHelperText(this, businessErrors, this.muiProps);

                if (this.startIcon)
                {
                    // End adornment is used instead of start since when start
                    // is set the field moves the label above the input
                    endAdornment =
                        <React.Fragment>
                            <Icon
                                classes={{ root: this.props.classes.startIcon }}
                                icon={this.startIcon}
                            />
                            {endAdornment}
                        </React.Fragment>;

                    this.muiProps.className +=
                        `${this.props.classes.rootWithStartIcon} `;
                }

                result = (
                    <muiTextField.default
                        {...this.muiProps}
                        InputLabelProps={
                            {
                                classes:
                                {
                                    root: this.props.classes.inputLabelRoot,
                                    shrink: this.props.classes.inputLabelShrink,
                                },
                            }}
                    />
                );
            }
        }

        if (endAdornment)
        {
            this.muiProps.InputProps!.endAdornment =
                <InputAdornment position="end" children={endAdornment} />;
        }

        return result;
    }
}

export default withStyles(styles)(SLTextEdit);
