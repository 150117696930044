import { createStyles, Theme, WithStyles, withStyles }
    from '@material-ui/core/styles';
import * as React from 'react';
import Button from '../coreui/Button';
import { TableChildProps } from '../coreui/Table';

interface Props
{
    dataId: string;
    name: string;
    propagated: TableChildProps;
}

const styles = (theme: Theme) => createStyles(
    {
        root:
        {
        },
    });

export class TablePrint extends
    React.PureComponent<Props & WithStyles<typeof styles>>
{
    public render()
    {
        const gridApi = this.props.propagated.parentTable.getApi();

        return (
            <Button
                color="default"
                icon="fas fa-print"
                size="small"
                onClick={() =>
                {
                    const currentPageSize = gridApi.paginationGetPageSize();
                    gridApi.paginationSetPageSize(
                        gridApi.paginationGetTotalPages());

                    setTimeout(() =>
                    {
                        window.print();
                        gridApi.paginationSetPageSize(currentPageSize);
                    });
                }}
            />);
    }
}

export default withStyles(styles)(TablePrint);
