import grey from '@material-ui/core/colors/grey';
import { createStyles, Theme, WithStyles, withStyles }
    from '@material-ui/core/styles';
import { GetQuickFilterTextParams } from 'ag-grid-community';
import { observer } from 'mobx-react';
import * as React from 'react';
import Sys from '../../core/Sys';
import Button from '../../coreui/Button';
import { EditMask as EditMaskBase } from '../../coreui/EditMask';
import ErrorBadge from '../../coreui/ErrorBadge';
import Icon from '../../coreui/Icon';
import MultilineTextFieldDialog from '../../coreui/MultilineTextFieldDialog';
import Presentation from '../../coreui/Presentation';
import { TableChildProps } from '../../coreui/Table';
import { CellFocusUtil, FocusCellRendererParams }
    from '../../coreui/table/CellFocusUtil';
import { CellUtil } from '../../coreui/table/CellUtil';
import PaneRow, { RuntimeWidget } from '../../models/PaneRow';
import Api, { AccessLevel } from '../Api';
import { FunctionName } from '../TableSummary';

interface ConfigProperties extends FocusCellRendererParams
{
    dataId: string;
    editMask?: string;
    justification?: 'Left' | 'Right';
    name: string;
    propagated: TableChildProps;
    showEllipsis?: boolean;
}

interface State
{
    isDialogOpen: boolean;
    isErrorBadgeOpen?: boolean;
}

interface RuntimeProperties
{
    accessLevel: AccessLevel;
    bodyText: string[];
}

const styles = (theme: Theme) => createStyles(
    {
        edit:
        {
            color: theme.palette.grey[300],
            textAlign: 'left',
        },
        expandButton:
        {
            backgroundColor: theme.palette.common.white,
        },
    });

@observer
export class TextColumn extends
    React.Component<ConfigProperties & WithStyles<typeof styles>, State>
{
    public static getQuickFilterText(params: GetQuickFilterTextParams): string
    {
        const row = params.data as PaneRow;
        const bodyText = row.getReadOnlyProperties(
            params.colDef.field!).bodyText;

        if (bodyText)
        {
            return bodyText.join(' ');
        }

        return params.value ? params.value : '';
    }

    public static getSummaryValue(
        runtimeData: RuntimeWidget[],
        configProperties: ConfigProperties,
        functionName: FunctionName
        ): string | null
    {
        let result = 0;
        for (const data of runtimeData)
        {
            if (data.value !== null && typeof (data.value) !== 'string')
            {
                throw new Error(`Unexpected data type ${typeof (data.value)}`);
            }

            const runtimeProperties = data.properties as RuntimeProperties;

            const value: string | null = runtimeProperties.bodyText
                ? runtimeProperties.bodyText.join('\n') : data.value;

            if (value)
            {
                result += 1;
            }
        }

        return result.toString();
    }

    public constructor(props: ConfigProperties & WithStyles<typeof styles>)
    {
        super(props);

        this.state = { isDialogOpen: false, isErrorBadgeOpen: false };

        CellFocusUtil.subscribeToCellKeyboardFocusedEvent(
            props, this.onCellFocus, this.onCellBlur);
        props.eGridCell.addEventListener('keydown', this.onCellKeyDown);
    }

    private onCellBlur = (): void =>
    {
        this.setState({ isErrorBadgeOpen: false });
    };

    private onCellFocus = (): void =>
    {
        this.setState({ isErrorBadgeOpen: true });
    };

    private onCellKeyDown = (event: KeyboardEvent): void =>
    {
        if (event.key === 'Enter'
            && !this.props.column.isCellEditable(this.props.node))
        {
            event.preventDefault();
            event.stopPropagation();

            this.setState({ isDialogOpen: true });

            return;
        }

        CellUtil.customizeGridNavigation(event, this.props);
    };

    private onCloseErrorBadge = (): void =>
    {
        this.setState({ isErrorBadgeOpen: false });
    };

    private onDialogCancel = (): void =>
    {
        this.setState({ isDialogOpen: false });
    };

    private onOpenErrorBadge = (): void =>
    {
        this.setState({ isErrorBadgeOpen: true });
    };

    private showDialog = (): void =>
    {
        this.setState({ isDialogOpen: true });
    };

    public componentWillUnmount(): void
    {
        CellFocusUtil.unsubscribeToCellKeyboardFocusedEvent(
            this.props, this.onCellFocus, this.onCellBlur);
        this.props.eGridCell.removeEventListener('keydown', this.onCellKeyDown);
    }

    public render(): React.ReactNode
    {
        const _props = { ...this.props };
        const runtimeProperties =
            Api.getWidgetProperties(_props, _props.data) as RuntimeProperties;

        if (!runtimeProperties)
        {
            return null;
        }

        CellUtil.setReadOnlyAttribute(
            _props.eGridCell,
            runtimeProperties.accessLevel <= AccessLevel.actionable);

        let expandButton: React.ReactNode = null;
        let expandButtonDialog: React.ReactNode = null;
        let value: string | null = runtimeProperties.bodyText
            ? runtimeProperties.bodyText.join('\n') : _props.value;
        const errors: string[] = _props.colDef.cellEditorFramework ?
            _props.colDef.cellEditorFramework.getErrors(
                _props.colDef.cellEditorParams,
                _props.data,
                value) : [];

        value = _props.editMask && value
            ? EditMaskBase.formatValue(_props.editMask, value) : value;

        if (value && !_props.showEllipsis)
        {
            const columnWidth = _props.eGridCell.getBoundingClientRect().width;
            const textWidth: number = Presentation.measureText(value);

            // If its enterable we need to ajust the size for the pencil.
            if ((runtimeProperties.accessLevel >= AccessLevel.enterable
                && textWidth > (columnWidth - 67))
                || (runtimeProperties.accessLevel < AccessLevel.enterable
                    && textWidth > (columnWidth - 50)))
            {
                expandButton = (
                    <Button
                        aria-hidden
                        className={_props.classes.expandButton}
                        customSize="tiny"
                        icon="fas fa-ellipsis-h"
                        onClick={this.showDialog}
                        size="small"
                        tabIndex={-1}
                    />);

                expandButtonDialog = (
                    <MultilineTextFieldDialog
                        label={_props.colDef.headerName}
                        onCancel={this.onDialogCancel}
                        open={this.state.isDialogOpen}
                        readOnly={true}
                        value={value}
                    />);
            }
        }

        let result: React.ReactNode = null;

        if (!_props.justification)
        {
            _props.justification = 'Left';
        }

        if (runtimeProperties.accessLevel >= AccessLevel.enterable)
        {
            const edit: React.ReactNode =
                value ? null :
                    <div className={_props.classes.edit}>
                        {Sys.getTranslation('Edit...', 'DataTable')}
                    </div>;

            if (errors.length)
            {
                result =
                    <ErrorBadge
                        isShort={_props.data.isNew
                            && _props.propagated.parentTable.isDocumentGrid}
                        message={Api.getErrorMessages(errors)}
                        onClose={this.onCloseErrorBadge}
                        onOpen={this.onOpenErrorBadge}
                        open={this.state.isErrorBadgeOpen}
                        suppressEdit={true}
                    >
                        <div
                            style={
                                {
                                    alignItems: 'center',
                                    display: 'flex',
                                    flex: 'auto',
                                    height: '100%',
                                    margin: '0 24px',
                                    overflow: 'hidden',
                                }}
                        >
                            <div className="cx-print">
                                <Icon
                                    fixedWidth
                                    icon="fas fa-pencil"
                                    style={
                                        {
                                            color: grey[300],
                                            fontSize: 12,
                                            marginLeft: 1,
                                            marginRight: '.4em',
                                        }}
                                />
                            </div>
                            <div
                                style={
                                    {
                                        flex: 'auto',
                                        lineHeight: 'normal',
                                        overflow: 'hidden',
                                        textAlign:
                                            Api.getAlignment(_props.justification),
                                        textOverflow:
                                            _props.showEllipsis
                                                ? 'ellipsis' : 'clip',
                                        whiteSpace: 'nowrap',
                                    }}
                            >
                                {value}
                                {edit}
                            </div>
                            {expandButton}
                            {expandButtonDialog}
                        </div>
                    </ErrorBadge>;
            }
            else
            {
                result =
                    <div
                        style={
                            {
                                alignItems: 'center',
                                display: 'flex',
                                height: '100%',
                                margin: '0 24px',
                            }}
                    >
                        <div className="cx-print">
                            <Icon
                                fixedWidth
                                icon="fas fa-pencil"
                                style={
                                    {
                                        color: grey[300],
                                        fontSize: 12,
                                        height: 'auto',
                                        marginRight: '.4em',
                                    }}
                            />
                        </div>
                        <div
                            style={
                                {
                                    flex: 'auto',
                                    lineHeight: 'normal',
                                    overflow: 'hidden',
                                    textAlign:
                                        Api.getAlignment(_props.justification),
                                    textOverflow:
                                        _props.showEllipsis ? 'ellipsis' : 'clip',
                                    whiteSpace: 'nowrap',
                                }}
                        >
                            {value}
                            {edit}
                        </div>
                        {expandButton}
                        {expandButtonDialog}
                    </div>;
            }
        }
        else
        {
            result =
                <div
                    style={
                        {
                            alignItems: 'center',
                            display: 'flex',
                            height: '100%',
                            margin: '0px 24px',
                        }}
                >
                    <div
                        style={
                            {
                                lineHeight: 'normal',
                                overflow: 'hidden',
                                textAlign: Api.getAlignment(_props.justification),
                                textOverflow: _props.showEllipsis
                                    ? 'ellipsis' : 'clip',
                                whiteSpace: 'nowrap',
                                width: '100%',
                            }}
                    >
                        {value}
                    </div>
                    {expandButton}
                    {expandButtonDialog}
                </div>;
        }

        return result;
    }
}

export default withStyles(styles)(TextColumn);
