import * as React from 'react';
import Presentation from '../coreui/Presentation';
import ErrorBoundary from './ErrorBoundary';

interface Props
{
    presentationId?: number;
}

class Panel extends
    React.PureComponent<Props>
{
    public componentDidMount()
    {
        // Only track panels that are rendered for a presentation.
        if (this.props.presentationId)
        {
            Presentation.currentComponent = this;
        }
    }

    public componentWillUnmount()
    {
        if (this.props.presentationId)
        {
            Presentation.currentComponent = undefined;
            Presentation.currentPresentationId = undefined;

            // Wait until presentation is fully unmounted before clearing data.
            // This prevents data changed handlers from firing during unmounting
            const presentationId = this.props.presentationId.toString();
            setTimeout(() => Presentation.clearPaneData(presentationId));
        }
    }

    public render()
    {
        if (this.props.presentationId)
        {
            Presentation.currentPresentationId = this.props.presentationId;
        }

        const result: React.ReactNode = <div>{this.props.children}</div>;

        if (process.env.NODE_ENV !== 'production')
        {
            return <ErrorBoundary>{result}</ErrorBoundary>;
        }

        return result;
    }
}

export default Panel;
