import lightGreen from '@material-ui/core/colors/lightGreen';
import { createStyles, Theme, WithStyles, withStyles }
    from '@material-ui/core/styles';
import * as React from 'react';
import Sys from '../../core/Sys';
import Dialog from '../Dialog';
import DialogContent from '../DialogContent';
import Typography from '../Typography';

interface Props
{
    onClose: () => void;
    open: boolean;
    percent: number;
}

const styles = (theme: Theme) => createStyles(
    {
        innerProgress:
        {
            backgroundColor: lightGreen[500],
            height: 8,
            transitionProperty: 'width',
            transitionTimingFunction: 'linear',
        },
        outerProgress:
        {
            backgroundColor: lightGreen[100],
            height: 8,
            marginTop: 24,
            width: '100%',
        },
    });

export class UploadDialog extends
    React.PureComponent<Props & WithStyles<typeof styles>>
{
    public render()
    {
        const duration: string =
            this.props.percent > 90 && this.props.percent < 100 ? '5s' : '.1s';

        return (
            <Dialog
                fullScreen={false}
                fullWidth
                onClose={this.props.onClose}
                open={this.props.open}
            >
                <DialogContent style={{ paddingBottom: 16 }}>
                    <Typography variant="h3">
                        {Sys.getTranslation('Uploading...')}
                    </Typography>
                    <div className={this.props.classes.outerProgress}>
                        <div
                            className={this.props.classes.innerProgress}
                            style={
                                {
                                    transitionDuration: duration,
                                    width: `${this.props.percent}%`,
                                }}
                        />
                    </div>
                </DialogContent>
            </Dialog>);
    }
}

export default withStyles(styles)(UploadDialog);
