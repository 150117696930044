import * as muiSelect from '@material-ui/core/Select';
import { createStyles, Theme, WithStyles, withStyles }
    from '@material-ui/core/styles';
import * as React from 'react';
import Icon from './Icon';

export interface SelectProps extends muiSelect.SelectProps
{
    hasOptions: boolean;
}

interface State
{
    isOpen?: boolean;
}

const styles = (theme: Theme) => createStyles(
    {
        root:
        {
        },
    });

export class Select extends
    React.PureComponent<SelectProps & WithStyles<typeof styles>, State>
{
    private static openInstance: Select | null;
    protected muiProps: muiSelect.SelectProps;

    public static closeAll()
    {
        if (Select.openInstance)
        {
            Select.openInstance.close(true);
        }
    }

    public constructor(props: SelectProps & WithStyles<typeof styles>)
    {
        super(props);

        this.state = { isOpen: false };

        this.muiProps =
        {
            autoFocus: props.autoFocus,
            autoWidth: true,
            children: props.children,
            displayEmpty: true,
            fullWidth: props.fullWidth,
            IconComponent: () => (
                <Icon
                    icon="fas fa-caret-down"
                    style={
                        {
                            cursor: 'pointer',
                            fontSize: 16,
                            pointerEvents: 'none',
                            position: 'absolute',
                            right: 24,
                            top: 'calc(50% - 8px)',
                        }}
                />),
            MenuProps: props.MenuProps,
            onChange: props.onChange,
            onFocus: props.onFocus,
            onOpen: () => this.open(),
            SelectDisplayProps: props.SelectDisplayProps,
            style: props.style,
        };

        if (props.onClose)
        {
            this.muiProps.onClose = (event) =>
            {
                this.close(false);
                props.onClose!(event);
            };
        }
        else
        {
            this.muiProps.onClose = () => this.close(false);
        }
    }

    public close(disableRestoreFocus: boolean)
    {
        this.muiProps.MenuProps!.disableRestoreFocus =
            disableRestoreFocus;
        Select.openInstance = null;
        this.setState({ isOpen: false });

        if (this.props.onClose)
        {
            this.props.onClose({} as React.ChangeEvent<{}>);
        }
    }

    public open()
    {
        if (this.props.hasOptions)
        {
            Select.openInstance = this;
            this.setState({ isOpen: true });
        }
    }

    public render()
    {
        const _props = { ...this.props };

        this.muiProps.error = _props.error;
        this.muiProps.open = this.state.isOpen;
        this.muiProps.value = _props.value;

        return <muiSelect.default {...this.muiProps} />;
    }
}

export default withStyles(styles)(Select);
