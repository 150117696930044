import { WithWidth } from '@material-ui/core/withWidth';
import * as React from 'react';
import MenuItem, { MenuItemConfig } from './MenuItem';
import { ToolbarOverflowChild } from './ToolbarContainerOverflowSection';

export interface ConfigProperties extends WithWidth
{
    child: { props: MenuItemConfig; type: string };
    dataId: string;
    isLayoutOnly: boolean;
    lg: boolean;
    md: boolean;
    propagated: ToolbarOverflowChild;
    sm: boolean;
    xl: boolean;
    xs: boolean;
}

export class ToolbarContainerOverflowItem extends
    React.PureComponent<ConfigProperties & WithWidth>
{
    public render()
    {
        if (this.props.propagated.hideIcons
            && this.props.child.props['iconName'])
        {
            this.props.child.props['iconName'] = undefined;
        }

        // Hidden via JavaScript instead of CSS to avoid wrapping MenuItem
        // which breaks keyboard navigation
        if (!this.props[this.props.width])
        {
            return null;
        }

        return (
            <MenuItem
                child={this.props.child}
                propagated={this.props.propagated}
            />
        );
    }
}

export default ToolbarContainerOverflowItem;
