import { createStyles, Theme, WithStyles, withStyles }
    from '@material-ui/core/styles';
import * as muiTypography from '@material-ui/core/Typography';
import * as React from 'react';
import Button from '../../coreui/Button';
import Dialog from '../../coreui/Dialog';
import DialogActions from '../../coreui/DialogActions';
import DialogContent from '../../coreui/DialogContent';
import Icon from '../../coreui/Icon';
import Grid from '../../mustangui/Grid';
import GridItem from '../../mustangui/GridItem';
import { ButtonColor } from '../ActionButton';
import Api from '../Api';

interface Props
{
    cancelButtonText: string;
    continueButtonColor: ButtonColor;
    continueButtonIcon?: string;
    continueButtonText: string;
    isOpen: boolean;
    message: string;
    onCancel: () => void;
    onContinue: () => void;
    title: string;
}

const styles = (theme: Theme) => createStyles(
    {
        root:
        {
        },
    });

export class ConfirmationDialog extends
    React.Component<Props & WithStyles<typeof styles>>
{
    private readonly dangerColor: string;

    public constructor(props: Props & WithStyles<typeof styles>)
    {
        super(props);

        this.dangerColor = Api.getSystemColor('danger');
    }

    public render()
    {
        const isDanger: boolean = this.props.continueButtonColor === 'Danger';

        return (
            <Dialog
                fullScreen={false}
                onClose={() => this.props.onCancel()}
                open={this.props.isOpen}
            >
                <DialogContent>
                    <Grid grouping="Closely Related" lg={1} md={1} sm={1} xs={1}>
                        <GridItem xl={1} lg={1} md={1} sm={1} xs={1}>
                            <muiTypography.default
                                style={{
                                    color: isDanger
                                        ? this.dangerColor : undefined,
                                }}
                                variant="h3"
                            >
                                {isDanger ? (
                                    <div
                                        className="fa-layers"
                                        style={{ marginRight: '.4em' }}
                                    >
                                        <Icon
                                            fixedWidth
                                            icon="fas fa-exclamation"
                                            style={{ fontSize: '.6em' }}
                                        />
                                        <Icon
                                            fixedWidth
                                            icon="far fa-octagon"
                                        />
                                    </div>
                                ) : null}
                                {this.props.title}
                            </muiTypography.default>
                        </GridItem>
                        <GridItem xl={1} lg={1} md={1} sm={1} xs={1}>
                            <muiTypography.default>
                                {this.props.message}
                            </muiTypography.default>
                        </GridItem>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        autoFocus
                        color={this.props.continueButtonColor.toLowerCase()}
                        icon={this.props.continueButtonIcon}
                        onClick={() => this.props.onContinue()}
                    >
                        {this.props.continueButtonText}
                    </Button>
                    <Button
                        onClick={() => this.props.onCancel()}
                        style={{ marginLeft: 40 }}
                    >
                        {this.props.cancelButtonText}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(styles)(ConfirmationDialog);
