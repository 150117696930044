import * as muiSnackbarContent from '@material-ui/core/SnackbarContent';
import { createStyles, Theme, WithStyles, withStyles }
    from '@material-ui/core/styles';
import * as React from 'react';

interface Props extends muiSnackbarContent.SnackbarContentProps
{
}

const styles = (theme: Theme) => createStyles(
    {
        message:
        {
            width: 'calc(100% - 48px)',
        },
        root:
        {
        },
    });

export class SnackbarContent extends
    React.PureComponent<Props & WithStyles<typeof styles>>
{
    public render()
    {
        const _props = { ...this.props };

        return <muiSnackbarContent.default {..._props}/>;
    }
}

export default withStyles(styles)(SnackbarContent);
