import Routing from '../core/Routing';
import Sys, { BusinessError, SessionMessage } from '../core/Sys';
import Credentials from '../models/Credentials';
import BaseService from './BaseService';

export interface AccountActivationResponse
{
    succeeded: boolean;
}

export interface CompleteAuthenticationResponse
{
    businessErrors: BusinessError[];
    expirationSeconds?: number;
    sessionMessages?: SessionMessage[];
    userAccountObjectHandle?: string;
}

export interface ExternalAuthenticator
{
    description: string;
    iconName: string;
    providerName: string;
    url: string;
}

export interface NewSessionResponse
{
    expirationSeconds?: number;
    sessionMessages?: SessionMessage[];
    userAccountObjectHandle?: string;
}

export interface PasswordResetResponse
{
    succeeded: boolean;
}

export interface UserInfoResponse
{
    sessionMessages: SessionMessage[];
    userAccountObjectHandle: string;
}

export default class UserService
{
    public static accountObjectHandle: string;

    public static get isAuthenticated(): boolean
    {
        return !!Sys.getCookie(Sys.sessionTokenCookie);
    }

    public static get isGuest(): boolean
    {
        return !!Sys.getCookie(Sys.guestSessionTokenCookie);
    }

    public static async checkSession(): Promise<number>
    {
        return new Promise<number>((resolve, reject) =>
        {
            BaseService.requestObject<number>(
                'User/Session', null, null, null, 'GET'
            ).then((response) =>
            {
                if (response && response > 0)
                {
                    resolve(response);
                }
                else
                {
                    reject(null);
                }
            })
            .catch(() => reject(null));
        });
    }

    public static async completeAuthentication(
        parameters: string | null
        ): Promise<number | undefined>
    {
        return new Promise<number | undefined>((resolve, reject) =>
        {
            const providerName = Sys.getCookie('authenticatorProviderName');

            BaseService.requestObject(
                `User/CompleteAuthentication/${providerName}`,
                null,
                null,
                {
                    parameters,
                },
                'POST',
                true
            ).then((response: CompleteAuthenticationResponse) =>
            {
                if (response.businessErrors.length)
                {
                    if (Sys.settings.useConfiguredAuthentication)
                    {
                        Routing.renderSignIn().then(() =>
                        {
                            Sys.deleteCookie('authenticatorProviderName');
                            Sys.deleteCookie('authenticatorHref');
                            setTimeout(
                                () =>
                                {
                                    Sys.setBusinessErrors(response.businessErrors);
                                },
                                200);
                        });
                    }
                    else
                    {
                        Sys.deleteCookie('authenticatorProviderName');
                        Sys.deleteCookie('authenticatorHref');
                        Routing.goToErrorPage(
                            Sys.getTranslation('Error'),
                            response.businessErrors[0].message);
                    }
                }
                else
                {
                    window.location.replace(
                        Sys.getCookie('authenticatorHref')!);

                    Sys.deleteCookie('authenticatorProviderName');
                    Sys.deleteCookie('authenticatorHref');
                }

                if (response.userAccountObjectHandle)
                {
                    UserService.accountObjectHandle =
                        response.userAccountObjectHandle;
                    Sys.sessionMessages = response.sessionMessages!;
                }

                resolve(response.expirationSeconds);
            }).catch((response) =>
            {
                Sys.deleteCookie('authenticatorProviderName');
                Sys.deleteCookie('authenticatorHref');

                reject(null);
            });
        });
    }

    public static async getExternalAuthenticators(
        ): Promise<ExternalAuthenticator[]>
    {
        return await BaseService.requestObject<ExternalAuthenticator[]>(
            'User/GetExternalAuthenticators', null, null, null, 'GET');
    }

    public static async getUserInfo(): Promise<void>
    {
        return new Promise<void>((resolve, reject) =>
        {
            BaseService.requestObject<UserInfoResponse>(
                'User/UserInfo', null, null, null, 'GET'
            ).then((response) =>
            {
                UserService.accountObjectHandle =
                    response.userAccountObjectHandle;
                Sys.sessionMessages = response.sessionMessages;
                resolve();
            })
            .catch(() => reject());
        });
    }

    public static logonAsGuest(): Promise<number>
    {
        return new Promise<number>((resolve, reject) =>
        {
            BaseService.requestObject<NewSessionResponse>(
                'User/GuestSession'
            ).then((response) =>
            {
                if (response.expirationSeconds
                    && response.expirationSeconds > 0)
                {
                    if (response.userAccountObjectHandle)
                    {
                        UserService.accountObjectHandle =
                            response.userAccountObjectHandle;
                        Sys.sessionMessages = response.sessionMessages!;
                    }

                    resolve(response.expirationSeconds);
                }
                else
                {
                    reject(null);
                }
            })
            .catch(() =>
            {
                reject(null);
            });
        });
    }

    public static async logout(navigate: boolean = true): Promise<void>
    {
        if (navigate)
        {
            Sys.setHash('', false, true);
        }

        await BaseService.request('User/Session', undefined, 'DELETE');

        Sys.clearRequestCache(navigate);
    }

    public static async validateCredentials(
        credentials: Credentials
        ): Promise<boolean>
    {
        return new Promise<boolean>((resolve, reject) =>
        {
            if (!(credentials.UserName && credentials.Password))
            {
                credentials.Message =
                    Sys.getTranslation(
                        'Please provide an Email Address and Password');
                reject(false);
            }
            else
            {
                credentials.Message = null;

                BaseService.requestObject(
                    'User/Session',
                    null,
                    {
                        password: credentials.Password,
                        userName: credentials.UserName,
                    },
                    null,
                    'POST',
                    false
                ).then((response: NewSessionResponse) =>
                {
                    credentials.DisplayName = response['displayName'];

                    if (response.userAccountObjectHandle)
                    {
                        UserService.accountObjectHandle =
                            response.userAccountObjectHandle;
                        Sys.sessionMessages = response.sessionMessages!;
                    }

                    resolve(true);
                })
                .catch((response) =>
                {
                    credentials.Message =
                        BaseService.getRequestExceptionMessage(response);

                    reject(false);
                });
            }
        });
    }
}
