import { createStyles, Theme, WithStyles, withStyles }
    from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import * as React from 'react';
import Button from './Button';
import Dialog from './Dialog';
import DialogActions from './DialogActions';
import DialogContent from './DialogContent';
import TextField from './TextField';

interface Props
{
    getErrors?: (value: string) => string[];
    helperText?: React.ReactNode;
    label: React.ReactNode;
    onAccept?: (value: string) => void;
    onCancel: () => void;
    onExited?: () => void;
    open: boolean;
    readOnly?: boolean;
    value: string;
}

interface State
{
    value: string;
}

const styles = (theme: Theme) => createStyles(
    {
        contentRoot:
        {
            display: 'flex',
            // Set flex such that it agressively allocates vertical space, but
            // then shrinks down to what is available.
            flex: '0 1 100vh',
        },
        contentRootReadOnly:
        {
            display: 'flex',
            overflow: 'hidden',
        },
        contentWrapper:
        {
            display: 'flex',
            flex: '1 1 auto',
            width: 600,
        },
        contentWrapperReadOnly:
        {
            width: 600,
        },
        inputElement:
        {
            height: '100%',
            marginLeft: -16,
            marginRight: -16,
            paddingLeft: 16,
            paddingRight: 16,
        },
        inputRoot:
        {
            flex: '1 1 auto',
            paddingBottom: 22,
        },
        readOnlyContent:
        {
            overflow: 'auto',
        },
        readOnlyRoot:
        {
            height: '100%',
        },
    });

@observer
export class MultilineTextFieldDialog extends
    React.Component<Props & WithStyles<typeof styles>, State>
{
    public constructor(props: Props & WithStyles<typeof styles>)
    {
        super(props);

        this.state = { value: props.value };
    }

    private onAccept = () =>
    {
        if (this.props.onAccept)
        {
            this.props.onAccept(this.state.value);
        }
    };

    private onCancel = () =>
    {
        this.props.onCancel();
    };

    private onExited = () =>
    {
        if (this.props.onExited)
        {
            this.props.onExited();
        }
    };

    private onValueChange = (value: string) =>
    {
        this.setState({ value });
    };

    public componentDidUpdate(prevProps: Props, prevState: State)
    {
        if (!prevProps.open && this.props.open)
        {
            this.setState({ value: this.props.value });
        }
    }

    public render()
    {
        const contentRootClassName = this.props.readOnly
            ? this.props.classes.contentRootReadOnly
            : this.props.classes.contentRoot;

        const contentWrapperClassName = this.props.readOnly
            ? this.props.classes.contentWrapperReadOnly
            : this.props.classes.contentWrapper;

        return (
            <Dialog
                disableBackdropClick={true}
                onClose={this.onCancel}
                onExited={this.onExited}
                open={this.props.open}
            >
                <DialogContent className={contentRootClassName}>
                    <div className={contentWrapperClassName}>
                        <TextField
                            fullWidth={true}
                            getErrors={this.props.getErrors}
                            helperText={this.props.helperText}
                            InputProps={{
                                classes:
                                {
                                    input: this.props.classes.inputElement,
                                    root: this.props.classes.inputRoot,
                                },
                            }}
                            label={this.props.label}
                            multiline={true}
                            onValueChange={this.onValueChange}
                            readOnly={this.props.readOnly}
                            readOnlyProps={{
                                classes:
                                {
                                    content: this.props.classes.readOnlyContent,
                                    root: this.props.classes.readOnlyRoot,
                                },
                            }}
                            rows={1}
                            value={this.state.value}
                            variant="filled"
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    {
                        !this.props.readOnly
                        ? <Button onClick={this.onAccept}>OK</Button>
                        : null
                    }
                    <Button
                        autoFocus={this.props.readOnly}
                        onClick={this.onCancel}
                        style={{ marginLeft: 40 }}
                    >
                        {this.props.readOnly ? 'Close' : 'Cancel'}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(styles)(MultilineTextFieldDialog);
