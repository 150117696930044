import { createStyles, Theme, WithStyles, withStyles }
    from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import * as React from 'react';
import Sys, { SessionMessage } from '../core/Sys';
import Button from '../coreui/Button';
import Icon from '../coreui/Icon';
import SnackbarContent from '../coreui/SnackbarContent';
import Api from './Api';

interface Props
{
}

const styles = (theme: Theme) => createStyles(
    {
        caution:
        {
            '& > svg':
            {
                top: '2px !important',
            },
        },
        content:
        {
            '& > div':
            {
                [theme.breakpoints.up('sm')]: { width: 568 },
                [theme.breakpoints.up('md')]: { width: 912 },
                [theme.breakpoints.up('lg')]: { width: 1200 },
            },
            [theme.breakpoints.only('xs')]:
            {
                marginLeft: -16,
                marginRight: -16,
            },
            [theme.breakpoints.up('sm')]:
            {
                maxWidth: 'none',
            },
            justifyContent: 'center',
        },
        light:
        {
            backgroundColor: theme.palette.grey[100],
            color: theme.palette.grey[800],
        },
        message:
        {
            alignItems: 'center',
            display: 'flex',
        },
        root:
        {
        },
    });

@observer
export class SessionMessageDisplay extends
    React.Component<Props & WithStyles<typeof styles>>
{
    private removeMessage(message: SessionMessage)
    {
        const index = Sys.sessionMessages.indexOf(message);

        if (index > -1)
        {
            Sys.sessionMessages.splice(index, 1);
        }

        const request: XMLHttpRequest = new XMLHttpRequest();

        request.open(
            'DELETE',
            `${Sys.baseUrl}User/SessionMessage/${message.messageId}`);
        request.send();
    }

    public render()
    {
        const _props = { ...this.props };
        const content: React.ReactNode[] = [];
        let action: React.ReactNode = undefined;
        let messageNode: React.ReactNode = null;

        for (const message of Sys.sessionMessages)
        {
            action =
                <Button
                    fab
                    icon="fas fa-times"
                    onClick={() =>
                    {
                        this.removeMessage(message);
                    }}
                    size="small"
                />;

            switch (message.messageType)
            {
                case 'Caution':
                    messageNode =
                        <div className={_props.classes.message}>
                            <div
                                className="fa-layers"
                                style={
                                    {
                                        color: Api.getSystemColor('warning'),
                                        minWidth: '1em',
                                    }}
                            >
                                <Icon
                                    className={_props.classes.caution}
                                    icon="fas fa-exclamation"
                                    style={
                                        {
                                            color: Api.getSystemColor('dark'),
                                            fontSize: '.5em',
                                        }}
                                />
                                <Icon
                                    icon="far fa-triangle"
                                />
                            </div>
                            <div style={{ flex: 'auto', margin: '0px .4em' }}>
                                {message.message}
                            </div>
                            {action}
                        </div>;
                    break;
                case 'Danger':
                    messageNode =
                        <div className={_props.classes.message}>
                            <div
                                className="fa-layers"
                                style={
                                    {
                                        color: Api.getSystemColor('danger'),
                                        minWidth: '1em',
                                    }}
                            >
                                <Icon
                                    icon="fas fa-exclamation"
                                    style={{ fontSize: '.6em' }}
                                />
                                <Icon
                                    icon="far fa-octagon"
                                />
                            </div>
                            <div style={{ flex: 'auto', margin: '0px .4em' }}>
                                {message.message}
                            </div>
                            {action}
                        </div>;
                    break;
                case 'Info':
                    messageNode =
                        <div className={_props.classes.message}>
                            <Icon
                                fixedWidth
                                icon="far fa-info-circle"
                                style={
                                    {
                                        color: Api.getSystemColor('information'),
                                        float: 'left',
                                    }}
                            />
                            <div style={{ flex: 'auto', margin: '0px .4em' }}>
                                {message.message}
                            </div>
                            {action}
                        </div>;
                    break;
                case 'Success':
                    messageNode =
                        <div className={_props.classes.message}>
                            <Icon
                                fixedWidth
                                icon="far fa-check-circle"
                                style={
                                    {
                                        color: Api.getSystemColor('success'),
                                        float: 'left',
                                    }}
                            />
                            <div style={{ flex: 'auto', margin: '0px .4em' }}>
                                {message.message}
                            </div>
                            {action}
                        </div>;
                    break;
                default:
                    throw new Error(
                        `Unknown message type ${message.messageType}`);
            }

            content.push(
                <SnackbarContent
                    className={
                        `${_props.classes.light} ${_props.classes.content}`}
                    key={message.messageId}
                    message={messageNode}
                />);
        }

        delete _props.classes;

        let result: React.ReactNode = null;

        if (Sys.sessionMessages.length)
        {
            result =
                <div>
                    {content}
                </div>;
        }

        return result;
    }
}

export default withStyles(styles)(SessionMessageDisplay);
