import * as muiBadge from '@material-ui/core/Badge';
import * as muiExpansionPanel from '@material-ui/core/ExpansionPanel';
import * as muiExpansionPanelSummary from
    '@material-ui/core/ExpansionPanelSummary';
import { createStyles, Theme, WithStyles, withStyles }
    from '@material-ui/core/styles';
import * as React from 'react';
import Sys from '../core/Sys';
import Api from '../mustangui/Api';
import Icon from './Icon';
import InformationBadge from './InformationBadge';
import Link from './Link';
import Typography from './Typography';

interface Props
{
    badge?: 'contents' | 'errors';
    children: React.ReactNode;
    disabled?: boolean;
    disabledHelpText?: string;
    expanded: boolean;
    label?: React.ReactNode;
    onChange: (event: React.ChangeEvent<{}>, value: string) => void;
    showDisabledHelp?: boolean;
    value: string;
}

const styles = (theme: Theme) => createStyles(
    {
        badge:
        {
            display: 'inline',
            right: -12,
            top: 4,
        },
        errorIcon:
        {
            color: theme.palette.error.main,
            fontSize: '16px',
        },
        expandIcon:
        {
            display: 'flex',
            flex: 'auto',
            justifyContent: 'flex-end',
        },
        label:
        {
            display: 'inline-block',
            fontSize: 14,
            letterSpacing: 1,
            textTransform: 'uppercase',
        },
        labelDisabled:
        {
            color: theme.palette.grey[300],
        },
        link:
        {
            justifyContent: 'flex-start',
            marginTop: 1,
            minHeight: 0,
            minWidth: 0,
            padding: 0,
        },
        linkLabel:
        {
            overflow: 'visible',
        },
        summary:
        {
            [theme.breakpoints.up('xs')]:
            {
                paddingLeft: Sys.settings.baselineGridSize * 4,
                paddingRight: Sys.settings.baselineGridSize * 3,
            },
            [theme.breakpoints.up('md')]:
            {
                paddingLeft: Sys.settings.baselineGridSize * 6,
                paddingRight: Sys.settings.baselineGridSize * 5,
            },
            [theme.breakpoints.up('lg')]:
            {
                paddingLeft: Sys.settings.baselineGridSize * 10,
                paddingRight: Sys.settings.baselineGridSize * 9,
            },
        },
        summaryDisabled:
        {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            opacity: '1 !important' as any,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            pointerEvents: 'auto !important' as any,
        },
    });

export class ExpansionPanel extends
    React.PureComponent<Props & WithStyles<typeof styles>>
{
    private onChange = (event: React.ChangeEvent<{}>) =>
    {
        if (!this.props.disabled)
        {
            this.props.onChange(event, this.props.value);
        }
    };

    private onClickLink = (event: React.MouseEvent) =>
    {
        event.stopPropagation();

        if (!this.props.disabled)
        {
            this.props.onChange(event, this.props.value);
        }
    };

    public render()
    {
        let labelClasses: string = this.props.classes.label;
        if (this.props.disabled)
        {
            labelClasses += ` ${this.props.classes.labelDisabled}`;
        }

        let label = this.props.label;
        if (this.props.disabled)
        {
            if (this.props.showDisabledHelp)
            {
                label = (
                    <InformationBadge
                        classes={{
                            badge: this.props.classes.badge,
                            root: labelClasses,
                        }}
                        fullWidth={false}
                        message={this.props.disabledHelpText}
                    >
                        {label}
                    </InformationBadge>
                );
            }
        }
        else if (this.props.badge)
        {
            label = (
                <muiBadge.default
                    badgeContent={
                        this.props.badge === 'contents' ? (
                            <Icon
                                fixedWidth
                                icon="far fa-exclamation-circle"
                                style={{
                                    color: Api.getSystemColor('information'),
                                    fontSize: 16,
                                }}
                            />
                        ) : (
                            <div className="fa-layers fa-fw">
                                <Icon
                                    className={this.props.classes.errorIcon}
                                    fixedWidth
                                    icon="fas fa-exclamation"
                                    style={{ fontSize: '.8em' }}
                                />
                                <Icon
                                    className={this.props.classes.errorIcon}
                                    fixedWidth
                                    icon="far fa-octagon"
                                />
                            </div>
                        )}
                    classes={{
                        badge: this.props.classes.badge,
                        root: labelClasses,
                    }}
                >
                    {label}
                </muiBadge.default>
            );
        }
        else
        {
            label = <div className={labelClasses}>{label}</div>;
        }

        return (
            <muiExpansionPanel.default
                disabled={this.props.disabled}
                elevation={0}
                expanded={this.props.expanded}
                onChange={this.onChange}
            >
                <muiExpansionPanelSummary.default
                    classes={{
                        disabled: this.props.classes.summaryDisabled,
                        root: this.props.classes.summary,
                    }}
                    tabIndex={-1}
                >
                    <Link
                        classes={{ label: this.props.classes.linkLabel }}
                        className={this.props.classes.link}
                        component="a"
                        disableFocusRipple={this.props.disabled}
                        disableTouchRipple={true}
                        onClick={this.onClickLink}
                        tabIndex={this.props.disabled ? -1 : 0}
                    >
                        <Typography component="span">
                            {label}
                        </Typography>
                    </Link>
                    <Typography className={this.props.classes.expandIcon}>
                        <Icon
                            icon={this.props.expanded
                                ? 'fas fa-minus' : 'fas fa-plus'}
                            fixedWidth
                            style={{
                                height: 24,
                                marginLeft: this.props.badge
                                    || this.props.disabled ? 32 : 8,
                                width: 24,
                            }}
                        />
                    </Typography>
                </muiExpansionPanelSummary.default>
                {this.props.expanded ? this.props.children : null}
            </muiExpansionPanel.default>);
    }
}

export default withStyles(styles)(ExpansionPanel);
