import * as muiDialogContent from '@material-ui/core/DialogContent';
import { createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { CustomTheme } from '../muiTheme';

interface Props extends muiDialogContent.DialogContentProps
{
}

const styles = (theme: CustomTheme) => createStyles(
    {
        root:
        {
            [theme.breakpoints.up('xs')]:
            {
                '&:first-child':
                {
                    paddingTop: theme.dialog.padding.xs,
                },

                paddingBottom: 4,  // Space for button shadow
                paddingLeft: theme.dialog.padding.xs,
                paddingRight: theme.dialog.padding.xs,
                paddingTop: theme.dialog.padding.xs,
            },
            [theme.breakpoints.up('sm')]:
            {
                '&:first-child':
                {
                    paddingTop: theme.dialog.padding.sm,
                },

                paddingLeft: theme.dialog.padding.sm,
                paddingRight: theme.dialog.padding.sm,
                paddingTop: theme.dialog.padding.sm,
            },
        },
    });

export class DialogContent extends
    React.PureComponent<Props & WithStyles<typeof styles>>
{
    public render()
    {
        const _props = { ...this.props };

        return <muiDialogContent.default {..._props}/>;
    }
}

export default withStyles(styles)(DialogContent);
